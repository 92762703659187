import {ModalType} from 'components/ModalsSystem/ModalsSystem'
import {CLOSE_MODAL, OPEN_MODAL, REMOVE_MODAL, RESET_MODAL} from 'constants/actionTypes'
import {OPENED_MODAL} from 'constants/mixpanelConstants'
import type {RootAction} from 'reducers'
import {trackMixpanelEvent} from 'services/mixpanel'

// modalType needs to match the name of the modal component to be opened
// as a string. ModalsSystem will import all modal components and the
// component to render will be looked up there via the modalType string
export function openModal(payload: ModalType): RootAction {
  trackMixpanelEvent(OPENED_MODAL, payload)

  return {
    type: OPEN_MODAL,
    payload
  }
}

// id is optional. If closeModal is called without an id it will trigger
// all modals to close
export function closeModal(id?: number): RootAction {
  return {
    type: CLOSE_MODAL,
    payload: id
  }
}

export function removeModal(id?: number): RootAction {
  return {
    type: REMOVE_MODAL,
    payload: id
  }
}

export function resetModal(): RootAction {
  return {
    type: RESET_MODAL,
    payload: []
  }
}

import easeIcon from 'assets/ease-icon.svg'
import controlIcon from 'assets/control-icon.svg'
import teamIcon from 'assets/team-icon.svg'

export const LANDING_DATA = [
  {
    icon: easeIcon,
    title: 'Order with ease',
    description: 'Sign up to order Clear Capital products with a credit card in just a few minutes.'
  },
  {
    icon: controlIcon,
    title: 'Always be in control',
    description:
      'Check statuses, place holds, and request revisions on all of your valuation orders.'
  },
  {
    icon: teamIcon,
    title: 'Collaborate with your team',
    description: 'Add users to easily place, manage, and share orders together as a team.'
  }
]

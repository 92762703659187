import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {Field, useForm} from 'react-final-form'

import DetailsSection from 'components/OrderForm/DetailsSection'
import {LoanDetailsInputsType} from 'types/productTypes'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import {PurposeOptions} from 'constants/loanDetailsConstants'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredValidationRules} from 'constants/validationRulesConstants'

export interface LoanDetailsProps {
  inputs: LoanDetailsInputsType
}

const LoanDetails = ({inputs}: LoanDetailsProps) => {
  const {getFieldState} = useForm()
  const isFannie = getFieldState('primaryGse')?.value === 'FANNIE'

  const primaryGseOptions = [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Fannie Mae',
      value: 'FANNIE'
    },
    {
      label: 'Freddie Mac',
      value: 'FREDDIE'
    }
  ]

  const {
    primaryGse: shouldShowPrimaryGse,
    loanNumber: shouldShowLoanNumber,
    loanType: shouldShowLoanType,
    loanPurpose: shouldShowLoanPurpose,
    borrower: shouldShowBorrower,
    estimatedClosingDate: shouldShowEstimatedClosingDate,
    caseNumber: shouldShowCaseNumber,
    duCaseNumber: shouldShowDuCaseNumber,
    lpaCaseNumber: shouldShowLpaCaseNumber,
    lenderOrClientName: shouldShowLenderOrClientName,
    investor: shouldShowInvestor
  } = inputs

  return (
    <LoanDetails.Styled>
      <DetailsSection title='Loan Details'>
        {shouldShowPrimaryGse && (
          <div className='field'>
            <label htmlFor='primaryGse' className='label'>
              Primary GSE
            </label>
            <div id='primaryGse' className='input-wrapper'>
              <Field
                name='primaryGse'
                validate={value => getInputValidity(value, requiredValidationRules).errorMsg}
              >
                {({input, meta}) => {
                  return (
                    <Select
                      options={primaryGseOptions}
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      sqaPrefix='primary-gse'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowLoanNumber && (
          <div className='field'>
            <label htmlFor='loanNumber' className='label'>
              Loan Number
            </label>
            <div id='loanNumber' className='input-wrapper'>
              <Field
                name='clientLoanNumber'
                validate={value => getInputValidity(value, requiredValidationRules).errorMsg}
              >
                {({input, meta}) => {
                  return (
                    <TextInput
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      sqaPrefix='loan-number'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowLenderOrClientName && (
          <div className='field'>
            <label htmlFor='lenderOrClientName' className='label'>
              Lender or Client Name
            </label>
            <div id='lenderOrClientName' className='input-wrapper'>
              <Field
                name='clientLenderName'
                validate={value => getInputValidity(value, requiredValidationRules).errorMsg}
              >
                {({input, meta}) => {
                  return (
                    <TextInput
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      sqaPrefix='lender-or-client-name'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowLoanType && (
          <div className='field'>
            <label htmlFor='loanType' className='label'>
              Loan Type
            </label>
            <div id='loanType' className='input-wrapper'>
              {/* this is a readonly input and we don't need to submit it as part of the form, so we're not wrapped in a Field component */}
              <TextInput
                className='text-input-disabled'
                value=''
                disabled={true}
                sqaPrefix='loan-type'
              />
            </div>
          </div>
        )}
        {shouldShowBorrower && (
          <div className='field'>
            <label htmlFor='borrower' className='label'>
              Borrower Name
            </label>
            <div id='borrower' className='input-wrapper'>
              <Field
                name='borrowerName'
                validate={value => getInputValidity(value, requiredValidationRules).errorMsg}
              >
                {({input, meta}) => {
                  return (
                    <TextInput
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      sqaPrefix='borrower-name'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowEstimatedClosingDate && (
          <div className='field'>
            <label htmlFor='estimatedClosingDate' className='label'>
              Estimated Closing Date
            </label>
            <div id='estimatedClosingDate' className='input-wrapper'>
              {/* this is a readonly input and we don't need to submit it as part of the form, so we're not wrapped in a Field component */}
              <TextInput
                className='text-input-disabled'
                value=''
                disabled={true}
                sqaPrefix='estimated-closing-date'
              />
            </div>
          </div>
        )}
        {shouldShowCaseNumber && (
          <div className='field'>
            <label htmlFor='caseNumber' className='label'>
              Case Number (FHA/VA)
            </label>
            <div id='caseNumber' className='input-wrapper'>
              {/* this is a readonly input and we don't need to submit it as part of the form, so we're not wrapped in a Field component */}
              <TextInput
                className='text-input-disabled'
                value=''
                disabled={true}
                sqaPrefix='case-number'
              />
            </div>
          </div>
        )}
        {shouldShowDuCaseNumber && (
          <div className='field'>
            <label htmlFor='duCaseNumber' className='label'>
              DU Case Number
            </label>
            <div id='duCaseNumber' className='input-wrapper'>
              <Field
                name='duCaseNumber'
                initialValue=''
                validate={
                  isFannie
                    ? value => getInputValidity(value, requiredValidationRules).errorMsg
                    : undefined
                }
                // https://github.com/final-form/react-final-form/issues/985#issuecomment-1363973861
                // @ts-expect-error
                data={isFannie ? '1' : '0'}
                key='duCaseNumber'
              >
                {({input, meta}) => {
                  return (
                    <TextInput
                      className='text-input'
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      sqaPrefix='du-case-number'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowLpaCaseNumber && (
          <div className='field'>
            <label htmlFor='lpaCaseNumber' className='label'>
              LPA Key
            </label>
            <div id='lpaCaseNumber' className='input-wrapper'>
              <Field name='lpaCaseNumber' initialValue=''>
                {({input, meta}) => {
                  const isDisabled = meta.pristine && !!input.value
                  return (
                    <TextInput
                      className={classnames('text-input', {'text-input-disabled': isDisabled})}
                      value={input.value}
                      error={meta.error && meta.submitFailed}
                      helperText={meta.submitFailed && meta.error}
                      onChange={input.onChange}
                      disabled={isDisabled}
                      sqaPrefix='lpa-case-number'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowLoanPurpose && (
          <div className='field'>
            <label htmlFor='loanPurpose' className='label'>
              Loan Purpose <span className='label-optional'> - Optional</span>
            </label>
            <div id='purpose' className='input-wrapper'>
              <Field name='purpose'>
                {({input}) => {
                  return (
                    <Select
                      options={PurposeOptions}
                      value={input.value}
                      onChange={input.onChange}
                      sqaPrefix='purpose'
                    />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
        {shouldShowInvestor && (
          <div className='field'>
            <label htmlFor='investor' className='label'>
              Investor <span className='label-optional'> - Optional</span>
            </label>
            <div id='investor' className='input-wrapper'>
              <Field name='investor'>
                {({input}) => {
                  return (
                    <TextInput value={input.value} onChange={input.onChange} sqaPrefix='investor' />
                  )
                }}
              </Field>
            </div>
          </div>
        )}
      </DetailsSection>
    </LoanDetails.Styled>
  )
}

LoanDetails.Styled = styled.div`
  .field {
    display: flex;
    flex-direction: column;

    :not(:last-child) {
      margin-right: 16px;
    }
  }

  .label,
  .input-wrapper {
    width: 211px;
  }

  .label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 4px;

    &-optional {
      font-size: 0.75rem;
      font-weight: 300;
    }
  }

  .text-input {
    &-disabled {
      border: 1px solid ${({theme}) => theme.colors.stone.light};
      border-radius: 6px;
      padding-bottom: 0;
    }
  }
`

export default LoanDetails

import React from 'react'
import styled from 'styled-components'
import {Field, useForm} from 'react-final-form'
import {CircularProgress} from '@material-ui/core'

import TextInput from 'components/TextInput'
import Button from 'components/Button'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredEmailValidationRules} from 'constants/validationRulesConstants'
import {LogInSignUpModalFormContentProps} from 'types/loginSignUpModalTypes'

interface ForgotPasswordProps {
  isSubmitting?: boolean
  handleInputChange: LogInSignUpModalFormContentProps['handleInputChange']
}

const ForgotPassword = ({
  isSubmitting = false,
  handleInputChange
}: ForgotPasswordProps): JSX.Element => {
  const {getFieldState} = useForm()
  const emailField = getFieldState('forgotPasswordEmail')
  const shouldDisableSubmitButton =
    isSubmitting || (emailField?.submitFailed && emailField?.invalid)

  return (
    <ForgotPassword.Styled>
      <p className='instructions'>
        You will receive an email with instructions to reset your password if the account exists.
      </p>
      <Field
        id='email'
        name='forgotPasswordEmail'
        validate={value => getInputValidity(value, requiredEmailValidationRules).errorMsg}
      >
        {({input, meta}) => {
          return (
            <TextInput
              className='text-input'
              label='Email'
              value={input.value}
              error={meta.error && meta.submitFailed}
              helperText={meta.submitFailed && meta.error}
              sqaPrefix='email'
              onChange={handleInputChange(input)}
            />
          )
        }}
      </Field>
      <Button
        type='submit'
        className='button'
        title={
          isSubmitting ? (
            <div className='progress'>
              <CircularProgress className='progress-icon' />
            </div>
          ) : (
            'Submit'
          )
        }
        size='large'
        disabled={shouldDisableSubmitButton}
        sqaPrefix='submit'
      />
    </ForgotPassword.Styled>
  )
}

ForgotPassword.Styled = styled.div`
  .instructions {
    margin: 0 0 24px 0;
  }

  .button {
    width: 100%;
  }

  .form-error {
    color: ${({theme}) => theme.colors.ruby.base};
    margin-top: 0;
  }

  .progress {
    width: 22px;
    height: 22px;

    &-icon {
      color: ${({theme}) => theme.colors.stone.base};
    }
  }
`

export default ForgotPassword

import MUITooltip, {TooltipProps} from '@material-ui/core/Tooltip'
import type {ReactNode} from 'react'
import React, {forwardRef} from 'react'
import styled from 'styled-components/macro'

type Props = {
  text: TooltipProps['title']
  children: ReactNode
  className?: string
} & Omit<TooltipProps, 'title' | 'children'>

const Tooltip = ({text, children, className, ...rest}: Props) => {
  return (
    <MUITooltip className={className} title={text} {...rest}>
      <Tooltip.Content>{children}</Tooltip.Content>
    </MUITooltip>
  )
}

// Required by Material UI for adding listeners
Tooltip.Content = forwardRef<HTMLSpanElement, {children: ReactNode}>(
  (
    props: {
      children: ReactNode
    },
    ref
  ) => {
    return (
      <Styled {...props} ref={ref}>
        {props.children}
      </Styled>
    )
  }
)

const Styled = styled.span`
  border-bottom: 1px dashed;
  cursor: default;
`

;(Tooltip.Content as any).Styled = Styled

export default Tooltip

// Styles
import styled from 'styled-components/macro'

// Core
import React, {useMemo} from 'react'
import {useDispatch} from 'react-redux'

// Components, services, constants
import {openModal} from 'actions/modalActions'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import LinkButton from 'components/LinkButton'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import {getAnalyticsCookieValue, setAnalyticsCookieValue} from 'services/cookieServices'

// 3rd party
import {Form, Field} from 'react-final-form'
import type {FieldInputProps, FormProps} from 'react-final-form'
import {CheckboxProps} from '@material-ui/core'

type Props = {
  open: boolean
  handleClose: () => void
  handleExited: () => void
}

const CookieModal = ({open, handleClose, handleExited}: Props) => {
  const dispatch = useDispatch()
  const initialFormValues = useMemo(
    () => ({
      required: true,
      analytics: getAnalyticsCookieValue()
    }),
    []
  )

  const handleCheckboxChange: (
    input: FieldInputProps<any, HTMLElement>
  ) => CheckboxProps['onChange'] = input => se => {
    input.onChange(se.target.checked)
  }

  const closeModal = () => {
    handleClose()
  }

  const onIgnoreUnsavedWork = (closeUnsavedWorkModal?: () => void) => () => {
    closeUnsavedWorkModal && closeUnsavedWorkModal()
    closeModal()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      closeModal()
    }
  }

  const handleFormSubmit: FormProps['onSubmit'] = (values, form) => {
    const {dirty} = form.getState()
    if (dirty) {
      const {analytics} = values
      setAnalyticsCookieValue(analytics)
      closeModal()
    } else {
      closeModal()
    }
  }

  return (
    <Form
      className='cookie-modal-content'
      // @ts-ignore
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
      render={({form, handleSubmit, dirty, submitting, values}) => (
        <CookieModal.Styled
          className='cookie-modal'
          open={open}
          onClose={handleCloseModalRequest(dirty)}
          onExited={handleExited}
          showCloseButton
        >
          <Modal.Header>
            <div className='modal-title-wrap'>
              <h3>Cookies</h3>
            </div>
          </Modal.Header>
          <Modal.Content>
            <p>
              Select the cookies you want to enable as you use the portal. You cannot deselect the
              required cookies as these are used to make the site work.&nbsp;
              <LinkButton
                variant='tertiary'
                title='Learn more about our policies.'
                target='_blank'
                href='https://www.clearcapital.com/privacy-policy/'
              />
            </p>
            <Field name='required'>
              {({input, meta}) => {
                return (
                  <Checkbox
                    label='Required (Used to authenticate account activity)'
                    checked={Boolean(input.value)}
                    onChange={handleCheckboxChange(input)}
                    disabled
                  />
                )
              }}
            </Field>
            <Field name='analytics'>
              {({input, meta}) => {
                return (
                  <Checkbox
                    label='Analytics (Used to analyze activity to better improve the tool)'
                    checked={Boolean(input.value)}
                    onChange={handleCheckboxChange(input)}
                  />
                )
              }}
            </Field>
          </Modal.Content>
          <Modal.Footer>
            <Button
              variant='secondary'
              title='Cancel'
              size='large'
              onClick={handleCloseModalRequest(dirty)}
            />
            <Button
              variant='primary'
              disabled={submitting}
              title={submitting ? <span>Saving Preferences&hellip;</span> : 'Save Preferences'}
              type='submit'
              size='large'
              onClick={handleSubmit}
            />
          </Modal.Footer>
        </CookieModal.Styled>
      )}
    />
  )
}

CookieModal.Styled = styled(Modal)`
  .portal-MuiDialog-paperFullWidth {
    height: 336px;
  }
  .header {
    height: auto;
    padding-bottom: 0;

    .modal-title-wrap {
      align-self: flex-start;

      h3 {
        margin-bottom: 8px;
      }
    }
    .close-button {
      align-self: flex-start;
      /* account for button padding */
      margin-top: -6px;
    }
  }
  .content {
    p {
      margin: 6px 0 0;
    }
    label {
      margin-top: 18px;
      margin-right: 0;
    }
  }
  .footer {
    padding: 16px 24px;
    min-height: 73px;

    .left {
      align-items: center;
      display: flex;
      margin-right: auto;

      > img {
        margin-left: 4px;
      }
    }
  }
`

export default CookieModal

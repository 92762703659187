import {CircularProgress} from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

export const LoadingSpinner = () => {
  return (
    <LoadingSpinner.Styled>
      <CircularProgress className='progress-icon' />
    </LoadingSpinner.Styled>
  )
}

LoadingSpinner.Styled = styled.div`
  width: 22px;
  height: 22px;

  .progress-icon {
    color: ${({theme}) => theme.colors.stone.base};
  }
`

export default LoadingSpinner

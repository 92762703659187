import {useSelector} from 'react-redux'
import {orderByOpsOrderIdSelector} from 'selectors'
import useProductGroups from './useProductGroups'
import {getProductGroup} from 'services/productsServices'
import {PRICE_OPINION, CDA, INSPECTION, EVALUATION} from 'constants/productCardConstants'
import {getViewStatus} from 'services/orderDetailsServices'
import {STATUS_INFO_MAP} from 'constants/ordersConstants'

export default function useGetOrderDetailStatusInfo() {
  const order = useSelector(orderByOpsOrderIdSelector)
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)
  const viewStatus = getViewStatus(order)
  let result = STATUS_INFO_MAP[viewStatus]

  if (viewStatus === 'ASSIGNED') {
    if (productGroup === CDA) {
      result = {...result, title: 'Appraiser is working on your report'}
    } else if ([EVALUATION, INSPECTION, PRICE_OPINION].includes(productGroup)) {
      result = {...result, title: 'Broker is working on your report'}
    }
  }
  return result
}

// Firefox 1.0+
// @ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined'

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test((window as any).HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(typeof (window as any).safari !== 'undefined' && (window as any).safari?.pushNotification)

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!(document as any).documentMode

// Chrome 1 - 79
export const isChrome =
  !!(window as any).chrome &&
  (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime)

// Edge (based on chromium) detection
export const isEdgeChromium = navigator.userAgent.toLowerCase().indexOf('edg') > -1

// Edge 20+
export const isEdge = (!isIE && !!(window as any).StyleMedia) || isEdgeChromium

import {DocumentUploadType} from 'types/orderTypes'

export type FileExtension = 'bmp' | 'doc' | 'docx' | 'gif' | 'jpeg' | 'pdf' | 'png' | 'txt' | 'xml'

interface OrderDocumentTypeItem {
  label: string
  allowedFileExtensions: FileExtension[]
}

const defaultFileExtensions: FileExtension[] = [
  'bmp',
  'doc',
  'docx',
  'gif',
  'jpeg',
  'pdf',
  'png',
  'txt'
]

export const ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE: Record<
  DocumentUploadType,
  OrderDocumentTypeItem
> = {
  APPRAISAL: {
    label: 'Appraisal',
    allowedFileExtensions: ['pdf', 'xml']
  },
  APPRAISAL_MISMO: {
    label: 'Appraisal XML',
    allowedFileExtensions: ['xml']
  },
  APPRAISAL_PDF: {
    label: 'Appraisal PDF',
    allowedFileExtensions: ['pdf']
  },
  SUPPLEMENTAL: {label: 'Supplemental', allowedFileExtensions: defaultFileExtensions}
}

// Styles
import styled from 'styled-components'

// Core
import React, {Fragment, useState} from 'react'
import type {ReactNode} from 'react'

// Components, services, etc
import {copyToClipboard} from 'services/clipboard'
import IconButton from 'components/IconButton'
import SuccessSnackbar from 'components/SuccessSnackbar'
import Tooltip from 'components/Tooltip'

// 3rd-party
import FileCopyIcon from '@material-ui/icons/FileCopy'

type Props = {
  contentToCopy: string
  tooltipTitle: NonNullable<ReactNode>
}

const ClickToCopyIconButton = ({contentToCopy, tooltipTitle}: Props) => {
  const [snackbarOpened, setSnackbarOpened] = useState(false)
  const toggleSnackbar = () => setSnackbarOpened(snackbarOpened => !snackbarOpened)
  const copySection = () => {
    copyToClipboard(contentToCopy, toggleSnackbar)
  }

  return (
    <Fragment>
      <ClickToCopyIconButton.Styled className='copy-to-clipboard-tooltip' text={tooltipTitle}>
        <IconButton onClick={copySection}>
          <FileCopyIcon />
        </IconButton>
      </ClickToCopyIconButton.Styled>
      {snackbarOpened && <SuccessSnackbar text='Copied!' onClose={toggleSnackbar} />}
    </Fragment>
  )
}

ClickToCopyIconButton.Styled = styled(Tooltip)`
  &.copy-to-clipboard-tooltip {
    border: none;
  }
`

export default ClickToCopyIconButton

import {
  RESET_PROPERTY,
  SET_PROPERTY_LOADED,
  SET_PROPERTY_LOADING,
  SET_PROPERTY_SEARCHING
} from 'constants/actionTypes'
import type {RootAction} from 'reducers'
import {propertyAggregationApi} from 'services/apis'
import type {AppDispatch} from 'services/store'
import type {GetPropertyResponse, PropertyType} from 'types/propertyTypes'
export type QueryAddressType = {
  addressLine1?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  apartmentNumber?: string | null
}

export function getPropertyByAddress({
  addressLine1 = '',
  city = '',
  state = '',
  zip = '',
  apartmentNumber = ''
}: QueryAddressType) {
  if (apartmentNumber) {
    addressLine1 = `${addressLine1?.trim()} #${apartmentNumber?.trim()}`
  }

  // use named function so redux-testkit can test it was called
  return async function getPropertyByAddress(dispatch: AppDispatch) {
    let property
    dispatch({type: SET_PROPERTY_LOADING})
    try {
      if (addressLine1) {
        const addressUrl = `address=${encodeURIComponent(
          addressLine1
        )}&city=${city}&state=${state}&zip=${zip}`

        property = await propertyAggregationApi.get<GetPropertyResponse>(
          `property?${addressUrl}&characteristics_type=PUBLIC_RECORDS`,
          null,
          {credentials: 'omit'}
        )
      }
    } catch (err) {
      property = ({
        address: {
          displayAddressLine1: addressLine1?.trim(),
          city,
          state,
          zip
        }
      } as unknown) as PropertyType
    } finally {
      if (property) {
        dispatch({
          type: SET_PROPERTY_LOADED,
          payload: property
        })
      }
    }

    return property
  }
}

export function setPropertySearching(searching: boolean) {
  return {
    type: SET_PROPERTY_SEARCHING,
    payload: searching
  }
}

export function resetPropertyState(): RootAction {
  return {
    type: RESET_PROPERTY
  }
}

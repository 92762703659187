import colors from './colors'

const styledComponentsTheme = {
  colors: {
    ...colors
  }
}

export default styledComponentsTheme
export type ThemeType = typeof styledComponentsTheme

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof colors
  }
}

import React, {useEffect} from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import {Field, useForm} from 'react-final-form'

import DetailsSection from 'components/OrderForm/DetailsSection'
import {ProductGroupType} from 'types/productTypes'
import RadioGroup from 'components/RadioGroup'
import useProductGroups from 'hooks/useProductGroups'
import {
  getProduct,
  getProductGroupDataByProductGroup,
  isInstantDeliveryProduct
} from 'services/productsServices'
import {sortByObjectKeys} from 'services/sort'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredValidationRules} from 'constants/validationRulesConstants'
import {REQUIRED_DELIVERY_OPTION} from 'constants/validationErrorMessagesConstants'

interface DeliveryOptionsProps {
  productGroup: ProductGroupType
}

const DeliveryOptions = ({productGroup}: DeliveryOptionsProps) => {
  const {productGroups} = useProductGroups()
  const {getFieldState, resetFieldState, change} = useForm()
  const productType = getFieldState('productType')?.value
  const turnTime = getFieldState('turnTime')?.value
  const supportedProductGroups = useSupportedProductGroups()

  const {prices: deliveryOptions} =
    getProduct({
      productId: productType,
      productGroups: supportedProductGroups,
      productGroupId: productGroup
    }) ?? {}

  useEffect(() => {
    if (productType) {
      resetFieldState('turnTime')
      change('turnTime', undefined)
    }
  }, [productType, resetFieldState, change])

  const {usesLocationBasedPricing} =
    getProductGroupDataByProductGroup(productGroup, supportedProductGroups) ?? {}

  const sortedDeliveryOptions = deliveryOptions && sortByObjectKeys(deliveryOptions, 'turnTime')

  const getOptions = () => {
    const shouldRenderPricesUnavailable = productGroups?.addressWarning && usesLocationBasedPricing

    const options = sortedDeliveryOptions?.map(
      ({turnTime = 0, estimatedDueDate = '', estimate: price}, index) => {
        const formattedDueDate = new Date(estimatedDueDate).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })

        let priceText
        if (!shouldRenderPricesUnavailable) {
          priceText = price ? `$${price}` : ''
        } else if (shouldRenderPricesUnavailable && index === 0) {
          priceText =
            "*Address was not found. Pricing to be resolved manually according to lender's contract once order is placed."
        } else {
          priceText = ''
        }

        const businessDays = turnTime / 24
        const isInstantDelivery = isInstantDeliveryProduct(turnTime)

        return {
          value: turnTime,
          label: (
            <div className='radio-label-wrapper'>
              <p className='radio-label-time'>
                <span className='radio-label-time-days'>
                  {isInstantDelivery
                    ? 'Instant Delivery'
                    : `${businessDays} Business ${businessDays > 1 ? 'Days' : 'Day'}`}
                </span>
                {!isInstantDelivery && (
                  <span className='radio-label-time-due-date'>Estimated {formattedDueDate}</span>
                )}
              </p>
              <p
                className={classnames('radio-label-price', {
                  unavailable: shouldRenderPricesUnavailable
                })}
              >
                {priceText}
              </p>
            </div>
          )
        }
      }
    )

    return options ?? []
  }

  const options = getOptions()

  useEffect(() => {
    if (options.length === 1 && turnTime !== options[0].value) {
      resetFieldState('turnTime')
      change('turnTime', options[0].value)
    }
  }, [options, turnTime, resetFieldState, change])

  const shouldShowSubtitle = !options.find(option => option.value === 0)

  return options.length ? (
    <DeliveryOptions.Styled>
      <DetailsSection
        title='Delivery Options'
        subtitle={
          shouldShowSubtitle
            ? 'Please note the cost is reflective of report prioritization and estimated turn times with Clear Capital.'
            : ''
        }
      >
        <Field
          name='turnTime'
          // we can't use type='radio' because that value changes the behavior of the Field
          // component in such a way that our RadioGroup won't get the proper value from input.value
          type='radio-group'
          validate={value =>
            getInputValidity(value, requiredValidationRules).fieldIsValid
              ? undefined
              : REQUIRED_DELIVERY_OPTION
          }
        >
          {({input, meta}) => (
            <RadioGroup
              className='radio-group'
              value={input.value}
              options={options}
              error={meta.error && meta.submitFailed}
              helperText={meta.submitFailed && meta.error}
              onChange={(event, value) => input.onChange(Number(value))}
            />
          )}
        </Field>
      </DetailsSection>
    </DeliveryOptions.Styled>
  ) : null
}

DeliveryOptions.Styled = styled.div`
  .radio-label-wrapper {
    display: flex;
    padding-left: 6px;
  }

  .radio-label-time {
    width: 200px;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    margin: 0;
    flex: 0 0 200px;

    &-due-date {
      color: ${({theme}) => theme.colors.grayscale.gray};
    }
  }

  .radio-label-price {
    font-weight: 500;
    margin: 0;
    text-align: right;
  }

  .unavailable {
    flex: 1 1 auto;
    font-weight: 400;
    margin: 0;
    text-align: left;
  }

  .portal-MuiFormControlLabel-root {
    align-items: flex-start;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export default DeliveryOptions

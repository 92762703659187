// Components, services, etc
import {getAccessToken, oauthApi, updateAuthTokens, updateBasicToken} from 'services/apis'
import store from 'services/store'
import {openModal} from 'actions/modalActions'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import {RESET_APP} from 'constants/actionTypes'
import {casRefreshToken} from 'services/casServices'
import {handleUserAuthenticated} from 'actions/authActions'

// 3rd-party
import jwtDecode, {JwtPayload} from 'jwt-decode'
import moment from 'moment'
import type {GetAccessTokenResponse} from 'types/authenticationTypes'
import {openNotification} from './notificationServices'
import {SESSION_EXPIRED_MESSAGE} from 'constants/notificationMessageConstants'

const EXPIRATION_BUFFER_IN_SECONDS = 30

export const isAccessTokenExpiredOrAboutToExpire = () => {
  const token = getAccessToken()
  return isTokenExpiredOrAboutToExpire(token)
}

export const handleExpiredAccessToken = async () => {
  const {dispatch} = store
  return casRefreshToken()
    .then(res => {
      if (res.accessToken) {
        // update the tokens (this will update both the access token and basic auth token)
        handleUserAuthenticated(dispatch, {
          isUserAuthenticated: true,
          token: res.accessToken,
          idToken: res.idToken
        })
      }
    })
    .catch(() => {
      const isLoginSignUpModalOpen = store.getState().modals.length > 0

      if (!isLoginSignUpModalOpen) {
        dispatch({
          type: RESET_APP
        })

        // clear the locally stored tokens (this will update both the access token and basic auth token)
        updateAuthTokens(undefined)
        openNotification({
          type: 'error',
          text: SESSION_EXPIRED_MESSAGE
        })

        dispatch(
          openModal({
            modalType: LOG_IN_SIGN_UP_MODAL,
            mode: 'logIn',
            mixpanelEventData: {
              initiatedFrom: 'Token Service - Expired Refresh Token'
            }
          })
        )
      }
    })
}

export const handleExpiredBasicToken = async () => {
  return oauthApi
    .post<GetAccessTokenResponse>(
      'token',
      {
        grant_type: 'client_credentials',
        scope: 'property-aggregation-service/public'
      },
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    )
    .then(res => {
      updateBasicToken(res?.access_token)
    })
    .catch(err => {
      console.error(err)
      throw err
    })
}

function isTokenExpiredOrAboutToExpire(token?: string) {
  let tokenIsAboutToExpire = false
  if (token) {
    // get the expiration unix timestamp from our JWT token
    const {exp} = jwtDecode(token) as any
    const expirationMoment = moment(exp)
    const nowMoment = moment(Math.floor(new Date().valueOf() / 1000))
    const diff = expirationMoment.diff(nowMoment)
    tokenIsAboutToExpire = diff < EXPIRATION_BUFFER_IN_SECONDS
  }

  return tokenIsAboutToExpire
}

export const getTokenExpirationTimeInMilliseconds = (token: string = ''): number | undefined => {
  const {exp} = jwtDecode<JwtPayload>(token)
  return exp ? exp * 1000 : exp
}

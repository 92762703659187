import {useEffect, useState} from 'react'
import useGetDocumentByOpsOrderId from './useGetDocumentByOpsOrderId'
import {OrderDocumentTypes} from 'types/orderTypes'

export type DownloadDocumentMap = {
  APPRAISAL: {documentId: string; fileName: string; documentType: OrderDocumentTypes}
  DELIVERABLE_PDF: {documentId: string; fileName: string; documentType: OrderDocumentTypes}
}

export const useDownloadDocumentsMap = () => {
  const [error, setError] = useState('')
  const [data, setData] = useState<DownloadDocumentMap>()
  const {data: documentsData, isLoading} = useGetDocumentByOpsOrderId()

  useEffect(() => {
    try {
      const downloadDocumentMap = {} as DownloadDocumentMap
      const filteredAppraisals = documentsData?.filter(
        ({documentType}) => documentType === 'APPRAISAL_PDF' || documentType === 'APPRAISAL_MISMO'
      )
      const sortedAppraisals = filteredAppraisals?.sort((a, b) => {
        const bUploadDate = b?.uploadDate && new Date(b?.uploadDate).valueOf()
        const aUploadDate = a?.uploadDate && new Date(a?.uploadDate).valueOf()
        if (bUploadDate && aUploadDate) return bUploadDate - aUploadDate
        else return 0
      })
      const appraisalDocumentId = sortedAppraisals?.[0]?.documentId
      const appraisalFileName = sortedAppraisals?.[0]?.fileName
      const appraisalDocumentType = sortedAppraisals?.[0]?.documentType
      if (appraisalDocumentId && appraisalFileName) {
        downloadDocumentMap.APPRAISAL = {
          documentId: appraisalDocumentId,
          fileName: appraisalFileName,
          documentType: appraisalDocumentType
        }
      }
      const deliverablePdf = documentsData?.find(
        ({documentType}) => documentType === 'DELIVERABLE_PDF'
      )
      if (deliverablePdf?.documentId && deliverablePdf?.fileName)
        downloadDocumentMap.DELIVERABLE_PDF = {
          documentId: deliverablePdf?.documentId,
          fileName: deliverablePdf?.fileName,
          documentType: deliverablePdf?.documentType
        }

      setData(downloadDocumentMap)
    } catch (e) {
      setError('get documents failed')
    }
  }, [documentsData])

  return {data, error, loading: isLoading}
}

// Styles
import styled from 'styled-components'

// Core
import React, {Fragment, useRef} from 'react'
import {useSelector} from 'react-redux'

// Components, services, etc
import {createCustomer} from 'actions/customerActions'
import {openModal} from 'actions/modalActions'
import {updateUser} from 'actions/userActions'
import Button from 'components/Button'
import LinkButton from 'components/LinkButton'
import Modal from 'components/Modal'
import TextInput from 'components/TextInput'
import {SUPPORT_CENTER_URL} from 'constants/appConstants'
import {COMPANY_NAME_TAKEN_ERROR_MESSAGE, EMAIL} from 'constants/confirmCodeConstants'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import {
  COMPANY_INFO_FIELDS,
  COMPANY_INFO_TITLE,
  COMPANY_INFO_TOOLTIP,
  CONFIRM_TEXT_CONTINUE,
  EMAIL_DESCRIPTION,
  USER_INFO_FIELDS
} from 'constants/userProfileConstants'
import {
  companyNameValidationRules,
  requiredEmailValidationRules,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {getViewedMarketingWelcomeModalKey} from 'hooks/useWelcomeModal'
import {customerSelector} from 'selectors/customerSelectors'
import {userSelector} from 'selectors/userSelectors'
import {RuleType, validateForm} from 'services/formValidationHelpers'

// 3rd-party
import {UnsavedWorkDialogProps} from 'components/UnsavedWorkDialog/UnsavedWorkDialog'
import UserProfileVerificationFormField from 'components/UserProfileModal/UserProfileVerificationFormField'
import {Field, FieldInputProps, Form, FormProps} from 'react-final-form'
import {useAppDispatch} from 'services/store'
import type {UpdateUserRequestBody} from 'types/authenticationTypes'
import Tooltip from 'components/Tooltip'
import FetchApiError from 'services/FetchApiError'

type Props = {
  open: boolean
  handleClose: () => void
  handleExited: () => void
}

type FormValuesType = {
  firstName?: string
  lastName?: string
  emailAddress?: string
  companyName?: string
  contactFirstName?: string
  contactLastName?: string
  contactEmail?: string
}

type WelcomeModalFormContentProps = {
  handleInputChange: (
    a: FieldInputProps<any, HTMLElement>
  ) => React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  handleSubmit: () => void
  values: FormValuesType
  contactInputType: typeof EMAIL
}

const fieldRulesDictionary = {
  emailAddress: requiredEmailValidationRules
}

const WelcomeModal = ({open, handleClose, handleExited}: Props) => {
  const dispatch = useAppDispatch()
  const userData = useSelector(userSelector)
  const customerData = useSelector(customerSelector)
  const contactInputType = EMAIL
  const existingCompanyNameMap = useRef<{[key: string]: boolean}>({})

  const {email, firstName, lastName} = userData

  const initialFormValues = useRef({
    emailAddress: email,
    companyName: customerData?.name ?? '',
    firstName,
    lastName,
    contactEmail: customerData?.billingContact?.email,
    contactFirstName: customerData?.billingContact?.firstName,
    contactLastName: customerData?.billingContact?.lastName
  } as FormValuesType)

  const closeModal = () => {
    localStorage.setItem(getViewedMarketingWelcomeModalKey(), 'true')
    handleClose()
  }

  const onIgnoreUnsavedWork: UnsavedWorkDialogProps['onConfirm'] = (
    closeUnsavedWorkModal?: () => void
  ) => () => {
    closeUnsavedWorkModal && closeUnsavedWorkModal()
    closeModal()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      closeModal()
    }
  }

  const handleFormSubmit: FormProps<FormValuesType>['onSubmit'] = async (values, form) => {
    const {dirty} = form.getState()
    if (dirty) {
      const {
        firstName,
        lastName,
        companyName,
        contactFirstName,
        contactLastName,
        contactEmail
      } = values

      const userPayload: UpdateUserRequestBody = {
        firstName,
        lastName
      }

      const customerPayload = {
        name: companyName,
        billingContact: {
          firstName: contactFirstName,
          lastName: contactLastName,
          email: contactEmail
        }
      }
      try {
        if (userPayload.phone || userData.firstName || userData.lastName) {
          await dispatch(updateUser(userPayload))
        }

        if (companyName) {
          await dispatch(createCustomer(customerPayload))
        }
        closeModal()
      } catch (err) {
        const {
          mutators: {setFieldTouched}
        } = form

        if (err instanceof FetchApiError && err.response?.status === 409 && companyName) {
          existingCompanyNameMap.current[companyName] = true

          // we need to manually trigger the field as touched so the error
          // will be shown
          setFieldTouched('companyName', true)
        } else {
          // TODO: add toast
        }
      }
    } else {
      closeModal()
    }
  }

  const handleInputChange: WelcomeModalFormContentProps['handleInputChange'] = input => se => {
    input.onChange(se.target.value)
  }

  const validate: FormProps<FormValuesType>['validate'] = values => {
    const filteredRulesDictionary: {
      [key: string]: RuleType[]
    } = fieldRulesDictionary

    if (
      values.contactEmail ||
      values.contactFirstName ||
      values.contactLastName ||
      values.companyName
    ) {
      filteredRulesDictionary.companyName = [
        ...requiredValidationRules,
        ...companyNameValidationRules,
        {
          rule: (companyName: string) => !existingCompanyNameMap.current[companyName],
          errorMsg: COMPANY_NAME_TAKEN_ERROR_MESSAGE
        }
      ]
    }

    return validateForm(filteredRulesDictionary, values)
  }

  const setFieldTouched = (args: [string, boolean], state: {fields: {[x: string]: any}}) => {
    const [name, touched] = args
    const field = state.fields[name]
    if (field) {
      field.touched = !!touched
    }
  }

  return (
    <Form
      initialValues={initialFormValues.current}
      onSubmit={handleFormSubmit}
      validate={validate}
      mutators={{
        setFieldTouched
      }}
      render={({handleSubmit, dirty, submitting, values}) => (
        <Fragment>
          <WelcomeModal.Styled
            size='md'
            open={open}
            onClose={handleCloseModalRequest(dirty)}
            onExited={handleExited}
            showCloseButton
            closeOnBackdropClick={false}
          >
            <Modal.Header>
              <div className='modal-title-wrap'>
                <h3>Welcome to Clear Capital</h3>
                <span className='subtitle'>Tell us a little bit about yourself.</span>
              </div>
            </Modal.Header>
            <Modal.Content>
              <WelcomeModal.FormContent
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                values={values}
                contactInputType={contactInputType}
              />
              <div className='support-section'>
                <div className='left'>
                  <h5>Have any questions?</h5>
                  <p>Find answers to commonly asked questions.</p>
                </div>
                <LinkButton
                  target='_blank'
                  href={SUPPORT_CENTER_URL}
                  variant='secondary'
                  title='Visit Support Center'
                />
              </div>
            </Modal.Content>
            <Modal.Footer>
              <Button
                variant='primary'
                disabled={submitting}
                title={submitting ? <span>Saving Changes&hellip;</span> : CONFIRM_TEXT_CONTINUE}
                type='submit'
                onClick={handleSubmit}
              />
            </Modal.Footer>
          </WelcomeModal.Styled>
        </Fragment>
      )}
    />
  )
}

WelcomeModal.FormContent = ({
  handleInputChange,
  handleSubmit,
  contactInputType,
  values
}: WelcomeModalFormContentProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h4>Personal Information</h4>
        <div className='section-content'>
          {USER_INFO_FIELDS.map(({id, label}) => {
            return (
              <Field name={id} key={id}>
                {({input, meta}) => {
                  return (
                    <TextInput
                      label={label}
                      value={input.value}
                      onChange={handleInputChange(input)}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                    />
                  )
                }}
              </Field>
            )
          })}
          {contactInputType === EMAIL ? (
            <UserProfileVerificationFormField
              label='Email Address'
              buttonTitle='Verify Email'
              verificationType='EMAIL'
              field='emailAddress'
              helperText={EMAIL_DESCRIPTION}
              value={values.emailAddress}
            />
          ) : null}
        </div>
      </section>
      <section className='company-info-section'>
        <Tooltip className='company-info-tooltip' text={COMPANY_INFO_TOOLTIP} placement='right-end'>
          <h4 className='has-subtitle'>{COMPANY_INFO_TITLE}</h4>
        </Tooltip>
        <div className='section-content'>
          {COMPANY_INFO_FIELDS.map(({id, label}) => {
            return (
              <Field name={id} key={id}>
                {({input, meta}) => {
                  return (
                    <TextInput
                      label={label}
                      value={input.value}
                      onChange={handleInputChange(input)}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                    />
                  )
                }}
              </Field>
            )
          })}
        </div>
      </section>
    </form>
  )
}

WelcomeModal.Styled = styled(Modal)`
  .header {
    height: 80px;
    padding-bottom: 0;

    .modal-title-wrap {
      align-self: flex-start;

      h3 {
        margin-bottom: 8px;
      }
      .subtitle {
        display: block;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: ${({theme}) => theme.colors.grayscale.gray};
      }
    }

    .close-button {
      align-self: flex-start;
      /* account for button padding */
      margin-top: -6px;
    }
  }

  .content {
    &.portal-MuiDialogContent-root {
      padding-top: 22px;

      section {
        margin-bottom: 16px;

        .section-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 24px;
          column-gap: 16px;
        }

        h4 {
          font-size: 0.875rem;
          margin-bottom: 16px;
          line-height: 1.125rem;
        }

        &.company-info-section {
          .section-content {
            margin-top: 16px;
          }

          .company-info-tooltip {
            display: inline-block;

            .has-subtitle {
              margin-bottom: 0;
            }
          }
        }

        .portal-MuiFormControl-root {
          padding-bottom: 0;
        }

        .portal-MuiFormHelperText-root {
          position: relative;
          white-space: normal;
        }

        + section {
          margin-top: 32px;
        }
      }
    }

    .support-section {
      display: flex;
      background-color: ${({theme}) => theme.colors.stone.lighter};
      border-radius: 8px;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      p {
        margin: 0;
      }
    }
  }

  .left {
    h5 {
      margin-bottom: 4px;
      font-weight: 500;
    }
  }

  .footer {
    padding: 16px 24px;
  }
`

export default WelcomeModal

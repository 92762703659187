const colors = {
  grayscale: {
    black: '#333333',
    gray: '#747678',
    white: '#ffffff'
  },
  stone: {
    darker: '#2C3133',
    dark: '#4d5659',
    base: '#a9b0b3',
    light: '#D0D4D9',
    lighter: '#f4f5f6',
    lightest: null
  },
  cobalt: {
    darker: '#00345e',
    dark: '#005ba5',
    base: '#0076d6',
    light: '#1c8be8',
    lighter: '#71b6f0',
    lightest: '#D4E8F6'
  },
  gold: {
    darker: '#804906',
    dark: '#cc7811',
    base: '#ffc220',
    light: '#ffd566',
    lighter: '#ffe08f',
    lightest: '#FFF6DA'
  },
  ruby: {
    darker: '#661717',
    dark: '#871e1e',
    base: '#bc0303',
    light: '#d73b3b',
    lighter: '#FE8383',
    lightest: '#FFCDCD'
  },
  citrine: {
    darker: '#802606',
    dark: '#cb470f',
    base: '#f2811c',
    light: '#f49b42',
    lighter: '#f8c07f',
    lightest: '#fbdfbf'
  },
  turquoise: {
    darker: '#005447',
    dark: '#008573',
    base: '#00a39b',
    light: '#3db5ae',
    lighter: '#99d1cc',
    lightest: '#c2e3e0'
  },
  amethyst: {
    darker: '#482157',
    dark: '#733595',
    base: '#9042c4',
    light: '#a86dd0',
    lighter: '#c8a7e1',
    lightest: '#e3d3f0'
  },
  emerald: {
    darker: '#215400',
    dark: '#348600',
    base: '#4bb623',
    light: '#79c447',
    lighter: '#b2da83',
    lightest: '#cdebab'
  }
}

export default colors

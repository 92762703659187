// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment} from 'react'
import type {ReactNode} from 'react'

// Components, services, etc
import Button from 'components/Button'
import Modal from 'components/Modal'
import TextInput from 'components/TextInput'
import {Field, Form} from 'react-final-form'
import {INVITE_USER_FIELDS, ROLE_OPTIONS} from 'constants/inviteUserConstants'
import {kebabCase} from 'lodash'
import Select from 'components/Select'
import {InviteCustomerModalFormTypes, InviteCustomerRequestBody} from 'types/customerTypes'
import {RuleType, validateForm} from 'services/formValidationHelpers'
import {
  emailValidationRules,
  phoneValidationRules,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {customerApi} from 'services/apis'
import {useDispatch, useSelector} from 'react-redux'
import {customerKeySelector} from 'selectors/customerSelectors'
import {openNotification} from 'services/notificationServices'
import {
  INVITE_USER_FAILURE_MESSAGE,
  INVITE_USER_SUCCESS_MESSAGE
} from 'constants/notificationMessageConstants'
import {openModal, resetModal} from 'actions/modalActions'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import useCustomerUsers from 'hooks/useCustomerUsers'
import {INVITE_USER} from 'constants/mixpanelConstants'
import {trackMixpanelEvent} from 'services/mixpanel'

export type InviteUserModalProps = {
  content?: ReactNode
  handleClose: () => void
  handleExited?: () => void
  onCancel?: () => void
  open: boolean
  title?: ReactNode
}

const fieldRulesDictionary: {[key in keyof InviteCustomerModalFormTypes]: RuleType[]} = {
  email: [
    ...emailValidationRules,
    {
      ...requiredValidationRules[0]
    }
  ],
  firstName: requiredValidationRules,
  lastName: requiredValidationRules,
  roles: requiredValidationRules,
  phone: phoneValidationRules
}

const InviteUserModal = ({handleClose, handleExited, onCancel, open}: InviteUserModalProps) => {
  const customerKey = useSelector(customerKeySelector)
  const dispatch = useDispatch()
  const {customerUsersMutate} = useCustomerUsers()

  const handleCloseAndResetModal = () => {
    handleClose()
    dispatch(resetModal())
  }

  const handleFormSubmit = async (values: InviteCustomerModalFormTypes) => {
    try {
      const requestPayload: InviteCustomerRequestBody = {
        ...values,
        roles: values.roles === 'userManager' ? ['user-management'] : []
      }
      await customerApi.post(`customer/${customerKey}/users`, requestPayload)
      trackMixpanelEvent(INVITE_USER)
      openNotification({
        type: 'success',
        text: INVITE_USER_SUCCESS_MESSAGE
      })
      handleCloseAndResetModal()
      customerUsersMutate()
    } catch {
      openNotification({
        type: 'error',
        text: INVITE_USER_FAILURE_MESSAGE
      })
      handleCloseAndResetModal()
    }
  }

  const onIgnoreUnsavedWork: (
    closeModal: () => void
  ) => () => void = closeUnsavedWorkModal => () => {
    handleCloseAndResetModal()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      handleCloseAndResetModal()
    }
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={values => validateForm(fieldRulesDictionary, values)}
      render={({dirty, handleSubmit, submitting, hasValidationErrors, submitFailed}) => {
        const shouldDisableSubmit = (submitFailed && hasValidationErrors) || submitting

        return (
          <InviteUserModal.Styled
            closeOnBackdropClick={false}
            closeOnEscapeKeyDown={true}
            open={open}
            onClose={handleCloseModalRequest(dirty)}
            onExited={handleExited}
            showCloseButton
            size='md'
          >
            <Modal.Header>
              <div>
                <p className='title'>Add User</p>
                <p className='subtitle'> Invitation will be sent to the user's email address.</p>
              </div>
            </Modal.Header>
            <Modal.Content>
              <form>
                <div className='section-content'>
                  {INVITE_USER_FIELDS.map(({id, label, type, required, shouldTrimValue}) => {
                    let inputElement
                    const sqaPrefix = kebabCase(id)
                    const updatedLabel = !required ? (
                      <Fragment>
                        {label}
                        <span className='optional'> - Optional</span>
                      </Fragment>
                    ) : (
                      label
                    )

                    switch (type) {
                      case 'text':
                        inputElement = (
                          <Field name={id} key={id}>
                            {({input, meta}) => {
                              return (
                                <TextInput
                                  label={updatedLabel}
                                  value={input.value}
                                  onChange={ev => {
                                    const value = ev.target.value
                                    const newValue = shouldTrimValue && value ? value.trim() : value
                                    input.onChange(newValue)
                                  }}
                                  error={
                                    (meta.submitError && !meta.dirtySinceLastSubmit) ||
                                    (meta.submitFailed && meta.error)
                                  }
                                  helperText={
                                    meta.submitError && !meta.dirtySinceLastSubmit
                                      ? meta.submitError
                                      : meta.submitFailed && meta.error
                                  }
                                  sqaPrefix={sqaPrefix}
                                />
                              )
                            }}
                          </Field>
                        )
                        break
                      case 'select':
                        inputElement = (
                          <Field name={id} key={id}>
                            {({input, meta}) => {
                              return (
                                <Select
                                  label={updatedLabel}
                                  options={ROLE_OPTIONS}
                                  value={input.value}
                                  onChange={input.onChange}
                                  sqaPrefix={sqaPrefix}
                                  error={
                                    (meta.submitError && !meta.dirtySinceLastSubmit) ||
                                    (meta.submitFailed && meta.error)
                                  }
                                  helperText={
                                    meta.submitError && !meta.dirtySinceLastSubmit
                                      ? meta.submitError
                                      : meta.submitFailed && meta.error
                                  }
                                />
                              )
                            }}
                          </Field>
                        )
                        break
                      default:
                        throw new Error('Unhandle Input Type')
                    }
                    return inputElement
                  })}
                </div>
              </form>
            </Modal.Content>
            <Modal.Footer>
              <Button
                onClick={handleCloseModalRequest(dirty)}
                size='large'
                title='Cancel'
                variant='secondary'
              />
              <Button
                className='button-send-invitation'
                disabled={shouldDisableSubmit}
                isLoading={submitting}
                onClick={handleSubmit}
                size='large'
                title='Send Invitation'
                variant='primary'
              />
            </Modal.Footer>
          </InviteUserModal.Styled>
        )
      }}
    />
  )
}

InviteUserModal.Styled = styled(Modal)`
  .title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0 !important;
    padding-bottom: 4px;
  }

  .subtitle {
    color: ${({theme}) => theme.colors.grayscale.gray};
    font-size: 0.875rem;
    line-height: 0;
    margin-bottom: 0;
  }

  .content {
    margin-top: 24px;
    padding-bottom: 24px !important;

    .section-content {
      column-gap: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 4px;
    }

    .optional {
      color: ${({theme}) => theme.colors.grayscale.gray};
      font-weight: 400;
    }

    .portal-MuiFormControl-root {
      height: 84px;
      padding-bottom: 0;
    }
  }

  .footer {
    padding: 16px 24px;

    .button-send-invitation {
      width: 144px;
    }
  }
`

export default InviteUserModal

// Styles
import 'styles/index.css'

// Polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/string/repeat'
import 'core-js/es/object'
import 'core-js/es/symbol'
import 'core-js/es/symbol/iterator'
import 'core-js/es/map'
import 'core-js/es/set'
import 'regenerator-runtime/runtime'
import 'core-js/stable/url-search-params'
import 'services/polyfills'

// Core
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Components, services, etc
import App from './App'
import AppMain from 'views/AppMain'
import {getHostedEnv} from 'services/apis'
import {beforeSend} from 'services/sentryServices'

// 3rd-party
import WebFont from 'webfontloader'
import {QA, INTEG, PROD} from 'constants/environmentConstants'

const env = getHostedEnv()

if (env && [QA, INTEG, PROD].includes(env)) {
  const replaysOnErrorSampleRate = env === 'production' ? 1.0 : 0.0

  Sentry.init({
    dsn: 'https://ce02b223f60e370ab8b63e11c32af3c1@o236539.ingest.us.sentry.io/4507466523869184',
    environment: env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({levels: ['error']})
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    normalizeDepth: 5,
    beforeSend
  })
}

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <AppMain />,
        </Router>
      </App>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

WebFont.load({
  active: renderApp, // render app after fonts are loaded to avoid incorrect calculation of nav bar size and position
  inactive: renderApp, // render the app if the fonts fail to load
  google: {
    families: ['Inter:400,500,600', 'sans-serif']
  },
  timeout: 1000
})

import {ProductPaymentMethodConfigMap} from 'types/productTypes'

export const PRODUCT_PAYMENT_METHODS_CONFIG_MAP: ProductPaymentMethodConfigMap = {
  INVOICE: {
    id: 'INVOICE',
    title: 'Invoice',
    description: 'Payment will be charged to your monthly invoice.'
  },
  // PAYMENT_LINK: {
  //   id: 'PAYMENT_LINK',
  //   title: 'Borrower Credit Card',
  //   description: 'Payment link will be sent to the borrower.'
  // },
  LENDER_CARD: {
    id: 'LENDER_CARD',
    title: 'Credit Card',
    description: 'Payment information will be provided below.'
  }
}

import styled from 'styled-components'
import {Field} from 'react-final-form'
import React from 'react'

import TextInput from 'components/TextInput'
import {PatternFormat} from 'react-number-format'
import {RuleType, getInputValidity} from 'services/formValidationHelpers'
import {PhoneType} from 'types/orderTypes'
import {getSqaId} from 'services/testingServices'

interface ContactPhoneNumberProps {
  name: string
  type: PhoneType
  label: string
  isOptional: boolean
  validations: RuleType[]
  sqaPrefix?: string
}

const ContactPhoneNumber = ({
  name,
  type,
  label,
  isOptional,
  validations,
  sqaPrefix
}: ContactPhoneNumberProps) => {
  return (
    <ContactPhoneNumber.Styled>
      <div className='field'>
        <label htmlFor={type} className='label' {...getSqaId(label, sqaPrefix)}>
          {label}
          {isOptional && <span className='label-optional'> - Optional</span>}
        </label>
        <div id={type} className='input-wrapper'>
          <Field
            name={`${name}.value`}
            validate={value => {
              return getInputValidity(value, validations).errorMsg
            }}
          >
            {({input, meta}) => (
              <PatternFormat
                {...input}
                className='text-input'
                error={meta.error && meta.submitFailed}
                helperText={meta.submitFailed && meta.error}
                format='###-###-####'
                type='tel'
                customInput={TextInput}
                mask='_'
                sqaPrefix={name}
              />
            )}
          </Field>
        </div>
      </div>
    </ContactPhoneNumber.Styled>
  )
}

ContactPhoneNumber.defaultProps = {
  isOptional: false,
  validations: []
}

ContactPhoneNumber.Styled = styled.div`
  .field {
    width: 155px;
  }

  .label {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin-bottom: 4px;

    &-optional {
      font-weight: 300;
    }
  }
`

export default ContactPhoneNumber

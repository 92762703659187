import {SET_WALLET_ERROR, SET_WALLET_LOADED, SET_WALLET_LOADING} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import type {WalletType} from 'types/orderTypes'

type WalletState = {
  status: ReduxStateStatus
  data: WalletType
  error?: null
}
const initialState: WalletState = {
  status: UNSET,
  data: {},
  error: null
}
type WalletAction =
  | {
      type: typeof SET_WALLET_ERROR
      payload: WalletState['error']
    }
  | {
      type: typeof SET_WALLET_LOADING
    }
  | {
      type: typeof SET_WALLET_LOADED
      payload: WalletState['data']
    }

export default function walletReducer(state = initialState, action: WalletAction): WalletState {
  const {type} = action

  switch (type) {
    case SET_WALLET_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_WALLET_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_WALLET_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    default:
      return state
  }
}

import {OrderStatus, OrderDisplayStatus, OrderAlert} from 'types/orderTypes'
import type {ThemeType} from 'styles/styledComponentsTheme'
import assignedSvg from 'assets/orderStatus/assigned.svg'
import canceledSvg from 'assets/orderStatus/canceled.svg'
import completedSvg from 'assets/orderStatus/completed.svg'
import onHoldSvg from 'assets/orderStatus/onhold.svg'
import orderedSvg from 'assets/orderStatus/ordered.svg'
import reviewingSvg from 'assets/orderStatus/reviewing.svg'
import {CDADisplayStatus} from 'services/orderDetailsServices'

export const ORDERS_PER_PAGE_OPTIONS = [50, 100, 200]
export const ORDERS_PAGING_LOADING = 'ORDERS_PAGING_LOADING'

export const ORDER_DISPLAY_STATUS_MAP: Record<OrderStatus, OrderDisplayStatus | undefined> = {
  ORDERED: 'Order Placed',
  ACCEPTED: 'Order Placed',
  ASSIGNED: undefined,
  REVIEWING: 'Under Review',
  COMPLETED: 'Report Delivered',
  CANCELED: 'Canceled'
}

export const ORDER_DISPLAY_ALERTS_MAP: Record<
  OrderAlert,
  {
    text?: string
    color: Exclude<keyof ThemeType['colors'], 'grayscale'>
  }
> = {
  'Contact Support': {
    text: 'Please contact support regarding order #',
    color: 'ruby'
  },
  'Payment Failed': {
    text:
      'Your payment has been declined. Please verify your payment details and try placing the order again.',
    color: 'ruby'
  },
  'Payment Processing': {
    text:
      'Your payment is currently being processed. The order will be placed once the payment is complete.',
    color: 'amethyst'
  },
  'On Hold': {
    text: '',
    color: 'citrine'
  },
  'Cancelation Requested': {
    text: '',
    color: 'amethyst'
  },
  'Revision Requested': {
    text: '',
    color: 'amethyst'
  }
}

export const STATUS_INFO_MAP: {
  [key in CDADisplayStatus]: {imageUrl: string; title?: string; description?: string}
} = {
  ORDERED: {imageUrl: orderedSvg, title: 'We are processing your order'},
  ACCEPTED: {imageUrl: orderedSvg, title: 'We have received your order'},
  ASSIGNED: {imageUrl: assignedSvg},
  REVIEWING: {imageUrl: reviewingSvg, title: 'We are reviewing your report'},
  COMPLETED: {imageUrl: completedSvg, title: 'Your report is now available for download'},
  CANCELED: {
    imageUrl: canceledSvg,
    title: 'Your order has been canceled',
    description: 'Cancellation details have been emailed to you.'
  },
  CANCEL_REQUESTED: {
    imageUrl: canceledSvg,
    title: 'Your cancellation request is being processed',
    description: 'We will email you when we have an update.'
  },
  ON_HOLD: {
    imageUrl: onHoldSvg,
    title: 'Your order has been placed on hold',
    description:
      'If our support team placed the hold, you would have received an email with further instructions.'
  },
  ON_HOLD_REMOVING: {
    imageUrl: reviewingSvg,
    title: 'We are processing your hold removal request',
    description: 'We will email you when we have an update.'
  }
}

import {getEnv} from 'services/apis'

const STRIPE_PROD_KEY = 'pk_live_XaBSRk6GmXlEb48bC9MtbPe4'
const STRIPE_DEV_KEY = 'pk_test_R1qdOpMZWIAAAO2YdHMi2h57'

export const stripeKey = getStripeApiKey()

function getStripeApiKey() {
  const env = getEnv()
  return env === 'production' ? STRIPE_PROD_KEY : STRIPE_DEV_KEY
}

import {INVALID_ACCESS_CODE_MSG} from 'constants/validationErrorMessagesConstants'

export const PASSWORD_ERROR_MESSAGE_MAP_BY_CODE = {
  CodeMismatchException: INVALID_ACCESS_CODE_MSG,
  PasswordReuseException:
    'Enter a password that has not been used before. Passwords cannot be reused.',
  PasswordContainsCompanyNameException:
    'Try a different password. Passwords cannot contain your company name.',
  PasswordContainsEmailException: 'Try a different password. Passwords cannot contain your email.',
  PasswordContainsPhoneException:
    'Try a different password. Passwords cannot contain your phone number.'
}

export type PasswordRequirement = {text: string; regexp: RegExp}

export const PASSWORD_REQUIREMENTS: PasswordRequirement[] = [
  {
    text: 'One number',
    regexp: /\d+/
  },
  {
    text: 'One uppercase character',
    regexp: /[A-Z]+/
  },
  {
    text: 'One lowercase character',
    regexp: /[a-z]+/
  },
  {
    text: '8 characters minimum',
    regexp: /.{8,}/
  }
]

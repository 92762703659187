import {opsApi} from 'services/apis'
import useSWR from 'swr'
import {OpsOrderId, OpsOrderResponse} from 'types/orderTypes'

export default function useGetOrderData(opsOrderId: OpsOrderId) {
  const {data, error, isLoading, mutate} = useSWR<OpsOrderResponse>(
    opsOrderId ? `orders/${opsOrderId}` : null,
    async () => opsApi.get<OpsOrderResponse>(`orders/${opsOrderId}`),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false
    }
  )

  return {data, isLoading, error, mutate}
}

export const copyToClipboard = async (text: string, sideEffects?: () => void) => {
  // Browser compatibility from https://developer.mozilla.org/
  if (navigator?.clipboard?.writeText) {
    // It is the newest and the best approach, works asynchronously without blocking JS thread
    // Supports Edge 79+, Firefox 63+, Chrome 66+, Safari 13.1+, Opera 53+, IOS Safari 13.4+
    try {
      await navigator.clipboard.writeText(text)
      sideEffects && sideEffects()
    } catch (error) {
      console.warn('Copy to clipboard failed.', error)
    }
  } else if ((window as any)?.clipboardData?.setData) {
    // Old approach, block JS thread.
    // Supports IE 5+, Edge 12+, Firefox 22+, Chrome YES, Safari YES, Opera YES, IOS Safari YES
    ;(window as any).clipboardData.setData('Text', text)
    sideEffects && sideEffects()
  }
}

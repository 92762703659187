import {StripeElementsOptions, StripePaymentElementOptions} from '@stripe/stripe-js'
import colors from 'styles/colors'

export const ELEMENTS_OPTIONS: StripeElementsOptions = {
  mode: 'payment',
  currency: 'usd',
  // the amount value is a placeholder, it must be updated before creating the payment method; otherwise, we will charge an incorrect amount.
  amount: 50,
  paymentMethodCreation: 'manual',
  appearance: {
    variables: {
      borderRadius: '6px',
      colorBackground: colors.grayscale.white,
      colorDanger: colors.ruby.base,
      colorText: colors.grayscale.black,
      fontFamily: 'Inter, Roboto, Arial, "Times New Roman", sans-serif'
    },
    rules: {
      '.Label': {
        fontSize: 'var(--fontSize3Xs)',
        fontWeight: '500',
        lineHeight: 'var(--p-spacing5)',
        marginBottom: '4px'
      },
      '.Input': {
        lineHeight: '38px',
        backgroundColor: colors.grayscale.white,
        border: `1px solid ${colors.stone.light}`,
        boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.1)',
        fontSize: '0.875rem',
        fontWeight: 'var(--fontWeightNormal)',
        padding: '0 20px 0 12px'
      },
      '.Input:hover': {
        borderColor: colors.grayscale.black
      },
      '.Input:focus': {
        borderColor: colors.grayscale.black,
        boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.1)'
      },
      '.Input--invalid': {
        borderColor: colors.ruby.base,
        boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.1)'
      },
      '.Input--invalid:hover': {
        borderColor: colors.ruby.base
      },
      '.Input--invalid:focus': {
        borderColor: colors.ruby.base
      },
      '.Error': {
        fontSize: 'var(--fontSize3Xs)',
        marginTop: '6px',
        lineHeight: '1.25rem'
      }
    }
  }
}

export const PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {
  layout: 'tabs'
}

export const reportSections = [
  'ESTIMATE_SUBJECT',
  'ESTIMATE_AVM',
  'HDI',
  'SUBJECT_PUBLIC_RECORDS',
  'SUBJECT_MLS_DETAILS',
  'SUBJECT_MLS_PHOTOS',
  'COMPS_SALES',
  'COMPS_LISTING',
  'COMPS_SELECTED',
  'COMPS_MAP',
  'ANALYTICS_MARKET_HISTORY',
  'SUBJECT_MLS_HISTORY',
  'SUBJECT_TRANSFER_HISTORY',
  'SUBJECT_OWNER_HISTORY',
  'SUBJECT_TAX_RECORDS'
]

import type {RootState} from 'services/store'
import {modalsMap} from 'components/ModalsSystem/ModalsSystem'
import {createSelector} from 'reselect'

export const modalsSelector = (state: RootState) => state.modals

export const isOrderFormModalOpenSelector = createSelector([modalsSelector], modal =>
  Boolean(
    modal.find(
      ({modalType}: {modalType: keyof typeof modalsMap}) => modalType === 'ORDER_FORM_MODAL'
    )
  )
)

export const isDocumentUploadModalOpenSelector = createSelector([modalsSelector], modal =>
  Boolean(
    modal.find(
      ({modalType}: {modalType: keyof typeof modalsMap}) => modalType === 'DOCUMENT_UPLOAD_MODAL'
    )
  )
)

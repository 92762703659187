import mixpanel from 'mixpanel-browser'
import {COOKIE_PREFERENCE_ANALYTICS} from 'constants/appConstants'
import {handleMixpanelSuperPropertiesAndUser} from 'services/mixpanel'
import {fireCustomEvent} from 'services/customEventService'

export const getAnalyticsCookieValue = () => {
  const analyticsCookieValue = getCookieValue(COOKIE_PREFERENCE_ANALYTICS)
  const preference = analyticsCookieValue === 'true'
  return preference
}

export const setAnalyticsCookieValue = (value: string) => {
  // We want to fire the correct Mixpanel function to control in app tracking
  if (value) {
    // First we opt the user in, then we set the super properties and identify the user
    // (if needed)
    mixpanel.opt_in_tracking()
    handleMixpanelSuperPropertiesAndUser()
  } else {
    mixpanel.opt_out_tracking()
  }

  // We want to fire off custom event for Clear Prop (and any other children apps) to listen for
  fireCustomEvent('updateTrackingPreferences', value)

  // Finally we want to set the value of the cookie so we have access to it on refresh
  setCookieValue(COOKIE_PREFERENCE_ANALYTICS, value)
}

export const getCookieValue = (cookieName: string) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${cookieName}=`))
    // the use of regex here is to ensure that a cookie value which contains
    // an equal sign is not split in the resulting array. e.g. a cookie with
    // a name of 'myCookie' and a value of 'my=value' will be properly split
    // to `['myCookie', 'my=value', '']`, not `['myCookie', 'my', 'value']`.
    ?.split(/=(.*)/)[1]

  return cookieValue ?? null
}

export const setCookieValue = (cookieName: string, value: string) => {
  document.cookie = `${cookieName}=${value}`
}

import {opsApi} from 'services/apis'
import type {
  CreateOrderRequestBody,
  CreateOrderResponse,
  SetCancelRequestBody,
  SetHoldRequestBody,
  SetRevisionRequestBody
} from 'types/orderTypes'
import {openNotification} from './notificationServices'
import {
  CANCEL_ORDER_FAILURE_MESSAGE,
  REQUEST_HOLD_FAILURE_MESSAGE,
  REVISION_REQUEST_FAILURE_MESSAGE
} from 'constants/notificationMessageConstants'

export const createOrderPost = (payload: CreateOrderRequestBody) => {
  return opsApi.post<CreateOrderResponse>('orders', payload)
}

export const requestOnHold = async (opsOrderId: string, payload: SetHoldRequestBody) => {
  try {
    await opsApi.post(`orders/${opsOrderId}/hold`, payload)
    // TODO: toast success
  } catch (e) {
    openNotification({
      type: 'error',
      text: REQUEST_HOLD_FAILURE_MESSAGE
    })
  }
}

export const requestOnHoldRemoval = async (opsOrderId: string) => {
  try {
    await opsApi.delete(`orders/${opsOrderId}/hold`)
    // TODO: toast success
  } catch (e) {
    openNotification({
      type: 'error',
      text: REQUEST_HOLD_FAILURE_MESSAGE
    })
  }
}

export const requestRevision = async (opsOrderId: string, payload: SetRevisionRequestBody) => {
  try {
    await opsApi.post(`orders/${opsOrderId}/revision`, payload)
    // TODO: toast success
  } catch (e) {
    openNotification({
      type: 'error',
      text: REVISION_REQUEST_FAILURE_MESSAGE
    })
  }
}

export const requestCancelOrder = async (opsOrderId: string, payload: SetCancelRequestBody) => {
  try {
    await opsApi.post(`orders/${opsOrderId}/cancel`, payload)
    // TODO: toast success
  } catch (e) {
    openNotification({
      type: 'error',
      text: CANCEL_ORDER_FAILURE_MESSAGE
    })
  }
}

// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'

// Components, Services and Constants
import PropertyDetailsCard from 'components/PropertyDetailsCard'
import Image from 'components/Image'
import {getAddressImageUrl} from 'services/addressServices'

import {resetPropertyState} from 'actions/propertyActions'
import {ERROR} from 'constants/reduxStatuses'
import {
  isPropertyDataLoadingSelector,
  propertyDetailsSelector,
  propertyHeroDataSelector,
  propertyPidSelector,
  propertyStatusSelector
} from 'selectors'

// 3rd-party
import CircularProgress from '@material-ui/core/CircularProgress'
import Address from 'components/Address'
import PropertyProductsList from './PropertyProductsList'
import {usePropertyData} from 'hooks/usePropertyData'
import {resetOrdersByPId} from 'actions/ordersActions'
import useProductGroups from 'hooks/useProductGroups'

const Property = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const propertyDetails = useSelector(propertyDetailsSelector)
  const propertyHeroData = useSelector(propertyHeroDataSelector)
  const {address} = propertyHeroData
  const {isProductGroupsLoading} = useProductGroups()
  const isLoading = useSelector(isPropertyDataLoadingSelector) || isProductGroupsLoading
  const pid = useSelector(propertyPidSelector)
  usePropertyData()

  const propertyStatus = useSelector(propertyStatusSelector)

  useEffect(() => {
    const isError = propertyStatus === ERROR
    if (isError) {
      // we use history.replace here to ensure that clicking the Go Back button on the
      // NotFound will actually take the user to the previous route and avoids the continuous
      // loop that occurs if we use history.push instead
      history.replace({
        pathname: location.pathname,
        state: {
          error: true
        }
      })
    }
  }, [dispatch, history, location, propertyStatus])

  // Clear property slice of redux when navigating away from page. This ensures
  // that any errors that might have come as a result of a bad pid are cleared
  // and good pids will still load after the fact.
  useEffect(() => {
    return () => {
      dispatch(resetPropertyState())
      dispatch(resetOrdersByPId())
    }
  }, [dispatch])

  return (
    <Property.Styled $isLoading={isLoading}>
      {isLoading ? (
        <div className='loading-wrap'>
          {/* inner div needed for IE11 */}
          <div>
            <CircularProgress size={100} />
          </div>
        </div>
      ) : (
        <Fragment>
          <div className='property-overview'>
            <div className='left-column column'>
              <Address address={address} sqaPrefix='header' className='property-overview-address' />
              <div className='image-list-view-wrapper'>
                <Image
                  className='property-overview-image'
                  src={getAddressImageUrl(address)}
                  width='587'
                  height='280'
                />
                <PropertyDetailsCard selectorData={propertyDetails} className='details-card' />
              </div>
            </div>
            <div className='right-column column'>
              <PropertyProductsList pid={pid} />
            </div>
          </div>
        </Fragment>
      )}
    </Property.Styled>
  )
}

Property.Styled = styled.div<{$isLoading?: boolean}>`
  height: ${({$isLoading}) => ($isLoading ? '100%' : 'auto')};
  display: ${({$isLoading}) => ($isLoading ? 'flex' : 'block')};
  flex: none;
  flex-direction: column;

  .property-overview {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0px 40px;
    max-width: 1360px;
    margin: 0 auto;

    .left-column {
      padding-left: 40px;
      padding-right: 40px;
    }

    .right-column {
      background: white;
      padding-left: 40px;
      padding-right: 40px;
    }

    .column {
      padding-top: 40px;
    }

    .card {
      padding: 0px 24px;
    }

    .image-list-view-wrapper {
      width: 100%;
      height: 280px;
    }

    .property-overview-address {
      font-weight: 500;
      font-size: 1.375rem;
      margin-bottom: 20px;
      display: block;
    }

    .property-overview-image {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      border-radius: 6px;
    }
  }

  .image-cover.fallback {
    background-size: 128px;
    background-repeat: no-repeat;
    background-color: ${({theme}) => theme.colors.stone.light};
    border-radius: 6px;
    position: relative;
    :after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 99.23%);
      border-radius: 6px;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  @media (max-width: 1364px) {
    .property-overview {
      width: 900px;
      .image-list-view-wrapper {
        width: 100%;
      }
      .product-card {
        justify-content: flex-start;
      }
    }
  }
`

export default Property

import {MOBILE_NUMBER_INPUT_PROPS} from './userProfileConstants'

export const INVITE_USER_FIELDS = [
  {
    id: 'firstName',
    type: 'text',
    label: 'First Name',
    required: true
  },
  {
    id: 'lastName',
    type: 'text',
    label: 'Last Name',
    required: true
  },
  {
    id: 'email',
    type: 'text',
    label: 'Email',
    required: true,
    shouldTrimValue: true
  },
  {
    id: 'roles',
    type: 'select',
    label: 'Role',
    required: true
  },
  {
    id: 'phone',
    type: 'text',
    label: 'Mobile Number',
    InputProps: MOBILE_NUMBER_INPUT_PROPS,
    required: false
  },
  {
    id: 'jobTitle',
    type: 'text',
    label: 'Job Title',
    required: false
  }
]

export const ROLE_OPTIONS = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'User Manager',
    value: 'userManager'
  }
]

import {LANDING_DATA} from 'constants/landingConstants'
import React from 'react'
import styled from 'styled-components'

type LandingDataType = typeof LANDING_DATA[number]

export const LandingDescription = (data: LandingDataType) => {
  const {title, description, icon} = data

  return (
    <LandingDescription.Styled>
      <img className='icon' src={icon} alt='' />
      <h3 className='title'>{title}</h3>
      <p>{description}</p>
    </LandingDescription.Styled>
  )
}

LandingDescription.Styled = styled.div`
  width: 216px;

  .icon {
    margin-bottom: 16px;
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
  }
`

export default LandingDescription

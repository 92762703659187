import React, {Fragment, useState} from 'react'
import {openModal} from 'actions/modalActions'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {INVITE_USER_MODAL} from 'constants/modalsConstants'
import {useAppDispatch} from 'services/store'
import styled from 'styled-components'
import useCustomerUsers from 'hooks/useCustomerUsers'
import {CircularProgress} from '@material-ui/core'
import CustomerUsersTable from 'components/CustomerUsersTable'
import classNames from 'classnames'
import Checkbox from 'components/Checkbox'
import TextInput from 'components/TextInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import {CustomerUsersTableFilters} from 'types/customerTypes'
import {trackMixpanelEvent} from 'services/mixpanel'
import {CUSTOMER_USER_CLICKED_FILTER} from 'constants/mixpanelConstants'

const Admin = () => {
  const dispatch = useAppDispatch()
  const {isCustomerUsersLoading} = useCustomerUsers()
  const [filters, setFilters] = useState<CustomerUsersTableFilters>({
    search: '',
    enabled: true,
    pending: true,
    disabled: false
  })

  const handleInviteUser = () => () => {
    dispatch(
      openModal({
        modalType: INVITE_USER_MODAL
      })
    )
  }

  return (
    <Admin.Styled>
      <div className='title-wrapper'>
        <div className='title'>Users</div>
        <Button
          variant='primary'
          title={
            <Fragment>
              <Icon icon='add_circle' className='add-circle-icon' />
              <span>Invite User</span>
            </Fragment>
          }
          onClick={handleInviteUser()}
        />
      </div>
      <div className='toolbar'>
        <div className='search'>
          <TextInput
            className='search-input'
            placeholder='Search Users'
            sqaPrefix='search-users'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon icon='search' className='search-input-icon' />
                </InputAdornment>
              )
            }}
            value={filters.search}
            onChange={ev =>
              setFilters({
                ...filters,
                search: ev.target.value
              })
            }
          />
        </div>
        <div className='filters'>
          <Checkbox
            label='Enabled'
            checked={filters.enabled}
            sqaPrefix='filter-enabled'
            onChange={() => {
              setFilters({
                ...filters,
                enabled: !filters.enabled
              })

              trackMixpanelEvent(CUSTOMER_USER_CLICKED_FILTER, {
                Component: 'Enabled'
              })
            }}
          />
          <Checkbox
            label='Pending'
            checked={filters.pending}
            sqaPrefix='filter-pending'
            onChange={() => {
              setFilters({
                ...filters,
                pending: !filters.pending
              })

              trackMixpanelEvent(CUSTOMER_USER_CLICKED_FILTER, {
                Component: 'Pending'
              })
            }}
          />
          <Checkbox
            label='Disabled'
            checked={filters.disabled}
            sqaPrefix='filter-disabled'
            onChange={() => {
              setFilters({
                ...filters,
                disabled: !filters.disabled
              })

              trackMixpanelEvent(CUSTOMER_USER_CLICKED_FILTER, {
                Component: 'Disabled'
              })
            }}
          />
        </div>
      </div>
      <div
        className={classNames('customer-users-wrapper', {
          'customer-users-wrapper-loading': isCustomerUsersLoading
        })}
      >
        {isCustomerUsersLoading ? (
          <CircularProgress className='progress-icon' />
        ) : (
          <CustomerUsersTable sqaPrefix='customer-users' filters={filters} />
        )}
      </div>
    </Admin.Styled>
  )
}

Admin.Styled = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  min-height: 506px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  padding: 40px;

  .title-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.125rem;
      font-weight: 500;
    }

    .add-circle-icon {
      color: ${({theme}) => theme.colors.grayscale.black};
      font-size: 1rem;
      margin-right: 4px;
      width: 16px;
    }
  }

  .toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .search {
      flex-grow: 1;

      &-input {
        max-width: 500px;
        padding-bottom: 0;

        &-icon {
          color: ${({theme}) => theme.colors.grayscale.gray};
          font-size: 1rem;
        }
      }
    }
  }

  .customer-users-wrapper {
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.colors.grayscale.white};
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    overflow: hidden;

    &-loading {
      align-items: center;
    }
  }
`

export default Admin

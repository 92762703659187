// Styles
import styled from 'styled-components/macro'

// Core
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'

// Components, services, etc
import OrdersTable from 'components/OrdersTable'
import {setInitialOrders, resetOrdersState} from 'actions/ordersActions'
import NoOrdersIcon from 'assets/empty_orders.svg'
import {useOrders} from 'hooks/useOrders'
import {resetPropertyState} from 'actions/propertyActions'
import {resetDocumentsState} from 'actions/documentsActions'

// 3rd Party
import CircularProgress from '@material-ui/core/CircularProgress'

const Orders = () => {
  const dispatch = useDispatch()
  const {data, isValidating: isOrdersDataLoading, error} = useOrders()
  const noOrders = data?.Items?.length === 0 || Boolean(error)

  useEffect(() => {
    if (data || error) {
      dispatch(setInitialOrders(data, error))
    }
    return () => {
      dispatch(resetOrdersState())
    }
  }, [data, dispatch, error])

  useEffect(() => {
    return () => {
      dispatch(resetPropertyState())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetDocumentsState())
    }
  })

  return (
    <Orders.Styled>
      <h3 className='title'>All Orders</h3>
      {isOrdersDataLoading ? (
        <div className='loading-wrap'>
          {/* inner div needed for IE11 */}
          <div>
            <CircularProgress size={100} />
          </div>
        </div>
      ) : noOrders ? (
        <div className='empty-orders'>
          <img src={NoOrdersIcon} alt='No Orders Icon' />
          <h3>No Recent Orders</h3>
        </div>
      ) : (
        <OrdersTable sqaPrefix='orders-table' />
      )}
    </Orders.Styled>
  )
}

Orders.Styled = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 16px 40px 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 117px);
  min-height: 500px;

  .title {
    margin: 24px 0;
  }

  .loading-wrap,
  .empty-orders {
    background-color: ${({theme}) => theme.colors.grayscale.white};
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  }

  .loading-wrap {
    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 48px;
    }
  }
  .empty-orders {
    h3 {
      margin-bottom: 0;
    }
  }
`

export default Orders

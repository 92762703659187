import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Field, useFormState} from 'react-final-form'

import Select from 'components/Select'
import IconButton from 'components/IconButton'
import {validateRequiredDocumentType} from 'services/formValidatorServices'
import type {OrderFormDocument, ProductGroupType} from 'types/productTypes'
import {uploadDocument} from 'services/documentsServices'
import {useFieldArray} from 'react-final-form-arrays'
import {openNotification} from 'services/notificationServices'
import {CircularProgress} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {
  DELETE_UPLOADED_DOCUMENTS,
  SET_DOCUMENT_UPLOAD_FORM_SUBMITTED,
  SET_UPLOADED_DOCUMENTS
} from 'constants/actionTypes'
import {closeModal} from 'actions/modalActions'
import {resetDocumentsState} from 'actions/documentsActions'
import {DOCUMENT_UPLOAD_FAILURE_MESSAGE} from 'constants/notificationMessageConstants'
import {
  isDocumentUploadModalOpenSelector,
  modalsSelector,
  orderByOpsOrderIdSelector
} from 'selectors'
import {trackMixpanelEvent} from 'services/mixpanel'
import {DELETE_DOCUMENT} from 'constants/mixpanelConstants'
import {getMixpanelModalValue} from 'services/mixpanelServices'
import {DocumentTypeOptions} from 'types/orderTypes'
import {AURA} from 'constants/productCardConstants'
import {
  documentsUploadedSelector,
  documentsUploadFormSubmittedSelector
} from 'selectors/documentsSelectors'

interface AssignDocumentTypeProps {
  fieldName: string
  options: DocumentTypeOptions
  index: number
  productGroup?: ProductGroupType
}

const AssignDocumentType = ({fieldName, options, index, productGroup}: AssignDocumentTypeProps) => {
  const {errors} = useFormState()
  const dispatch = useDispatch()
  const {status} = useSelector(orderByOpsOrderIdSelector)
  const {fields} = useFieldArray('customerDocumentList')
  const {opsOrderId} = useSelector(orderByOpsOrderIdSelector)
  const uploadedDocuments = useSelector(documentsUploadedSelector)
  const isLoading = uploadedDocuments[index]?.loading
  const modals = useSelector(modalsSelector)
  const {values} = useFormState()
  const documentType = fields.value?.[index]?.documentType
  const file = fields.value?.[index]?.file
  const {name, size} = file
  const isCompleted = status?.orderStatus === 'COMPLETED'
  const isAURAProductGroup = productGroup === AURA
  const hasNoProductSelected = !isCompleted ? !values.productType : false
  const shouldValidateFileExtension = !(isAURAProductGroup && !isCompleted && hasNoProductSelected)
  const isDocumentUploadModalOpen = useSelector(isDocumentUploadModalOpenSelector)
  const hasDocumentUploadFormSubmitted = useSelector(documentsUploadFormSubmittedSelector)
  const canUploadToS3 =
    !(isAURAProductGroup && hasNoProductSelected) &&
    // the below logic should be removed once PP-1451 ticket gets completed
    !(isDocumentUploadModalOpen && !hasDocumentUploadFormSubmitted)

  useEffect(() => {
    if (options.length === 1) {
      fields.update(index, {
        ...fields.value[index],
        documentType: options[0].value
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      let response
      try {
        if (!errors?.customerDocumentList?.[index]?.documentType && canUploadToS3) {
          dispatch({
            type: SET_UPLOADED_DOCUMENTS,
            payload: {
              name,
              loading: true
            }
          })
          response = await uploadDocument(file, documentType, opsOrderId)
          dispatch({
            type: SET_UPLOADED_DOCUMENTS,
            payload: {
              name,
              storageKey: response?.storageKey,
              loading: false
            }
          })
        }
      } catch (err) {
        dispatch(closeModal())
        dispatch(resetDocumentsState())
        openNotification({
          type: 'error',
          text: DOCUMENT_UPLOAD_FAILURE_MESSAGE
        })
        fields.remove(index)
      }
    }
    documentType && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType, hasDocumentUploadFormSubmitted, canUploadToS3])

  // the below logic should be handled as part of DocumentUploadModal ideally once PP-1451 ticket gets
  // completed
  useEffect(() => {
    if (
      isDocumentUploadModalOpen &&
      hasDocumentUploadFormSubmitted &&
      uploadedDocuments.length &&
      uploadedDocuments.every(document => document.storageKey)
    ) {
      dispatch({type: SET_DOCUMENT_UPLOAD_FORM_SUBMITTED, payload: false})
      if (productGroup !== AURA) dispatch(closeModal())
    }
  }, [
    dispatch,
    hasDocumentUploadFormSubmitted,
    isDocumentUploadModalOpen,
    opsOrderId,
    productGroup,
    uploadedDocuments
  ])

  const onDeleteFileClickHandler = (event: React.MouseEvent) => {
    event.preventDefault()
    trackMixpanelEvent(DELETE_DOCUMENT, {modal: getMixpanelModalValue(modals[0].modalType)})
    dispatch({
      type: DELETE_UPLOADED_DOCUMENTS,
      payload: name
    })
    fields.remove(index)
  }

  return (
    <AssignDocumentType.Styled>
      <div className='col-left'>
        <p className='label'>Document Type</p>
        <Field<OrderFormDocument['documentType']>
          name={`${fieldName}.documentType`}
          validate={value =>
            validateRequiredDocumentType(value, name, size, shouldValidateFileExtension)
          }
        >
          {({input, meta}) => {
            const shouldDisableDocumentTypeSelect =
              options.length === 1 && options[0].value === input.value

            return (
              <Select
                className='document-type'
                disabled={shouldDisableDocumentTypeSelect}
                error={meta.error && meta.submitFailed}
                helperText={meta.submitFailed && meta.error}
                onChange={input.onChange}
                options={options}
                value={input.value ?? ''}
              />
            )
          }}
        </Field>
      </div>
      <div className='col-center'>
        <p className='label'>Filename</p>
        <div className='filename-wrapper'>
          <div className='filename'>{file?.name}</div>
          {isLoading && <CircularProgress />}
        </div>
      </div>
      <div className='col-right'>
        <IconButton
          className='delete-button'
          disabled={isLoading}
          icon='delete'
          onClick={onDeleteFileClickHandler}
          size='small'
        />
      </div>
    </AssignDocumentType.Styled>
  )
}

AssignDocumentType.Styled = styled.div`
  width: 100%;
  display: flex;

  .col-left {
    width: 200px;
    flex: 0 0 auto;

    .document-type {
      .portal-MuiOutlinedInput-root.Mui-disabled {
        border: 1px solid ${({theme}) => theme.colors.stone.light};
      }
    }
  }

  .col-center {
    flex: 1 1 auto;
    padding-left: 18px;

    .filename-wrapper {
      display: flex;
      padding-top: 10px;
      .filename {
        font-size: 0.75rem;
        line-height: 1.375rem;
        margin-right: 14px;
      }
      .portal-MuiCircularProgress-root {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  .col-right {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    .delete-button {
      width: 24px;
      height: 24px;
    }
  }

  .label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0 0 4px;
  }

  /* override the padding set in our MUI theme overrides for all inputs in this form */
  .portal-MuiFormControl-root {
    padding-bottom: 0;
  }

  .portal-MuiFormControl-root:has(.portal-MuiFormHelperText-root) {
    padding-bottom: 27px;
  }

  .portal-MuiFormHelperText-root {
    overflow: visible;
  }
`

export default AssignDocumentType

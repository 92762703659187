// Styles
import muiTheme from 'styles/muiTheme'
import styledComponentsTheme from 'styles/styledComponentsTheme'

// Core
import React, {PropsWithChildren} from 'react'

// Components, services, etc
import store from 'services/store'
import {stripeKey} from 'services/stripeServices'

// 3rd-party
import {
  createGenerateClassName,
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {Provider} from 'react-redux'
import {ThemeProvider as ScThemeProvider} from 'styled-components/macro'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {ELEMENTS_OPTIONS} from 'constants/paymentMethodConstants'

const stripePromise = loadStripe(stripeKey)

const generateClassName = createGenerateClassName({
  productionPrefix: 'portal',
  seed: 'portal'
})

function App({children}: PropsWithChildren<{}>) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <ScThemeProvider theme={styledComponentsTheme}>
          <Provider store={store}>
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
              <div className='app'>{children}</div>
            </Elements>
          </Provider>
        </ScThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default App

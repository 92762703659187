export function loadjscssfile(filename: string, filetype: string) {
  let fileref
  if (filetype === 'js') {
    fileref = document.createElement('script')
    fileref.setAttribute('type', 'text/javascript')
    fileref.setAttribute('src', filename)
    fileref.setAttribute('async', 'async')
    fileref.setAttribute('defer', 'defer')
    fileref.setAttribute('id', 'clear-prop-js')
  } else if (filetype === 'css') {
    fileref = document.createElement('link')
    fileref.setAttribute('rel', 'stylesheet')
    fileref.setAttribute('type', 'text/css')
    fileref.setAttribute('href', filename)
    fileref.setAttribute('id', 'clear-prop-css')
  }

  if (fileref) {
    document.getElementsByTagName('head')[0].appendChild(fileref)
  }
}

export function unloadJsCssFiles(filename: string, filetype: string) {
  const targetelement = filetype === 'js' ? 'script' : filetype === 'css' ? 'link' : 'none'

  const targetattr = filetype === 'js' ? 'src' : filetype === 'css' ? 'href' : 'none'

  const allElements = document.getElementsByTagName(targetelement)

  for (let i = allElements.length; i >= 0; i--) {
    if (
      allElements[i] &&
      allElements[i].getAttribute(targetattr) !== null &&
      allElements[i].getAttribute(targetattr)?.indexOf(filename) !== -1
    ) {
      allElements[i].parentNode?.removeChild(allElements[i])
    }
  }
}

import {ERROR} from 'constants/reduxStatuses'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {orderByOpsOrderIdStatusSelector} from 'selectors'

const useHandleOrderByOpsOrderIdStatusError = () => {
  const orderByOpsOrderIdStatus = useSelector(orderByOpsOrderIdStatusSelector)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (orderByOpsOrderIdStatus === ERROR) {
      // we use history.replace here to ensure that clicking the Go Back button on the
      // NotFound will actually take the user to the previous route and avoids the continuous
      // loop that occurs if we use history.push instead
      history.replace({
        pathname: location.pathname,
        state: {
          error: true
        }
      })
    }
  }, [history, location.pathname, orderByOpsOrderIdStatus])

  return null
}

export default useHandleOrderByOpsOrderIdStatusError

import {
  RESET_ORDERS_BY_OPS_ORDER_ID,
  SET_CURRENT_PAGE,
  SET_ORDERS_BY_ADDRESS_ERROR,
  SET_ORDERS_BY_ADDRESS_LOADED,
  SET_ORDERS_BY_ADDRESS_LOADING,
  RESET_ORDERS_BY_ADDRESS,
  SET_ORDERS_ERROR,
  SET_ORDERS_LOADED,
  SET_ORDERS_LOADING,
  SET_ORDERS_PAGING_LOADING,
  SET_ORDER_BY_OPS_ORDER_ID_ERROR,
  SET_ORDER_BY_OPS_ORDER_ID_LOADED,
  SET_ORDER_BY_OPS_ORDER_ID_LOADING,
  SET_ORDER_PAGINATION_HISTORY,
  SET_PER_PAGE,
  UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID,
  UPDATE_ORDER_REVISION_BY_OPS_ORDER_ID
} from 'constants/actionTypes'
import {ORDERS_PAGING_LOADING} from 'constants/ordersConstants'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import type {OpsOrderListType, OpsOrderType, OrderStatus, Revision} from 'types/orderTypes'

// This file exports multiple orders related reducers. It breaks our common convention
// of having a one-to-one-to-one relationship between reducers, actions and selectors files.
// This is necessary however to keep our common reducer shape and to still maintain a basic
// file structure. There will be a reducer for each slice of orders related data, but we will
// still only have one action, one reducer and one selectors file for all of them.

type OrderListState = {
  status: ReduxStateStatus | typeof ORDERS_PAGING_LOADING
  data: OpsOrderListType & {ordersPerPage?: number}
  error?: null
}

type OrderListData =
  | {
      type: typeof SET_ORDERS_ERROR
      payload: OrderListState['error']
    }
  | {
      type: typeof SET_ORDERS_LOADING | typeof SET_ORDERS_PAGING_LOADING
    }
  | {
      type: typeof SET_ORDERS_LOADED
      payload: OrderListState['data']
    }
  | {
      type: typeof SET_PER_PAGE
      payload: number
    }

const initialState = {
  status: UNSET,
  data: {},
  error: null
} as const

const initialOrdersState: OrderListState = {
  ...initialState,
  data: {
    Items: [],
    // change it to undefiend?
    Count: null as any,
    // change it to undefiend?
    ScannedCount: null as any,
    ordersPerPage: Number(localStorage.getItem('ordersPerPagePreference')) || 50
  }
}

export function ordersReducer(state = initialOrdersState, action: OrderListData): OrderListState {
  const {type} = action

  switch (type) {
    case SET_ORDERS_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_ORDERS_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_ORDERS_PAGING_LOADING:
      return {
        ...state,
        status: ORDERS_PAGING_LOADING
      }
    case SET_ORDERS_LOADED:
      return {
        ...state,
        status: LOADED,
        data: {
          ...action.payload,
          ordersPerPage: Number(localStorage.getItem('ordersPerPagePreference')) || 50
        },
        error: null
      }
    case SET_PER_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          ordersPerPage: action.payload
        }
      }
    default:
      return state
  }
}

type OrderPIDState = {
  status: ReduxStateStatus
  data: OpsOrderListType
  error?: null
}
type OrderPIDData =
  | {
      type: typeof SET_ORDERS_BY_ADDRESS_ERROR
      payload: OrderPIDState['error']
    }
  | {
      type: typeof SET_ORDERS_BY_ADDRESS_LOADING
    }
  | {
      type: typeof SET_ORDERS_BY_ADDRESS_LOADED
      payload: OrderPIDState['data']
    }
  | {
      type: typeof RESET_ORDERS_BY_ADDRESS
    }

const initialOrdersByPidState: OrderPIDState = {
  ...initialState,
  data: {
    Items: [],
    Count: null as any,
    ScannedCount: null as any
  }
}

export function ordersByAddressReducer(
  state = initialOrdersByPidState,
  action: OrderPIDData
): OrderPIDState {
  const {type} = action

  switch (type) {
    case SET_ORDERS_BY_ADDRESS_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_ORDERS_BY_ADDRESS_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_ORDERS_BY_ADDRESS_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    case RESET_ORDERS_BY_ADDRESS:
      return initialOrdersByPidState
    default:
      return state
  }
}

type OrderByOpsOrderIdState = {
  status: ReduxStateStatus
  data: OpsOrderType
  error?: null
}
type OrderByOpsOrderIdData =
  | {
      type: typeof SET_ORDER_BY_OPS_ORDER_ID_ERROR
      payload: OrderByOpsOrderIdState['error']
    }
  | {
      type: typeof SET_ORDER_BY_OPS_ORDER_ID_LOADING
    }
  | {
      type: typeof SET_ORDER_BY_OPS_ORDER_ID_LOADED
      payload: OrderByOpsOrderIdState['data']
    }
  | {
      type: typeof RESET_ORDERS_BY_OPS_ORDER_ID
    }
  | {
      type: typeof UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID
      payload: OrderStatus
    }
  | {type: typeof UPDATE_ORDER_REVISION_BY_OPS_ORDER_ID; payload: Revision}
const initialOrderByOpsOrderIdState: OrderByOpsOrderIdState = {
  ...initialState,
  data: {} as OpsOrderType
}

export function orderByOpsOrderIdReducer(
  state = initialOrderByOpsOrderIdState,
  action: OrderByOpsOrderIdData
): OrderByOpsOrderIdState {
  const {type} = action

  switch (type) {
    case RESET_ORDERS_BY_OPS_ORDER_ID:
      return initialOrderByOpsOrderIdState
    case SET_ORDER_BY_OPS_ORDER_ID_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_ORDER_BY_OPS_ORDER_ID_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_ORDER_BY_OPS_ORDER_ID_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    case UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID:
      return {
        ...state,
        data: {
          ...state.data,
          status: {
            ...state.data.status,
            orderStatus: action.payload
          }
        }
      }
    case UPDATE_ORDER_REVISION_BY_OPS_ORDER_ID:
      return {
        ...state,
        data: {
          ...state.data,
          status: {
            ...state.data.status,
            revision: action.payload
          }
        }
      }
    default:
      return state
  }
}

type HistoryState = {
  currentPage: number
  history: {
    [key: number]: {created?: string; opsOrderId?: string}
  }
}
type HistoryData =
  | {
      type: typeof SET_CURRENT_PAGE
      payload: number
    }
  | {
      type: typeof SET_ORDER_PAGINATION_HISTORY
      payload: {
        page: number
        created?: string
        opsOrderId?: string
      }
    }
  | {
      type: typeof SET_ORDER_BY_OPS_ORDER_ID_LOADED
      payload: OrderByOpsOrderIdState['data']
    }
  | {
      type: typeof SET_PER_PAGE | typeof SET_ORDERS_LOADING
    }

const initialHistoryState: HistoryState = {
  currentPage: 1,
  history: {
    1: {created: undefined, opsOrderId: undefined}
  }
}

export function ordersHistoryReducer(
  state = initialHistoryState,
  action: HistoryData
): HistoryState {
  const {type} = action

  switch (type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case SET_ORDER_PAGINATION_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.page]: {
            created: action.payload.created,
            opsOrderId: action.payload.opsOrderId
          }
        }
      }
    // Reset the history state when ordersPerPage updated,
    // a new order is ordered from the quick order form,
    // or user pages back to page 1 (to account for new Orders when Customer support added)
    case SET_PER_PAGE:
    case SET_ORDERS_LOADING:
      return {
        ...initialHistoryState
      }
    default:
      return state
  }
}

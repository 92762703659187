// Styles
import styled from 'styled-components/macro'

// Core
import React, {ChangeEventHandler, useState} from 'react'

// Components, services, etc
import {answerAuthChallenge} from 'actions/authActions'
import {updateUser} from 'actions/userActions'
import Button from 'components/Button'
import ConfirmCodeModalContent from 'components/ConfirmCodeModalContent'
import IconButton from 'components/IconButton'
import Modal from 'components/Modal'
import TextInput from 'components/TextInput'
import {
  EMAIL,
  EMAIL_TAKEN_ERROR_MESSAGE,
  GENERIC_UPDATE_ERROR_MESSAGE
} from 'constants/confirmCodeConstants'
import {MODAL_DATA} from 'constants/updateVerificationConstants'
import {
  requiredEmailValidationRules,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {casSignInPost, getCasClientId} from 'services/casServices'
import {RuleType, validateForm} from 'services/formValidationHelpers'

// 3rd-party
import {Mutator} from 'final-form'
import {Field, FieldInputProps, Form, FormProps} from 'react-final-form'
import {useAppDispatch} from 'services/store'
import type {SignInResponse, UpdateUserRequestBody} from 'types/authenticationTypes'
import FetchApiError from 'services/FetchApiError'

export type UpdateVerificationModalProps = {
  inputType: typeof EMAIL
  open: boolean
  handleClose: () => void
  handleExited: () => void
}

type UpdateVerificationModalHeaderContentProps = {
  activeScreen: 'MAIN' | 'CONFIRM' | 'ERROR'
  handleHeaderBackClick: () => void
  title: string
}

type UpdateVerificationModalFormContentProps = {
  description: string
  inputType: string
  handleInputChange: (a: FieldInputProps<any, HTMLElement>) => ChangeEventHandler<HTMLInputElement>
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>
  submitting?: boolean
}

const MAIN = 'MAIN'
const CONFIRM = 'CONFIRM'
const ERROR = 'ERROR'

const fieldRulesDictionary = {
  email: requiredEmailValidationRules
}

type LoginType = {
  email: string
}

function assertNever(): never {
  throw new Error()
}

const UpdateVerificationModal = ({
  inputType,
  open,
  handleClose,
  handleExited
}: UpdateVerificationModalProps) => {
  const dispatch = useAppDispatch()
  const [activeScreen, setActiveScreen] = useState<
    UpdateVerificationModalHeaderContentProps['activeScreen']
  >(MAIN)
  const [casPayload, setCasPayload] = useState<SignInResponse | null>(null)
  const [isValidationCodeValid, setIsValidationCodeValid] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isCodeValidating, setIsCodeValidating] = useState(false)

  const closeModal = () => {
    handleClose()
  }

  const handleFormSubmit: FormProps<LoginType>['onSubmit'] = async (values, form) => {
    const {dirty} = form.getState()
    if (dirty) {
      const payload = {} as UpdateUserRequestBody
      let emailOrPhone: string
      if (inputType === EMAIL) {
        payload.email = values.email
        emailOrPhone = values.email
      }

      return dispatch(updateUser(payload))
        .then(() => {
          setActiveScreen(CONFIRM)
          const clientId = getCasClientId()
          casSignInPost({
            emailOrPhone,
            clientId
          })
            .then(signInPayload => {
              setCasPayload(signInPayload)
            })
            .catch(err => {
              console.error('sign in error', err)
            })
        })
        .catch(err => {
          setActiveScreen(ERROR)

          let errorMessage = GENERIC_UPDATE_ERROR_MESSAGE

          if (
            err instanceof FetchApiError &&
            err.payload?.errorMessage?.includes('already exists')
          ) {
            if (inputType === EMAIL) {
              errorMessage = EMAIL_TAKEN_ERROR_MESSAGE
            }
          }

          setErrorMessage(errorMessage)
          // need to run form reset outside of submit function, so we use setTimeout
          setTimeout(form.reset)
        })
    }
  }

  const handleInputChange: UpdateVerificationModalFormContentProps['handleInputChange'] = input => se => {
    input.onChange(se.target.value)
  }

  const handleHeaderBackClick = (resetValidationCode: () => void) => () => {
    resetValidationCode()
    setActiveScreen(MAIN)
  }

  const handleResendCode = (resetValidationCode: () => void, values: LoginType) => () => {
    const clientId = getCasClientId()
    const payload = {
      emailOrPhone: values.email,
      clientId
    }
    return casSignInPost(payload)
      .then(signInPayload => {
        resetValidationCode()
        setCasPayload(signInPayload)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const validate: FormProps['validate'] = values => {
    // we only care about certain fields based on the inputType
    let filteredRulesDictionary: {
      [key: string]: RuleType[]
    }
    if (inputType === EMAIL) {
      filteredRulesDictionary = {
        ...fieldRulesDictionary,
        confirmEmail: [
          ...requiredValidationRules,
          {
            rule: (value: LoginType) => value === values.email,
            errorMsg: 'Email addresses must match'
          }
        ]
      }
    } else {
      assertNever()
    }

    return validateForm(filteredRulesDictionary, values)
  }

  const resetValidationCode: Mutator<LoginType> = (args, state, utils) => {
    utils.changeValue(state, 'validationCode', val => {
      return val ? Array(val.length).fill('') : val
    })
    // reset the validation
    setIsValidationCodeValid(null)
  }

  const handleValidationCodeComplete = (resetValidationCode: () => void) => (
    valueString: string
  ) => {
    const clientId = getCasClientId()
    if (casPayload?.emailOrPhone && casPayload.session) {
      setIsCodeValidating(true)
      const emailOrPhone = casPayload.emailOrPhone
      const session = casPayload.session
      const payload = {
        emailOrPhone,
        session,
        answer: valueString,
        clientId
      }
      return dispatch(answerAuthChallenge(payload))
        .then(res => {
          const {authenticated, ...rest} = res!
          if (authenticated) {
            handleClose()
            // user is authenticated
          } else {
            setCasPayload(rest)
            setIsValidationCodeValid(false)
          }
        })
        .catch(err => {
          console.error(err)
          // If the user fails 3 times an error will be returned. At that point
          // bring the user back to the codeSentFrom screen and reset the validation code
          resetValidationCode()
          setActiveScreen(MAIN)
        })
        .finally(() => {
          setIsCodeValidating(false)
        })
    }
  }

  const {description, title} = MODAL_DATA[inputType]

  return (
    <Form
      // @ts-ignore no definition className for Form?
      className='user-profile-modal-content'
      onSubmit={handleFormSubmit}
      validate={validate}
      mutators={{
        resetValidationCode
      }}
      render={({form, handleSubmit, dirty, submitting, values}) => (
        <UpdateVerificationModal.Styled
          disableEnforceFocus
          open={open}
          onClose={closeModal}
          onExited={handleExited}
          showCloseButton
          closeOnBackdropClick={activeScreen !== CONFIRM}
        >
          <Modal.Header
            // @ts-ignore seems className will not work
            className={activeScreen === ERROR ? 'error-heading' : null}
          >
            <UpdateVerificationModal.HeaderContent
              activeScreen={activeScreen}
              handleHeaderBackClick={handleHeaderBackClick(form.mutators.resetValidationCode)}
              title={title}
            />
          </Modal.Header>

          <Modal.Content>
            {activeScreen === ERROR ? (
              <p>{errorMessage}</p>
            ) : (
              <form onSubmit={handleSubmit}>
                {activeScreen === MAIN && (
                  <UpdateVerificationModal.FormContent
                    description={description}
                    inputType={inputType}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                  />
                )}
                {activeScreen === CONFIRM && (
                  <ConfirmCodeModalContent
                    codeSentToType={inputType}
                    codeSentTo={values.email}
                    handleResendCode={handleResendCode(form.mutators.resetValidationCode, values)}
                    handleValidationCodeComplete={handleValidationCodeComplete(
                      form.mutators.resetValidationCode
                    )}
                    isValidationCodeValid={isValidationCodeValid}
                    isCodeValidating={isCodeValidating}
                  />
                )}
              </form>
            )}
          </Modal.Content>
        </UpdateVerificationModal.Styled>
      )}
    />
  )
}

UpdateVerificationModal.HeaderContent = ({
  activeScreen,
  handleHeaderBackClick,
  title
}: UpdateVerificationModalHeaderContentProps) => {
  return (
    <div className='modal-title-wrap'>
      <h3>
        {activeScreen !== MAIN ? (
          <IconButton
            className='back-icon-button'
            icon='arrow_back'
            onClick={handleHeaderBackClick}
          />
        ) : null}
        {activeScreen === CONFIRM ? <span>Verify your email</span> : null}
        {activeScreen === MAIN && title ? <span>{title}</span> : null}
        {activeScreen === ERROR ? <span>We were unable to update your information</span> : null}
      </h3>
    </div>
  )
}

UpdateVerificationModal.FormContent = ({
  description,
  inputType,
  handleInputChange,
  handleSubmit,
  submitting
}: UpdateVerificationModalFormContentProps) => {
  return (
    <div>
      <p className='description'>{description}</p>
      <div className='row'>
        <Field name='email'>
          {({input, meta}) => {
            return (
              <TextInput
                label='Email Address'
                placeholder='Email Address'
                value={input.value}
                onChange={handleInputChange(input)}
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
              />
            )
          }}
        </Field>
        <Field name='confirmEmail'>
          {({input, meta}) => {
            return (
              <TextInput
                label='Confirm Email Address'
                placeholder='Confirm Email Address'
                value={input.value}
                onChange={handleInputChange(input)}
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
              />
            )
          }}
        </Field>
      </div>
      <div className='row'>
        <Button
          className='continue-button'
          variant='primary'
          disabled={submitting}
          title={submitting ? <span>Sending&hellip;</span> : 'Continue'}
          type='submit'
          size='large'
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

UpdateVerificationModal.Styled = styled(Modal)`
  .portal-MuiDialog-paper {
    height: auto;
  }

  .header.error-heading {
    padding-bottom: 12px;
  }

  .modal-title-wrap {
    h3 {
      margin: 0;
      display: flex;
      align-items: center;

      .back-icon-button {
        margin-left: -11px;
        margin-right: 8px;
      }
    }
  }

  .description {
    margin: 0 0 21px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .portal-MuiFormControl-root {
      max-width: calc(50% - 8px);
      padding-bottom: 20px;
    }
  }

  .continue-button {
    width: 100%;
  }
`

export default UpdateVerificationModal

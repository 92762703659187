import React, {useEffect, useState} from 'react'
import {getOrderByOpsOrderId} from 'actions/ordersActions'
import {useSelector, useDispatch} from 'react-redux'
import {isUserAuthenticatedSelector, orderByOpsOrderIdSelector} from 'selectors'
import {useQuery} from 'hooks/useQuery'
import {useHistory, Redirect} from 'react-router-dom'
import {openModal} from 'actions/modalActions'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import useHandleOrderByOpsOrderIdStatusError from 'hooks/useHandleOrderByOpsOrderIdStatusError'
import {getProductGroup} from 'services/productsServices'
import useProductGroups from 'hooks/useProductGroups'
import {getOrderDetailsLink} from 'services/productNavigationServices'

const RedirectRoute = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const opsOrderId = query.get('opsOrderId')
  const history = useHistory()
  const order = useSelector(orderByOpsOrderIdSelector)
  const [redirectRoute, setRedirectRoute] = useState('')
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  useHandleOrderByOpsOrderIdStatusError()
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)

  useEffect(() => {
    if (opsOrderId) {
      if (isLoggedIn) {
        dispatch(getOrderByOpsOrderId(opsOrderId))
      } else {
        dispatch(
          openModal({
            modalType: LOG_IN_SIGN_UP_MODAL,
            mode: 'logIn'
          })
        )
      }
    }
  }, [dispatch, history, isLoggedIn, opsOrderId])

  useEffect(() => {
    if (Object.entries(order).length && productGroup) {
      setRedirectRoute(getOrderDetailsLink(productGroup, order.opsOrderId))
    }
  }, [order, productGroup])

  return redirectRoute ? <Redirect to={redirectRoute} /> : null
}

export default RedirectRoute

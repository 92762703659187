import {streetViewApiUrl} from 'constants/googleConstants'
import MAP_KEY from 'constants/keys'
import {formatData} from 'services/dataFormatter'
import type {MetaData} from 'types/metaDataTypes'
import type {SuppliedAddress} from 'types/orderTypes'
import type {AddressType, PropertyType} from 'types/propertyTypes'

// propertyData is expected to be a response object from the prop agg service
export function createSuppliedAddressObject(propertyData?: PropertyType): SuppliedAddress {
  // seems address can be empty, yaml is wrong?
  const {city, state, zip, standardizedAddress = {}, displayAddressLine1} =
    propertyData?.address ?? {}

  const {
    addressNumber = '',
    postDirection = '',
    preDirection = '',
    streetName = '',
    streetSuffix = '',
    unitNum
  } = standardizedAddress as any // yaml file is wrong about this def, ignore it for now
  const street = `${preDirection} ${streetName} ${postDirection}`.trim() || displayAddressLine1
  const streetAddress = `${addressNumber} ${street} ${streetSuffix}`.trim()
  return {
    street: streetAddress,
    city,
    state,
    zipcode: zip,
    unitNumber: unitNum
  }
}

export const getAddressImageUrl = (address: PropertyType['address']) => {
  let imageURL = ''
  if (address) {
    const {displayAddressLine1, city, state, zip} = address
    const addressString = `${displayAddressLine1}, ${city}, ${state}, ${zip}`

    imageURL = `${streetViewApiUrl}location=${encodeURIComponent(
      addressString
    )}&size=1440x351&key=${MAP_KEY}`
  }

  return imageURL
}

export type SubjectDataType = {
  metaData: MetaData[]
  address?: AddressType & {
    street?: AddressType['displayAddressLine1']
  }
}

export const getAddressMetaData = (property: PropertyType) => {
  let {address, characteristics} = property
  address = address ?? {}
  characteristics = characteristics ?? {}
  const metaData: MetaData[] = [
    {
      key: 'bed',
      labelPrefix: 'Bed',
      value: characteristics.bedCount
    },
    {
      key: 'bath',
      labelPrefix: 'Bath',
      value: characteristics.bathFullCount
    },
    {
      key: 'gla',
      labelPrefix: 'GLA',
      value: formatData('gla', characteristics.buildingSize)
    },
    {
      key: 'lotSize',
      labelPrefix: 'Lot Size',
      value: formatData('lotSize', characteristics.lotSize)
    },
    {
      key: 'built',
      labelPrefix: 'Built',
      value: characteristics.yearBuilt
    }
  ]

  return {
    address: address && {...address, zipcode: address.zip, street: address.displayAddressLine1},
    metaData
  }
}

export const getAddressKey = (address: AddressType): string =>
  `${address?.displayAddressLine1?.toLowerCase() ?? ''};${address?.zip ?? ''}`

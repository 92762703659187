import {closeModal, removeModal} from 'actions/modalActions'
import {ModalType} from 'components/ModalsSystem/ModalsSystem'

import {OPEN_MODAL, CLOSE_MODAL, REMOVE_MODAL, RESET_MODAL} from 'constants/actionTypes'
import store from 'services/store'

export type ModalDefinition = {
  id: number
  open: boolean
  handleClose: (...args: any) => any
  handleExited: (...args: any) => any
}

type ModelState = (ModalDefinition & ModalType)[]

type ModelAction =
  | {
      type: typeof OPEN_MODAL
      payload: ModalType & {
        handleClose?: (...args: any) => any
        handleExited?: (...args: any) => any
      }
    }
  | {
      type: typeof CLOSE_MODAL | typeof REMOVE_MODAL
      payload?: number
    }
  | {
      type: typeof RESET_MODAL
      payload: []
    }

const initialState: ModelState = []

export default function modal(state = initialState, action: ModelAction): ModelState {
  const {type, payload} = action

  switch (type) {
    case OPEN_MODAL: {
      const id = new Date().getTime() + Math.random()
      const {handleClose, handleExited, ...rest} = payload
      const isModalAlreadyOpen = (): boolean => {
        return (
          !!state.find(modal => {
            return modal.modalType === rest.modalType
          })?.open === true
        )
      }

      let updatedState = state

      if (!isModalAlreadyOpen()) {
        updatedState = [
          ...state,
          {
            ...rest,
            open: true,
            id,
            // we include the handleClose and handleExited functions as a convenience
            // so the modal will receive them as props
            handleClose: (...args: any) => {
              store.dispatch(closeModal(id))
              handleClose && handleClose(...args)
            },
            handleExited: (...args: any) => {
              store.dispatch(removeModal(id))
              handleExited && handleExited(...args)
            }
          }
        ]
      }

      return updatedState
    }
    case CLOSE_MODAL:
      return state.map(modal => {
        let returnModal = modal
        if (payload) {
          if (modal.id === payload) {
            returnModal = {
              ...modal,
              open: false
            }
          }
        } else {
          returnModal = {
            ...modal,
            open: false
          }
        }
        return returnModal
      })
    case REMOVE_MODAL:
      return state.filter(modal => modal.id !== payload)
    case RESET_MODAL:
      return initialState
    default:
      return state
  }
}

import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Field, useForm} from 'react-final-form'

import CodeValidationInput from 'components/CodeValidationInput'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredAccessCodeValidationRules} from 'constants/validationRulesConstants'
import PasswordInputWithRequirements from 'components/PasswordInputWithRequirements'

const ResetPassword = (): JSX.Element => {
  const {resetFieldState, change, getState} = useForm()
  const {values} = getState()

  useEffect(() => {
    resetFieldState('accessCode')
    change('accessCode', undefined)

    resetFieldState('resetPassword')
    change('resetPassword', undefined)
  }, [resetFieldState, change])

  return (
    <ResetPassword.Styled>
      <p className='instructions'>
        Enter the verification code sent to{' '}
        <span className='email'>{values?.forgotPasswordEmail ?? ''}</span> followed by your new
        password.
      </p>
      <div className='access-code-input-wrapper'>
        <label htmlFor='accessCode' className='label'>
          Verification Code
        </label>
        <Field
          id='accessCode'
          name='accessCode'
          validate={value =>
            getInputValidity(value?.join('') ?? '', requiredAccessCodeValidationRules).errorMsg
          }
        >
          {({input, meta}) => {
            let errorText: string
            if (meta.submitFailed && meta.error) {
              errorText = meta.error
            } else if (!meta.modifiedSinceLastSubmit && meta.submitError) {
              errorText = meta.submitError
            } else {
              errorText = ''
            }
            return (
              <CodeValidationInput
                className={errorText ? '' : 'access-code-input'}
                value={input.value}
                error={!!errorText}
                helperText={errorText}
                onChange={input.onChange}
              />
            )
          }}
        </Field>
      </div>
      <PasswordInputWithRequirements fieldName='resetPassword' label='New Password' />
    </ResetPassword.Styled>
  )
}

ResetPassword.Styled = styled.div`
  .instructions {
    margin: 0 0 20px 0;

    .email {
      font-weight: 500;
    }
  }

  .access-code-input-wrapper {
    .label {
      display: block;
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .access-code-input {
      margin-bottom: 32px;
    }
  }

  .form-error {
    color: ${({theme}) => theme.colors.ruby.base};
    margin-top: 0;
  }
`

export default ResetPassword

import colors from 'styles/colors'

import {Chip as MUIChip} from '@material-ui/core'
import classnames from 'classnames'
import Icon from 'components/Icon'
import React from 'react'
import type {ThemeType} from 'styles/styledComponentsTheme'
import {getSqaId} from 'services/testingServices'

export type ChipProps = {
  color: Exclude<keyof ThemeType['colors'], 'grayscale'>
  label: string
  className?: string
  onDelete?: () => void
  sqaPrefix?: string
}

const Chip = ({color, label, onDelete, className, sqaPrefix = ''}: ChipProps) => {
  const style = {
    backgroundColor: colors[color].lightest || colors[color].light,
    color: colors[color].darker
  }
  return (
    <MUIChip
      label={label}
      onDelete={onDelete}
      deleteIcon={<Icon icon='clear' style={style} />}
      className={classnames('chip', className)}
      style={style}
      {...getSqaId('chip', sqaPrefix)}
    />
  )
}

Chip.defaultProps = {
  color: 'emerald'
}

export default Chip

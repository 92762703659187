import {createSelector} from 'reselect'
import type {RootState} from 'services/store'

export const rootCustomerSelector = (state: RootState) => state.customer
export const customerSelector = createSelector([rootCustomerSelector], customer => customer.data)

export const isInvoicePaySelector = createSelector(
  [customerSelector],
  customer => !!customer?.canInvoice
)

export const customerNameSelector = createSelector([customerSelector], customer => customer?.name)

export const customerKeySelector = createSelector(
  [customerSelector],
  customer => customer?.customerKey
)

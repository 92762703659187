// Styles
import styled from 'styled-components/macro'

// Core
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useHistory, useLocation, useParams} from 'react-router-dom'

// Components, services, etc
import {getOrderByOpsOrderId} from 'actions/ordersActions'
import Card from 'components/Card'
import ClickToCopyIconButton from 'components/ClickToCopyIconButton'
import LinkButton from 'components/LinkButton'
import {ERROR, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import {isUserAuthenticatedSelector} from 'selectors'
import {
  opsOrderIdToProductOrderIdMapSelector,
  orderByOpsOrderIdSelector,
  orderByOpsOrderIdStatusSelector
} from 'selectors/ordersSelectors'
import {userSelector} from 'selectors/userSelectors'
import {getAccessToken, getClearPropHostname} from 'services/apis'
import {isIE} from 'services/browserServices'
import {getAnalyticsCookieValue} from 'services/cookieServices'
import {loadjscssfile, unloadJsCssFiles} from 'services/fileLoader'
import {getMixpanelUserData} from 'services/mixpanel'

// 3rd-party
import CircularProgress from '@material-ui/core/CircularProgress'
import {ClearPropRoute} from 'constants/appRoutesConstants'

const ClearPropContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const {opsOrderId} = useParams<ClearPropRoute>()
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {username} = useSelector(userSelector)
  const orderByOpsOrderIdStatus = useSelector(orderByOpsOrderIdStatusSelector)
  const {opsOrderId: orderOpsOrderId} = useSelector(orderByOpsOrderIdSelector)
  const opsOrderIdToProductOrderIdMap = useSelector(opsOrderIdToProductOrderIdMapSelector)
  const [clearPropLoaded, setClearPropLoaded] = useState(false)

  const orderId = useMemo(() => opsOrderIdToProductOrderIdMap[opsOrderId], [
    opsOrderId,
    opsOrderIdToProductOrderIdMap
  ])

  useEffect(() => {
    if (!isIE) {
      const isOpsOrderIdDifferent = orderOpsOrderId !== opsOrderId
      if (isOpsOrderIdDifferent) {
        const isLoading = orderByOpsOrderIdStatus === LOADING
        const isError = orderByOpsOrderIdStatus === ERROR
        if (!isLoading) {
          dispatch(getOrderByOpsOrderId(opsOrderId))
        }
        if (isError) {
          history.replace({
            pathname: location.pathname,
            state: {
              error: true
            }
          })
        }
      }
    }
  }, [
    dispatch,
    history,
    location.pathname,
    opsOrderId,
    opsOrderIdToProductOrderIdMap,
    orderByOpsOrderIdStatus,
    orderId,
    orderOpsOrderId
  ])

  const handle401 = () => {}

  const loadClearProp = useCallback(async ({orderId, token, username}) => {
    const hostname = getClearPropHostname()
    const proxy = {
      clearprop: hostname
    }
    const isCPLocalDev = process.env.REACT_APP_CP_DEV
    const analyticsCookieValue = getAnalyticsCookieValue()

    const {
      companyId,
      companyName,
      $first_name: firstname,
      $last_name: lastName,
      $email
    } = getMixpanelUserData()

    window.__CPINIT__ = {
      orderId,
      token,
      proxy,
      onTokenExpire: handle401,
      isCPLocalDev,
      isCPNested: true,
      includeAvm: true,
      includeSubjectEstimatedValue: true,
      userId: username,
      allowAnalyticsTracking: analyticsCookieValue,
      customerId: companyId,
      sourceSystemUser: {
        companyName,
        firstName: firstname,
        lastName,
        email: $email
      }
    }

    let icons:
      | string
      | null = `${hostname}/clear-prop-static/css/styles/libs/material-design-icons/iconfont/material-icons.css`
    let styles: string | null = `${hostname}/clear-prop-static/css/main.css`
    let scripts = `${hostname}/clear-prop-static/js/main.js`

    // To do this, ClearProp must be running locally on port 4000
    if (isCPLocalDev) {
      // we do not need icons or styles as clearprop will be running with the webpack dev server
      icons = null
      styles = null
      scripts = `http://localhost:4000/clear-prop-static/js/bundle.js`
    }

    if (icons) {
      await loadjscssfile(icons, 'css')
    }
    if (styles) {
      await loadjscssfile(styles, 'css')
    }
    if (scripts) {
      await loadjscssfile(scripts, 'js')
    }

    setClearPropLoaded(true)
  }, [])

  const unloadClearProp = useCallback(async () => {
    const hostname = getClearPropHostname()
    const isCPLocalDev = process.env.REACT_APP_CP_DEV

    const icons = `${hostname}/clear-prop-static/css/styles/libs/material-design-icons/iconfont/material-icons.css`
    const styles = `${hostname}/clear-prop-static/css/main.css`
    const scripts = isCPLocalDev
      ? `http://localhost:4000/clear-prop-static/js/bundle.js`
      : `${hostname}/clear-prop-static/js/main.js`

    await unloadJsCssFiles(icons, 'css')
    await unloadJsCssFiles(styles, 'css')
    await unloadJsCssFiles(scripts, 'js')
  }, [])

  useEffect(() => {
    if (!isIE) {
      if (!clearPropLoaded && orderId && isUserAuthenticated) {
        loadClearProp({
          orderId,
          token: getAccessToken(),
          username
        })
      }
      return () => {
        unloadClearProp()
      }
    }
  }, [isUserAuthenticated, clearPropLoaded, loadClearProp, orderId, unloadClearProp, username])

  return (
    <ClearPropContainer.Styled>
      <ClearPropContainer.Content
        location={location}
        orderByOpsOrderIdStatus={orderByOpsOrderIdStatus}
        orderId={orderId}
      />
    </ClearPropContainer.Styled>
  )
}

ClearPropContainer.Content = ({
  location,
  orderByOpsOrderIdStatus,
  orderId
}: {
  orderByOpsOrderIdStatus: ReduxStateStatus
  orderId?: string
  location: {pathname: string}
}) => {
  let content = null

  if (isIE) {
    content = <ClearPropContainer.IEMessage />
  } else if (orderId) {
    content = (
      <div id='clear-prop'>
        <div className='loading-wrap'>
          {/* inner div needed for IE11 */}
          <div>
            <CircularProgress size={100} />
          </div>
        </div>
      </div>
    )
  } else if (orderByOpsOrderIdStatus === LOADING || orderByOpsOrderIdStatus === UNSET) {
    content = (
      <div className='loading-wrap'>
        {/* inner div needed for IE11 */}
        <div>
          <CircularProgress size={100} />
        </div>
      </div>
    )
  } else if (orderByOpsOrderIdStatus === ERROR) {
    content = (
      <Redirect
        to={{
          pathname: location.pathname,
          state: {
            error: true
          }
        }}
      />
    )
  }

  return content
}

ClearPropContainer.IEMessage = () => {
  return (
    <div className='ie-message'>
      <Card>
        <h3>This site cannot load ClearProp in Internet Explorer.</h3>
        <p>We are sorry for the inconvenience. Please use one of these supported browsers:</p>
        <ul>
          <li>
            Chrome (
            <LinkButton
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.google.com/chrome'
              variant='tertiary'
              title='download link'
            />
            )
          </li>
          <li>
            Microsoft Edge (
            <LinkButton
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.microsoft.com/en-us/edge'
              variant='tertiary'
              title='download link'
            />
            )
          </li>
          <li>
            Firefox (
            <LinkButton
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.mozilla.org/en-US/firefox'
              variant='tertiary'
              title='download link'
            />
            )
          </li>
        </ul>
        <p>
          If you have one of the browsers installed, please copy the link and paste in your
          preferred browser:
        </p>
        <div className='url-wrap'>
          <span className='url'>{window.location.href}</span>
          <ClickToCopyIconButton
            contentToCopy={window.location.href}
            tooltipTitle='Copy to Clipboard'
          />
        </div>
      </Card>
    </div>
  )
}

ClearPropContainer.Styled = styled.div`
  flex-grow: 1;

  .ie-message {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;

    p {
      font-size: 16px;
      line-height: 22px;
    }

    ul {
      padding: 0;
      li {
        font-size: 16px;
        list-style: none;
      }
    }

    .url-wrap {
      display: flex;
      align-items: center;

      .url {
        background-color: ${({theme}) => theme.colors.stone.lighter};
        border-radius: 6px;
        padding: 12px;
        margin-right: 8px;
      }
    }
  }

  .loading-wrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 48px;
    }
  }

  #clear-prop {
    position: relative;
    z-index: 0;
    background-color: ${({theme}) => theme.colors.stone.lighter};

    .clear-prop-app-header {
      top: 48px;
    }
  }
`

export default ClearPropContainer

import useProductGroups from './useProductGroups'
import {getProductGroup} from 'services/productsServices'
import {useSelector} from 'react-redux'
import {orderByOpsOrderIdSelector} from 'selectors'
import {ProductGroupType} from 'types/productTypes'

export default function useGetProductGroup(): ProductGroupType {
  const order = useSelector(orderByOpsOrderIdSelector)
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)

  return productGroup
}

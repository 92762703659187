// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, MouseEventHandler, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// Components, services, constants
import {getOrdersPaginated, setCurrentPage, setOrdersTablePerPage} from 'actions/ordersActions'
import Button from 'components/Button'
import Icon from 'components/Icon'
import TablePagination from 'components/TablePagination'
import {ORDERS_PER_PAGE_OPTIONS} from 'constants/ordersConstants'
import {currentPageSelector, ordersPerPageSelector} from 'selectors'
import {getSqaId} from 'services/testingServices'

// 3rd party
import Menu from '@material-ui/core/Menu'
import MenuItem, {MenuItemProps} from '@material-ui/core/MenuItem'
import classnames from 'classnames'
import type {TablePaginationProps} from 'components/TablePagination/TablePagination'

type Props = {
  className?: string
  sqaPrefix: string
  newPageLoading: boolean
}

const OrderTableFooter = ({className, sqaPrefix, newPageLoading}: Props) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const ordersPerPage = useSelector(ordersPerPageSelector)
  const currentPage = useSelector(currentPageSelector)

  const handlePageChange: TablePaginationProps['onChange'] = direction => () => {
    const nextPage = direction === 'back' ? currentPage - 1 : currentPage + 1
    dispatch(setCurrentPage(nextPage))
    dispatch(getOrdersPaginated(direction, nextPage))
  }

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectOrdersPerPage: MenuItemProps['onClick'] = se => {
    dispatch(setOrdersTablePerPage((se.target as HTMLLIElement).value))
    dispatch(getOrdersPaginated())
    handleCloseMenu()
  }

  return (
    <OrderTableFooter.Styled className={classnames('order-table-footer', className)}>
      <div className='table-footer table-footer-left' />
      <div className='table-footer table-footer-center'>
        <TablePagination
          currentPage={currentPage}
          onChange={handlePageChange}
          newPageLoading={newPageLoading}
        />
      </div>
      <div className='table-footer table-footer-right'>
        orders per page
        <Button
          variant='tertiary'
          title={
            <Fragment>
              {ordersPerPage}
              <Icon icon='expand_more' />
            </Fragment>
          }
          onClick={handleOpenMenu}
          {...getSqaId('-cases-per-page-menu', sqaPrefix)}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {ORDERS_PER_PAGE_OPTIONS.map((val, index) => (
            <MenuItemStyled
              value={val}
              onClick={handleSelectOrdersPerPage}
              key={val}
              className={classnames({'is-selected': val === ordersPerPage})}
              {...getSqaId('-orders-per-page-menu-item-', sqaPrefix, index)}
            >
              {val}
            </MenuItemStyled>
          ))}
        </Menu>
      </div>
    </OrderTableFooter.Styled>
  )
}

OrderTableFooter.Styled = styled.div`
  background: ${({theme}) => theme.colors.grayscale.white};
  box-shadow: 0 -0.51px ${({theme}) => theme.colors.stone.light};
  width: 100%;
  padding: 8px 24px;
  border-radius: 0px 0px 8px 8px;
  flex: 0 0 auto;

  display: flex;
  align-items: center;

  .table-footer {
    display: flex;
    align-items: center;
  }

  .table-footer-left {
    flex: 0 0 25%;
    justify-content: flex-start;

    span {
      color: ${({theme}) => theme.colors.grayscale.gray};
    }

    .count-sep {
      padding: 0px 5px;
    }
  }

  .table-footer-center {
    flex: 0 0 50%;
    justify-content: center;
  }

  .table-footer-right {
    flex: 0 0 25%;
    justify-content: flex-end;
    color: ${({theme}) => theme.colors.grayscale.gray};

    button {
      background-color: transparent;
      margin-left: 7px;
      min-width: auto;

      span i.material-icons {
        font-size: 16px;
      }
    }
  }
`

const MenuItemStyled = styled(MenuItem)`
  &:hover {
    background-color: ${({theme}) => theme.colors.stone.lighter};
  }
  &.is-selected,
  &.is-selected:hover {
    background-color: ${({theme}) => theme.colors.stone.light};
  }
`

export default OrderTableFooter

import type {QueryAddressType} from 'actions/propertyActions'
import {kebabCase} from 'lodash'
import {OpsOrderType} from 'types/orderTypes'
import {ProductGroupType} from 'types/productTypes'

export const getPropertyUrlByAddress = (address: QueryAddressType) => {
  return address ? `/property-overview?${encodeURIComponent(JSON.stringify(address))}` : ''
}

export const getOrderDetailsLink = (
  productGroup: ProductGroupType,
  opsOrderId: OpsOrderType['opsOrderId']
): string => `/orders/${kebabCase(productGroup?.toLowerCase())}/${opsOrderId}`

import React from 'react'
import styled from 'styled-components'
import {Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'

import {ContactsDetailsInputsType} from 'constants/orderFormConstants'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import ContactPhoneNumber from 'components/ContactPhoneNumber'
import {getOrderFormContactTypeOptions} from 'services/contactsServices'
import {required} from 'services/formValidatorServices'
import IconButton from 'components/IconButton'
import {PHONE_NUMBER_FIELDS_MAP} from 'constants/orderContactConstants'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredEmailValidationRules} from 'constants/validationRulesConstants'
import {PhoneType} from 'types/orderTypes'
import {trackMixpanelEvent} from 'services/mixpanel'
import {DELETE_CONTACT} from 'constants/mixpanelConstants'
import {getMixpanelModalValue} from 'services/mixpanelServices'
import {useSelector} from 'react-redux'
import {modalsSelector} from 'selectors'

interface ContactProps {
  name: string
  shouldShowDeleteButton: boolean
  inputs?: ContactsDetailsInputsType
  onDelete?: () => void
}

const Contact = ({name, shouldShowDeleteButton, inputs, onDelete}: ContactProps) => {
  const firstNameFieldName = `${name}.firstName`
  const lastNameFieldName = `${name}.lastName`
  const emailFieldName = `${name}.email`
  const phoneNumbersFieldName = `${name}.phoneNumbers`
  const shouldShowEmail = inputs?.email
  const shouldShowPhoneNumbers = inputs?.phoneNumbers
  const shouldShowSecondRow = shouldShowEmail || shouldShowPhoneNumbers
  const modal = useSelector(modalsSelector)

  const onDeleteContact = (event: React.MouseEvent) => {
    event.preventDefault()
    trackMixpanelEvent(DELETE_CONTACT, {modal: getMixpanelModalValue(modal[0].modalType)})
    if (onDelete?.()) {
      onDelete()
    }
  }

  return (
    <Contact.Styled className='contact-container'>
      <div className='wrapper'>
        {shouldShowDeleteButton && (
          <IconButton
            className='delete-button'
            icon='delete'
            size='small'
            onClick={onDeleteContact}
            sqaPrefix={name}
          />
        )}
        <div className='row-container'>
          <div className='row'>
            <div className='field'>
              <label htmlFor='type' className='label'>
                Contact Type
              </label>
              <div id='type' className='input-wrapper'>
                <Field name={`${name}.type`} validate={required}>
                  {({input, meta}) => {
                    return (
                      <Select
                        className='select-input'
                        options={getOrderFormContactTypeOptions()}
                        value={input.value}
                        error={meta.error && meta.submitFailed}
                        helperText={meta.submitFailed && meta.error}
                        onChange={input.onChange}
                        sqaPrefix={`${name}-type`}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
            <div className='field'>
              <label htmlFor='firstName' className='label'>
                First Name
              </label>
              <div id='firstName' className='input-wrapper'>
                <Field name={firstNameFieldName} validate={required}>
                  {({input, meta}) => {
                    return (
                      <TextInput
                        className='text-input'
                        value={input.value}
                        error={meta.error && meta.submitFailed}
                        helperText={meta.submitFailed && meta.error}
                        onChange={input.onChange}
                        sqaPrefix={firstNameFieldName}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
            <div className='field'>
              <label htmlFor='lastName' className='label'>
                Last Name
              </label>
              <div id='lastName' className='input-wrapper'>
                <Field name={lastNameFieldName} validate={required}>
                  {({input, meta}) => {
                    return (
                      <TextInput
                        className='text-input'
                        value={input.value}
                        error={meta.error && meta.submitFailed}
                        helperText={meta.submitFailed && meta.error}
                        onChange={input.onChange}
                        sqaPrefix={lastNameFieldName}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
          </div>
          {shouldShowSecondRow && (
            <div className='row'>
              {shouldShowEmail && (
                <div className='field'>
                  <label htmlFor='email' className='label'>
                    Email
                  </label>
                  <div id='email' className='input-wrapper'>
                    <Field
                      name={emailFieldName}
                      validate={value =>
                        getInputValidity(value, requiredEmailValidationRules).errorMsg
                      }
                    >
                      {({input, meta}) => {
                        return (
                          <TextInput
                            className='text-input'
                            value={input.value}
                            error={meta.error && meta.submitFailed}
                            helperText={meta.submitFailed && meta.error}
                            onChange={input.onChange}
                            sqaPrefix={emailFieldName}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </div>
              )}
              {shouldShowPhoneNumbers && (
                <FieldArray name={phoneNumbersFieldName}>
                  {({fields}) =>
                    fields.map((name, index) => {
                      const type: PhoneType = fields.value[index].type
                      const {label, isOptional, validations} = PHONE_NUMBER_FIELDS_MAP[type]

                      return (
                        <ContactPhoneNumber
                          key={name}
                          name={name}
                          type={type}
                          label={label}
                          isOptional={isOptional}
                          validations={validations}
                          sqaPrefix={phoneNumbersFieldName}
                        />
                      )
                    })
                  }
                </FieldArray>
              )}
            </div>
          )}
        </div>
      </div>
    </Contact.Styled>
  )
}

Contact.Styled = styled.div`
  width: 100%;

  .wrapper {
    position: relative;
  }

  .delete-button {
    position: absolute;
    top: -8px;
    right: -10px;
  }

  .row {
    &:first-child {
      margin-bottom: 16px;
    }

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .field {
    flex: 0 0 155px;
  }

  .label {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin-bottom: 4px;

    &-optional {
      font-weight: 300;
    }
  }
`

export default Contact

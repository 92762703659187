import React, {useState, useEffect, useRef, useCallback} from 'react'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Icon from 'components/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'autosuggest-highlight/parse'
import {Paper} from '@material-ui/core'

import TextInput from 'components/TextInput'

import googleLogo from 'assets/google-logo.png'
import colors from 'styles/colors'

type Option = google.maps.places.AutocompletePrediction | string
type Options = Option[]
export interface AddressObject {
  address?: string
  city?: string
  state?: string
  zipcode?: string
}

export interface GoogleAutocompleteProps {
  inputValue: string
  error?: boolean
  onInputChange: (value: string) => void
  onAddressSelect: (selectedAddress: AddressObject) => void
}

const GoogleAutocomplete = ({
  inputValue,
  error = false,
  onInputChange,
  onAddressSelect
}: GoogleAutocompleteProps): JSX.Element => {
  const [options, setOptions] = useState<Options>([])
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()

  const [isGooglePlacesLoaded, setIsGooglePlacesLoaded] = useState(false)
  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | null>(null)
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null)

  // load Google maps SDK
  useEffect(() => {
    const initMap = async (): Promise<void> => {
      const {AutocompleteSessionToken} = (await google.maps.importLibrary(
        'places'
      )) as google.maps.PlacesLibrary
      setSessionToken(new AutocompleteSessionToken())
      setIsGooglePlacesLoaded(true)
    }

    initMap()
  }, [])

  // initialize Google Autocomplete and Places services
  useEffect(() => {
    if (isGooglePlacesLoaded) {
      if (autocompleteServiceRef.current === null) {
        autocompleteServiceRef.current = new google.maps.places.AutocompleteService()
      }

      if (placesServiceRef.current === null) {
        const reactRoot = document.getElementById('root')
        const newDiv = document.createElement('div')

        newDiv.style.display = 'none'
        document.body.insertBefore(newDiv, reactRoot)

        placesServiceRef.current = new google.maps.places.PlacesService(newDiv)
      }
    }
  }, [isGooglePlacesLoaded])

  const handleInputChange = useCallback(
    (event, newInputValue, reason) => {
      if (reason !== 'reset') {
        if (newInputValue === '') {
          setOptions([])
        } else if (autocompleteServiceRef.current !== null) {
          autocompleteServiceRef.current
            ?.getPlacePredictions({
              input: newInputValue,
              types: ['address'],
              language: 'en',
              componentRestrictions: {
                country: 'us'
              },
              sessionToken
            })
            .then(results => {
              let newOptions: Options = []

              if (newInputValue) {
                newOptions = [newInputValue]
              }

              if (results?.predictions.length) {
                newOptions = [...newOptions, ...results.predictions]
              }
              setOptions(newOptions)
            })
            .catch(err => {
              setOptions([])
              console.error(err)
            })
        }

        onInputChange(newInputValue)
      }
    },
    [sessionToken, onInputChange]
  )

  return (
    <GoogleAutocomplete.Styled>
      <Autocomplete<Option, false, true, false>
        id='google-map-autocomplete'
        fullWidth
        getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
        filterOptions={x => x}
        options={options}
        disableClearable
        forcePopupIcon={false}
        clearOnBlur={false}
        popupIcon={null}
        inputValue={inputValue}
        onChange={(_, newValue) => {
          setOptions(newValue !== '' ? [newValue] : [])

          if (newValue !== null && typeof newValue !== 'string') {
            placesServiceRef.current?.getDetails(
              {
                placeId: newValue.place_id,
                fields: ['address_components'],
                sessionToken
              },
              placeDetails => {
                let addressObject: AddressObject = {}
                if (placeDetails && placeDetails.address_components) {
                  const addressParts = placeDetails.address_components.reduce<{
                    street_number?: string
                    route?: string
                    locality?: string
                    postal_code?: string
                    administrative_area_level_1?: string
                  }>(
                    (accumulator, addressComponent) => ({
                      ...accumulator,
                      [addressComponent.types[0]]: addressComponent.short_name
                    }),
                    {}
                  )

                  addressObject = {
                    address: [addressParts?.street_number, addressParts?.route]
                      .filter(streetData => streetData)
                      .join(' '),
                    city: addressParts.locality,
                    state: addressParts.administrative_area_level_1,
                    zipcode: addressParts.postal_code
                  }
                }
                setSessionToken(new google.maps.places.AutocompleteSessionToken())
                onAddressSelect(addressObject)
              }
            )
          }
        }}
        onInputChange={handleInputChange}
        renderInput={params => {
          return (
            <TextInput {...params} placeholder='Street Address' error={error} sqaPrefix='address' />
          )
        }}
        renderOption={option => {
          // we use inline styles here because the options are rendered
          // in the paper component which is injected into the root of
          // the <body> tag and the GoogleAutocomplete's styled
          // components styles aren't in scope and won't apply.
          if (typeof option === 'string') {
            return (
              <Grid container alignItems='center'>
                <Grid item>
                  <Icon
                    style={{
                      color: colors.grayscale.gray,
                      marginRight: '16px'
                    }}
                    icon='location_on'
                  />
                </Grid>
                <Grid item xs>
                  <span>{option}</span>
                </Grid>
              </Grid>
            )
          } else {
            const matches = option.structured_formatting.main_text_matched_substrings
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map(match => [match.offset, match.offset + match.length])
            )

            return (
              <Grid container alignItems='center'>
                <Grid item>
                  <Icon
                    style={{
                      color: colors.grayscale.gray,
                      marginRight: '16px'
                    }}
                    icon='location_on'
                  />
                </Grid>
                <Grid item xs>
                  {parts.map(part => (
                    <span
                      key={`${option.structured_formatting.main_text}:${part.text}`}
                      style={{fontWeight: part.highlight ? 700 : 400}}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant='body2' color='textSecondary'>
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
        }}
        PaperComponent={paperProps => (
          <Paper {...paperProps}>
            {paperProps.children}
            {options.length > 0 && (
              <div
                // we use an inline style here because the paper component
                // is injected into the root of the <body> tag and the
                // GoogleAutocomplete's styled components styles aren't in
                // scope and won't apply.
                style={{
                  alignItems: 'center',
                  color: colors.grayscale.gray,
                  display: 'flex',
                  gap: '2px',
                  justifyContent: 'right',
                  paddingRight: '8px',
                  paddingBottom: '6px'
                }}
              >
                powered by <img src={googleLogo} alt='Google logo' />
              </div>
            )}
          </Paper>
        )}
      />
    </GoogleAutocomplete.Styled>
  )
}

GoogleAutocomplete.Styled = styled.div`
  width: 100%;

  .icon {
    color: ${({theme}) => theme.colors.grayscale.gray};
    margin-right: 16px;
  }

  .portal-MuiOutlinedInput-root {
    padding: 0;
  }

  .portal-MuiAutocomplete-inputRoot .portal-MuiAutocomplete-input:first-child {
    padding: 0 20px 0 12px;
  }
`

export default GoogleAutocomplete

import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'

export default function configureStore(initialState = {}) {
  // const logger = store => next => action => {
  //   // eslint-disable-line
  //   console.log('dispatching', action) // eslint-disable-line no-console
  //   const result = next(action)
  //   console.log('next state', store.getState()) // eslint-disable-line no-console
  //   return result
  // }

  // use the second middleware declaration (with `logger`) to enable redux logging during dev.
  const middleware = [thunk]
  // const middleware = [thunk, logger]
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  const enhancers = [applyMiddleware(...middleware)]

  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers))

  // For hot reloading of react components
  // Also for debugging
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

import React from 'react'
import {NO_DATA, DEFAULT_DATE_INPUT, DEFAULT_DATE_OUTPUT} from 'constants/appConstants'
import moment from 'moment'
import {convertDateStringToMs} from 'services/datetime'
import {isExisty} from 'services/utils'

export function addSpaceToCommas(data: string) {
  return data ? data.replace(/,\s*/g, ', ') : NO_DATA
}

export function currency(n: number, showCents?: boolean) {
  return n || n === 0 ? `$${numberWithCommas(showCents ? Number(n.toFixed(2)) : n)}` : NO_DATA
}

export function numberWithCommas(n: number) {
  return n || n === 0 ? n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : NO_DATA
}

export function denominate(n: number, roundNumber: boolean = true) {
  let value
  let suffix

  if (n / 1e9 >= 1) {
    value = Math.round((n / 1e9) * 100) / 100
    suffix = 'B'
  } else if (n / 1e6 >= 1) {
    value = Math.round((n / 1e6) * 100) / 100
    suffix = 'M'
  } else if (n / 1e3 >= 1 && n / 1e3 < 1000) {
    value = Math.round((n / 1e3) * 100) / 100
    suffix = 'K'
  }

  // @ts-ignore what if value == undefined?
  return suffix ? `${roundNumber ? Math.round(value) : value}${suffix}` : `${value}`
}

export function denominateCurrency(n: number) {
  return `$${denominate(n)}`
}

function capitalizeAfterSlash(word: string) {
  const idx = word.indexOf('/')
  if (idx >= 0) {
    return word.replace(word[idx + 1], word[idx + 1].toUpperCase())
  }
  return word
}

export function titleCase(str: string) {
  if (isExisty(str)) {
    if (typeof str === 'string') {
      str = str.replace(/,/g, ', ')
      str = str.replace(/\s+/g, ' ')
      str = str.replace(/\s+$/, '')
      str = str.replace(/_/g, ' ')

      return str
        .toLowerCase()
        .split(' ')
        .map(word =>
          word === '&' || word === '' || word === ' ' || word === undefined
            ? word
            : word[0] === '('
            ? capitalizeAfterSlash(word.replace(word[1], word[1].toUpperCase()))
            : capitalizeAfterSlash(word.replace(word[0], word[0].toUpperCase()))
        )
        .join(' ')
    } else {
      return str
    }
  } else {
    return NO_DATA
  }
}

export function formatDate(
  input: moment.MomentInput,
  inputFormat: string = DEFAULT_DATE_INPUT,
  outputFormat: string = DEFAULT_DATE_OUTPUT
): string {
  let output = NO_DATA

  if (input) {
    output = moment(input, inputFormat).format(outputFormat)
  }

  return output
}

export function formatGLA(value?: number) {
  let gla

  // TODO: Research value. Number, string, or both?
  if (typeof value === 'number') {
    gla = `${numberWithCommas(value)} ft\u00B2`
  } else if (!value) {
    gla = NO_DATA
  } else {
    gla = value
  }

  return gla
}

export function formatLotSize(lotSize: number | string, short: boolean = true) {
  const SQFT_ACRE = 43560

  if (typeof lotSize === 'string') {
    lotSize = ~~lotSize
  }

  return lotSize || lotSize === 0
    ? lotSize >= SQFT_ACRE
      ? `${Math.round((lotSize / SQFT_ACRE) * 100) / 100}${short ? ' ac' : ' Acres'}`
      : `${numberWithCommas(lotSize)} ft\u00B2`
    : NO_DATA
}

export function formatDaysOnMarket(startDate: moment.MomentInput, endDate: moment.MomentInput) {
  const startDateMs = convertDateStringToMs(startDate, 'YYYY-MM-DD')
  const endDateMs = convertDateStringToMs(endDate, 'YYYY-MM-DD')
  return moment(endDateMs).diff(moment(startDateMs), 'days')
}

export function roundNumberToMaxDecimals(number: number, maxDecimals = 2) {
  return Number(number.toFixed(maxDecimals))
}

export function formatPricePerSqFt(value: number, sqft: number) {
  return value && sqft ? currency(Math.round(value / sqft)) : NO_DATA
}

export function formatPhoneNumber(phoneNumberString: number | string) {
  let result
  if (!phoneNumberString && phoneNumberString !== 0) {
    result = NO_DATA
  } else {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    result = match ? (
      <span>
        ({match[1]})&nbsp;{match[2]}-{match[3]}
      </span>
    ) : null
  }
  return result
}

export function formatDistance(distanceToSubject: number, short: boolean) {
  return distanceToSubject
    ? `${Math.round(distanceToSubject * 100) / 100}${short ? ' mi' : ' Miles'}`
    : NO_DATA
}

export function cleanPhoneNumber(input?: string) {
  let result = input

  if (input?.slice(0, 2) === '+1') {
    result = input.substring(2)
  }

  return result
}

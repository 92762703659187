// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import type {ReactNode} from 'react'

// 3rd-party
import classnames from 'classnames'

type Props = {
  children: ReactNode
  className?: string
}

const Card = ({children, className}: Props) => {
  return <Card.Styled className={classnames('card', className)}>{children}</Card.Styled>
}

Card.Styled = styled.div`
  background: ${({theme}) => theme.colors.grayscale.white};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px;
`

export default Card

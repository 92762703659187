import {FormHelperText} from '@material-ui/core'
import {PrivacyPolicy, TermsOfService} from 'components/LinkButton'
import React from 'react'
import {Field} from 'react-final-form'
import Checkbox from 'components/Checkbox'
import styled from 'styled-components'
import {getSqaId} from 'services/testingServices'

const AgreementTerms = ({
  field,
  classname,
  sqaPrefix,
  validate
}: {
  field: string
  classname: string
  sqaPrefix?: string
  validate?: (arg: any) => void
}) => {
  return (
    <Field name={field} validate={validate}>
      {({input, meta}) => {
        return (
          <AgreementTerms.Styled className={classname}>
            <Checkbox
              label={
                <span className='agreement-terms-text' {...getSqaId('label', sqaPrefix)}>
                  I agree to Clear Capital’s&nbsp;
                  <TermsOfService />
                  &nbsp;and&nbsp;
                  <PrivacyPolicy />
                </span>
              }
              checked={Boolean(input.value)}
              onChange={e => {
                input.onChange(e.target.checked)
              }}
              sqaPrefix={sqaPrefix}
              inputProps={{'data-sqa-id': 'agreement-terms-checkbox-input'}}
            />
            <FormHelperText error {...getSqaId('error-text', sqaPrefix)}>
              {meta.touched && meta.error}
            </FormHelperText>
          </AgreementTerms.Styled>
        )
      }}
    </Field>
  )
}

AgreementTerms.Styled = styled.div`
  position: relative;

  .agreement-terms-text {
    display: flex;
  }

  .portal-MuiTypography-root {
    display: flex;
  }

  .Mui-error {
    position: absolute;
    top: 24px;
    overflow: visible;
  }
`

export default AgreementTerms

import {NO_DATA} from 'constants/appConstants'

export const getFormattedDate = (date?: string) => {
  let result = NO_DATA
  if (date) {
    const datePart = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(new Date(date))
    const timePart = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(new Date(date))
    result = `${datePart} ${timePart}`
  }

  return result
}

import {captureException} from '@sentry/react'
import {ErrorEvent, EventHint, ScopeContext} from '@sentry/types'
import {FETCH_ERROR_MESSAGE_PREFIX} from 'constants/validationErrorMessagesConstants'
import FetchApiError from './FetchApiError'

export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  const eventMessage = event.message
  let modifiedEvent: ErrorEvent | null = event

  /****************************************************
   * Filter certain CaptureConsole integration events *
   ****************************************************/
  if (event.logger === 'console') {
    // don't sent React warnings if Sentry is enabled on localhost
    if (event.environment === 'localhost' && eventMessage?.startsWith('Warning')) {
      modifiedEvent = null
    }

    // don't send logged fetch errors, those are sent to Sentry by the Fetch Api console errors
    // @ts-ignore originalException is defined as unknown from the sentry lib
    if (hint.originalException?.message?.startsWith(FETCH_ERROR_MESSAGE_PREFIX)) {
      modifiedEvent = null
    }
  }

  if (hint.originalException instanceof FetchApiError) {
    event.fingerprint = [
      '{{default}}',
      // @ts-ignore the value for extra keys is typed out as unknown in sentry lib
      event.extra?.options?.method,
      event.extra?.parameterizedUrl,
      ...(event.extra?.statusCode ? [String(event.extra.statusCode)] : [])
    ]
  }

  // @ts-ignore the value for extra keys is typed out as unknown in sentry lib
  if (event.extra?.url?.includes('refresh-token') && event.extra.statusCode === 401) {
    modifiedEvent = null
  }

  return modifiedEvent
}

export const trackError = (error: Error, extraData?: ScopeContext['extra']) => {
  captureException(error, {
    ...(extraData && {extra: extraData})
  })
}

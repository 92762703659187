import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import type {RootAction} from 'reducers'
import {ThunkDispatch} from 'redux-thunk'
import configureStore from 'services/configureStore'
const store = configureStore()
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<RootState, undefined, RootAction>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment} from 'react'

// Components, services, etc
import {getSqaId} from 'services/testingServices'

// 3rd-party
import classnames from 'classnames'
import startCase from 'lodash/startCase'
import type {SuppliedAddress} from 'types/orderTypes'

type Props = {
  address?: SuppliedAddress
  className?: string
  sqaPrefix?: string
  twoLines?: boolean
}

type AddressStreetProps = {
  address: {
    street?: string
    unitNumber?: string
  }
  streetOnly: boolean
  twoLines?: boolean
}

type AddressCityStateZipProps = {
  address: {
    city?: string
    state?: string
    zipcode?: string
  }
  twoLines?: boolean
}

const Address = ({
  address: {city, state, street, unitNumber, zipcode} = {},
  className,
  sqaPrefix,
  twoLines
}: Props) => {
  const streetOnly = !city && !state && !zipcode
  const formatStreet = startCase(street)
  const formatState = state?.toUpperCase() ?? ''
  const formatZip = zipcode?.substr(0, 5) ?? ''

  return (
    <Address.Styled
      className={classnames('address', {'two-lines': twoLines}, className)}
      data-sqa-id={`${sqaPrefix}-address`}
    >
      <Address.Street
        address={{street: formatStreet, unitNumber}}
        streetOnly={streetOnly}
        twoLines={twoLines}
      />
      {!streetOnly && (
        <Address.CityStateZip
          address={{city, state: formatState, zipcode: formatZip}}
          twoLines={twoLines}
        />
      )}
    </Address.Styled>
  )
}

const Street = ({address = {}, streetOnly, twoLines}: AddressStreetProps) => {
  const {street, unitNumber} = address
  if (!street) {
    return null
  }

  return (
    <Fragment>
      <span className='street' {...getSqaId('street')}>
        {street ?? ''}
        {unitNumber && ` #${unitNumber}`}
      </span>
      <Street.Suffix streetOnly={streetOnly} twoLines={twoLines} />
    </Fragment>
  )
}

Street.Suffix = ({streetOnly, twoLines}: {streetOnly: boolean; twoLines?: boolean}) => {
  let content = null
  if (!streetOnly) {
    content = twoLines ? <br /> : <React.Fragment>{', '}</React.Fragment>
  }

  return content
}
Address.Street = Street

Address.CityStateZip = ({address, twoLines}: AddressCityStateZipProps) => {
  const {city, state, zipcode} = address
  return (
    <span
      className={classnames('city-state-zip', {'line-two': twoLines})}
      {...getSqaId('city-state-zip')}
    >
      {city ?? ''}
      {city && (state || zipcode) && ','} {state ?? ''} {zipcode ?? ''}
    </span>
  )
}

Address.Styled = styled.span`
  &.two-lines {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${({theme}) => theme.colors.grayscale.black};

    .city-state-zip {
      &.line-two {
        color: ${({theme}) => theme.colors.grayscale.gray};
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`

export default Address

// Styles
import {withStyles} from '@material-ui/core'
import colors from 'styles/colors'

// Core
import React from 'react'

// 3rd-party
import type {TabTypeMap} from '@material-ui/core'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import {TabsProps} from 'types/tabTypes'
import {getSqaId} from 'services/testingServices'

const Tabs = ({className, onChange, tabs, value, variant, scrollOptions, innerRef}: TabsProps) => {
  const {Tab, Tabs} = tabVariantsMap[variant]

  return (
    <Tabs
      className={className}
      // we want to pass false here when value is undefined since in the property overview page
      // we don't want any of the tabs to be selected and mui library throws a console error if
      // the values don't match with the tabs children value. The only way to suppress the console
      // error is to pass false in those situation.
      value={value || false}
      onChange={onChange}
      {...scrollOptions}
      ref={innerRef}
    >
      {tabs.map((tabObj: any) => {
        const {sqaPrefix, ...rest} = tabObj
        return <Tab key={tabObj.label} {...rest} {...getSqaId('tab', sqaPrefix)} />
      })}
    </Tabs>
  )
}

Tabs.Tabs = React.forwardRef<any, Omit<TabsProps, 'variant'>>((props, ref) => {
  return <MuiTabs {...props} ref={ref} />
})

Tabs.Tab = (props: TabTypeMap) => <MuiTab disableRipple {...props} />

Tabs.Pills = withStyles({
  root: {
    minHeight: 0
  },
  indicator: {
    display: 'none'
  }
})(MuiTabs)

Tabs.Pill = withStyles(theme => ({
  root: {
    fontSize: 12,
    fontFamily: 'inherit',
    fontWeight: 500,
    letterSpacing: '0.18px',
    minHeight: 24,
    minWidth: 40,
    padding: '1px 8px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.stone.lighter,
      borderRadius: 13,
      color: colors.grayscale.gray
    },
    '&$selected': {
      backgroundColor: colors.cobalt.lightest,
      borderRadius: 13,
      color: colors.cobalt.dark
    },
    // this is the equivalent of "MuiTab-root + MuiTab-root"
    '& + &': {
      marginLeft: 8
    }
  },
  textColorInherit: {
    color: colors.grayscale.gray,
    opacity: 1
  },
  selected: {} // needed empty
}))(props => <MuiTab disableRipple {...props} />)

const tabVariantsMap = {
  tab: {
    Tabs: Tabs.Tabs,
    Tab: Tabs.Tab
  },
  pill: {
    Tabs: Tabs.Pills,
    Tab: Tabs.Pill
  }
}

Tabs.defaultProps = {
  variant: 'tab'
}

export default Tabs

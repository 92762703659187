//  thanks David! https://davidwalsh.name/query-string-javascript
export const getQueryParameter = (name: string, url = window.location.search) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const createQueryStringFromObject = (data: {[key: string]: any}) => {
  const tempData: string[] = []
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach((value: string) => {
        tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      })
    } else {
      tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
  })
  return tempData.join('&')
}

const cleanUpCacheBustingParams = (url: string): string => {
  let output = url
  if (url.includes('?_')) {
    output = url.split('?_')[0]
  } else if (url.includes('&_')) {
    output = url.split('&_')[0]
  }
  return output
}

const replaceQueryParamsFromURL = (url: string): string => {
  let output = url
  if (url.includes('?')) {
    output = `${url.split('?')[0]}?{queryParams}`
  }
  return output
}

const replaceUUIDFromURL = (url: string): string => {
  return url.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, () => '{UUID}')
}

export const getParametrizedURL = (url: string) =>
  replaceUUIDFromURL(replaceQueryParamsFromURL(cleanUpCacheBustingParams(url)))

// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import {useDispatch} from 'react-redux'

// Components, services, constants
import {getCurrentYear} from 'services/utils'
import Button from 'components/Button'
import LinkButton from 'components/LinkButton'
import {COOKIE_MODAL} from 'constants/modalsConstants'
import {openModal} from 'actions/modalActions'
import {APP_FOOTER_LINKS} from 'constants/appFooterLinkConstants'

// 3rd party
import classnames from 'classnames'

type Props = {
  className?: string
}

const Footer = ({className}: Props) => {
  const dispatch = useDispatch()
  const openCookieModal = () => {
    dispatch(
      openModal({
        modalType: COOKIE_MODAL,
        mixpanelEventData: {
          initiatedFrom: 'Footer'
        }
      })
    )
  }
  return (
    <Footer.Styled className={classnames('footer', className)}>
      <div className='inner'>
        <div className='logo-wrap'>
          <p>© {getCurrentYear()} ClearCapital.com, Inc. All Rights Reserved</p>
        </div>
        <div className='links-wrap'>
          {APP_FOOTER_LINKS.map(linkObj => (
            <LinkButton
              key={linkObj.title}
              variant='tertiary'
              title={linkObj.title}
              href={linkObj.href}
              target='_blank'
            />
          ))}
          <Button title='Cookies' variant='tertiary' onClick={openCookieModal} />
        </div>
      </div>
    </Footer.Styled>
  )
}

Footer.Styled = styled.div`
  background: ${({theme}) => theme.colors.stone.lighter};
  width: 100vw;
  border-top: 1px solid ${({theme}) => theme.colors.stone.light};
  margin-top: auto;
  padding: 24px 80px 22px;
  display: flex;
  justify-content: center;
  z-index: 1;

  .inner {
    flex-wrap: wrap;
    max-width: 1280px;
    width: 100%;
    &,
    .links-wrap,
    .logo-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        color: ${({theme}) => theme.colors.grayscale.gray};
      }
    }
  }

  .links-wrap {
    a,
    button {
      margin-left: 16px;
      &.portal-MuiButton-root {
        min-width: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 24px 40px 22px;
  }

  @media (max-width: 667px) {
    padding: 24px 20px 22px;
  }

  @media (max-width: 611px) {
    .inner {
      justify-content: center;
      .links-wrap {
        margin-bottom: 4px;
      }
    }
  }
  @media (max-width: 400px) {
    padding: 24px 8px 22px;
  }
`

export default Footer

import {combineReducers} from 'redux'
import {RESET_APP} from 'constants/actionTypes'
import auth from './authReducer'
import property from './propertyReducer'
import modals from './modalReducer'
// ordersReducer exports multiple orders related reducers. It breaks our common convention
// of having a one-to-one-to-one relationship between reducers, actions and selectors files.
// This is necessary however to keep our common reducer shape and to still maintain a basic
// file structure. There will be a reducer for each slice of orders related data, but we will
// still only have one action, one reducer and one selectors file for all of them.
import {
  ordersReducer as orders,
  ordersByAddressReducer as ordersByAddress,
  ordersHistoryReducer as ordersHistory,
  orderByOpsOrderIdReducer as orderByOpsOrderId
} from './ordersReducer'
import user from './userReducer'
import wallet from './walletReducer'
import customer from './customerReducer'
import documents from './documentsReducer'

const reducerMap = {
  auth,
  documents,
  property,
  modals,
  orders,
  ordersByAddress,
  orderByOpsOrderId,
  ordersHistory,
  user,
  wallet,
  customer
}

const combinedReducer = combineReducers(reducerMap)
type ReducersType = typeof reducerMap
type InferAction<F> = F extends (state: any, action: infer R) => any ? R : never

type ReducerAction = {
  [k in keyof ReducersType]: InferAction<ReducersType[k]>
}[keyof ReducersType]

export type RootAction = ReducerAction | {type: typeof RESET_APP}

const rootReducer = (state: any, action: RootAction) => {
  if (action.type === RESET_APP) {
    state = undefined
  }

  return combinedReducer(state, action as ReducerAction)
}

export default rootReducer

import React, {ReactNode, useState, Fragment} from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import MUITableContainer from '@material-ui/core/TableContainer'
import MUITable from '@material-ui/core/Table'
import MUITableHead from '@material-ui/core/TableHead'
import MUITableRow from '@material-ui/core/TableRow'
import MUITableCell from '@material-ui/core/TableCell'
import MUITableBody from '@material-ui/core/TableBody'
import {CircularProgress} from '@material-ui/core'
import {FormApi} from 'final-form'
import {Form, Field, FormSpy} from 'react-final-form'
import {useSelector} from 'react-redux'

import {getSqaId} from 'services/testingServices'
import {CustomerUserStatus, CustomerUserTable, CustomerUsersTableFilters} from 'types/customerTypes'
import {CUSTOMER_USERS_STATUS} from 'constants/customerUsersConstants'
import IconButton from 'components/IconButton'
import Chip from 'components/Chip'
import useCustomerUsers from 'hooks/useCustomerUsers'
import {openNotification} from 'services/notificationServices'
import {
  RESEND_INVITE_FAILURE_MESSAGE,
  RESEND_INVITE_SUCCESS_MESSAGE,
  UPDATE_CUSTOMER_USER_SUCCESS_MESSAGE,
  UPDATE_CUSTOMER_USER_ERROR_MESSAGE
} from 'constants/notificationMessageConstants'
import {NO_DATA} from 'constants/appConstants'
import {trackMixpanelEvent} from 'services/mixpanel'
import {
  EDIT_CUSTOMER_USER,
  RESEND_INVITE,
  CUSTOMER_USER_CLICKED_SORT
} from 'constants/mixpanelConstants'
import useSortableData from 'hooks/useSortableData'
import Icon from 'components/Icon'
import Select from 'components/Select'
import {ROLE_OPTIONS} from 'constants/inviteUserConstants'
import {customerKeySelector} from 'selectors/customerSelectors'
import {
  updateCustomerUser,
  getStatusOptionsByCustomerUserStatus,
  resendInvite,
  filterCustomerUsers
} from 'services/customerUsersServices'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredValidationRules} from 'constants/validationRulesConstants'
import Tooltip from 'components/Tooltip'

type EditForm = {
  roles: string
  userStatus: Exclude<CustomerUserStatus, 'pending'>
}

export type HeaderCell = {
  id: string
  label: string
  style?: object
  render: (value: CustomerUserTable) => ReactNode
}

interface CustomerUsersTableProps {
  className?: string
  sqaPrefix?: string
  filters: CustomerUsersTableFilters
}

const CustomerUsersTable = ({className, sqaPrefix, filters}: CustomerUsersTableProps) => {
  const {customerUsers, customerUsersMutate} = useCustomerUsers()
  const [customerUserEditMode, setCustomerUserEditMode] = useState<CustomerUserTable>()
  const filteredCustomerUsers = filterCustomerUsers(customerUsers, filters)
  const {
    items: sortedCustomerUsers,
    sortConfig,
    requestSort
  } = useSortableData(filteredCustomerUsers, [{sortKey: 'firstName', sortOrder: 'asc'}])
  const customerKey = useSelector(customerKeySelector)

  const isCustomerUserOnEditMode = (customerUser: CustomerUserTable) =>
    customerUser.username === customerUserEditMode?.username

  const HEADER_CELLS: HeaderCell[] = [
    {
      id: 'firstName',
      label: 'First Name',
      style: {width: '200px'},
      render: (customerUser: CustomerUserTable) => customerUser.firstName || NO_DATA
    },
    {
      id: 'lastName',
      label: 'Last Name',
      style: {width: '200px'},
      render: (customerUser: CustomerUserTable) => customerUser.lastName || NO_DATA
    },
    {
      id: 'email',
      label: 'Email',
      style: {width: '359px'},
      render: (customerUser: CustomerUserTable) => customerUser.email || NO_DATA
    },
    {
      id: 'roles',
      label: 'Role',
      style: {width: '185px'},
      render: (customerUser: CustomerUserTable) => {
        let content
        if (isCustomerUserOnEditMode(customerUser)) {
          content = (
            <Field
              name='roles'
              initialValue={
                ROLE_OPTIONS.find(roleOption => roleOption.label === customerUserEditMode?.roles)
                  ?.value
              }
              validate={value => !getInputValidity(value, requiredValidationRules).fieldIsValid}
            >
              {({input, meta}) => {
                return (
                  <Select
                    options={ROLE_OPTIONS}
                    value={input.value}
                    onChange={input.onChange}
                    error={meta.submitFailed && meta.error}
                  />
                )
              }}
            </Field>
          )
        } else {
          content = customerUser.roles || NO_DATA
        }

        return content
      }
    },
    {
      id: 'userStatus',
      label: 'Status',
      style: {width: '130px'},
      render: (customerUser: CustomerUserTable) => {
        const customerUserStatus = customerUser.userStatus
        let content
        if (isCustomerUserOnEditMode(customerUser)) {
          content = (
            <Field
              name='userStatus'
              initialValue={
                customerUserEditMode?.userStatus === 'pending'
                  ? 'enabled'
                  : customerUserEditMode?.userStatus
              }
            >
              {({input, meta}) => {
                return (
                  <Select
                    options={getStatusOptionsByCustomerUserStatus(customerUserStatus)}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )
              }}
            </Field>
          )
        } else {
          const {label, color} =
            CUSTOMER_USERS_STATUS.find(status => status.value === customerUserStatus) ?? {}
          if (customerUserStatus && label && color) {
            return <Chip label={label} color={color} />
          } else {
            return NO_DATA
          }
        }

        return content
      }
    },
    {
      id: 'action',
      label: 'Action',
      style: {width: '82px'},
      render: (customerUser: CustomerUserTable) => {
        const customerUserStatus = customerUser.userStatus
        let content
        if (isCustomerUserOnEditMode(customerUser)) {
          content = (
            <FormSpy
              render={({form}) => {
                const formState = form.getState()
                const shouldDisabledSubmitButton =
                  formState.hasValidationErrors && formState.submitFailed

                return formState.submitting ? (
                  <div className='progress'>
                    <CircularProgress />
                  </div>
                ) : (
                  <Fragment>
                    <Tooltip className='tooltip' text='Confirm'>
                      <IconButton
                        className={classnames('submit-button', {
                          'submit-button-disabled': shouldDisabledSubmitButton
                        })}
                        icon='check'
                        disabled={shouldDisabledSubmitButton}
                      />
                    </Tooltip>
                    <Tooltip className='tooltip' text='Cancel'>
                      <IconButton
                        className='cancel-button'
                        icon='close'
                        onClick={() => {
                          trackMixpanelEvent(EDIT_CUSTOMER_USER, {
                            savedChanges: false,
                            ...getMixpanelProperties(form.getState().values as EditForm)
                          })
                          setCustomerUserEditMode(undefined)
                          form.reset()
                        }}
                      />
                    </Tooltip>
                  </Fragment>
                )
              }}
            />
          )
        } else {
          const shouldDisableButtons = !!customerUserEditMode
          content = (
            <Fragment>
              {customerUserStatus && (
                <Tooltip className='tooltip' text='Edit'>
                  <IconButton
                    icon='edit'
                    disabled={shouldDisableButtons}
                    onClick={() => setCustomerUserEditMode(customerUser)}
                  />
                </Tooltip>
              )}
              {customerUserStatus === 'pending' && (
                <Tooltip className='tooltip' text='Resend Invite'>
                  <IconButton
                    icon='send'
                    disabled={shouldDisableButtons}
                    onClick={async ev => {
                      ev.preventDefault()
                      try {
                        await resendInvite(customerKey, customerUser.username, customerUser.email)
                        trackMixpanelEvent(RESEND_INVITE)
                        openNotification({
                          type: 'success',
                          text: RESEND_INVITE_SUCCESS_MESSAGE
                        })
                      } catch (err) {
                        openNotification({
                          type: 'error',
                          text: RESEND_INVITE_FAILURE_MESSAGE
                        })
                      }
                    }}
                  />
                </Tooltip>
              )}
            </Fragment>
          )
        }
        return <div className='actions'>{content}</div>
      }
    }
  ]

  const resetEditionMode = (form: FormApi<EditForm, Partial<EditForm>>) => {
    setCustomerUserEditMode(undefined)
    form.reset()
  }

  const getMixpanelProperties = ({roles, userStatus}: EditForm) => {
    const statusChanged =
      customerUserEditMode?.userStatus !== userStatus
        ? CUSTOMER_USERS_STATUS.find(status => status.value === userStatus)?.label
        : undefined
    const currentRolesLabel = ROLE_OPTIONS.find(roleOption => roleOption.value === roles)?.label
    const roleChanged =
      customerUserEditMode?.roles !== currentRolesLabel ? currentRolesLabel : undefined

    return {
      ...(statusChanged && {statusChanged}),
      ...(roleChanged && {roleChanged})
    }
  }

  const onSubmit = async (
    values: EditForm,
    form: FormApi<EditForm, Partial<EditForm>>
  ): Promise<void> => {
    const {roles, userStatus} = values
    let savedChanges = true
    try {
      await updateCustomerUser(customerKey, customerUserEditMode?.username, roles, userStatus)
      await customerUsersMutate()
      resetEditionMode(form)
      openNotification({
        type: 'success',
        text: UPDATE_CUSTOMER_USER_SUCCESS_MESSAGE
      })
    } catch (err) {
      openNotification({
        type: 'error',
        text: UPDATE_CUSTOMER_USER_ERROR_MESSAGE
      })
      savedChanges = false
    }

    trackMixpanelEvent(EDIT_CUSTOMER_USER, {
      savedChanges,
      ...getMixpanelProperties(values)
    })
  }

  return (
    <CustomerUsersTable.Styled className={classnames(className)}>
      <MUITableContainer>
        <Form
          onSubmit={onSubmit}
          render={({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit}>
                <MUITable stickyHeader {...getSqaId('table', sqaPrefix)}>
                  <MUITableHead {...getSqaId('table-head', sqaPrefix)}>
                    <MUITableRow>
                      {HEADER_CELLS.map(headerCell => {
                        const sortOrder = sortConfig.find(
                          config => headerCell.id === config.sortKey
                        )?.sortOrder
                        const shouldShowSortIcon = Boolean(sortOrder)

                        return (
                          <MUITableCell
                            key={headerCell.id}
                            className='header-sticky'
                            style={headerCell.style}
                            onClick={() => {
                              requestSort(headerCell.id)
                              const sortColumn = HEADER_CELLS.find(
                                cell => cell.id === headerCell.id
                              )
                              trackMixpanelEvent(CUSTOMER_USER_CLICKED_SORT, {
                                Component: sortColumn?.label
                              })
                            }}
                          >
                            <div className='header-cell'>
                              {headerCell.label}
                              {shouldShowSortIcon ? (
                                <Icon
                                  className='sort-icon'
                                  icon={sortOrder === 'asc' ? 'arrow_downward' : 'arrow_upward'}
                                />
                              ) : null}
                            </div>
                          </MUITableCell>
                        )
                      })}
                    </MUITableRow>
                  </MUITableHead>
                  <MUITableBody {...getSqaId('table-body', sqaPrefix)}>
                    {sortedCustomerUsers.map((customerUser, index) => (
                      <MUITableRow
                        key={customerUser.username}
                        {...getSqaId('row', sqaPrefix, index.toString())}
                      >
                        {HEADER_CELLS.map(headerCell => {
                          return (
                            <MUITableCell
                              className={className}
                              key={`${customerUser.username}-${headerCell.id}`}
                              {...getSqaId(`row-${headerCell.id}-cell`, sqaPrefix)}
                            >
                              {headerCell.render(customerUser)}
                            </MUITableCell>
                          )
                        })}
                      </MUITableRow>
                    ))}
                  </MUITableBody>
                </MUITable>
              </form>
            )
          }}
        />
      </MUITableContainer>
    </CustomerUsersTable.Styled>
  )
}

CustomerUsersTable.Styled = styled.div`
  display: flex;
  padding: 16px 24px;
  overflow: hidden;

  .portal-MuiTableContainer-root {
    flex-grow: 1;

    .portal-MuiTable-root {
      table-layout: fixed;

      .portal-MuiTableCell-root {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .portal-MuiTableCell-stickyHeader {
        width: 100%;
        background-color: ${({theme}) => theme.colors.grayscale.white};
        box-shadow: inset 0px -1px 0px ${({theme}) => theme.colors.stone.dark};
        line-height: 22px;
        padding: 8px 8px 12px 8px;

        .header-cell {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          font-weight: 600;
          font-size: 0.875rem;

          .sort-icon {
            font-size: 12px;
            margin-left: 5px;
          }
        }

        &:last-child {
          pointer-events: none;
        }
      }

      .portal-MuiTableCell-body {
        padding: 14px 8px;

        &:last-child {
          padding: 8px 0;
        }

        .portal-MuiFormControl-root {
          padding-bottom: 0;
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 10px;

    .submit-button {
      color: ${({theme}) => theme.colors.emerald.base};

      &-disabled {
        color: ${({theme}) => theme.colors.stone.base};
      }
    }

    .cancel-button {
      color: ${({theme}) => theme.colors.ruby.base};
    }

    .progress {
      width: 24px;
      height: 24px;
    }
  }

  .tooltip {
    border: none;
  }
`

export default CustomerUsersTable

// Core
import React from 'react'

// Components, services, constants
import Card from 'components/Card'
import FlatList from 'components/FlatList'
import LabelValuePair from 'components/LabelValuePair'

// 3rd party
import styled from 'styled-components/macro'

type Props = {
  className?: string
  selectorData: any
}

const PropertyDetailsCard = ({selectorData}: Props) => {
  const {bath, bedroomCount, gla, lastValueEffectiveDate, lotSize, propertyTypeName} = selectorData

  const orderDetailsData = [
    {
      key: 'lastValueEffectiveDate',
      content: (
        <LabelValuePair
          label='Sale Date'
          value={lastValueEffectiveDate}
          sqaPrefix='lastValueEffectiveDate'
        />
      )
    },
    {
      key: 'propertyTypeName',
      content: (
        <LabelValuePair
          label='Property Type'
          value={propertyTypeName}
          sqaPrefix='propertyTypeName'
        />
      )
    },
    {
      key: 'bedroomCount',
      content: <LabelValuePair label='Bed' value={bedroomCount} sqaPrefix='bedroomCount' />
    },
    {
      key: 'bath',
      content: <LabelValuePair label='Bath' value={bath} sqaPrefix='bath' />
    },
    {
      key: 'gla',
      content: <LabelValuePair label='GLA' value={gla} sqaPrefix='gla' />
    },
    {
      key: 'lotSize',
      content: <LabelValuePair label='Lot Size' value={lotSize} sqaPrefix='lotSize' />
    }
  ]

  return (
    <PropertyDetailsCard.Styled>
      <FlatList className='order-details' items={orderDetailsData} withDividers />
    </PropertyDetailsCard.Styled>
  )
}

PropertyDetailsCard.Styled = styled(Card)`
  flex-grow: 1;
  flex-basis: 50%;
`

export default PropertyDetailsCard

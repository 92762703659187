// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'

// Components, services, etc
import Icon from 'components/Icon'

// 3rd-party
import classnames from 'classnames'
import type {ReactNode} from 'react'
import type {ThemeType} from 'styles/styledComponentsTheme'
import {getSqaId} from 'services/testingServices'
export type IconButtonVariant = 'dark' | 'light' | 'inherit'

interface BaseProps {
  className?: string
  variant: IconButtonVariant
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  innerRef?: React.RefObject<HTMLButtonElement>
  size: 'small' | 'medium' | 'large'
  sqaPrefix?: string
}
interface IconProps extends BaseProps {
  icon: string
}
interface ChildrenProps extends BaseProps {
  children: ReactNode
}

type IconButtonProps = IconProps | ChildrenProps

const IconButton = ({
  className,
  disabled,
  onClick,
  variant,
  innerRef,
  size,
  sqaPrefix,
  ...rest
}: IconButtonProps) => {
  return (
    <Styled
      ref={innerRef}
      className={classnames('icon-button', className)}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      $size={size}
      {...getSqaId('button', sqaPrefix)}
    >
      {(rest as ChildrenProps).children || <Icon icon={(rest as IconProps).icon} />}
    </Styled>
  )
}

const getColor = (color: 'dark' | 'light' | 'inherit', theme: ThemeType) => {
  const dictionary = {
    dark: {
      bg: theme.colors.stone.dark,
      color: theme.colors.stone.light
    },
    light: {
      bg: theme.colors.stone.light,
      color: theme.colors.stone.dark
    },
    inherit: {
      bg: 'rgba(255, 255, 255, 0.4)',
      // set color to invalid so color of icon remains the same
      color: 'invalid'
    }
  }
  return dictionary[color]
}

const sizeMap = {
  small: 20,
  medium: 24,
  large: 28
}

export const Styled = styled.button<{variant: IconButtonVariant; $size: keyof typeof sizeMap}>`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: ${({variant, theme}) => (variant === 'inherit' ? variant : theme.colors.stone.base)};
  cursor: pointer;
  display: flex;
  flex: none;
  padding: 6px;
  justify-content: center;
  user-select: none;
  /* disable the focus ring for mouse, touch and keyboard users. */
  outline: 0;
  &::-moz-focus-inner {
    /* Remove Firefox dotted outline. */
    border-style: 'none';
  }

  .material-icons {
    font-size: ${({$size}) => sizeMap[$size]}px;
  }

  &:hover {
    background-color: ${({variant, theme}) => getColor(variant, theme).bg};
    color: ${({variant, theme}) => getColor(variant, theme).color};
  }

  &:disabled {
    pointer-events: none;
    color: ${({variant, theme}) =>
      variant === 'dark' ? theme.colors.stone.dark : theme.colors.stone.light};
  }

  &:active {
    transform: scale(0.96);
  }
`

IconButton.defaultProps = {
  variant: 'light',
  size: 'medium'
}

export default IconButton

// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import type {ReactNode} from 'react'

// 3rd-party
import classnames from 'classnames'

export type ItemProps = {
  className?: string
  content: ReactNode
  key: string
}

type Props = {
  className?: string
  items: Array<ItemProps>
  withDividers?: boolean
}

const FlatList = ({className, items, withDividers}: Props) => {
  return (
    <FlatList.Styled className={classnames('flat-list', className)} withDividers={withDividers}>
      {items.map(item => {
        return <FlatList.Item key={item.key} item={item} />
      })}
    </FlatList.Styled>
  )
}

FlatList.Item = ({item}: {item: ItemProps}) => {
  return <li className={classnames('flat-list-item', item.className)}>{item.content}</li>
}

FlatList.Styled = styled.ul<{withDividers?: boolean}>`
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 100%;

  .flat-list-item {
    min-height: 48px;
    max-width: 100%;
    box-shadow: ${({withDividers, theme}) =>
      /* On non-retina displays, firefox and safari round partial pixels, so we need .51px to display divider in those cases */
      withDividers ? `inset 0px -0.51px 0px ${theme.colors.stone.light}` : 'none'};
    display: flex;
    align-items: center;

    /* IE11 fix for centered flex items with min-height: https://github.com/philipwalton/flexbugs/issues/231#issuecomment-362790042 */
    &:after {
      content: '';
      min-height: inherit;
      display: block;
      font-size: 0;
    }

    &:last-of-type {
      box-shadow: none;
    }
  }
`

export default FlatList

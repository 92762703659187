import React, {forwardRef} from 'react'
import type {PropsWithChildren, ReactElement} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

export type DetailsSectionProps = PropsWithChildren<{
  className?: string
  title: string
  subtitle?: string
  renderHeaderRight?: () => ReactElement
}>

const DetailsSection = forwardRef<HTMLDivElement, DetailsSectionProps>(
  ({className, title, subtitle, children, renderHeaderRight}: DetailsSectionProps, ref) => {
    const hasSubtitle = !!subtitle

    return (
      <StyledSection className={classnames('section', className)} ref={ref}>
        <header>
          <div className='header-left'>
            <h4 className={classnames({title: true, 'title-smaller': hasSubtitle})}>{title}</h4>
            <h5 className='subtitle'>{subtitle}</h5>
          </div>
          {renderHeaderRight && <div className='header-right'>{renderHeaderRight?.()}</div>}
        </header>
        <div className='content-wrapper'>{children}</div>
      </StyledSection>
    )
  }
)

const StyledSection = styled.div`
  background-color: ${({theme}) => theme.colors.stone.lighter};
  border-radius: 6px;
  padding: 24px;

  header {
    display: flex;

    .header-left {
      flex: 1 0 0;

      .title {
        margin-bottom: 16px;

        &-smaller {
          margin-bottom: 8px;
        }
      }

      .subtitle {
        color: ${({theme}) => theme.colors.grayscale.gray};
        font-weight: 400;
        margin-bottom: 16px;
      }
    }
  }

  .content-wrapper {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
  }
`

export default DetailsSection

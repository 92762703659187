import {SET_USER_ERROR, SET_USER_LOADED, SET_USER_LOADING} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import type {GetUserResponse} from 'types/authenticationTypes'

type UserState = {
  status: ReduxStateStatus
  data: GetUserResponse
  error: null
}
const initialState: UserState = {
  status: UNSET,
  data: {} as GetUserResponse,
  error: null
}

type UserAction =
  | {
      type: typeof SET_USER_ERROR
      payload: UserState['error']
    }
  | {type: typeof SET_USER_LOADING}
  | {
      type: typeof SET_USER_LOADED
      payload: UserState['data']
    }

export default function userReducer(state = initialState, action: UserAction): UserState {
  const {type} = action

  switch (type) {
    case SET_USER_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_USER_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_USER_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    default:
      return state
  }
}

// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment} from 'react'

// Components, services, etc
import Icon from 'components/Icon'
import {NO_DATA} from 'constants/appConstants'
import type {MetaData} from 'types/metaDataTypes'
import {getSqaId} from 'services/testingServices'

type Props = {
  metaData: Array<MetaData>
  sqaPrefix?: string
}

const MetaDataList = ({metaData, sqaPrefix = ''}: Props) => {
  return (
    <MetaDataList.Styled className='meta-data'>
      {metaData.map((item, index) => {
        const {key, value, labelPrefix, labelSuffix} = item
        let stringValue
        if (value === null || value === undefined) {
          stringValue = NO_DATA
        } else {
          stringValue = value
          if (labelPrefix) {
            stringValue = `${labelPrefix} ${stringValue}`
          }
          if (labelSuffix) {
            stringValue = `${stringValue} ${labelSuffix}`
          }
        }
        return (
          <Fragment key={key}>
            {index !== 0 && <Icon className='separator' icon='lens' />}
            <span {...getSqaId(key, sqaPrefix)}>{stringValue}</span>
          </Fragment>
        )
      })}
    </MetaDataList.Styled>
  )
}

MetaDataList.Styled = styled.div`
  align-items: center;
  color: ${({theme}) => theme.colors.grayscale.gray};
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 22px;

  .separator {
    display: inline-block;
    font-size: 6px;
    margin: 0 8px;
  }
`

export default MetaDataList

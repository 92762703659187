import React from 'react'
import IconButton from 'components/IconButton/IconButton'
import type {IconButtonVariant} from 'components/IconButton/IconButton'

type Props = {
  variant?: IconButtonVariant
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const CloseButton = ({variant, onClick}: Props) => (
  <IconButton className='close-button' variant={variant} icon='close' onClick={onClick} />
)

export default CloseButton

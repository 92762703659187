import {
  SET_AUTH_ERROR,
  SET_AUTH_LOADED,
  SET_AUTH_LOADING,
  SET_BASIC_AUTH_TOKEN
} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'

type AuthState = {
  status: ReduxStateStatus
  data: {
    isUserAuthenticated: boolean
    isUserManager: boolean
    authentication?: {
      // seems no use of this
      basicToken?: string
    }
  }
  error?: null
}

const initialState: AuthState = {
  status: UNSET,
  data: {
    isUserAuthenticated: false,
    isUserManager: false
  },
  error: null
}

type AuthAction =
  | {
      type: typeof SET_AUTH_ERROR
      payload: AuthState['error']
    }
  | {
      type: typeof SET_AUTH_LOADING
    }
  | {
      type: typeof SET_AUTH_LOADED
      payload: {
        isUserAuthenticated: boolean
        isUserManager: boolean
      }
    }
  | {
      type: typeof SET_BASIC_AUTH_TOKEN
      payload?: string
    }

export default function authReducer(state = initialState, action: AuthAction): AuthState {
  const {type} = action

  switch (type) {
    case SET_AUTH_ERROR:
      return {
        ...state,
        data: {
          isUserAuthenticated: false,
          isUserManager: false
        },
        status: ERROR,
        error: action.payload
      }
    case SET_AUTH_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_AUTH_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    case SET_BASIC_AUTH_TOKEN:
      return {
        ...state,
        data: {
          ...state.data,
          authentication: {
            ...state.data.authentication,
            basicToken: action.payload
          }
        }
      }
    default:
      return state
  }
}

import {createTheme} from '@material-ui/core/styles'
import colors from './colors'
import muiThemeOverrides from './muiThemeOverrides'

const muiTheme = createTheme({
  typography: {
    // @ts-ignore seems need a string or object
    fontFamily: ['Inter', 'Roboto', 'Arial', '"Times New Roman"', 'sans-serif']
  },
  palette: {
    primary: {
      main: colors.gold.base
    },
    error: {
      main: colors.ruby.base
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true
    }
  },
  overrides: {
    ...muiThemeOverrides
  }
})

export default muiTheme

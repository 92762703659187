// Styles
import styled from 'styled-components/macro'
import {makeStyles} from '@material-ui/core/styles'

// Core
import React, {ReactNode} from 'react'

// Components, services, etc
import colors from 'styles/colors'

// 3rd-party
import classnames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import MuiRadioGroup, {RadioGroupProps} from '@material-ui/core/RadioGroup'

type Props = {
  className?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  label?: string
  onChange: RadioGroupProps['onChange']
  options: Array<{
    disabled?: boolean
    value: string
    label: ReactNode
  }>
  value: string
}

const useStyles = makeStyles({
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    border: `2px solid ${colors.stone.base}`,
    backgroundColor: colors.grayscale.white,
    'input:disabled ~ &': {
      border: `2px solid ${colors.stone.light}`,
      background: colors.stone.lighter
    }
  },
  checkedIcon: {
    backgroundColor: colors.stone.dark,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: colors.grayscale.white,
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: colors.stone.dark
    },
    'input:disabled ~ &': {
      border: `2px solid ${colors.stone.light}`,
      background: colors.stone.light
    }
  }
})

const StyledRadio = (props: {}) => {
  const classes = useStyles()
  return (
    <Radio
      // @ts-ignore seems no classes.root?
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

const RadioGroup = ({
  className,
  disabled,
  error,
  helperText,
  label,
  onChange,
  options,
  value
}: Props) => {
  return (
    <RadioGroup.Styled
      className={classnames('radio-group-wrapper', className)}
      disabled={disabled}
      error={error}
    >
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <MuiRadioGroup value={value} onChange={onChange}>
        {options.map(({disabled, value, label}) => (
          <FormControlLabel
            key={value}
            disabled={disabled}
            value={value}
            label={label}
            control={<StyledRadio />}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </RadioGroup.Styled>
  )
}

RadioGroup.Styled = styled(FormControl)`
  .portal-MuiFormControlLabel-label {
    margin-left: 1px;
  }
`

export default RadioGroup

import {SUPPORT_CENTER_URL, TERM_OF_USE_URL} from 'constants/appConstants'

export const APP_FOOTER_LINKS = [
  {
    title: 'Support Center',
    href: SUPPORT_CENTER_URL
  },
  {
    title: 'Terms of Service',
    href: TERM_OF_USE_URL
  },
  {
    title: 'Privacy Policy',
    href: 'https://www.clearcapital.com/privacy-policy/'
  },
  {
    title: 'CCPA',
    href: 'https://www.clearcapital.com/ccpa/'
  }
]

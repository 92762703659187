export const ACCESS_DETAILS_TABLE_HEADER = [
  {
    id: 'type',
    label: 'Contact Type',
    style: {width: '15%'}
  },
  {
    id: 'name',
    label: 'Name',
    style: {width: '20%'}
  },
  {
    id: 'phoneNumbers',
    label: 'Phone',
    style: {width: '35%'}
  },
  {
    id: 'email',
    label: 'Email',
    style: {width: '30%'}
  }
] as const

import React, {Fragment, useState} from 'react'
import styled from 'styled-components'
import {Form, Field} from 'react-final-form'
import {FormApi} from 'final-form'
import {useHistory, useLocation} from 'react-router-dom'

import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {SEARCHED_ADDRESS} from 'constants/mixpanelConstants'
import {getMixpanelPage, trackMixpanelEvent} from 'services/mixpanel'
import {getPropertyUrlByAddress} from 'services/productNavigationServices'
import {useAppDispatch} from 'services/store'
import {getPropertyByAddress, setPropertySearching} from 'actions/propertyActions'
import type {QueryAddressType} from 'actions/propertyActions'
import {openNotification} from 'services/notificationServices'
import {ADDRESS_SEARCH_INPUTS_REQUIRED} from 'constants/validationErrorMessagesConstants'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredValidationRules} from 'constants/validationRulesConstants'
import GoogleAutocomplete, {AddressObject} from 'components/GoogleAutocomplete'
import Select from 'components/Select'
import STATE_SELECT_OPTIONS from 'constants/statesConstants'
import {ADDRESS_SEARCH_ERROR} from 'constants/notificationMessageConstants'
import {useSelector} from 'react-redux'
import {propertySearchingSelector} from 'selectors'

type SearchForm = {
  address?: string
  city?: string
  state?: string
  zipcode?: string
  unitNumber?: string
}
interface AddressSearchProps {
  shouldShowSearchButton?: boolean
}

const AddressSearch = ({shouldShowSearchButton = true}: AddressSearchProps): JSX.Element => {
  const [shouldShowUnitField, setShouldShowUnitField] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const searching = useSelector(propertySearchingSelector)

  const onSubmit = async (values: SearchForm): Promise<void> => {
    dispatch(setPropertySearching(true))
    trackMixpanelEvent(SEARCHED_ADDRESS, {
      page: getMixpanelPage(location.pathname),
      component: 'page'
    })

    try {
      const {address, city, state, zipcode, unitNumber} = values
      const queryAddress = {
        addressLine1: address,
        city,
        state,
        zip: zipcode,
        apartmentNumber: unitNumber
      } as QueryAddressType

      dispatch(getPropertyByAddress(queryAddress))
        .then(propertyData => {
          const url = getPropertyUrlByAddress({
            addressLine1: propertyData?.address?.displayAddressLine1,
            city: propertyData?.address?.city,
            state: propertyData?.address?.state,
            zip: propertyData?.address?.zip,
            apartmentNumber: propertyData?.address?.standardizedAddress?.unitNum
          })

          if (url && url !== location.pathname) {
            history.push(url)
          } else {
            throw new Error('Unable to load the provided address.')
          }
        })
        .finally(() => {
          dispatch(setPropertySearching(false))
        })
    } catch (err) {
      dispatch(setPropertySearching(false))
      openNotification({
        type: 'error',
        text: ADDRESS_SEARCH_ERROR
      })
    }
  }

  const onAddressSelect = (
    selectedAddress: AddressObject,
    form: FormApi<SearchForm, Partial<SearchForm>>
  ) => {
    const {address, city, state, zipcode} = selectedAddress
    form.change('address', address)
    form.change('city', city)
    form.change('state', state)
    form.change('zipcode', zipcode)
  }

  return (
    <AddressSearch.Styled>
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, hasValidationErrors, submitFailed, form}) => {
          return (
            <form
              onSubmit={ev => {
                if (hasValidationErrors) {
                  openNotification({
                    type: 'error',
                    text: ADDRESS_SEARCH_INPUTS_REQUIRED
                  })
                }
                handleSubmit(ev)
              }}
            >
              <div className='container'>
                <div className='inputs'>
                  <div className='inputs-row'>
                    <Field
                      name='address'
                      validate={value =>
                        !getInputValidity(value, requiredValidationRules).fieldIsValid
                      }
                    >
                      {({input, meta}) => {
                        return (
                          <GoogleAutocomplete
                            inputValue={input.value}
                            error={meta.error && meta.submitFailed}
                            onInputChange={(value: string) => input.onChange(value)}
                            onAddressSelect={selectedAddress =>
                              onAddressSelect(selectedAddress, form)
                            }
                          />
                        )
                      }}
                    </Field>
                    {shouldShowUnitField && (
                      <Field name='unitNumber'>
                        {({input}) => {
                          return (
                            <TextInput
                              className='unit-text-input'
                              placeholder='Unit #'
                              value={input.value}
                              sqaPrefix='unit'
                              onChange={input.onChange}
                            />
                          )
                        }}
                      </Field>
                    )}
                  </div>
                  <div className='inputs-row'>
                    <Field
                      name='city'
                      validate={value =>
                        !getInputValidity(value, requiredValidationRules).fieldIsValid
                      }
                    >
                      {({input, meta}) => {
                        return (
                          <TextInput
                            className='city'
                            placeholder='City'
                            value={input.value}
                            error={meta.error && meta.submitFailed}
                            sqaPrefix='city'
                            onChange={input.onChange}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name='state'
                      validate={value =>
                        !getInputValidity(value, requiredValidationRules).fieldIsValid
                      }
                    >
                      {({input, meta}) => {
                        return (
                          <Select
                            className='state'
                            renderValue={() =>
                              STATE_SELECT_OPTIONS.find(item => item.value === input.value)
                                ?.label ?? <span className='state-placeholder'>State</span>
                            }
                            options={STATE_SELECT_OPTIONS}
                            value={input.value}
                            error={meta.error && meta.submitFailed}
                            sqaPrefix='state'
                            onChange={input.onChange}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name='zipcode'
                      validate={value =>
                        !getInputValidity(value, requiredValidationRules).fieldIsValid
                      }
                    >
                      {({input, meta}) => {
                        return (
                          <TextInput
                            className='zipcode'
                            placeholder='ZIP Code'
                            value={input.value}
                            error={meta.error && meta.submitFailed}
                            sqaPrefix='zipcode'
                            onChange={input.onChange}
                            inputProps={{
                              type: 'number'
                            }}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </div>
                {shouldShowSearchButton && (
                  <Button
                    className='search-button'
                    type='submit'
                    title={<Icon icon='search' className='search-button-icon' />}
                    disabled={searching || (hasValidationErrors && submitFailed)}
                    isLoading={searching}
                    sqaPrefix='submit'
                  />
                )}
              </div>
              {!shouldShowUnitField && (
                <Button
                  className='add-unit-button'
                  variant='tertiary'
                  data-sqa-id='add-unit-button'
                  title={
                    <Fragment>
                      <Icon icon='add_circle' className='add-circle-icon' />
                      <span>Add Unit #</span>
                    </Fragment>
                  }
                  onClick={() => setShouldShowUnitField(true)}
                />
              )}
            </form>
          )
        }}
      />
    </AddressSearch.Styled>
  )
}

AddressSearch.Styled = styled.div`
  width: 100%;
  height: 146px;

  .container {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    .inputs {
      width: 100%;

      &-row {
        display: flex;
        flex: 1 1 auto;
        gap: 8px;

        .portal-MuiOutlinedInput-input {
          height: 52px;
        }

        .portal-MuiFormControl-root {
          padding: 0;
        }

        .portal-MuiSelect-root {
          align-items: center;
          display: inline-flex;
          padding: 0 20px 0 12px;
        }

        .unit-text-input {
          max-width: 125px;
        }

        .city {
          flex: 1 1 auto;
        }

        .state {
          max-width: 150px;
          flex: 1 1 auto;

          &-placeholder {
            color: #757575;
            opacity: 0.8;
          }
        }

        .zipcode {
          max-width: 125px;
          appearance: textfield;
          flex: 1 1 auto;
          -moz-appearance: textfield;

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        &:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    .search-button {
      min-width: 52px;
      flex: 0 0 auto;
      padding: 0;

      &-icon {
        font-size: 1.75rem;
      }

      &:not([disabled]) {
        background: ${({theme}) => theme.colors.grayscale.black};

        .search-button-icon {
          color: ${({theme}) => theme.colors.grayscale.white};
        }
      }
    }
  }

  .add-unit-button {
    color: ${({theme}) => theme.colors.grayscale.black};
    padding: 0;
  }

  .add-circle-icon {
    width: 16px;
    color: ${({theme}) => theme.colors.grayscale.black};
    font-size: 1rem;
    margin-right: 4px;
  }
`

export default AddressSearch

import React, {HTMLAttributes, SyntheticEvent} from 'react'
import {toast, ToastOptions} from 'react-hot-toast'
import DOMPurify from 'dompurify'

import {getSqaId} from 'services/testingServices'

import {
  ActionType,
  ACTION_ERROR_MESSAGE_END,
  ACTION_ERROR_MESSAGE_PREFIX_BY_TYPE,
  ACTION_LIMIT_EXCEEDED_ERROR_MESSAGE_END
} from 'constants/notificationMessageConstants'

import Icon from 'components/Icon'
import {
  Notification,
  NotificationIcon,
  NotificationBody,
  NotificationActions,
  NotificationButton
} from 'components/Notification'
import {NotificationType, NOTIFICATION_CONFIG_MAP_BY_TYPE} from 'constants/notificationConstants'

export type Button = {
  text: string
  shouldClose?: boolean
  sqaPrefix?: string
  onClick: (se: SyntheticEvent<HTMLButtonElement>) => void
} & HTMLAttributes<HTMLButtonElement>

export const openNotification = ({
  type,
  text,
  buttons,
  options,
  sqaPrefix
}: {
  type: NotificationType
  text: string
  buttons?: Button[]
  options?: ToastOptions
  sqaPrefix?: string
}): string => {
  const {icon, duration, position} = NOTIFICATION_CONFIG_MAP_BY_TYPE[type]

  const onButtonSelect = (
    se: SyntheticEvent<HTMLButtonElement>,
    toastId: string,
    shouldClose: boolean,
    onClick: Button['onClick']
  ) => {
    if (shouldClose) {
      onClick(se)
      toast.remove(toastId)
    } else {
      onClick(se)
    }
  }

  return toast.custom(
    t => (
      <Notification className={t.visible ? '' : 'fade-out'} sqaPrefix={sqaPrefix}>
        <NotificationIcon variant={type}>
          <Icon icon={icon} />
        </NotificationIcon>
        <NotificationBody>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text, {USE_PROFILES: {html: true}})
            }}
          />
        </NotificationBody>
        {buttons && (
          <NotificationActions>
            {buttons.map(button => {
              const {text, shouldClose = true, onClick, sqaPrefix, ...buttonProps} = button
              return (
                <NotificationButton
                  key={text}
                  {...getSqaId('notification_button', sqaPrefix)}
                  {...buttonProps}
                  onClick={(se: SyntheticEvent<HTMLButtonElement>) =>
                    onButtonSelect(se, t.id, shouldClose, onClick)
                  }
                >
                  {text}
                </NotificationButton>
              )
            })}
          </NotificationActions>
        )}
      </Notification>
    ),
    {
      duration,
      position,
      ...options
    }
  )
}

export const closeNotification = (toastId: string): void => toast.remove(toastId)

export const getErrorMessageByActionType = (type: ActionType): string => {
  return type ? `${ACTION_ERROR_MESSAGE_PREFIX_BY_TYPE[type]} ${ACTION_ERROR_MESSAGE_END}` : ''
}

export const getLimitExceededErrorMessageByActionType = (type: ActionType): string => {
  return type
    ? `Maximum ${ACTION_ERROR_MESSAGE_PREFIX_BY_TYPE[
        type
      ].toLowerCase()} ${ACTION_LIMIT_EXCEEDED_ERROR_MESSAGE_END}`
    : ''
}

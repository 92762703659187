// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import type {ReactNode} from 'react'

// 3rd-party
import classnames from 'classnames'

type Props = {
  children: ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const MultiInputContainer = ({children, onClick, className}: Props) => {
  return (
    <MultiInputContainer.Styled onClick={onClick} className={classnames('multi-input', className)}>
      {children}
    </MultiInputContainer.Styled>
  )
}

MultiInputContainer.Styled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  background: ${({theme}) => theme.colors.grayscale.white};
  border: 1px solid ${({theme}) => theme.colors.stone.light};
  box-sizing: border-box;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

export default MultiInputContainer

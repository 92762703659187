import {openModal, resetModal} from 'actions/modalActions'
import Button from 'components/Button'
import Modal from 'components/Modal'
import {ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP} from 'constants/orderFormConstants'
import useGetProductGroup from 'hooks/useGetProductGroup'
import React, {Fragment} from 'react'
import {Form} from 'react-final-form'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {
  CONTACT_INITIAL_VALUE,
  INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP
} from 'constants/orderContactConstants'
import {createContact} from 'services/ordersServices'
import {convertContactTypes} from 'services/contactsServices'
import Contact from 'components/Contact'
import {Contacts, OpsOrderId} from 'types/orderTypes'
import {openNotification} from 'services/notificationServices'
import {
  ADD_CONTACT_SUCCESS_MESSAGE,
  FAILURE_ADD_CONTACT_MESSAGE
} from 'constants/notificationMessageConstants'
import useGetOrderData from 'hooks/useGetOrderData'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import {trackMixpanelEvent} from 'services/mixpanel'
import {ADD_CONTACT} from 'constants/mixpanelConstants'

interface AddContactModalProps {
  open: boolean
  handleClose: () => void
  opsOrderId: OpsOrderId
}

export default function AddContactModal({open, handleClose, opsOrderId}: AddContactModalProps) {
  const dispatch = useDispatch()
  const productGroup = useGetProductGroup()
  const {mutate} = useGetOrderData(opsOrderId)
  const orderFormConfig = ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP[productGroup]
  const sections = orderFormConfig?.sections

  const handleCloseAndResetModal = () => {
    handleClose()
    dispatch(resetModal())
  }

  const onClose = () => {
    handleCloseAndResetModal()
  }

  const onSubmit = async (values: {contacts: Contacts[]}) => {
    try {
      await createContact(opsOrderId, {contacts: convertContactTypes(values.contacts)})
      trackMixpanelEvent(ADD_CONTACT, {
        modal: 'Order Toggle Menu',
        'Contact Type': INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP[values.contacts[0].type]
      })
      mutate()
      openNotification({
        type: 'success',
        text: ADD_CONTACT_SUCCESS_MESSAGE
      })
      onClose()
    } catch {
      openNotification({
        type: 'error',
        text: FAILURE_ADD_CONTACT_MESSAGE
      })
    }
  }

  const onIgnoreUnsavedWork: (onClose: () => void) => () => void = () => () => {
    onClose()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      onClose()
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{contacts: [CONTACT_INITIAL_VALUE]}}
      mutators={{
        ...arrayMutators
      }}
      render={({
        dirty,
        dirtySinceLastSubmit,
        handleSubmit,
        hasSubmitErrors,
        submitting,
        hasValidationErrors,
        submitFailed
      }) => {
        return (
          <AddContactModal.Styled
            open={open}
            closeOnBackdropClick={false}
            disableEnforceFocus
            showCloseButton
            size='md'
            onClose={handleCloseModalRequest(dirty)}
          >
            <Modal.Header>Add Contact</Modal.Header>
            <Modal.Content>
              <div className='subtitle'>
                Include only contacts who can provide the vendor with interior access to building.
              </div>
              <FieldArray name='contacts'>
                {({fields}) => (
                  <Fragment>
                    <div className='all-contacts-container'>
                      {fields.map((name, index) => (
                        <Contact
                          key={name}
                          name={name}
                          inputs={sections?.CONTACTS_DETAILS?.inputs}
                          onDelete={() => fields.remove(index)}
                          shouldShowDeleteButton={false}
                        />
                      ))}
                    </div>
                  </Fragment>
                )}
              </FieldArray>
            </Modal.Content>
            <Modal.Footer>
              <Button
                variant='secondary'
                title='Cancel'
                size='large'
                onClick={handleCloseModalRequest(dirty)}
                sqaPrefix='cancel'
              />
              <Button
                className='btn-submit'
                variant='primary'
                disabled={
                  submitting ||
                  (submitFailed && hasValidationErrors) ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                title={submitting ? 'Adding Contact...' : 'Add Contact'}
                type='submit'
                size='large'
                onClick={handleSubmit}
                sqaPrefix='add-contact'
              />
            </Modal.Footer>
          </AddContactModal.Styled>
        )
      }}
    />
  )
}

AddContactModal.Styled = styled(Modal)`
  .subtitle {
    color: ${({theme}) => theme.colors.grayscale.gray};
    margin-bottom: 24px;
  }

  .row {
    &:first-child {
      margin-bottom: 0px;
    }
  }
`

// Styles
import styled from 'styled-components/macro'

// Core
import classnames from 'classnames'
import type {MouseEvent, MouseEventHandler, ReactNode} from 'react'
import React, {useEffect, useState} from 'react'

// Components, services, etc
import CloseButton from 'components/CloseButton'

// 3rd-party
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from '@material-ui/core'

type ModalContentProps = {
  children: ReactNode
  className?: 'string'
}

const Content = ({children, className}: ModalContentProps) => {
  return <DialogContent className={classnames('content', className)}>{children}</DialogContent>
}

Content.displayName = 'ModalContent'

type ModalHeaderProps = {
  children: ReactNode
  onClose?: () => any
  sqaPrefix?: string
  showCloseButton?: boolean
}

const Footer = ({children}: ModalContentProps) => {
  return children ? <DialogActions className='footer'>{children}</DialogActions> : null
}

Footer.displayName = 'ModalFooter'

const Header = ({children, onClose, showCloseButton, sqaPrefix}: ModalHeaderProps) => {
  return (
    <DialogTitle disableTypography className='header'>
      {children}
      {showCloseButton && <CloseButton onClick={onClose} />}
    </DialogTitle>
  )
}

// we add a displayName to Modal.Header so we can access it when mapping
// over the children in the modal above and can add the onClose prop
Header.displayName = 'ModalHeader'

type Props = {
  children: React.ReactElement<any, any>[]
  className?: string
  disableEnforceFocus: boolean
  size?: 'sm' | 'md' | 'lg'
  closeOnBackdropClick?: boolean
  closeOnEscapeKeyDown?: boolean
  open: boolean
  onClose: MouseEventHandler<HTMLButtonElement>
  showCloseButton?: boolean
  sqaPrefix?: string
  onExited?: DialogProps['onExit']
}

const Modal = ({
  children,
  className,
  disableEnforceFocus,
  size,
  closeOnBackdropClick,
  closeOnEscapeKeyDown,
  onClose,
  onExited,
  open,
  showCloseButton,
  sqaPrefix
}: Props) => {
  const [hasHeader, setHasHeader] = useState(false)
  const [hasFooter, setHasFooter] = useState(false)

  useEffect(() => {
    React.Children.forEach(children, child => {
      if (child.type.displayName === 'ModalHeader') {
        setHasHeader(true)
      } else if (child.type.displayName === 'ModalFooter') {
        setHasFooter(true)
      }
    })
  }, [children])

  return (
    <Modal.Styled
      className={className}
      disableEnforceFocus={disableEnforceFocus}
      fullWidth
      maxWidth={size}
      onClose={(event: MouseEvent<HTMLButtonElement>, reason) => {
        if (reason === 'backdropClick' && closeOnBackdropClick) {
          onClose(event)
        } else if (reason === 'escapeKeyDown' && closeOnEscapeKeyDown) {
          onClose(event)
        }
      }}
      TransitionProps={{
        onExited
      }}
      open={open}
      transitionDuration={500}
      size={size}
      // the $ prefix is specific to styled-components: prevent props meant to be consumed
      // by styled components from being passed to the underlying React node or rendered
      // to the DOM element: https://styled-components.com/docs/api#transient-props
      $hasHeader={hasHeader}
      $hasFooter={hasFooter}
    >
      {React.Children.map(children, child => {
        if (child.type.displayName === 'ModalHeader') {
          return React.cloneElement(child, {
            onClose,
            showCloseButton,
            sqaPrefix
          })
        }

        return child
      })}
    </Modal.Styled>
  )
}

Modal.Styled = styled(Dialog)<{size?: Props['size']; $hasHeader?: boolean; $hasFooter?: boolean}>`
  .header {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${({size}) => (size === 'sm' ? '24px 24px 16px 24px' : '24px')};
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    /* limit height so close button's padding doesn't make header too tall
     * height is set to line-height + padding top + padding bottom */
    height: ${({size}) => (size === 'sm' ? `${16 + 24 + 24}px` : `${24 + 24 + 24}px`)};

    .close-button {
      margin-right: -11px;

      i.material-icons {
        font-size: 24px;
      }
    }
  }

  /* we have to add the MuiDialogContent-root class to ensure we override
   * a MUI style that would otherwise take presedence over the styles below. */
  .content.portal-MuiDialogContent-root {
    padding-top: ${({$hasHeader}) => ($hasHeader ? 0 : '24px')};
    padding-right: 24px;
    padding-bottom: ${({$hasFooter}) => ($hasFooter ? '16px' : '24px')};
    padding-left: 24px;
  }

  .footer {
    align-items: center;
    border-top: 1px solid ${({theme}) => theme.colors.stone.light};
    display: flex;
    min-height: 62px;
    padding: ${({size}) => (size === 'sm' ? '16px 24px' : '24px')};
  }
`

Modal.defaultProps = {
  disableEnforceFocus: false,
  maxWidth: 'md',
  closeOnBackdropClick: true,
  closeOnEscapeKeyDown: true
}

Modal.Content = Content
Modal.Footer = Footer
Modal.Header = Header

export default Modal

import useSWR, {SWRConfiguration} from 'swr'
import {useSelector} from 'react-redux'

import {getProductGroups} from 'services/productsServices'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {ProductsResponse} from 'types/productTypes'
import {isOrderFormModalOpenSelector, propertyDataAddressSelector} from 'selectors'
import FetchApiError from 'services/FetchApiError'
import {defaultConfig} from 'swr/_internal'
import {MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED} from 'constants/orderFormConstants'

const useProductGroups = (swrConfig?: SWRConfiguration) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const address = useSelector(propertyDataAddressSelector)
  const isOrderFormOpen = useSelector(isOrderFormModalOpenSelector)

  const {data, error, isLoading, mutate} = useSWR<ProductsResponse>(
    isUserAuthenticated ? address || 'non-address' : null,
    getProductGroups,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      errorRetryInterval: 100,
      errorRetryCount: MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED,
      onErrorRetry(error, ...args) {
        if (isOrderFormOpen && error instanceof FetchApiError && error.response?.status === 404) {
          // To ensure the Products Group call succeeds, we retry it upon receiving a 404 error from the backend.
          // This is necessary because when a new customer places an order, the customer creation call is
          // asynchronous. Therefore, if the Products Group call precedes the customer creation in Jasper,
          // the call will fail.
          // @ts-ignore
          defaultConfig.onErrorRetry(error, ...args)
        }
      },
      ...swrConfig
    }
  )

  return {
    isProductGroupsLoading: isLoading,
    productGroups: data,
    productGroupsError: error,
    mutate
  }
}

export default useProductGroups

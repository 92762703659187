import {openModal, resetModal} from 'actions/modalActions'
import Button from 'components/Button'
import Modal from 'components/Modal'
import React from 'react'
import {Field, Form} from 'react-final-form'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {MessagesRequest, OpsOrderId} from 'types/orderTypes'
import {openNotification} from 'services/notificationServices'
import {
  COMPOSE_MESSAGE_SUCCESS_MESSAGE,
  FAILURE_COMPOSE_MESSAGE
} from 'constants/notificationMessageConstants'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import TextInput from 'components/TextInput'
import {composeMessage} from 'services/messageServices'
import useOrderMessages from 'hooks/useOrderMessages'
import {trackMixpanelEvent} from 'services/mixpanel'
import {SEND_MESSAGE} from 'constants/mixpanelConstants'
import {required} from 'services/formValidatorServices'
import {MAX_CHARACTERS_COMPOSE_MESSAGE} from 'constants/orderDetailsConstants'

interface ComposeMessageModalProps {
  open: boolean
  handleClose: () => void
  opsOrderId: OpsOrderId
}

export default function ComposeMessageModal({
  open,
  handleClose,
  opsOrderId
}: ComposeMessageModalProps) {
  const dispatch = useDispatch()
  const {mutate} = useOrderMessages(opsOrderId)

  const handleCloseAndResetModal = () => {
    handleClose()
    dispatch(resetModal())
  }

  const onClose = () => {
    handleCloseAndResetModal()
  }

  const onSubmit = async (values: MessagesRequest) => {
    try {
      await composeMessage(opsOrderId, {message: values.message})
      trackMixpanelEvent(SEND_MESSAGE)
      mutate()
      openNotification({
        type: 'success',
        text: COMPOSE_MESSAGE_SUCCESS_MESSAGE
      })
      onClose()
    } catch {
      openNotification({
        type: 'error',
        text: FAILURE_COMPOSE_MESSAGE
      })
    }
  }

  const onIgnoreUnsavedWork: (onClose: () => void) => () => void = () => () => {
    onClose()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      onClose()
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({
        dirty,
        dirtySinceLastSubmit,
        handleSubmit,
        hasSubmitErrors,
        submitting,
        hasValidationErrors,
        submitFailed
      }) => {
        return (
          <ComposeMessageModal.Styled
            open={open}
            closeOnBackdropClick={false}
            disableEnforceFocus
            showCloseButton
            size='md'
            onClose={handleCloseModalRequest(dirty)}
          >
            <Modal.Header>Compose Message</Modal.Header>
            <Modal.Content>
              <div className='subtitle'>
                Messages will be received by Clear Capital's support team.
              </div>
              <Field name='message' validate={required}>
                {({input, meta}) => (
                  <TextInput
                    className='message'
                    label='Message'
                    value={input.value}
                    onChange={e => input.onChange(e.target.value)}
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    autoFocus
                    multiline={true}
                    sqaPrefix='message'
                    inputProps={{
                      maxLength: MAX_CHARACTERS_COMPOSE_MESSAGE
                    }}
                  />
                )}
              </Field>
            </Modal.Content>
            <Modal.Footer>
              <Button
                variant='secondary'
                title='Cancel'
                size='large'
                onClick={handleCloseModalRequest(dirty)}
                sqaPrefix='cancel'
              />
              <Button
                className='btn-submit'
                variant='primary'
                disabled={
                  submitting ||
                  (submitFailed && hasValidationErrors) ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                title={submitting ? 'Sending Message...' : 'Send Message'}
                type='submit'
                size='large'
                onClick={handleSubmit}
                sqaPrefix='compose-message'
              />
            </Modal.Footer>
          </ComposeMessageModal.Styled>
        )
      }}
    />
  )
}

ComposeMessageModal.Styled = styled(Modal)`
  .subtitle {
    color: ${({theme}) => theme.colors.grayscale.gray};
    margin-bottom: 24px;
  }

  .message {
    .portal-MuiInputBase-input {
      min-height: 85px;
      max-height: 85px;
      overflow-y: scroll !important;
    }
  }

  .row {
    &:first-child {
      margin-bottom: 0px;
    }
  }
`

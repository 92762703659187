import {ContactType, PhoneType} from 'types/orderTypes'
import {
  optionalPhoneValidationRules,
  requiredPhoneValidationRules
} from './validationRulesConstants'
import {RuleType} from 'services/formValidationHelpers'

export const INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP: Record<ContactType, string> = {
  BORROWER: 'Borrower',
  HOMEOWNER: 'Home Owner',
  SELLING_AGENT: 'Selling Agent',
  LISTING_AGENT: 'Listing Agent',
  TENANT: 'Tenant',
  OTHER: 'Other'
}

export const PHONE_NUMBER_FIELDS_MAP: Record<
  PhoneType,
  {label: string; isOptional: boolean; validations?: Array<RuleType>}
> = {
  CELL_PHONE: {
    label: 'Cell Phone',
    isOptional: false,
    validations: requiredPhoneValidationRules
  },
  WORK_PHONE: {
    label: 'Work Phone',
    isOptional: true,
    validations: optionalPhoneValidationRules
  },
  HOME_PHONE: {
    label: 'Home Phone',
    isOptional: true,
    validations: optionalPhoneValidationRules
  },
  OTHER: {
    label: 'Other',
    isOptional: true,
    validations: optionalPhoneValidationRules
  }
}

export const CONTACT_INITIAL_VALUE = {
  type: '',
  firstName: '',
  email: '',
  phoneNumbers: [
    {
      type: 'CELL_PHONE',
      value: ''
    },
    {
      type: 'WORK_PHONE',
      value: ''
    },
    {
      type: 'HOME_PHONE',
      value: ''
    }
  ]
}

import React from 'react'
import styled from 'styled-components/macro'

import HeroBackgroundCCLogo from 'assets/hero-background-cc-logo.png'
import AddressSearch from 'components/AddressSearch'

type Props = {
  className?: String
  isLoggedIn?: boolean
}

const LandingHero = ({className, isLoggedIn = false}: Props) => {
  return (
    <LandingHero.Styled className='hero-section'>
      <section className='hero-address-section'>
        <h1>
          {isLoggedIn
            ? 'Welcome to the Portal'
            : 'Learn everything you need to know about a property.'}
        </h1>
        <p>Order valuations, data and analytics tools, and more for over 150 million properties.</p>
        <div className='address-search'>
          <AddressSearch />
        </div>
      </section>
    </LandingHero.Styled>
  )
}

LandingHero.Styled = styled.div`
  display: flex;
  width: 100vw;
  height: 360px;
  padding: 0 40px;
  justify-content: flex-start;
  background: url(${HeroBackgroundCCLogo}) no-repeat right,
    linear-gradient(179.29deg, rgba(255, 213, 102, 0.8) 4.76%, rgba(255, 194, 32, 0.8) 161.07%),
    linear-gradient(
      0deg,
      ${({theme}) => theme.colors.grayscale.white},
      ${({theme}) => theme.colors.grayscale.white}
    );

  .hero-address-section {
    width: 1360px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    h1 {
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 12px;
    }
    p {
      font-size: 1rem;
      line-height: 1.375rem;
      margin-bottom: 24px;
    }
    h1 + p {
      margin-top: 0px;
    }

    .address-search {
      max-width: 580px;
    }
  }
`

export default LandingHero

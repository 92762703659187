import React, {Fragment} from 'react'
import {Form} from 'react-final-form'
import styled from 'styled-components'
import arrayMutators from 'final-form-arrays'
import {useDispatch, useSelector} from 'react-redux'

import Modal from 'components/Modal'
import {resetModal} from 'actions/modalActions'
import {resetDocumentsState} from 'actions/documentsActions'
import UploadDocuments from 'components/UploadDocuments'
import Button from 'components/Button'
import useGetProductGroup from 'hooks/useGetProductGroup'
import {OpsOrderId} from 'types/orderTypes'
import useGetOrderData from 'hooks/useGetOrderData'
import {getConditionallyRequiredDocuments} from 'services/documentsServices'
import {ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP} from 'constants/orderFormConstants'
import {
  SET_AURA_REVISION_PROCESSING,
  SET_DOCUMENT_UPLOAD_FORM_SUBMITTED
} from 'constants/actionTypes'
import {documentsLoadingSelector, isAURAProcessingSelector} from 'selectors/documentsSelectors'

interface DocumentUploadModalProps {
  className?: string
  open: boolean
  handleClose: () => void
  opsOrderId?: OpsOrderId
}

export const DocumentUploadModal = ({
  opsOrderId,
  className,
  open,
  handleClose
}: DocumentUploadModalProps) => {
  const dispatch = useDispatch()
  const productGroup = useGetProductGroup()
  const {data} = useGetOrderData(opsOrderId)
  const productType = data?.productType
  const orderFormConfig = ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP[productGroup]
  const sections = orderFormConfig?.sections
  const filteredRequiredDocuments = getConditionallyRequiredDocuments(
    sections?.UPLOAD_DOCUMENTS?.requiredDocuments ?? [],
    {
      productType
    }
  )
  const isAURARevisionProcessing = useSelector(isAURAProcessingSelector)
  const isDocumentsLoading = useSelector(documentsLoadingSelector)
  const isLoading = isDocumentsLoading || isAURARevisionProcessing

  const handleCloseAndResetModal = () => {
    handleClose()
    dispatch(resetModal())
  }

  const onClose = () => {
    handleCloseAndResetModal()
    dispatch(resetDocumentsState())
  }

  const onSubmit = async () => {
    dispatch({
      type: SET_DOCUMENT_UPLOAD_FORM_SUBMITTED,
      payload: true
    })
    dispatch({
      type: SET_AURA_REVISION_PROCESSING,
      payload: true
    })
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      render={({
        dirtySinceLastSubmit,
        handleSubmit,
        hasSubmitErrors,
        submitting,
        hasValidationErrors,
        submitFailed
      }) => {
        return (
          <DocumentUploadModal.Styled
            className={className}
            open={open}
            closeOnBackdropClick={false}
            disableEnforceFocus
            showCloseButton
            size='md'
            onClose={onClose}
          >
            <Modal.Header>Upload Documents</Modal.Header>
            <Modal.Content>
              <Fragment>
                <div className='subtitle'>
                  Documents will be received by Clear Capital's support team
                </div>
                <UploadDocuments
                  className='upload-documents'
                  fieldName='customerDocumentList'
                  productGroup={productGroup}
                  requiredDocuments={filteredRequiredDocuments}
                />
              </Fragment>
            </Modal.Content>
            <Modal.Footer>
              <Button variant='secondary' title='Cancel' size='large' onClick={onClose} />
              <Button
                className='btn-submit'
                variant='primary'
                disabled={
                  submitting ||
                  isLoading ||
                  (submitFailed && hasValidationErrors) ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                title='Upload Document'
                type='submit'
                size='large'
                onClick={handleSubmit}
                isLoading={submitting || isLoading}
              />
            </Modal.Footer>
          </DocumentUploadModal.Styled>
        )
      }}
    />
  )
}

DocumentUploadModal.Styled = styled(Modal)`
  .subtitle {
    color: ${({theme}) => theme.colors.grayscale.gray};
  }
  .upload-documents {
    margin-top: 24px;
    .upload-documents-flat-list {
      margin-top: 16px;
      .flat-list-item {
        background-color: ${({theme}) => theme.colors.stone.lighter};
        padding: 24px;
      }
    }
  }
  .btn-submit {
    width: 165px;
  }
`

export default DocumentUploadModal

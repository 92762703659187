import {
  RESET_PROPERTY,
  SET_PROPERTY_ERROR,
  SET_PROPERTY_LOADED,
  SET_PROPERTY_LOADING,
  SET_PROPERTY_SEARCHING
} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import type {PropertyType} from 'types/propertyTypes'

type PropertyState = {
  status: ReduxStateStatus
  data: PropertyType
  error?: null
  searching: boolean
}
const initialState: PropertyState = {
  status: UNSET,
  data: {} as PropertyType,
  error: null,
  searching: false
}

type PropertyAction =
  | {type: typeof RESET_PROPERTY}
  | {
      type: typeof SET_PROPERTY_ERROR
      payload: PropertyState['error']
    }
  | {
      type: typeof SET_PROPERTY_LOADING
    }
  | {
      type: typeof SET_PROPERTY_LOADED
      payload: PropertyState['data']
    }
  | {type: typeof SET_PROPERTY_SEARCHING; payload: boolean}
export default function propertyReducer(
  state = initialState,
  action: PropertyAction
): PropertyState {
  const {type} = action

  switch (type) {
    case RESET_PROPERTY:
      return initialState
    case SET_PROPERTY_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_PROPERTY_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_PROPERTY_LOADED:
      return {
        ...state,
        status: LOADED,
        searching: false,
        data: action.payload,
        error: null
      }
    case SET_PROPERTY_SEARCHING:
      return {
        ...state,
        searching: action.payload
      }
    default:
      return state
  }
}

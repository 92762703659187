import React from 'react'

// Components, services, etc
import NavLinkWithRef from 'components/NavLinkWithRef'

// 3rd-party
import {Button as MUIButton} from '@material-ui/core'
import classnames from 'classnames'

type LinkType = {href: string}
type NavLinkType = {to: string}
type Props = {
  activeClassName?: string
  className?: string
  disabled?: boolean
  exact?: boolean
  isActive?: () => boolean
  location?: {
    key?: string
    pathname?: string
    search?: string
    hash?: string
    state?: {}
  }
  title?: string
  icon?: string
  target?: '_blank'
  variant: 'primary' | 'secondary' | 'tertiary' | 'warning'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  rel?: string
} & (LinkType | NavLinkType)

// Passing href to this component will make it an external link, otherwise it will
// be rendered as a React Router Link
const LinkButton = ({
  activeClassName,
  className,
  exact,
  isActive,
  location,
  variant,
  title,
  icon,
  disabled,
  target,

  // default this to a no-op
  onClick = () => {},
  ...rest
}: Props) => {
  const linkProps = (rest as LinkType).href
    ? // Even for links, we want to pass in the onClick for scenarios where we need to
      // support Mixpanel tracking events
      {href: (rest as LinkType).href, target, onClick}
    : {
        component: NavLinkWithRef,
        to: (rest as NavLinkType).to,
        activeClassName: classnames('link-button-active', activeClassName),
        exact,
        location,
        isActive,
        onClick
      }
  return (
    <MUIButton
      className={classnames('link-button', `${variant}-button`, className)}
      disabled={disabled}
      variant={variantMap[variant]}
      {...linkProps}
      // conditionally add props only in certain circumstances
      {...(variant === 'warning' ? {color: 'secondary'} : {})}
    >
      {title}
    </MUIButton>
  )
}

const variantMap = {
  primary: 'contained',
  secondary: 'outlined',
  tertiary: 'text',
  warning: 'contained'
} as const

LinkButton.defaultProps = {
  variant: 'primary'
}

export default LinkButton

import {useState, useMemo} from 'react'
import orderBy from 'lodash/orderBy'
import {SortOrder, SortMultipleConfig, SortConfig} from 'types/sortBuilderTypes'

const useSortableData = <T>(items: T[] | undefined, config: SortConfig[]) => {
  const [sortConfig, setSortConfig] = useState(config)

  const sortedItems = useMemo(() => {
    const sortKeysandSortOrder = sortConfig?.reduce<SortMultipleConfig>(
      (accumulator, current) => {
        accumulator.sortKey?.push(current.sortKey)
        accumulator.sortOrder?.push(current.sortOrder)
        return accumulator
      },
      {sortKey: [], sortOrder: []}
    )

    const sortableItems = orderBy(
      items,
      sortKeysandSortOrder?.sortKey,
      sortKeysandSortOrder?.sortOrder
    )

    return sortableItems
  }, [sortConfig, items])

  const requestSort = (sortKey: string) => {
    const configIndex = sortConfig.findIndex(config => config.sortKey === sortKey)
    if (configIndex === -1) {
      setSortConfig([{sortKey, sortOrder: 'asc'}])
    } else {
      if (config.length === 1) {
        const currentSortOrder = sortConfig[configIndex].sortOrder
        const updatedSordOrder: SortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc'
        const updatedSortConfig = [...sortConfig]
        updatedSortConfig[0].sortOrder = updatedSordOrder
        setSortConfig(updatedSortConfig)
      } else if (sortConfig.length > 1) {
        setSortConfig([sortConfig[configIndex]])
      }
    }
  }

  return {items: sortedItems, requestSort, sortConfig}
}

export default useSortableData

import React, {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link'

type LinkRouterProps = {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'initial'
    | 'textPrimary'
    | 'textSecondary'
  to: string
  children?: ReactNode
}
const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink} />

export default LinkRouter

import {createSelector} from 'reselect'
import type {RootState} from 'services/store'

export const rootDocumentsSelector = (state: RootState) => state.documents

export const documentsLoadingSelector = createSelector(
  [rootDocumentsSelector],
  documents => !!documents?.uploadedDocuments.find(({loading}) => loading)
)

export const documentsUploadedSelector = createSelector(
  [rootDocumentsSelector],
  documents => documents?.uploadedDocuments
)

export const documentsUploadFormSubmittedSelector = createSelector(
  [rootDocumentsSelector],
  documents => documents?.documentUploadFormSubmitted
)

export const isAURAProcessingSelector = createSelector(
  [rootDocumentsSelector],
  documents => documents?.processing
)

import React from 'react'
import styled from 'styled-components'
import {PaymentElement} from '@stripe/react-stripe-js'

import {PAYMENT_ELEMENT_OPTIONS} from 'constants/paymentMethodConstants'

const StripePaymentForm = () => {
  return <StripePaymentForm.Styled options={PAYMENT_ELEMENT_OPTIONS}></StripePaymentForm.Styled>
}

StripePaymentForm.Styled = styled(PaymentElement)`
  width: 438px;
`

export default StripePaymentForm

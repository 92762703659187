import React from 'react'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import CountdownTimer from 'components/CountdownTimer'
import {
  USER_IDLE_TIMEOUT_MODAL_TITLE,
  EXPIRES_IN,
  KEEP_WORKING,
  SIGN_OUT,
  USER_IDLE_TIMEOUT_MODAL_MESSAGE_BODY
} from 'constants/userIdleTimeoutConstants'
import {CountdownTimerProps} from 'components/CountdownTimer/CountdownTimer'

interface UserIdleTimeoutModalProps {
  className?: string
  open: boolean
  getRemainingTime: CountdownTimerProps['getRemainingTime']
  handleClose: () => void
  handleKeepWorking: () => void
  handleSignOut: () => void
}

const UserIdleTimeoutModal = ({
  className,
  open,
  getRemainingTime,
  handleClose,
  handleKeepWorking,
  handleSignOut
}: UserIdleTimeoutModalProps) => {
  const onKeepWorkingClick = () => {
    handleKeepWorking()
    handleClose()
  }

  const onSignOutClick = () => {
    handleSignOut()
    handleClose()
  }

  return (
    <UserIdleTimeoutModal.Styled
      className={className}
      open={open}
      closeOnBackdropClick={false}
      closeOnEscapeKeyDown={false}
      disableEnforceFocus
      onClose={handleClose}
    >
      <Modal.Header>{USER_IDLE_TIMEOUT_MODAL_TITLE}</Modal.Header>
      <Modal.Content>
        <p className='title'>{EXPIRES_IN}</p>
        <CountdownTimer className='countdown-timer' getRemainingTime={getRemainingTime} />
        <p className='text'>{USER_IDLE_TIMEOUT_MODAL_MESSAGE_BODY}</p>
      </Modal.Content>
      <Modal.Footer>
        <Button
          title={SIGN_OUT}
          size='large'
          variant='secondary'
          data-sqa-id='signout-button'
          onClick={onSignOutClick}
        />
        <Button
          title={KEEP_WORKING}
          size='large'
          data-sqa-id='keep-working-button'
          onClick={onKeepWorkingClick}
        />
      </Modal.Footer>
    </UserIdleTimeoutModal.Styled>
  )
}

UserIdleTimeoutModal.Styled = styled(Modal)`
  .title {
    margin: 0 0 12px;
  }

  .countdown-timer {
    margin-bottom: 12px;
  }

  .text {
    margin: 0 0 14px;
  }
`

export default UserIdleTimeoutModal

// Styles
import styled from 'styled-components/macro'

// Core
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// Components, services, etc
import LandingHero from 'components/LandingHero'
import ProductsList from 'views/Landing/ProductsList'
import {trackMixpanelEvent} from 'services/mixpanel'
import {VISITED_LANDING_PAGE} from 'constants/mixpanelConstants'

// 3rd party
import LandingDescription from './LandingDescription'
import {LANDING_DATA} from 'constants/landingConstants'
import Button from 'components/Button'
import {openModal} from 'actions/modalActions'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import {isUserAuthenticatedSelector} from 'selectors'
import {Divider} from '@material-ui/core'
import {LogInModeType} from 'types/loginSignUpModalTypes'

const Landing = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)

  // We only want to fire this once on page load
  useEffect(() => {
    // @ts-ignore this function need a second argument, can we pass {}
    trackMixpanelEvent(VISITED_LANDING_PAGE)
  }, [])

  const handleOpenModal = (mode: LogInModeType) => {
    dispatch(
      openModal({
        modalType: LOG_IN_SIGN_UP_MODAL,
        mode
      })
    )
  }

  return (
    <Landing.Styled>
      <LandingHero isLoggedIn={isLoggedIn} />
      <div className='landing-data-wrapper'>
        {!isLoggedIn && (
          <div className='landing-description-wrapper'>
            <div className='landing-data'>
              {LANDING_DATA.map(data => (
                <LandingDescription key={data.title} {...data} />
              ))}
              <div className='column'>
                <p className='join-title'>Join Portal Today </p>
                <Button
                  className='get-started-button'
                  variant='primary'
                  title='Get Started'
                  sqaPrefix='get-started'
                  onClick={() => handleOpenModal('signUp')}
                  size='large'
                  type='submit'
                />
              </div>
            </div>
            <Divider orientation='horizontal' />
          </div>
        )}

        <ProductsList />
      </div>
    </Landing.Styled>
  )
}

Landing.Styled = styled.div<{headerHeight?: number}>`
  .landing-data-wrapper {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;

    .landing-data {
      display: flex;
      padding: 40px 22px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .column {
        width: 216px;
        text-align: center;
      }

      .join-title {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 25px;
      }

      .get-started-button {
        width: 200px;
      }
    }

    @media (max-width: 1440px) {
      .landing-description-wrapper {
        padding-left: 40px;
      }
      .column {
        .join-title {
          text-align: left;
        }
        .get-started-button {
          display: flex;
        }
      }
    }
  }
`

export default Landing

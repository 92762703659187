import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {Field, useForm} from 'react-final-form'

import PasswordInput, {PasswordInputProps} from 'components/PasswordInput'
import Icon from 'components/Icon'
import {PASSWORD_REQUIREMENTS} from 'constants/passwordConstants'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredPasswordRules} from 'constants/validationRulesConstants'
import {getSqaId} from 'services/testingServices'

export interface PasswordInputWithRequirementsProps extends Omit<PasswordInputProps, 'onChange'> {
  fieldName?: string
}

const PasswordInputWithRequirements = ({
  fieldName = 'password',
  ...passwordInputProps
}: PasswordInputWithRequirementsProps): JSX.Element => {
  const {getFieldState} = useForm()
  const passwordField = getFieldState(fieldName)

  return (
    <PasswordInputWithRequirements.Styled>
      <Field
        id='password'
        name={fieldName}
        validate={value => getInputValidity(value, requiredPasswordRules).errorMsg}
      >
        {({input, meta}) => {
          let errorText: string
          if (meta.submitFailed && meta.error) {
            errorText = meta.error
          } else if (!meta.dirtySinceLastSubmit && meta.submitError) {
            errorText = meta.submitError
          } else {
            errorText = ''
          }
          return (
            <PasswordInput
              {...passwordInputProps}
              value={input.value}
              error={!!errorText}
              helperText={errorText}
              onChange={input.onChange}
            />
          )
        }}
      </Field>
      <div className='requirements'>
        <p className='requirements-title'>Your password must include the following</p>
        <ul className='requirements-list'>
          {PASSWORD_REQUIREMENTS.map(({text, regexp}, index) => {
            let icon
            let status = ''
            if (new RegExp(regexp).test(passwordField?.value ?? '')) {
              icon = 'check_circle'
              status = 'success'
            } else if (passwordField?.submitFailed && passwordField?.invalid) {
              icon = 'error'
              status = 'failed'
            }

            return (
              <li
                key={`${text}-${regexp}`}
                className={classNames('requirements-list-item', {
                  'requirements-list-item-with-icon': !!icon
                })}
                {...getSqaId(`requirement-${index}`)}
              >
                {icon ? (
                  <Icon
                    className={`requirements-list-item-icon requirements-list-item-with-icon-${status}`}
                    icon={icon}
                  />
                ) : (
                  <span className='requirements-list-item-bullet'>•</span>
                )}
                {text}
              </li>
            )
          })}
        </ul>
      </div>
    </PasswordInputWithRequirements.Styled>
  )
}

PasswordInputWithRequirements.Styled = styled.div`
  .requirements {
    margin-bottom: 27px;

    &-title {
      display: block;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
    }

    &-list {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-item {
        align-items: center;
        display: flex;

        &-icon,
        &-bullet {
          width: 16px;
          font-size: 1rem;
          margin-right: 4px;
        }

        &-bullet {
          text-align: center;
        }

        &-with-icon {
          &-success {
            color: ${({theme}) => theme.colors.emerald.base};
          }

          &-failed {
            color: ${({theme}) => theme.colors.ruby.base};
          }
        }
      }
    }
  }
`

export default PasswordInputWithRequirements

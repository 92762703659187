import {createSelector} from 'reselect'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {appRoutes} from 'constants/appRoutesConstants'
import {isUserManagerSelector} from './authSelectors'

export const availableRoutesSelector = createSelector(
  [isUserAuthenticatedSelector, isUserManagerSelector],
  (isLoggedIn, isUserManager) => {
    let result = []
    if (isLoggedIn) {
      result = appRoutes
      if (!isUserManager) {
        result = appRoutes.filter(({label}) => label !== 'Admin')
      }
    } else {
      result = appRoutes.filter(route => !route.authenticated)
    }
    return result
  }
)

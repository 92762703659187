import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {IIdleTimer} from 'react-idle-timer'

export interface CountdownTimerProps {
  className?: string
  getRemainingTime: IIdleTimer['getRemainingTime']
}

const CountdownTimer = ({className, getRemainingTime}: CountdownTimerProps) => {
  const [secondsRemaining, setSecondsRemaining] = useState(Math.floor(getRemainingTime() / 1000))
  const mins = Math.floor(secondsRemaining / 60)
  const secs = secondsRemaining - mins * 60

  useEffect(() => {
    let timerId: NodeJS.Timer | undefined
    if (!timerId) {
      timerId = setInterval(() => {
        setSecondsRemaining(Math.floor(getRemainingTime() / 1000))
      }, 500)
    }

    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [secondsRemaining, getRemainingTime])

  return (
    <CountdownTimer.Styled className={classnames(className)}>
      {mins > 0 && <span>{mins} min</span>} <span>{secs} secs</span>
    </CountdownTimer.Styled>
  )
}

CountdownTimer.Styled = styled.h2``

export default CountdownTimer

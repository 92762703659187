import colors from 'styles/colors'

const muiThemeOverrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        fontFamily: 'Inter'
      },
      'body, html, #root, .app': {
        backgroundColor: colors.stone.lighter
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.12)',
      backgroundColor: colors.stone.darker,
      color: colors.grayscale.white,
      marginTop: '4px !important',
      padding: '8px',
      fontSize: '12px',
      lineHeight: '20px'
    }
  },
  MuiSlider: {
    root: {
      color: colors.cobalt.base,
      height: 4,
      padding: '5px 0',
      '&.Mui-disabled .MuiSlider-track': {
        backgroundColor: colors.stone.light
      }
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: colors.cobalt.base,
      marginLeft: -10,
      marginTop: -8,
      '&.Mui-disabled': {
        height: 20,
        width: 20,
        backgroundColor: colors.grayscale.white,
        border: `2px solid ${colors.stone.light}`,
        marginLeft: -10,
        marginTop: -8
      },
      '&.MuiSlider-active': {
        boxShadow: '0px 0px 0px 14px rgba(212,232,246, 0.3)'
      },
      '&:hover': {
        boxShadow: '0px 0px 0px 8px rgba(212,232,246, 0.3)'
      }
    },
    track: {
      height: 4,
      borderRadius: 4
    },
    rail: {
      height: 4,
      borderRadius: 4,
      backgroundColor: colors.stone.light
    }
  },
  MuiButton: {
    root: {
      borderRadius: 6,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      padding: '7px 16px',
      textTransform: 'none',
      '&:active': {
        transform: 'scale(0.96)'
      }
    },
    contained: {
      backgroundColor: colors.gold.base,
      boxShadow: `0px 1px 3px rgba(128, 73, 6, 0.1), inset 0px -1px 0px rgba(204, 120, 17, 0.5), inset 0px 1px 0px ${colors.gold.light}`,
      color: colors.stone.darker,
      '&:hover': {
        backgroundColor: colors.gold.light,
        boxShadow: `0px 1px 3px rgba(128, 73, 6, 0.1), inset 0px -1px 0px rgba(204, 120, 17, 0.5), inset 0px 1px 0px ${colors.gold.light}`,
        color: colors.stone.darker
      },
      '&:disabled': {
        backgroundColor: colors.stone.lighter,
        color: colors.stone.base
      },
      '&:active': {
        boxShadow: `0px 1px 3px rgba(128, 73, 6, 0.1), inset 0px -1px 0px rgba(204, 120, 17, 0.5), inset 0px 1px 0px ${colors.gold.light}`
      }
    },
    containedSizeSmall: {
      padding: '5px 14px'
    },
    containedSizeLarge: {
      padding: '9px 18px'
    },
    // warning button styles
    containedSecondary: {
      backgroundColor: colors.ruby.base,
      boxShadow: `0px 1px 3px rgba(188, 3, 3, 0.16), inset 0px 1px 0px ${colors.ruby.light}, inset 0px -1px 0px rgba(135, 30, 30, 0.5)`,
      color: colors.grayscale.white,
      '&:hover': {
        backgroundColor: colors.ruby.light,
        boxShadow: `0px 1px 3px rgba(188, 3, 3, 0.16), inset 0px 1px 0px ${colors.ruby.light}, inset 0px -1px 0px rgba(135, 30, 30, 0.5)`,
        color: colors.grayscale.white
      },
      '&:disabled': {
        backgroundColor: colors.stone.lighter,
        color: colors.stone.base
      },
      '&:active': {
        boxShadow: `0px 1px 3px rgba(188, 3, 3, 0.16), inset 0px 1px 0px ${colors.ruby.light}, inset 0px -1px 0px rgba(135, 30, 30, 0.5)`
      }
    },
    outlined: {
      backgroundColor: colors.grayscale.white,
      border: `1px solid ${colors.stone.base}`,
      color: colors.stone.darker,
      // outlined buttons need to have 1px less padding than the standard buttons
      // due to their borders
      padding: '6px 15px',
      '&:hover': {
        backgroundColor: colors.stone.lighter,
        color: colors.stone.darker
      },
      '&:disabled': {
        // @ts-ignore colors.stone.white is not exists
        backgroundColor: colors.stone.white,
        border: `1px solid ${colors.stone.light}`,
        color: colors.stone.light
      }
    },
    // outlined buttons need to have 1px less padding than the standard buttons
    // due to their borders
    outlinedSizeSmall: {
      padding: '4px 13px'
    },
    outlinedSizeLarge: {
      padding: '8px 17px'
    },
    text: {
      color: colors.cobalt.dark,
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.cobalt.darker
      },
      '&:disabled': {
        color: colors.stone.base
      }
    }
  },
  MuiFormLabel: {
    root: {
      color: colors.grayscale.black,
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 600,
      '&$focused': {
        color: colors.grayscale.black
      },
      '&$error': {
        color: colors.grayscale.black
      },
      '&$disabled': {
        color: colors.grayscale.black
      }
    },
    filled: {
      '&:nth-child(2)': {
        display: 'none !important'
      }
    }
  },
  MuiFormControl: {
    root: {
      width: '100%',
      paddingBottom: 27
    }
  },
  MuiSelect: {
    selectMenu: {
      padding: '12px 20px 12px 12px'
    },
    outlined: {
      fontSize: 14,
      '&:focus': {
        borderRadius: 6,
        backgroundColor: colors.grayscale.white
      }
    }
  },
  MuiInputBase: {
    formControl: {
      fontSize: 14,
      'label + &': {
        marginTop: 20
      }
    }
  },
  MuiInputLabel: {
    outlined: {
      '&$shrink': {
        transform: 'none',
        fontSize: 12
      },
      '&:nth-child(2)': {
        fontSize: 14,
        transform: 'translate(12px, 34px) scale(1)',
        fontWeight: 400,
        color: colors.stone.base
      }
    }
  },
  MuiOutlinedInput: {
    input: {
      height: 40,
      padding: '0 20px 0 12px'
    },
    root: {
      borderRadius: 6,
      boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.1)',
      backgroundColor: colors.grayscale.white,
      '&$focused $notchedOutline': {
        borderColor: colors.grayscale.black,
        borderWidth: 1
      },
      '&$disabled': {
        backgroundColor: colors.stone.lighter,
        boxShadow: 'none',
        color: colors.grayscale.gray
      },
      '&$disabled $notchedOutline': {
        borderColor: colors.stone.lighter,
        boxShadow: 'none'
      }
    },
    notchedOutline: {
      borderColor: colors.stone.light,
      '& legend': {
        display: 'none'
      },
      top: 0
    },
    multiline: {
      padding: '9px 12px'
    }
  },
  MuiFormHelperText: {
    root: {
      position: 'absolute',
      bottom: 0,
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: colors.grayscale.gray
    },
    contained: {
      marginLeft: 0
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: 8
    },
    elevation8: {
      border: `0.5px solid ${colors.stone.light}`,
      boxShadow: '0px 1px 16px rgba(0, 0, 0, 0.12)'
    }
  },
  MuiMenuItem: {
    root: {
      fontSize: 14,
      minHeight: 39
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0
    },
    label: {
      fontSize: 14,
      marginLeft: 5,
      color: colors.grayscale.black,
      '&$disabled': {
        color: colors.stone.base
      }
    }
  },
  MuiCheckbox: {
    root: {
      padding: 0,
      color: colors.stone.base,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&$checked': {
        color: colors.stone.dark
      },
      '&$disabled': {
        color: colors.stone.light
      }
    }
  },
  MuiRadio: {
    // additional styles are handled in RadioGroup
    root: {
      padding: 3,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  },
  MuiTab: {
    root: {
      fontSize: 14,
      fontFamily: 'inherit',
      fontWeight: 500,
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 'inherit !important', // defaults to 160px otherwise
      minHeight: 40,
      textTransform: 'none',
      '&:hover': {
        color: colors.grayscale.black
      },
      '&$selected': {
        color: colors.grayscale.black
      },
      // this is the equivalent of "MuiTab-root + MuiTab-root"
      '& + &': {
        marginLeft: 24
      }
    },
    textColorInherit: {
      color: colors.grayscale.gray,
      opacity: 1
    },
    selected: {} // needed empty
  },
  MuiTabs: {
    root: {
      minHeight: 0
    },
    flexContainer: {
      justifyContent: 'left'
    },
    indicator: {
      height: 2,
      backgroundColor: colors.gold.base
    }
  },
  MuiDialog: {
    paperWidthSm: {
      maxWidth: 512
    },
    paperWidthMd: {
      maxWidth: 752
    },
    paperWidthLg: {
      maxWidth: 1248
    }
  },
  MuiChip: {
    root: {
      height: 'auto',
      padding: '2px 8px',
      borderRadius: '3px',
      fontSize: '12px'
    },
    label: {
      paddingLeft: 0,
      paddingRight: 0,
      lineHeight: '20px'
    },
    deleteIcon: {
      margin: '0 0 0 8px',
      color: colors.stone.base,
      width: '16px',
      height: '16px',
      fontSize: '16px',
      opacity: 0.48,
      '&:hover': {
        opacity: 1
      },
      '&:active': {
        transform: 'scale(0.96)'
      }
    }
  },
  MuiTable: {
    root: {
      borderCollapse: 'separate'
    }
  },
  MuiTableRow: {
    root: {
      '&$selected': {
        backgroundColor: 'transparent !important',

        '&:hover': {
          backgroundColor: colors.stone.lighter
        }
      }
    }
  },
  MuiTableCell: {
    root: {
      whiteSpace: 'nowrap',
      padding: 12,
      borderBottom: 'none',
      boxShadow: `inset 0px -0.5px 0px ${colors.stone.light}`
    },
    head: {
      fontWeight: 600,
      borderBottom: 'none',
      boxShadow: `inset 0px -0.5px 0px ${colors.stone.light}`
    }
  },
  MuiCircularProgress: {
    colorPrimary: {
      width: '100% !important',
      height: '100% !important',
      maxWidth: 48,
      maxHeight: 48,
      color: colors.cobalt.base
    }
  },
  MuiIconButton: {
    root: {
      padding: 6
    }
  }
} as const

export default muiThemeOverrides

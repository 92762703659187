import {useSelector} from 'react-redux'
import {orderByOpsOrderIdSelector} from 'selectors'
import useProductGroups from './useProductGroups'
import {getProductGroup} from 'services/productsServices'
import {useEffect, useState} from 'react'
import {AURA, CDA} from 'constants/productCardConstants'
import {
  ORDER_DETAILS_AURA_DATA,
  ORDER_DETAILS_CDA_DATA,
  ORDER_DETAILS_DATA
} from 'constants/orderDetailsConstants'

export default function useGetUpdatedOrderDetailsData() {
  const order = useSelector(orderByOpsOrderIdSelector)
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)
  const [orderDetailsData, setOrderDetailsData] = useState<
    typeof ORDER_DETAILS_DATA | typeof ORDER_DETAILS_CDA_DATA | typeof ORDER_DETAILS_AURA_DATA
  >(ORDER_DETAILS_DATA)

  useEffect(() => {
    if (productGroup === CDA) {
      setOrderDetailsData(ORDER_DETAILS_CDA_DATA)
    } else if (productGroup === AURA) {
      setOrderDetailsData(ORDER_DETAILS_AURA_DATA)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroup])

  return orderDetailsData
}

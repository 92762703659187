// Core
import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

// Components, services, etc
import {getSocialAuthTokens} from 'actions/authActions'
import {
  FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE,
  FEDERATED_LOGIN_REDIRECT_COOKIE,
  FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE
} from 'constants/appConstants'
import {getCookieValue, setCookieValue} from 'services/cookieServices'
import {getMixpanelPage, trackMixpanelEvent} from 'services/mixpanel'
import {getQueryParameter} from 'services/urlServices'

// 3rd-party
import CircularProgress from '@material-ui/core/CircularProgress'
import {useAppDispatch} from 'services/store'

const FederatedLoginLanding = () => {
  const dispatch = useAppDispatch()
  const code = getQueryParameter('code')
  const errorDescription = getQueryParameter('error_description')
  const [redirectPath, setRedirectPath] = useState<string>()
  useEffect(() => {
    const path = getCookieValue(FEDERATED_LOGIN_REDIRECT_COOKIE) ?? '/'
    const socialAuthUrl = getCookieValue(FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE)

    const navigateToRedirectPath = () => {
      setRedirectPath(path)
    }
    const clearCookies = () => {
      // delete the cookies
      setCookieValue(FEDERATED_LOGIN_REDIRECT_COOKIE, '; expires=Thu, 01 Jan 1970 00:00:00 UTC;')
      setCookieValue(
        FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE,
        '; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
      )
      setCookieValue(
        FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE,
        '; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
      )
    }

    const handleFederatedMixpanelEvent = () => {
      const event = getCookieValue(FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE)
      const page = getMixpanelPage(path)
      const payload = {
        page,
        method: 'federated'
      }
      // @ts-ignore here event can be empty, if event is empty, should we still call trackMixpanelEvent?
      trackMixpanelEvent(event, payload)
    }

    // It's possible the user did not authenticate via the federated log in screen. In
    // that case we just want to redirect and not try to authenticate them first.
    if (code) {
      dispatch(getSocialAuthTokens(code)).then(() => {
        handleFederatedMixpanelEvent()
        clearCookies()
        navigateToRedirectPath()
      })
    } else if (socialAuthUrl && errorDescription.includes('Already found an entry for username')) {
      window.location.href = socialAuthUrl
    } else {
      clearCookies()
      navigateToRedirectPath()
    }
  }, [code, dispatch, errorDescription])

  return redirectPath ? (
    <Redirect to={redirectPath} />
  ) : (
    <div className='loading-wrap'>
      {/* inner div needed for IE11 */}
      <div>
        <CircularProgress size={100} />
      </div>
    </div>
  )
}

export default FederatedLoginLanding

// DEV
const devCasClientId = '5769p16iu6643dv5u4mi2uqj84'
const devCasClientSecret = '1d7booi47u8n06e2ge20nu7q5jdd3rf4lh8639q81afmehll4u8k'
const devCasAuthContent = `Basic ${btoa(`${devCasClientId}:${devCasClientSecret}`)}`

// QA
const qaCasClientId = '21ud29fscs5s486clufiu0h970'
const qaCasClientSecret = 'tpieun44nshb0g7n9p1210b3ovhbpd94l1qlagtqg50217ful34'
const qaCasAuthContent = `Basic ${btoa(`${qaCasClientId}:${qaCasClientSecret}`)}`

// INTEG
const integCasClientId = '1mkcca1piqtqv1m83dqcsljtal'
const integCasClientSecret = 'm2d1cifkt4b14cg45q38d4fksrk1tv7meuqoo27itrms9m8o7ip'
const integCasAuthContent = `Basic ${btoa(`${integCasClientId}:${integCasClientSecret}`)}`

// PROD
const prodCasClientId = '276ft7c6vesatnmsm1gpf1f35d'
const prodCasClientSecret = '1tbl0upe8gm2qamuesrmn33tqhjpdtdp0f690kk72n5889us7913'
const prodCasAuthContent = `Basic ${btoa(`${prodCasClientId}:${prodCasClientSecret}`)}`

export const authContentMap = {
  dev: devCasAuthContent,
  qa: qaCasAuthContent,
  integ: integCasAuthContent,
  production: prodCasAuthContent
} as const

// This is the list of URLs where we don't want to restrict access even if the
// user's access token is about to expire
export const WHITELISTED_ACCESS_TOKEN_ENDPOINTS = [
  'refresh-token',
  'sign-in-password',
  'sign-up',
  'sign-up-confirm',
  'answer-challenge',
  'forgot-password',
  'forgot-password/confirm',
  '/password',
  'social-auth-tokens',
  'sign-out'
]

import HomeSvg from 'assets/home_grey.svg'
import classnames from 'classnames'
import {streetViewApiMetadataUrl, streetViewApiUrl} from 'constants/googleConstants'
import MUIImage from 'material-ui-image'
import type {ReactNode} from 'react'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components/macro'

const getLengthNumber = (length: string) => {
  return Number(length && length.match(/\d+/)?.[0]) || 0
}

export type ImageProps = {
  src?: string
  width: string
  height: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  className?: string
  children?: ReactNode
  asImg?: boolean
  variant?: 'cover' | 'contain'
}

const Image = ({src, width, height, onClick, asImg, className, children, variant}: ImageProps) => {
  const widthNumber = getLengthNumber(width)
  const heightNumber = getLengthNumber(height)
  const [imageSrc, setImageSrc] = useState(src)
  const [imageVariant, setImageVariant] = useState(variant)
  const [isStreetViewFallback, setIsStreetviewFallback] = useState(false)

  useEffect(() => {
    let isMounted = true
    const getImageSrc = async (src?: string, variant?: ImageProps['variant']) => {
      let imageSrc = src
      let isStreetViewFallback = false
      let imageVariant = variant
      if (!src) {
        imageSrc = HomeSvg
        imageVariant = 'contain'
      } else if (src.includes(streetViewApiUrl)) {
        const validationUrl = src.replace(streetViewApiUrl, streetViewApiMetadataUrl)

        const response = await fetch(validationUrl).then(resp => resp.json())
        if (response.status !== 'OK') {
          imageSrc = HomeSvg
          isStreetViewFallback = true
        }
      }

      if (isMounted) {
        setImageVariant(imageVariant)
        setIsStreetviewFallback(isStreetViewFallback)
        setImageSrc(imageSrc)
      }
    }

    getImageSrc(src, variant)

    return () => {
      isMounted = false
    }
  }, [src, variant])

  return asImg ? (
    <Image.Styled
      width={width}
      height={height}
      className={classnames('image-as-img', imageVariant && `image-${imageVariant}`, className)}
      onClick={onClick}
    >
      <MUIImage
        src={imageSrc ?? ''}
        imageStyle={{width, height}}
        aspectRatio={widthNumber && heightNumber ? widthNumber / heightNumber : 1}
        color='transparent'
      />
    </Image.Styled>
  ) : (
    <Image.Styled
      className={classnames(
        'image-as-background',
        imageVariant && `image-${imageVariant}`,
        className,
        {
          fallback: isStreetViewFallback
        }
      )}
      src={imageSrc!}
      width={width}
      height={height}
      onClick={onClick}
    >
      {children}
    </Image.Styled>
  )
}

Image.Styled = styled.div<{width: string; height: string; src?: string}>`
  &.image-as-img {
    width: ${({width}) => width};
    height: ${({height}) => height};
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.stone.light};
    ${({onClick}) => onClick && 'cursor: pointer'};

    &.image-cover,
    &.image-contain {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0 !important;
        height: ${({height}) => height};
      }
    }

    &.image-cover {
      > div {
        img {
          object-fit: cover;
          font-family: 'object-fit: cover;'; // IE11 polyfill
        }
      }
    }

    &.image-contain {
      > div {
        img {
          object-fit: contain;
          font-family: 'object-fit: contain;'; // IE11 polyfill
        }
      }
    }
  }

  &.image-as-background {
    background-image: ${({src}) => (src ? `url(${src})` : '')};
    width: ${({width}) => width};
    height: ${({height}) => height};
    position: relative;
    ${({onClick}) => onClick && 'cursor: pointer'};
    background-position: 50% 50%;

    &.image-cover {
      background-size: cover;
    }

    &.image-contain {
      background-size: contain;
    }
  }
`

Image.defaultProps = {
  variant: 'cover'
}

export default Image

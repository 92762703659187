export const USER_IDLE_TIMEOUT = 1000 * 60 * 30 // 30 mins in milliseconds
export const USER_IDLE_TIMEOUT_BUFFER = 1000 * 60 * 2 // 2 mins in milliseconds
export const USER_IDLE_TIMEOUT_REFRESH_TOKEN_BUFFER = 1000 * 60 * 5 // 5 min in milliseconds
export const USER_IDLE_TIMEOUT_ON_MESSAGE_THROTTLE_TIME = 10000 // 10 seconds

export const EXPIRES_IN = 'Your session will expire in'
export const KEEP_WORKING = 'Keep Working'
export const SIGN_OUT = 'Sign Out'
export const USER_IDLE_TIMEOUT_MODAL_MESSAGE_BODY =
  'Would you like to sign out now, or keep working?'
export const USER_IDLE_TIMEOUT_MODAL_TITLE = 'Session Timeout'

export const USER_IDLE_TIMEOUT_USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_IDLE_TIMEOUT_BASIC_TOKEN_SET = 'BASIC_TOKEN_SET'
export const USER_IDLE_TIMEOUT_SIGN_OUT = 'SIGN_OUT'
export const USER_IDLE_TIMEOUT_KEEP_WORKING = 'KEEP_WORKING'
export const USER_IDLE_TIMEOUT_ON_ACTION = 'ON_ACTION'

export const USER_IDLE_TIMER_NAME = 'CLEARCAPITAL_PRODUCT_PORTAL_IDLE_TIMER'

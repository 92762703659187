// Core
import React from 'react'
import {NavLink} from 'react-router-dom'
import type {NavLinkProps} from 'react-router-dom'

export type NavLinkWithRefProps = {
  to: string
} & NavLinkProps

const NavLinkWithRef = React.forwardRef<HTMLAnchorElement, NavLinkWithRefProps>((props, ref) => (
  <NavLink innerRef={ref} {...props} />
))

export default NavLinkWithRef

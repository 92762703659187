// Style
import styled from 'styled-components'

// Core
import {Button, ButtonProps, CircularProgress, Tooltip} from '@material-ui/core'
import React, {ChangeEvent, Fragment, useRef, useState} from 'react'

// Components, others
import {variantMap} from 'components/Button/Button'
import Icon from 'components/Icon'
import {modalsSelector} from 'selectors'
import {useSelector} from 'react-redux'
import {CHOOSE_DOCUMENT} from 'constants/mixpanelConstants'
import {trackMixpanelEvent} from 'services/mixpanel'
import {getMixpanelModalValue} from 'services/mixpanelServices'
import {AURA, CDA} from 'constants/productCardConstants'
import {useForm} from 'react-final-form'
import {ProductGroupType} from 'types/productTypes'
import useGetProductGroup from 'hooks/useGetProductGroup'

type UploadButtonProps = {
  variant?: keyof typeof variantMap
  fileName?: string
  onSelectFiles: (file: any) => void
  uploading?: boolean
  productGroup?: ProductGroupType
} & Omit<ButtonProps, 'variant'>

const UploadButton = ({
  variant = 'secondary',
  fileName,
  onSelectFiles,
  uploading,
  productGroup,
  ...buttonProps
}: UploadButtonProps) => {
  const [inputValue, setInputValue] = useState('')
  const randomId = useRef(`upload-button-${new Date().getTime()}`)
  const modals = useSelector(modalsSelector)
  const {getFieldState} = useForm()
  const customerDocumentList = getFieldState('customerDocumentList')
  const currentProductGroup = useGetProductGroup() || productGroup
  const shouldDisableFileUpload =
    customerDocumentList?.value?.length === 1 &&
    (currentProductGroup === CDA || currentProductGroup === AURA)

  const onFileChange = (event: ChangeEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement
    setInputValue('')
    if (target.files?.length) {
      trackMixpanelEvent(CHOOSE_DOCUMENT, {modal: getMixpanelModalValue(modals[0].modalType)})
      onSelectFiles(target.files)
    }
  }

  return (
    <Fragment>
      <Input
        id={randomId.current}
        type='file'
        multiple={currentProductGroup !== AURA && currentProductGroup !== CDA}
        value={inputValue}
        onChange={onFileChange}
        disabled={shouldDisableFileUpload}
      />
      <ButtonContainer htmlFor={randomId.current}>
        <Button
          component='span'
          variant={variantMap[variant]}
          {...buttonProps}
          disabled={shouldDisableFileUpload}
        >
          {getFileName(fileName, uploading)}
        </Button>
      </ButtonContainer>
    </Fragment>
  )
}

const getFileName = (name?: string, uploading?: boolean) => {
  let nameFragment
  if (uploading) {
    nameFragment = (
      <Fragment>
        <span>Uploading</span>
        <div className='loading-icon'>
          <CircularProgress />
        </div>
      </Fragment>
    )
  } else if (!name) {
    nameFragment = (
      <Fragment>
        <Icon icon='file_upload' />
        <span>Choose File</span>
      </Fragment>
    )
  } else {
    let displayFileName
    const [fileName, suffix = ''] = name.split(/\.(\w+)$/)
    if (fileName.length > 18) {
      const displayName = `${fileName.substring(0, 18)}...${suffix}`
      displayFileName = (
        <Tooltip title={name}>
          <span>{displayName}</span>
        </Tooltip>
      )
    } else {
      displayFileName = <span>{name}</span>
    }

    nameFragment = (
      <Fragment>
        {displayFileName}
        <Icon icon='close' />
      </Fragment>
    )
  }

  return nameFragment
}

const Input = styled.input`
  display: none;
`

const ButtonContainer = styled.label`
  .portal-MuiButton-label {
    column-gap: 4px;
  }

  .material-icons {
    font-size: 1rem;
  }

  .loading-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 8px;
  }
`

export default UploadButton

// UI
export const DEFAULT_DATE_INPUT = 'YYYY-MM-DD'
export const DEFAULT_DATE_OUTPUT = 'MMM DD, YYYY'
export const NO_DATA = '\u2014' // em dash
export const BOTTOM = 'BOTTOM'
export const TOP = 'TOP'
export const MIDDLE = 'MIDDLE'
export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'

// ENVIRONMENT
export const FALLBACK_ENV = 'qa' as const
export const DEV_ORIGIN = 'https://portal.dev.clearcapital.com'
export const QA_ORIGIN = 'https://portal.qa.clearcapital.com'
export const INTEG_ORIGIN = 'https://portal.integ.clearcapital.com'
export const PROD_ORIGIN = 'https://portal.clearcapital.com'

// COOKIE AND LOCAL STORAGE KEYS
export const FEDERATED_LOGIN_REDIRECT_COOKIE = '__CC_PORTAL_REDIRECT_PATH__'
export const FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE = '__CC_PORTAL_FEDERATED_MP_EVENT_TYPE__'
export const COOKIE_PREFERENCE_ANALYTICS = 'cc_portal_cookiePreference_analytics'
export const VIEWED_MARKETING_WELCOME_MODAL_KEY = '__CC_PORTAL_VIEWED_MARKETING_WELCOME_MODAL__'
export const FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE = '__CC_PORTAL_SOCIAL_CLIENT_URL__'
export const EMAIL_ADDRESS_COOKIE = '__CC_PORTAL_EMAIL_ADDRESS__'

// SUPPORT CONTACT INFO
export const SUPPORT_EMAIL = 'customer@clearcapital.com'
export const SUPPORT_PHONE = '(530) 550-2500'
export const SUPPORT_CENTER_URL = 'https://support.clearcapital.com/'
export const TERM_OF_USE_URL = 'https://www.clearcapital.com/terms-of-use/'
export const PRIVATE_POLICY_URL = 'https://clearcapital.com/privacy-policy/'

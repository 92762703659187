import type {ThemeType} from 'styles/styledComponentsTheme'
import type {CustomerUserStatus} from 'types/customerTypes'

export const CUSTOMER_USERS_STATUS: Array<{
  label: string
  value: CustomerUserStatus
  color: Exclude<keyof ThemeType['colors'], 'grayscale'>
}> = [
  {
    label: 'Enabled',
    value: 'enabled',
    color: 'emerald'
  },
  {
    label: 'Disabled',
    value: 'disabled',
    color: 'ruby'
  },
  {
    label: 'Pending',
    value: 'pending',
    color: 'amethyst'
  }
]

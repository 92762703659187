import React, {Fragment, useEffect} from 'react'
import styled from 'styled-components'
import {Field, useForm, useFormState} from 'react-final-form'
import {FieldArray, useFieldArray} from 'react-final-form-arrays'

import DetailsSection from 'components/OrderForm/DetailsSection'
import {ContactsDetailsInputsType} from 'constants/orderFormConstants'
import Contact from 'components/Contact'
import Button from 'components/Button'
import {CONTACT_INITIAL_VALUE} from 'constants/orderContactConstants'

import {getProduct} from 'services/productsServices'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import TextInput from 'components/TextInput'
import {ProductGroupType} from 'types/productTypes'
import Icon from 'components/Icon'
import {trackMixpanelEvent} from 'services/mixpanel'
import {ADD_CONTACT} from 'constants/mixpanelConstants'
import {useSelector} from 'react-redux'
import {modalsSelector} from 'selectors'
import {getMixpanelModalValue} from 'services/mixpanelServices'

export interface ContactsDetailsProps {
  productGroup: ProductGroupType
  inputs: ContactsDetailsInputsType
}

const ContactsDetails = ({productGroup, inputs}: ContactsDetailsProps) => {
  const {getFieldState, change} = useForm()
  const {
    values: {productType}
  } = useFormState()
  const contacts = getFieldState('contacts')
  const {fields} = useFieldArray('contacts')
  const lockbox = getFieldState('lockbox')?.value
  const supportedProductGroups = useSupportedProductGroups()
  const {isInteriorContactRequired} =
    getProduct({
      productId: productType,
      productGroups: supportedProductGroups,
      productGroupId: productGroup
    }) ?? {}
  const modal = useSelector(modalsSelector)

  useEffect(() => {
    if (contacts?.pristine && isInteriorContactRequired) {
      fields.push(CONTACT_INITIAL_VALUE)
    } else if (contacts?.dirty && !isInteriorContactRequired) {
      change('contacts', undefined)
    }
  }, [change, contacts, fields, isInteriorContactRequired])

  useEffect(() => {
    if (lockbox && !isInteriorContactRequired) {
      change('lockbox', undefined)
    }
  }, [lockbox, isInteriorContactRequired, change])

  const onAddContact = () => {
    trackMixpanelEvent(ADD_CONTACT, {
      modal: getMixpanelModalValue(modal[0].modalType)
    })
    fields.push(CONTACT_INITIAL_VALUE)
  }

  return isInteriorContactRequired ? (
    <ContactsDetails.Styled>
      <DetailsSection
        className='contact-details-section'
        title='Access Details'
        subtitle='Please include only contacts who can provide interior access to the vendor and provide a lockbox code if applicable.'
      >
        <FieldArray name='contacts'>
          {({fields}) => (
            <Fragment>
              <Button
                className='add-contact-button'
                title={
                  <span className='title-wrapper'>
                    <Icon className='icon-add' icon='add_circle' />
                    <div className='title'>Add Contact</div>
                  </span>
                }
                variant='tertiary'
                onClick={onAddContact}
              />
              <div className='all-contacts-container'>
                {fields.map((name, index) => (
                  <Contact
                    key={name}
                    name={name}
                    inputs={inputs}
                    shouldShowDeleteButton={fields.length! > 1}
                    onDelete={() => fields.remove(index)}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </FieldArray>
        <div className='lockbox-container'>
          <label htmlFor='lockbox' className='label'>
            Lockbox Code <span className='label-optional'> - Optional</span>
          </label>
          <div id='lockbox' className='input-wrapper'>
            <Field name='lockbox' initialValue={lockbox}>
              {({input}) => {
                return (
                  <TextInput
                    className='text-input'
                    value={input.value}
                    onChange={input.onChange}
                    sqaPrefix='lockbox'
                  />
                )
              }}
            </Field>
          </div>
        </div>
      </DetailsSection>
    </ContactsDetails.Styled>
  ) : null
}

ContactsDetails.Styled = styled.div`
  .contact-details-section {
    position: relative;
  }

  .all-contacts-container {
    .contact-container {
      border-bottom: 1px solid ${({theme}) => theme.colors.stone.light};
      margin-bottom: 20px;
      padding-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .add-contact-button {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 0;
    .title-wrapper {
      display: flex;
      align-items: center;
      .title {
        color: ${({theme}) => theme.colors.cobalt.dark};
        font-weight: 500;
      }
      .icon-add {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }

  .lockbox-container {
    flex: 0 1 326px;

    .label {
      display: block;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      margin-bottom: 4px;

      &-optional {
        font-weight: 300;
      }
    }
  }
`

export default ContactsDetails

import {opsApi} from 'services/apis'
import {SET_WALLET_ERROR, SET_WALLET_LOADED, SET_WALLET_LOADING} from 'constants/actionTypes'
import type {AppDispatch} from 'services/store'
import type {GetUserWalletResponse} from 'types/orderTypes'

export function getWallet() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_WALLET_LOADING
    })

    return opsApi
      .get<GetUserWalletResponse>('wallet')
      .then(res => {
        dispatch({
          type: SET_WALLET_LOADED,
          payload: res
        })
      })
      .catch(err => {
        dispatch({
          type: SET_WALLET_ERROR,
          payload: err
        })
      })
  }
}

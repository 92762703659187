import {listToArray} from 'services/formatData'
import moment, {MomentFormatSpecification} from 'moment'
import {PASSWORD_REQUIREMENTS} from 'constants/passwordConstants'
import {phone} from 'phone'

const isEmpty = (value: any) => {
  return value === '' || value === undefined || value == null
}

const isExisty = (value: any) => {
  return value !== null && value !== undefined
}

const isEmptyTrimmed = (value: any) => {
  if (typeof value === 'string') {
    return value.trim() === ''
  }
  return true
}

// return true to pass test, false to fail
const validators = {
  required: (value: any) =>
    typeof value === 'string'
      ? !isEmpty(value) && !isEmptyTrimmed(value)
      : // this !value===false check is for checkbox since the value is boolean for checkbox
        !isEmpty(value) && !(value === false),

  isArrayWithLength: (value: any) => value && value.length,

  matchRegexp: (value: any, regexp: RegExp) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp)
    return !isExisty(value) || isEmpty(value) || validationRegexp.test(value)
  },

  isEmail: (value: any) =>
    validators.matchRegexp(
      value,
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),

  isEmpty: (value: any) => isEmpty(value),

  trim: (value: any) => !isEmptyTrimmed(value),

  isNumber: (value: any) => validators.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

  isFloat: (value: any) => validators.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),

  isPositive: (value: any) => {
    if (isExisty(value)) {
      return (validators.isNumber(value) || validators.isFloat(value)) && value >= 0
    }
    return true
  },

  isNotDuplicate: (value: any) => {
    const testArray = listToArray(value)
    return new Set(testArray).size === testArray.length
  },

  isString: (value: any) => !isEmpty(value) || typeof value === 'string' || value instanceof String,

  maxNumber: (value: any, max: string) =>
    !isExisty(value) || isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),

  minNumber: (value: any, min: string) =>
    !isExisty(value) || isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),

  minStringLength: (value: any, length: number) =>
    validators.isString(value) && value.length >= length,

  maxStringLength: (value: any, length: number) =>
    validators.isString(value) && value.length <= length,

  validateUSAPhoneNumber: (value: any) => {
    if (isExisty(value)) {
      return phone(value, {country: 'USA'})?.isValid
    }
    return true
  },

  validateOptionalPhoneNumber: (value: any) => {
    return isExisty(value) && value !== '' ? phone(value, {country: 'USA'})?.isValid : true
  },

  validateUrl: (value: any) =>
    validators.matchRegexp(
      value,
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    ),

  validateDate: (value: any, format: MomentFormatSpecification) =>
    moment(value, format, true).isValid(),

  validatePassword: (value: string): boolean => {
    for (let i = 0; i < PASSWORD_REQUIREMENTS.length; i++) {
      const {regexp} = PASSWORD_REQUIREMENTS[i]
      if (!new RegExp(regexp).test(value)) {
        return false
      }
    }
    return true
  }
}

export default validators

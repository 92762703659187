import {ToastPosition} from 'react-hot-toast'

export type NotificationType = 'success' | 'error' | 'loading'

export const NOTIFICATION_CONFIG_MAP_BY_TYPE: Record<
  NotificationType,
  {
    icon: 'check_circle' | 'error' | 'sync'
    duration: number
    position?: ToastPosition
  }
> = {
  success: {
    icon: 'check_circle',
    duration: 3000
  },
  error: {
    icon: 'error',
    duration: 4000
  },
  loading: {
    icon: 'sync',
    duration: Infinity
  }
}

import {SET_CUSTOMER_ERROR, SET_CUSTOMER_LOADED, SET_CUSTOMER_LOADING} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import type {CustomerType} from 'types/customerTypes'

type CustomerState = {
  status: ReduxStateStatus
  data?: CustomerType
  error?: null
}

const initialCustomerState: CustomerState = {
  status: UNSET,
  data: undefined,
  error: null
}

type CustomerAction =
  | {
      type: typeof SET_CUSTOMER_ERROR
      payload: CustomerState['error']
    }
  | {
      type: typeof SET_CUSTOMER_LOADING
    }
  | {
      type: typeof SET_CUSTOMER_LOADED
      payload: CustomerState['data']
    }

export default function customerReducer(
  state = initialCustomerState,
  action: CustomerAction
): CustomerState {
  const {type} = action

  switch (type) {
    case SET_CUSTOMER_ERROR:
      return {
        ...state,
        status: ERROR,
        error: action.payload
      }
    case SET_CUSTOMER_LOADING:
      return {
        ...state,
        status: LOADING
      }
    case SET_CUSTOMER_LOADED:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
        error: null
      }
    default:
      return state
  }
}

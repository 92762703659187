// Style
import styled from 'styled-components'

// Core
import React from 'react'

// Components, actions, models, etc.
import UploadDocuments from 'components/UploadDocuments'
import useGetProductGroup from 'hooks/useGetProductGroup'

const UploadFormField = ({label}: {label: string}) => {
  const productGroup = useGetProductGroup()

  return (
    <UploadFormField.Styled>
      <p className='title'>{label}</p>
      <UploadDocuments
        className='upload-documents'
        fieldName='customerDocumentList'
        productGroup={productGroup}
      />
    </UploadFormField.Styled>
  )
}

UploadFormField.Styled = styled.div`
  .portal-MuiInputBase-formControl {
    min-height: 0 !important;
  }

  .title {
    font-weight: 500;
    margin-bottom: 16px;
  }

  .upload-documents {
    .upload-documents-flat-list {
      margin-top: 16px;
      .flat-list-item {
        background-color: ${({theme}) => theme.colors.stone.lighter};
        padding: 24px;
      }
    }
  }
`

export default UploadFormField

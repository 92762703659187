// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment} from 'react'
import {useHistory} from 'react-router-dom'

// Components, ervices, etc
import Button from 'components/Button'
import Card from 'components/Card'
import Icon from 'components/Icon'
import NotFoundImage from 'assets/not_found.svg'

const NotFound = () => {
  const history = useHistory()
  const handleClick = () => {
    history.goBack()
  }
  return (
    <NotFound.Styled className='not-found'>
      <Card>
        <img src={NotFoundImage} alt='Not Found Placeholder' />
        <h3>404, Page Not Found</h3>
        <p className='secondary'>The page you are trying to access doesn’t exist.</p>
        <Button
          variant='secondary'
          title={
            <Fragment>
              <Icon icon='arrow_back' />
              Go Back
            </Fragment>
          }
          onClick={handleClick}
        />
      </Card>
    </NotFound.Styled>
  )
}

NotFound.Styled = styled.div`
  &.not-found {
    padding: 40px;

    .card {
      min-height: 608px;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        margin-top: 24px;
        margin-bottom: 8px;
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        color: ${({theme}) => theme.colors.grayscale.gray};
      }

      button {
        margin-top: 24px;

        .material-icons {
          font-size: 16px;
          margin-right: 6px;
        }
      }
    }
  }
`

export default NotFound

import {createSelector} from 'reselect'
import {LOADING, UNSET} from 'constants/reduxStatuses'
import type {RootState} from 'services/store'

export const authSelector = (state: RootState) => state.auth
export const authStatusSelector = createSelector([authSelector], auth => auth.status)
export const isAuthLoadingSelector = createSelector(
  [authStatusSelector],
  authStatus => authStatus === UNSET || authStatus === LOADING
)
export const authDataSelector = createSelector([authSelector], auth => auth.data)
export const authAuthenticationSelector = createSelector(
  [authDataSelector],
  authData => authData.authentication
)
export const authErrorSelector = createSelector([authSelector], auth => auth.error)
export const isUserAuthenticatedSelector = createSelector(
  [authDataSelector],
  authData => authData.isUserAuthenticated
)

export const isUserManagerSelector = createSelector(
  [authDataSelector],
  authData => authData.isUserManager
)

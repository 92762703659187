import {createSelector} from 'reselect'
import {cleanPhoneNumber} from 'services/formats'
import type {RootState} from 'services/store'

const requiredProfileFields = ['email', 'firstName', 'lastName', 'phone'] as const
const requiredMarketingFields = ['email', 'phone'] as const

export const userRootSelector = (state: RootState) => state.user
export const userStatusSelector = createSelector([userRootSelector], user => user.status)
export const userSelector = createSelector([userRootSelector], user => user.data)

export const formattedUserSelector = createSelector([userSelector], user => {
  return {
    ...user,
    phone: cleanPhoneNumber(user.phone)
  }
})

export const userErrorSelector = createSelector([userRootSelector], user => user.error)
export const isUserProfileCompleteSelector = createSelector([userSelector], user => {
  let profileIsComplete = true
  for (let index = 0; index < requiredProfileFields.length; index++) {
    const fieldName = requiredProfileFields[index]
    if (!user[fieldName]) {
      profileIsComplete = false
      break
    }
  }

  return profileIsComplete
})
export const isMarketingInfoCompleteSelector = createSelector([userSelector], user => {
  let profileIsComplete = true
  for (let index = 0; index < requiredMarketingFields.length; index++) {
    const fieldName = requiredMarketingFields[index]
    if (!user[fieldName]) {
      profileIsComplete = false
      break
    }
  }

  return profileIsComplete
})

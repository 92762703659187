export const MESSAGE_CENTER_TABLE_HEADER = [
  {
    id: 'created',
    label: 'Date & Time',
    style: {width: '20%'}
  },
  {
    id: 'message',
    label: 'Message',
    style: {width: '60%'}
  },
  {
    id: 'name',
    label: 'From',
    style: {width: '20%'}
  }
] as const

// Styles
import styled from 'styled-components/macro'

// Core
import type {ReactElement} from 'react'
import React, {Fragment, MouseEventHandler, useCallback, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// Components, services, etc
import {signOut} from 'actions/authActions'
import {openModal} from 'actions/modalActions'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import {SUPPORT_CENTER_URL} from 'constants/appConstants'
import {USER_PROFILE_MODAL} from 'constants/modalsConstants'
import {customerNameSelector} from 'selectors/customerSelectors'
import {isUserProfileCompleteSelector} from 'selectors/userSelectors'

// 3rd-party
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import classnames from 'classnames'
// we have to import this icon since it doesn't exist in material-design-icons
import ContactSupport from '@material-ui/icons/ContactSupport'

type MenuItemContentProps = {
  icon: string | ReactElement<typeof ContactSupport>
  label: string
  name: string
  badges: null | {
    profile: true
  }
}

const ALL_MENU_ITEMS = [
  {
    icon: 'account_circle',
    label: 'My Profile',
    name: 'profile' as const
  },
  {
    icon: <ContactSupport />,
    label: 'Contact Support',
    name: 'contactSupport' as const
  },
  {
    icon: 'exit_to_app',
    label: 'Sign Out',
    name: 'signOut' as const
  }
]

type MenuItemType = 'paymentMethod' | 'profile' | 'signOut' | 'contactSupport'

const UserMenu = () => {
  const dispatch = useDispatch()
  const name = useSelector(customerNameSelector)

  const userProfileIsComplete = useSelector(isUserProfileCompleteSelector)
  const badges = userProfileIsComplete
    ? null
    : {
        profile: true as const
      }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleOpen: MouseEventHandler<HTMLButtonElement> = se => {
    setAnchorEl(se.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  const handleMenuItemClick = useCallback(
    (menuItem: MenuItemType) => () => {
      handleClose()

      const mixpanelEventData = {
        initiatedFrom: 'User Menu'
      }
      switch (menuItem) {
        case 'profile':
          dispatch(openModal({modalType: USER_PROFILE_MODAL, mixpanelEventData}))
          break
        case 'signOut':
          handleLogout()
          break
        default:
          break
      }
    },
    [dispatch, handleLogout]
  )

  return (
    <UserMenu.Styled className='user-menu-container'>
      <IconButton
        className={classnames({'has-badge': Boolean(badges)})}
        icon='account_circle'
        onClick={handleOpen}
      />
      <UserMenu.StyledMenu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
      >
        {name ? <li className='company-name truncated'>{name}</li> : null}
        {ALL_MENU_ITEMS.map(({icon, label, name}) => (
          <MenuItem onClick={handleMenuItemClick(name)} key={name}>
            {name === 'contactSupport' ? (
              <a
                className='link'
                href={SUPPORT_CENTER_URL}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Content icon={icon} label={label} name={name} badges={badges} />
              </a>
            ) : (
              <Content icon={icon} label={label} name={name} badges={badges} />
            )}
          </MenuItem>
        ))}
      </UserMenu.StyledMenu>
    </UserMenu.Styled>
  )
}

const Content = ({icon, label, name, badges}: MenuItemContentProps) => (
  <Fragment>
    {typeof icon === 'string' ? <Icon className='menu-item-icon' icon={icon} /> : icon}
    <span className={classnames('label', {'has-badge': badges && badges[name as 'profile']})}>
      {label}
    </span>
  </Fragment>
)

UserMenu.Styled = styled.div`
  .icon-button {
    &.has-badge {
      i {
        position: relative;

        &:after {
          position: absolute;
          top: -1px;
          right: -2px;
          z-index: 1;
          content: '';
          border-radius: 50%;
          width: 10px;
          height: 10px;
          background-color: ${({theme}) => theme.colors.ruby.light};
          border: 2px solid ${({theme}) => theme.colors.grayscale.white};
        }
      }
    }

    &:hover {
      i {
        &:after {
          border: 2px solid ${({theme}) => theme.colors.stone.light};
        }
      }
    }
  }
`

UserMenu.StyledMenu = styled(Menu)`
  .portal-MuiPaper-root {
    background: ${({theme}) => theme.colors.grayscale.white};
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    /* offset IconButton padding */
    margin-top: -6px;

    .company-name {
      padding: 0 16px 8px;
      border-bottom: 0.51px solid ${({theme}) => theme.colors.stone.light};
      color: ${({theme}) => theme.colors.grayscale.gray};
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
    }

    .portal-MuiList-root {
      width: 192px;
    }

    .portal-MuiMenuItem-root {
      padding-top: 8px;
      padding-bottom: 8px;

      .link {
        display: flex;
        align-items: center;
        text-decoration: none;
      }

      .portal-MuiSvgIcon-root,
      .menu-item-icon {
        font-size: 18px;
        color: ${({theme}) => theme.colors.stone.dark};
        margin-right: 18px;
      }

      .label {
        font-size: 14px;
        line-height: 22px;
        color: ${({theme}) => theme.colors.grayscale.black};
        position: relative;

        &.has-badge {
          &:after {
            position: absolute;
            top: -1px;
            right: -8px;
            z-index: 1;
            content: '';
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: ${({theme}) => theme.colors.ruby.light};
            border: 2px solid ${({theme}) => theme.colors.grayscale.white};
          }
        }
      }

      &:hover {
        background-color: ${({theme}) => theme.colors.stone.lighter};

        .label {
          &.has-badge {
            &:after {
              border: 2px solid ${({theme}) => theme.colors.stone.lighter};
            }
          }
        }
      }
    }
  }
`

export default UserMenu

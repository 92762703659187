import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import Tabs from './Tabs'
import {TabObject} from 'types/tabTypes'
import {trackMixpanelEvent} from 'services/mixpanel'
import {CLICKED_NAVIGATION} from 'constants/mixpanelConstants'

export const RoutingTabs = ({navTabs}: {navTabs: TabObject[]}) => {
  const location = useLocation()
  const [navTabsValue, setNavTabsValue] = useState<string | undefined>(location.pathname)

  useEffect(() => {
    const pathName = location.pathname
    const tabValue = navTabs.find(({to}) => to && location.pathname.includes(to))?.value ?? pathName
    const tabLabel = navTabs.find(({value}) => value === pathName)?.label
    if (tabLabel) {
      trackMixpanelEvent(CLICKED_NAVIGATION, {component: tabLabel})
    }
    setNavTabsValue(tabLabel ? tabValue : undefined)
  }, [location.pathname, navTabs])

  return <Tabs tabs={navTabs} value={navTabsValue} />
}

import {SET_CUSTOMER_ERROR, SET_CUSTOMER_LOADED, SET_CUSTOMER_LOADING} from 'constants/actionTypes'
import {customerApi} from 'services/apis'
import {setMixpanelUserData} from 'services/mixpanel'
import type {AppDispatch} from 'services/store'
import type {
  CreateCustomersRequestBody,
  CreateCustomersResponse,
  GetCustomersResponse,
  GetUserCustomersResponse,
  UpdateCustomersRequestBody,
  UpdateCustomersResponse
} from 'types/customerTypes'

const getCustomerByCustomerKey = async (customerKey: string) => {
  return await customerApi.get<GetCustomersResponse>(`customer/${customerKey}`)
}

export function getCustomer() {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: SET_CUSTOMER_LOADING
    })

    try {
      const customers = await customerApi.get<GetUserCustomersResponse>('users')
      const userCustomer = customers[0]

      let customer

      if (userCustomer?.customerKey) {
        try {
          customer = await getCustomerByCustomerKey(userCustomer.customerKey)
        } catch (e) {
          console.error('get customer by customerKey failed', e)
          // user is belong to a Customer, but failed to get detail of the Customer,
          // at least save the information we got
          customer = {...userCustomer, name: userCustomer.customerName}
        }
      }

      dispatch({
        type: SET_CUSTOMER_LOADED,
        payload: customer
      })
      // return the customer data to the caller so it can decide what to do next
      return customer
    } catch (err) {
      dispatch({
        type: SET_CUSTOMER_ERROR,
        payload: err as any
      })
    }
  }
}

export function createCustomer(payload: CreateCustomersRequestBody) {
  return async (dispatch: AppDispatch) => {
    try {
      const customerKey = await customerApi.post<CreateCustomersResponse>('customer', {
        ...payload,
        // All Portal customers must have this flag set to ensure they are setup in Jasper for VOA products
        isJasperCustomer: true
      })
      let customer
      try {
        customer = await getCustomerByCustomerKey(customerKey)
      } catch (e) {
        console.error('get customer by customerKey failed', e)
        // if create Customer success, but failed to get detail of the Customer,
        // at least save the information we got from FE
        customer = {...payload, customerKey}
      }

      dispatch({
        type: SET_CUSTOMER_LOADED,
        payload: customer
      })
      // The user data was updated, update the Mixpanel user.
      setMixpanelUserData()
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export function updateCustomer(customerKey: string, customer: UpdateCustomersRequestBody) {
  return (dispatch: AppDispatch) => {
    return customerApi
      .put<UpdateCustomersResponse>(`customer/${customerKey}`, customer)
      .then(data => {
        dispatch({
          type: SET_CUSTOMER_LOADED,
          payload: data
        })
      })
      .catch(err => {
        console.error(err)
        throw err
      })
  }
}

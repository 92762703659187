// Core
import React, {Fragment} from 'react'

// Components, services, etc
import ConfirmationModal from 'components/ConfirmationModal'
import {requestOnHoldRemoval} from 'services/opsServices'
import {useAppDispatch} from 'services/store'
import {getOrderByOpsOrderId} from 'actions/ordersActions'
import {OpsOrderId} from 'types/orderTypes'

type HoldRemovalConfirmationModalProps = {
  open: boolean
  handleClose: () => void
  handleExited: () => void
  opsOrderId?: OpsOrderId
}

const HoldRemovalConfirmationModal = ({
  opsOrderId,
  ...props
}: HoldRemovalConfirmationModalProps) => {
  const dispatch = useAppDispatch()
  const handleDeleteCard = async () => {
    if (opsOrderId) await requestOnHoldRemoval(opsOrderId)
    dispatch(getOrderByOpsOrderId(opsOrderId))
  }

  return (
    <ConfirmationModal
      title='Request Hold Removal'
      content={
        <Fragment>
          <p>Once approved, order processing will resume immediately. </p>
          <p>
            If you did not place the hold, please check your email for instructions from the support
            team.
          </p>
        </Fragment>
      }
      onConfirm={handleDeleteCard}
      sqaPrefix='hold-removal'
      onConfirmLoading='Sending Request'
      onConfirmText='Request Hold Removal'
      {...props}
    />
  )
}

export default HoldRemovalConfirmationModal

import {REQUIRED_FIELD_MSG} from 'constants/validationErrorMessagesConstants'
import {ValidationErrors} from 'final-form'
import validators from 'services/validationRules'

export type RuleType = {
  rule: keyof typeof validators | ((...args: any) => boolean | string | undefined)
  ruleParams?: any[]
  errorMsg: ((value: any) => string) | string
}
export const getInputValidity = (value: any, rules: RuleType[]) => {
  let fieldIsValid = true
  let errorMsg = ''
  const len = rules.length
  for (let i = 0; i < len; i++) {
    const ruleObj = rules[i]
    const {rule, ruleParams = []} = ruleObj
    const validatorMethod: RuleType['rule'] = typeof rule === 'string' ? validators[rule] : rule
    if (validatorMethod) {
      fieldIsValid = !!validatorMethod(value, ...ruleParams)
    }
    if (!fieldIsValid) {
      errorMsg = typeof ruleObj.errorMsg === 'function' ? ruleObj.errorMsg(value) : ruleObj.errorMsg
      break
    }
  }

  return {
    fieldIsValid,
    errorMsg
  }
}

export const validateForm = (
  fieldRulesDictionary: {[key: string]: RuleType[]},
  values: Record<string, any>
) => {
  const errors = Object.keys(fieldRulesDictionary).reduce<ValidationErrors>(
    (errors, currentField) => {
      const {fieldIsValid, errorMsg} = getInputValidity(
        values[currentField],
        fieldRulesDictionary[currentField]
      )
      const nextErrors = {...errors}
      if (!fieldIsValid) {
        nextErrors[currentField] = errorMsg
      }

      return nextErrors
    },
    {}
  )

  return errors
}

export const required = (value: string) => {
  if (typeof value === 'string') {
    value = value.trim()
  }
  return value ? undefined : REQUIRED_FIELD_MSG
}

export const requiredWithCustomErrorMessage = (value: string, customErrorMessage: string) =>
  required(value) ? customErrorMessage : undefined

import React from 'react'
import type {ReactNode} from 'react'
import MUICheckbox, {CheckboxProps as MUICheckboxProps} from '@material-ui/core/Checkbox'
import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import SvgIcon from '@material-ui/core/SvgIcon'
import styled from 'styled-components/macro'
import colors from 'styles/colors'

export type CheckboxProps = {
  onChange: MUICheckboxProps['onChange']
  checked: boolean
  label?: ReactNode
  disabled?: boolean
  className?: string
  sqaPrefix?: string
  inputProps?: {}
}

const DisabledCheckboxIcon = () => (
  <SvgIcon>
    <rect
      x='4'
      y='4'
      width='16'
      height='16'
      rx='1'
      fill={colors.stone.lighter}
      stroke={colors.stone.light}
      strokeWidth='2'
    />
  </SvgIcon>
)

const Checkbox = ({
  label,
  onChange,
  disabled,
  checked,
  className,
  sqaPrefix,
  inputProps
}: CheckboxProps) => {
  const getIconProp = (disabled?: boolean) => {
    return disabled ? {icon: <DisabledCheckboxIcon />} : {}
  }

  return label ? (
    <Checkbox.StyledMUIFormControlLabel
      label={label}
      className={className}
      control={
        <MUICheckbox
          onChange={onChange}
          disabled={disabled}
          color='default'
          checked={checked}
          data-sqa-id={`${sqaPrefix}-textbox`}
          inputProps={{...inputProps}}
          {...getIconProp(disabled)}
        />
      }
    />
  ) : (
    <MUICheckbox
      className={className}
      onChange={onChange}
      disabled={disabled}
      color='default'
      checked={checked}
      inputProps={{...inputProps}}
      {...getIconProp(disabled)}
    />
  )
}

Checkbox.StyledMUIFormControlLabel = styled(MUIFormControlLabel)`
  .portal-MuiFormControlLabel-label {
    line-height: 22px;
  }
`

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox

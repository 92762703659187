import {openModal} from 'actions/modalActions'
import {VIEWED_MARKETING_WELCOME_MODAL_KEY} from 'constants/appConstants'
import {WELCOME_MODAL} from 'constants/modalsConstants'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {isMarketingInfoCompleteSelector, userSelector} from 'selectors/userSelectors'
import store from 'services/store'

export const getViewedMarketingWelcomeModalKey = () => {
  const state = store.getState()
  const {id} = userSelector(state)
  return `${VIEWED_MARKETING_WELCOME_MODAL_KEY}${id}`
}

function useWelcomeModal() {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  const marketingInfoIsComplete = useSelector(isMarketingInfoCompleteSelector)
  const viewedMarketingWelcomeModal =
    localStorage?.getItem(getViewedMarketingWelcomeModalKey()) === 'true'

  useEffect(() => {
    if (
      !viewedMarketingWelcomeModal &&
      isLoggedIn &&
      pathname !== '/social-auth-tokens' &&
      !marketingInfoIsComplete
    ) {
      dispatch(openModal({modalType: WELCOME_MODAL}))
    }
  }, [dispatch, isLoggedIn, pathname, marketingInfoIsComplete, viewedMarketingWelcomeModal])
}

export default useWelcomeModal

import moment from 'moment'

export const convertDateStringToMs = (
  dateString: moment.MomentInput,
  dateStringFormat?: moment.MomentFormatSpecification
) => moment(dateString, dateStringFormat).valueOf()

export const convertDateStringToFormattedString = (
  dateString: moment.MomentInput,
  dateStringFormat?: moment.MomentFormatSpecification,
  outputFormat?: string
) => moment(dateString, dateStringFormat).format(outputFormat)

export const convertFromJSDateToFormattedDate = (date?: moment.MomentInput, format?: string) =>
  moment(date).format(format)

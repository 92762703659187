// Core
import React, {useEffect, useState} from 'react'

// Components, services, constants
import Card from 'components/Card'
import FlatList from 'components/FlatList'
import LabelValuePair from 'components/LabelValuePair'
import {PurposeOptions} from 'constants/loanDetailsConstants'
import type {OpsOrderType} from 'types/orderTypes'
import useProductGroups from 'hooks/useProductGroups'
import {getProductGroup} from 'services/productsServices'
import {PRICE_OPINION, CDA, INSPECTION, EVALUATION} from 'constants/productCardConstants'

const LoanDetailsCard = ({order}: {order: OpsOrderType}) => {
  const loanDetailsData = [
    {
      key: 'clientLoanNumber',
      content: (
        <LabelValuePair
          label='Loan #'
          value={order.clientLoanNumber}
          sqaPrefix='client-loan-number'
        />
      )
    },
    {
      key: 'clientLenderName',
      content: (
        <LabelValuePair
          label='Lender/Client'
          value={order.clientLenderName}
          sqaPrefix='client-lender-name'
        />
      )
    },
    {
      key: 'borrowerName',
      content: (
        <LabelValuePair
          label='Borrower Name'
          value={order.borrowerName}
          sqaPrefix='borrower-name'
        />
      )
    },
    {
      key: 'investor',
      content: <LabelValuePair label='Investor' value={order.investor} sqaPrefix='investor' />
    },
    {
      key: 'purpose',
      content: (
        <LabelValuePair
          label='Purpose'
          value={PurposeOptions.find(item => item.value === order.purpose)?.label}
          sqaPrefix='purpose'
        />
      )
    }
  ]
  const [loanDetails, setLoanDetails] = useState<typeof loanDetailsData>([])
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)

  useEffect(() => {
    if ([EVALUATION, INSPECTION, PRICE_OPINION].includes(productGroup)) {
      setLoanDetails(
        loanDetailsData.filter(({key}) => key === 'clientLoanNumber' || key === 'borrowerName')
      )
    } else if (productGroup === CDA) {
      setLoanDetails(loanDetailsData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroup])

  return (
    <Card>
      <FlatList items={loanDetails} withDividers />
    </Card>
  )
}

export default LoanDetailsCard

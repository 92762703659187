import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import DetailsSection from 'components/OrderForm/DetailsSection'
import Address from 'components/Address'
import Image from 'components/Image'
import MetaDataList from 'components/MetaDataList'
import {getAddressImageUrl, getAddressMetaData} from 'services/addressServices'
import {propertyDataSelector} from 'selectors'
import {ORDER_FORM_SQA_PREFIX} from 'constants/orderFormConstants'

const PropertyDetails = () => {
  const propertyData = useSelector(propertyDataSelector)
  const {address, metaData} = getAddressMetaData(propertyData)

  return (
    <PropertyDetails.Styled>
      <DetailsSection title='Property Details'>
        <div className='wrapper'>
          <Image
            width='48px'
            height='48px'
            asImg
            className='address-image'
            src={getAddressImageUrl(address)}
          />
          <div>
            <Address address={address} sqaPrefix='header' />
            <MetaDataList metaData={metaData} sqaPrefix={ORDER_FORM_SQA_PREFIX} />
          </div>
        </div>
      </DetailsSection>
    </PropertyDetails.Styled>
  )
}

PropertyDetails.Styled = styled.div`
  .wrapper {
    display: flex;
    column-gap: 12px;
    align-items: center;

    .address-image {
      border-radius: 6px;
    }

    .meta-data {
      font-size: 0.875rem;
    }
  }
`

export default PropertyDetails

import React, {useState, ChangeEventHandler, PointerEvent} from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'

import TextInput, {TextInputProps} from 'components/TextInput'
import IconButton from 'components/IconButton'

export type PasswordInputProps = Omit<TextInputProps, 'multiline' | 'onChange'> & {
  onChange: ChangeEventHandler<HTMLInputElement>
}

const PasswordInput = ({
  label = 'Password',
  value,
  onChange,
  helperText,
  disabled,
  error,
  placeholder = 'Password',
  className,
  InputProps,
  sqaPrefix = 'password',
  ...rest
}: PasswordInputProps): JSX.Element => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false)

  const onShouldShowPasswordButtonClick = (se: PointerEvent<HTMLButtonElement>) => {
    if (se.nativeEvent.pointerType === 'mouse') {
      se.preventDefault()
      setShouldShowPassword(!shouldShowPassword)
    }
  }

  return (
    <TextInput
      {...rest}
      className={className}
      label={label}
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}
      onChange={onChange}
      sqaPrefix={sqaPrefix}
      InputProps={{
        ...InputProps,
        type: shouldShowPassword ? 'text' : 'password',
        placeholder,
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              icon={shouldShowPassword ? 'visibility_off' : 'visibility'}
              onClick={onShouldShowPasswordButtonClick}
            />
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordInput

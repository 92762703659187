import * as formats from 'services/formats'
import {isExisty} from 'services/utils'
import {NO_DATA} from 'constants/appConstants'

export const formatDataObject = (properties: [string, any][]) => {
  return properties.reduce((accumulator, property) => {
    accumulator[property[0]] = formatData(property[0], property[1])
    return accumulator
  }, {} as {[key: string]: string | number})
}

export const formatData = (dataPointName: string, value: any) => {
  let formattedValue
  switch (dataPointName) {
    case 'status':
      formattedValue = value ? (value.toLowerCase() === 'sale' ? 'Sold' : value) : NO_DATA
      break

    case 'basementSize':
    case 'gla':
    case 'unfinishedBasementSize':
    case 'buildingSize':
      formattedValue = value ? formats.formatGLA(value) : NO_DATA
      break

    case 'assessedTotal':
    case 'avmValue':
    case 'hoaAmount':
    case 'listPrice':
    case 'marketImprovement':
    case 'marketTotal':
    case 'recorderAmount':
    case 'salePrice':
    case 'statusPrice':
    case 'taxAmount':
    case 'valueAmount':
    case 'lastValueAmount':
      formattedValue = value ? formats.currency(value) : NO_DATA
      break

    case 'pricePerSquareFoot':
      formattedValue = value ? `${formats.currency(value)}/ft\u00B2` : NO_DATA
      break

    case 'avmEffectiveDate':
    case 'listDate':
    case 'recorderDate':
    case 'saleDate':
    case 'statusDate':
    case 'valueDate':
    case 'lastValueEffectiveDate':
      formattedValue = value ? formats.formatDate(value, 'YYYY-MM-DD', 'MMM DD, YYYY') : NO_DATA
      break

    case 'overviewPool':
      formattedValue = isExisty(value) ? value : NO_DATA
      break

    case 'hoaPhoneNumber':
      formattedValue = value ? formats.formatPhoneNumber(value) : NO_DATA
      break

    case 'lotSize':
      formattedValue = value ? formats.formatLotSize(value) : NO_DATA
      break

    case 'avmConfidencePercent':
      formattedValue = value ? `${value}%` : NO_DATA
      break

    case 'hdiTrend':
      {
        const {model, trend, startDate, endDate} = value ?? {}
        const modelFormattedValueMap = {
          REPEAT_SALES: 'Repeat Sales',
          PPSF: 'Price Per ft\u00B2'
        }
        formattedValue = {
          trend: isExisty(trend) ? formats.roundNumberToMaxDecimals(trend * 100) : NO_DATA,
          startDate: startDate ? formats.formatDate(startDate, 'YYYY-MM-DD', 'MMM YYYY') : NO_DATA,
          endDate: endDate ? formats.formatDate(endDate, 'YYYY-MM-DD', 'MMM YYYY') : NO_DATA,
          model: modelFormattedValueMap[model as 'PPSF']
        }
      }
      break

    case 'distanceToSubject':
      formattedValue = formats.formatDistance(value, true)
      break

    case 'dataSource':
      formattedValue =
        value === 'PUBLIC'
          ? 'Public Records'
          : value === 'MLS' || value === 'PR' || value === 'PR/MLS'
          ? value
          : formats.titleCase(value)
      break

    case 'daysOnMarket':
      formattedValue = value ? formats.numberWithCommas(value) : NO_DATA
      break

    case 'avmFsd':
      formattedValue = isExisty(value) ? formats.roundNumberToMaxDecimals(value) : NO_DATA
      break

    case 'bath':
      formattedValue = `${value[0] || NO_DATA}/${value[1] || NO_DATA}`
      break

    case 'heatingCooling':
      formattedValue = formattedValue = `${formats.titleCase(value[0]) || NO_DATA} / ${
        formats.titleCase(value[1]) || NO_DATA
      }`
      break

    case 'garage':
      formattedValue = `${value[0] || NO_DATA}${value[1] ? ` (${value[1]})` : ''}`
      break

    default:
      formattedValue = formats.titleCase(value)
  }

  return formattedValue
}

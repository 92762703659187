import {getEnv} from 'services/apis'
import GoogleIcon from 'assets/google-icon.png'
import {DEV_ORIGIN, QA_ORIGIN, INTEG_ORIGIN, PROD_ORIGIN} from 'constants/appConstants'

// active screen options
export const MAIN = 'MAIN'
export const EMAIL = 'EMAIL'
export const CONFIRM = 'CONFIRM'
export const SIGN_UP_CREATE_PASSWORD = 'SIGN_UP_CREATE_PASSWORD'
export const LOG_IN = 'Log In'
export const SIGN_UP = 'Sign Up'
export const MOBILE = 'MOBILE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_EXPIRED_PASSWORD = 'RESET_EXPIRED_PASSWORD'

export const MODAL_DATA = {
  signUp: {
    title: 'Create an account',
    description: 'Sign up to start ordering now.'
  },
  logIn: {
    title: 'Welcome to the Portal',
    description: 'Log in to get back to your account.'
  }
}

export const FEDERATED_PROVIDER_DICTIONARY = {
  google: {
    imgSrc: GoogleIcon,
    label: 'Google',
    name: 'Google'
  }
}

const CAS_COGNITO_ENVIRONMENT_MAP = {
  dev: {
    userPoolDomainName: 'cas-dev-sam-user-pool',
    userPoolClientId: '37334ra8akl4pd7arlpqn9cfm7',
    redirect_uri: `${DEV_ORIGIN}/social-auth-tokens`
  },
  qa: {
    userPoolDomainName: 'cas-qa-sam-user-pool',
    userPoolClientId: 'moppmhii2p4bjtstumhie38ju',
    redirect_uri: `${QA_ORIGIN}/social-auth-tokens`
  },
  integ: {
    userPoolDomainName: 'cas-integ-sam-user-pool',
    userPoolClientId: '3jkbbptt9tr0qeolmbmo66r1ha',
    redirect_uri: `${INTEG_ORIGIN}/social-auth-tokens`
  },
  production: {
    userPoolDomainName: 'cas-prod-sam-user-pool',
    userPoolClientId: '2v131rm9il5aiuheusurv4io21',
    redirect_uri: `${PROD_ORIGIN}/social-auth-tokens`
  }
}

export const CAS_CNAME_SUBDOMAIN_MAP = {
  dev: 'federated-auth.dev',
  qa: 'federated-auth.qa',
  integ: 'federated-auth.integ',
  production: 'federated-auth.portal'
}

const getCasCognitoEnvironment = () => {
  const env = getEnv() as keyof typeof CAS_CNAME_SUBDOMAIN_MAP
  return CAS_COGNITO_ENVIRONMENT_MAP[env]
}

export const CAS_COGNITO_ENVIRONMENT = getCasCognitoEnvironment()

export const ACTION_BUTTON_TITLE = {
  signUp: SIGN_UP,
  logIn: LOG_IN
}

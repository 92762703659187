// Styles
import styled from 'styled-components/macro'

// Core
import React, {ChangeEvent, ChangeEventHandler, Fragment, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

// Components, services, etc
import {answerAuthChallenge, handleUserAuthenticated} from 'actions/authActions'
import {openModal} from 'actions/modalActions'
import Button from 'components/Button'
import ConfirmCodeModalContent from 'components/ConfirmCodeModalContent'
import IconButton from 'components/IconButton'
import TextInput from 'components/TextInput'
import {
  EMAIL_ADDRESS_COOKIE,
  FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE,
  FEDERATED_LOGIN_REDIRECT_COOKIE,
  FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE
} from 'constants/appConstants'
import {EMAIL} from 'constants/confirmCodeConstants'
import {
  CAS_CNAME_SUBDOMAIN_MAP,
  CAS_COGNITO_ENVIRONMENT,
  CONFIRM,
  FEDERATED_PROVIDER_DICTIONARY,
  MAIN,
  MODAL_DATA,
  SIGN_UP_CREATE_PASSWORD,
  ACTION_BUTTON_TITLE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_EXPIRED_PASSWORD
} from 'constants/logInSignUpConstants'
import {LOGGED_IN, SIGNED_UP} from 'constants/mixpanelConstants'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import {
  requiredEmailValidationRules,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {getEnv, updateAuthTokens} from 'services/apis'
import {
  getCasClientId,
  casSignInWithPasswordPost,
  casSignUpConfirmPost,
  casForgotPassword,
  casResetPassword,
  casSignUpChangePassword,
  casResetExpiredPassword
} from 'services/casServices'
import {getCookieValue, setCookieValue} from 'services/cookieServices'
import {RuleType, validateForm} from 'services/formValidationHelpers'
import {getMixpanelPage, trackMixpanelEvent} from 'services/mixpanel'
import Modal from 'components/Modal'

// 3rd-party
import {ConfirmCodeModalContentProps} from 'components/ConfirmCodeModalContent/ConfirmCodeModalContent'
import {Mutator} from 'final-form'
import {Field, FieldInputProps, Form, FormProps} from 'react-final-form'
import {AppDispatch, useAppDispatch} from 'services/store'
import {
  JWT_DECODE_RESPONSE,
  SignInRequestBody,
  SignInWithPasswordRequestBody,
  ForgotPasswordConfirmRequest,
  SignInWithPasswordResponse
} from 'types/authenticationTypes'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import {SIGN_UP_FIELDS} from 'constants/userProfileConstants'
import AgreementTerms from 'components/AgreementTerms/AgreementTerms'
import CreatePassword from 'components/CreatePassword'
import {kebabCase} from 'lodash'
import {
  ActiveScreenType,
  LogInSignUpModalFormContentProps,
  LoginType,
  LogInModeType,
  SignUpType,
  LogInSignUpModalProps
} from 'types/loginSignUpModalTypes'
import jwtDecode from 'jwt-decode'
import Checkbox from 'components/Checkbox'
import {getSqaId} from 'services/testingServices'
import PasswordInput from 'components/PasswordInput'
import FetchApiError from 'services/FetchApiError'
import {PASSWORD_ERROR_MESSAGE_MAP_BY_CODE} from 'constants/passwordConstants'
import {CircularProgress} from '@material-ui/core'
import {
  openNotification,
  getErrorMessageByActionType,
  getLimitExceededErrorMessageByActionType
} from 'services/notificationServices'
import {CheckboxProps} from 'components/Checkbox/Checkbox'
import {ACTION_LOG_IN_FAILURE_MESSAGE} from 'constants/notificationMessageConstants'
import ResetExpiredPassword from './ResetExpiredPassword'

const signUpfieldRulesDictionary: {[key: string]: RuleType[]} = {
  firstName: requiredValidationRules,
  lastName: requiredValidationRules,
  email: requiredEmailValidationRules,
  agreementField: requiredValidationRules
}

const logInfieldRulesDictionary: {[key: string]: RuleType[]} = {
  email: requiredEmailValidationRules,
  password: requiredValidationRules
}

const LogInSignUpModal = ({
  mode,
  open,
  handleClose,
  handleExited,
  onSuccess
}: LogInSignUpModalProps) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [activeScreen, setActiveScreen] = useState<ActiveScreenType>(MAIN)
  const [codeSentFrom, setCodeSentFrom] = useState<ActiveScreenType | null>(null)
  const [isValidationCodeValid, setIsValidationCodeValid] = useState<boolean | null>(null)
  const [isCodeValidating, setIsCodeValidating] = useState(false)
  const history = useHistory()
  const mixpanelEventType = useRef(mode === 'signUp' ? SIGNED_UP : LOGGED_IN)
  // we are using this additional loading state instead of react final form submitting
  // because when the modal component unmounts the button changes from loading state
  // to normal state which results in an odd experience in the UI before the modal closes.
  const [isLoading, setIsLoading] = useState(false)
  const closeModal = () => {
    const forceLogout = true
    // If the user's tokens have expired and this modal was opened in tokenService in an
    // attempt to have the user authenticate before moving forward, we will pass true
    // to the handleClose function that's passed in. That will force the user to be logged
    // out since they have chosen not to re-authenticate.
    handleClose(forceLogout)

    if (history.location.pathname.includes('redirect')) {
      history.push('/')
    }
  }
  const [userId, setUserId] = useState('')
  const [authSession, setAuthSession] = useState('')
  const [emailOrPhone, setEmailOrPhone] = useState('')
  const emailAddressFromCookie = getCookieValue(EMAIL_ADDRESS_COOKIE) ?? ''
  const [isTempPassword, setIsTempPassword] = useState(false)
  const [authResponse, setAuthResponse] = useState<SignInWithPasswordResponse>({})

  const handleUserAuthenticatedFlow = async (accessToken: string, idToken: string) => {
    const isUserAuthenticated = await handleUserAuthenticated(dispatch, {
      isUserAuthenticated: true,
      token: accessToken,
      idToken
    })
    if (isUserAuthenticated) {
      handleClose()
    }
  }

  const handleLogIn = (values: LoginType) => {
    setIsLoading(true)
    const clientId = getCasClientId()
    const requestPayload: SignInWithPasswordRequestBody = {
      username: values.email,
      password: values.password,
      clientId
    }

    return casSignInWithPasswordPost(requestPayload)
      .then(async response => {
        setAuthResponse(response)
        if (response?.accessToken && response?.idToken) {
          return await handleUserAuthenticatedFlow(response.accessToken, response.idToken)
        }
      })
      .catch(err => {
        setIsLoading(false)

        if (err?.response?.status === 401) {
          if (err.payload?.code === 'NotAuthorizedException') {
            // TODO : remove the logic of comparing with error messages and use unique codes
            // from api response for these 2 scenarios once the backend fix is in place
            if (err.payload?.errorMessage === 'Incorrect username or password.') {
              openNotification({
                type: 'error',
                text: ACTION_LOG_IN_FAILURE_MESSAGE
              })
            } else if (err.payload?.errorMessage === 'Password attempts exceeded') {
              openNotification({
                type: 'error',
                text: getLimitExceededErrorMessageByActionType('LOG_IN')
              })
            } else {
              openNotification({
                type: 'error',
                text: getErrorMessageByActionType('LOG_IN')
              })
            }
          } else if (err.payload?.code === 'PasswordExpiredException') {
            setActiveScreen(RESET_EXPIRED_PASSWORD)
          } else if (err.payload?.code === 'NEW_PASSWORD_REQUIRED') {
            setIsTempPassword(true)
            setActiveScreen(RESET_EXPIRED_PASSWORD)
          } else {
            openNotification({
              type: 'error',
              text: getErrorMessageByActionType('LOG_IN')
            })
          }
        } else {
          openNotification({
            type: 'error',
            text: getErrorMessageByActionType('LOG_IN')
          })
          handleClose()
        }
      })
  }

  function handleSignUp(values: LoginType): Promise<any> {
    const clientId = getCasClientId()
    setIsLoading(true)
    return casSignUpConfirmPost({...values, ...(clientId && {clientId})})
      .then(response => {
        setAuthSession(response.session ?? '')
        setEmailOrPhone(response.emailOrPhone ?? '')
        setActiveScreen(CONFIRM)
      })
      .catch(err => {
        if (err) {
          openNotification({
            type: 'error',
            text: getErrorMessageByActionType('SIGN_UP')
          })
          handleClose()
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleForgotPassword = async (
    values: LoginType
  ): Promise<void | Record<string, string>> => {
    try {
      const payload = {
        clientId: getCasClientId(),
        emailOrPhone: values.forgotPasswordEmail
      } as SignInRequestBody
      await casForgotPassword(payload)
      setActiveScreen(RESET_PASSWORD)
    } catch (err) {
      if (err instanceof FetchApiError && err.response?.status === 404) {
        setActiveScreen(RESET_PASSWORD)
      } else if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'LimitExceededException'
      ) {
        openNotification({
          type: 'error',
          text: getLimitExceededErrorMessageByActionType('FORGOT_PASSWORD')
        })
        handleClose()
      } else {
        openNotification({
          type: 'error',
          text: getErrorMessageByActionType('FORGOT_PASSWORD')
        })
        handleClose()
      }
    }
  }

  const handleResetPassword = async (values: LoginType): Promise<void | Record<string, string>> => {
    setIsLoading(true)
    try {
      const payload = {
        clientId: getCasClientId(),
        emailOrPhone: values.forgotPasswordEmail,
        accessCode: values.accessCode?.join(''),
        password: values.resetPassword
      } as ForgotPasswordConfirmRequest
      if (activeScreen === RESET_PASSWORD) {
        await casResetPassword(payload)
      } else {
        const payload = {
          clientId: getCasClientId() ?? undefined,
          password: values.password,
          newPassword: values.resetPassword,
          username: values.email
        }
        await casResetExpiredPassword(payload)
        handleClose()
        dispatch(
          openModal({
            modalType: LOG_IN_SIGN_UP_MODAL,
            mode
          })
        )
      }
      setActiveScreen(MAIN)
    } catch (err) {
      if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'CodeMismatchException'
      ) {
        return {
          accessCode: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.CodeMismatchException
        }
      } else if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'PasswordReuseException'
      ) {
        return {
          resetPassword: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.PasswordReuseException
        }
      } else if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'PasswordContainsCompanyNameException'
      ) {
        return {
          resetPassword: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.PasswordContainsCompanyNameException
        }
      } else if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'PasswordContainsPhoneException'
      ) {
        return {
          resetPassword: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.PasswordContainsPhoneException
        }
      } else if (
        err instanceof FetchApiError &&
        err.response?.status === 400 &&
        err.payload?.code === 'PasswordContainsEmailException'
      ) {
        return {
          resetPassword: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.PasswordContainsEmailException
        }
      } else {
        openNotification({
          type: 'error',
          text: getErrorMessageByActionType('RESET_PASSWORD')
        })
        handleClose()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormSubmit: FormProps<LoginType>['onSubmit'] = async (values, form) => {
    const {dirty} = form.getState()
    if (dirty) {
      if (mode === 'signUp') {
        setIsLoading(true)
        const idToken = authResponse?.idToken
        const accessToken = authResponse?.accessToken
        if (userId && idToken && accessToken) {
          updateAuthTokens(accessToken)
          try {
            await casSignUpChangePassword(userId, values?.password)
            handleUserAuthenticatedFlow(accessToken, idToken)
          } catch (err) {
            setIsLoading(false)
            if (
              err instanceof FetchApiError &&
              err.response?.status === 400 &&
              err.payload?.code === 'PasswordContainsEmailException'
            ) {
              return {
                password: PASSWORD_ERROR_MESSAGE_MAP_BY_CODE.PasswordContainsEmailException
              }
            } else {
              openNotification({
                type: 'error',
                text: getErrorMessageByActionType('SIGN_UP')
              })
              handleClose()
            }
          }
        } else {
          handleSignUp(values)
        }
      } else if (mode === 'logIn' && activeScreen === FORGOT_PASSWORD) {
        return await handleForgotPassword(values)
      } else if (
        (mode === 'logIn' && activeScreen === RESET_PASSWORD) ||
        activeScreen === RESET_EXPIRED_PASSWORD
      ) {
        return await handleResetPassword(values)
      } else if (mode === 'logIn') {
        return await handleLogIn(values)
      }
    }
  }

  const handleInputChange: LogInSignUpModalFormContentProps['handleInputChange'] = input => event => {
    const value = event.target.value
    input.onChange(['email', 'forgotPasswordEmail'].includes(input.name) ? value.trim() : value)
  }

  const handleHeaderBackClick = (resetValidationCode: () => void) => () => {
    // if the user clicks back from the EMAIL screen go back to MAIN else go back to the value of codeSentFrom
    resetValidationCode()
    let screen: ActiveScreenType
    if ([EMAIL, FORGOT_PASSWORD].includes(activeScreen)) {
      screen = MAIN
    } else if (activeScreen === RESET_PASSWORD) {
      screen = FORGOT_PASSWORD
    } else {
      screen = codeSentFrom as ActiveScreenType
    }
    setActiveScreen(screen)
    setCodeSentFrom(null)
  }

  const handleResendCode = (resetValidationCode: () => void, values: LoginType) => () => {
    const clientId = getCasClientId()
    return casSignUpConfirmPost({...values, ...(clientId && {clientId})})
      .then(response => {
        setAuthSession(response.session ?? '')
        setEmailOrPhone(response.emailOrPhone ?? '')
        resetValidationCode()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const validate = (values: LoginType | SignUpType) => {
    if (![FORGOT_PASSWORD, RESET_PASSWORD].includes(activeScreen)) {
      return mode === 'signUp'
        ? validateForm(signUpfieldRulesDictionary, values)
        : validateForm(logInfieldRulesDictionary, values)
    }
  }

  const resetValidationCode: Mutator<LoginType> = (args, state, utils) => {
    utils.changeValue(state, 'validationCode', val => {
      return val ? Array(val.length).fill('') : val
    })
    // reset the validation
    setIsValidationCodeValid(null)
  }

  const resetForgotPasswordEmail: Mutator<LoginType> = (args, state, utils) => {
    utils.changeValue(state, 'forgotPasswordEmail', val => {
      return state.lastFormState?.values.email
    })
  }

  const handleMixpanelLogInSignUpEvent = () => {
    const page = getMixpanelPage(location.pathname)
    const payload = {
      page,
      method: codeSentFrom === MAIN ? 'phone' : 'email'
    }
    trackMixpanelEvent(mixpanelEventType.current, payload)
  }

  const handleValidationCodeComplete: (
    resetValidationCode: () => void
  ) => ConfirmCodeModalContentProps['handleValidationCodeComplete'] = resetValidationCode => valueString => {
    const clientId = getCasClientId()
    const payload = {
      emailOrPhone,
      session: authSession,
      answer: valueString,
      clientId
    }
    setIsCodeValidating(true)
    return dispatch(answerAuthChallenge(payload))
      .then(response => {
        const authentication = response?.authentication
        const accessToken = authentication?.accessToken
        if (authentication) {
          setAuthResponse(authentication)
        }
        if (accessToken) {
          onSuccess && onSuccess()
          setActiveScreen(SIGN_UP_CREATE_PASSWORD)
          handleMixpanelLogInSignUpEvent()
          const decodedToken: JWT_DECODE_RESPONSE = jwtDecode(accessToken)
          setUserId(decodedToken?.username)
        } else {
          setIsValidationCodeValid(false)
          setAuthSession(response?.session ?? '')
        }
      })
      .catch(err => {
        console.error(err)
        openNotification({
          type: 'error',
          text: getErrorMessageByActionType('SIGN_UP')
        })
        handleClose()
      })
      .finally(() => {
        setIsCodeValidating(false)
      })
  }

  const {description, title} = MODAL_DATA[mode]

  const onForgotPassword = (resetForgotPasswordEmail: () => void) => () => {
    resetForgotPasswordEmail()
    setActiveScreen(FORGOT_PASSWORD)
  }

  return (
    <Form
      // @ts-ignore does not accept classname
      className='user-profile-modal-content'
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={{
        email: mode === 'logIn' ? emailAddressFromCookie : '',
        rememberMe: !!emailAddressFromCookie
      }}
      // we don't want to form to be reset completely when the user unchecks remember me and clicks on forgot password as we
      // want the email to be populated in the next step when the user clicks on Forgot Password.
      keepDirtyOnReinitialize
      mutators={{
        resetValidationCode,
        resetForgotPasswordEmail
      }}
      render={({
        dirtySinceLastSubmit,
        form,
        handleSubmit,
        submitting,
        values,
        hasValidationErrors,
        submitFailed,
        hasSubmitErrors
      }) => {
        const shouldDisableSubmit = (submitFailed && hasValidationErrors) || isLoading || submitting
        return (
          <LogInSignUpModal.Styled
            open={open}
            onClose={closeModal}
            onExited={handleExited}
            showCloseButton
            closeOnBackdropClick={activeScreen !== CONFIRM}
          >
            <Modal.Header>
              <LogInSignUpModal.HeaderContent
                activeScreen={activeScreen}
                handleHeaderBackClick={handleHeaderBackClick(form.mutators.resetValidationCode)}
                title={title}
              />
            </Modal.Header>
            <Modal.Content>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  const formState = form.getState()
                  const shouldPreventSubmit =
                    formState.hasSubmitErrors && !formState.dirtySinceLastSubmit
                  if (!shouldPreventSubmit) {
                    handleSubmit()
                  }
                }}
              >
                {activeScreen === MAIN && (
                  <LogInSignUpModal.FormContent
                    description={description}
                    dispatch={dispatch}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    location={location}
                    submitting={submitting}
                    mode={mode}
                    onForgotPassword={onForgotPassword(form.mutators.resetForgotPasswordEmail)}
                    hasValidationErrors={hasValidationErrors}
                    submitFailed={submitFailed}
                    isLoading={isLoading}
                    handleClose={handleClose}
                    values={values}
                    handleFederationLoginButtonClick={redirectUrl => {
                      const agreementField = form.getFieldState('agreementField')
                      form.restart()
                      if (mode === 'signUp' && agreementField?.error) {
                        agreementField.blur()
                      } else {
                        window.location.href = redirectUrl
                      }
                    }}
                  />
                )}
                {activeScreen === EMAIL && (
                  <LogInSignUpModal.EmailContent
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                  />
                )}
                {activeScreen === CONFIRM && (
                  <ConfirmCodeModalContent
                    codeSentToType={EMAIL}
                    codeSentTo={values.email}
                    handleResendCode={handleResendCode(form.mutators.resetValidationCode, values)}
                    handleValidationCodeComplete={handleValidationCodeComplete(
                      form.mutators.resetValidationCode
                    )}
                    isValidationCodeValid={isValidationCodeValid}
                    isCodeValidating={isCodeValidating}
                  />
                )}
                {activeScreen === SIGN_UP_CREATE_PASSWORD && (
                  <CreatePassword handleCreatePassword={handleSubmit} />
                )}
                {activeScreen === FORGOT_PASSWORD && (
                  <ForgotPassword isSubmitting={submitting} handleInputChange={handleInputChange} />
                )}
                {activeScreen === RESET_PASSWORD && <ResetPassword />}
                {activeScreen === RESET_EXPIRED_PASSWORD && (
                  <ResetExpiredPassword isTempPassword={isTempPassword} />
                )}
              </form>
            </Modal.Content>
            <Modal.Footer>
              {activeScreen === SIGN_UP_CREATE_PASSWORD ? (
                <Button
                  type='submit'
                  variant='primary'
                  isLoading={isLoading}
                  title='Get Started'
                  className='primary btn-change-password'
                  sqaPrefix='get-started'
                  onClick={handleSubmit}
                  size='large'
                  disabled={shouldDisableSubmit}
                />
              ) : activeScreen === RESET_PASSWORD ? (
                <Button
                  className='action-button-width reset-password-submit-button'
                  type='submit'
                  title={
                    submitting ? (
                      <div className='progress'>
                        <CircularProgress className='progress-icon' />
                      </div>
                    ) : (
                      'Continue'
                    )
                  }
                  disabled={
                    submitting ||
                    (hasValidationErrors && submitFailed) ||
                    (hasSubmitErrors && !dirtySinceLastSubmit)
                  }
                  sqaPrefix='continue'
                  onClick={handleSubmit}
                />
              ) : null}
              {activeScreen === RESET_EXPIRED_PASSWORD ? (
                <Button
                  type='submit'
                  variant='primary'
                  isLoading={isLoading}
                  title='Continue'
                  className='primary action-button-width'
                  sqaPrefix='continue-expired-password'
                  onClick={handleSubmit}
                  disabled={shouldDisableSubmit}
                />
              ) : null}
            </Modal.Footer>
          </LogInSignUpModal.Styled>
        )
      }}
    />
  )
}

type LogInSignUpModalFederatedLoginButtonProps = {
  location: {
    pathname: string
    search?: string
  }
  provider: 'google'
  mode: 'signUp' | 'logIn'
  onClick?: (redirectUrl: string) => void
}

LogInSignUpModal.FederatedLoginButton = ({
  location,
  provider,
  mode,
  onClick = () => {}
}: LogInSignUpModalFederatedLoginButtonProps) => {
  const {imgSrc, label, name} = FEDERATED_PROVIDER_DICTIONARY[provider]
  const getHref = () => {
    const env = getEnv() as keyof typeof CAS_CNAME_SUBDOMAIN_MAP
    // Construct URL for Google client
    return `https://${CAS_CNAME_SUBDOMAIN_MAP[env]}.clearcapital.com/oauth2/authorize?identity_provider=${name}&redirect_uri=${CAS_COGNITO_ENVIRONMENT.redirect_uri}&response_type=CODE&client_id=${CAS_COGNITO_ENVIRONMENT.userPoolClientId}&scope=aws.cognito.signin.user.admin email openid phone profile`
  }
  const handleClick = () => {
    const socialAuthUrl = getHref()
    // set cookie so we know what route to redirect the user to after successful login
    setCookieValue(
      FEDERATED_LOGIN_REDIRECT_COOKIE,
      `${location.pathname}${location.search ?? ''}; path=/`
    )
    // set cookie so we know what action type was performed (login or signup)
    const federatedLoginMpEventType = `${mode === 'signUp' ? SIGNED_UP : LOGGED_IN}; path=/`
    setCookieValue(FEDERATED_LOGIN_MP_EVENT_TYPE_COOKIE, federatedLoginMpEventType)
    setCookieValue(FEDERATED_LOGIN_SOCIAL_CLIENT_URL_COOKIE, `${socialAuthUrl}; path=/`)
    onClick(socialAuthUrl)
  }
  return (
    <Button
      className='federated-button'
      variant='secondary'
      title={
        <Fragment>
          <img src={imgSrc} alt={`${label} Icon`} />
          <span>{mode === 'logIn' ? `Log In with` : `Continue with`} </span>
          <span>&nbsp;{label}</span>
        </Fragment>
      }
      onClick={handleClick}
      size='large'
    />
  )
}

type LogInSignUpModalHeaderContentProps = {
  activeScreen: ActiveScreenType
  handleHeaderBackClick: () => void
  title: string
}

LogInSignUpModal.HeaderContent = ({
  activeScreen,
  handleHeaderBackClick,
  title
}: LogInSignUpModalHeaderContentProps) => {
  const shouldShowBackButton = [FORGOT_PASSWORD, RESET_PASSWORD].includes(activeScreen)
  const titles: Record<ActiveScreenType, string> = {
    MAIN: title ?? '',
    CONFIRM: 'Verify your email',
    EMAIL: 'Enter your email address',
    FORGOT_PASSWORD: 'Forgot Password',
    RESET_PASSWORD: 'Reset Password',
    RESET_EXPIRED_PASSWORD: 'Reset Password',
    SIGN_UP_CREATE_PASSWORD: 'Create your password'
  }

  return (
    <div className='modal-title-wrap'>
      <h3>
        {shouldShowBackButton && (
          <IconButton
            className='back-icon-button'
            icon='arrow_back'
            onClick={handleHeaderBackClick}
          />
        )}
        <span>{titles[activeScreen]}</span>
      </h3>
    </div>
  )
}

LogInSignUpModal.FormContent = ({
  description,
  dispatch,
  handleClose,
  handleInputChange,
  handleSubmit,
  isLoading,
  location,
  submitting,
  mode,
  onForgotPassword,
  hasValidationErrors,
  submitFailed,
  values,
  handleFederationLoginButtonClick
}: LogInSignUpModalFormContentProps) => {
  const shouldDisableSubmit = (submitFailed && hasValidationErrors) || isLoading ? true : submitting
  const isSignUpMode = mode === 'signUp'

  const onLinkHandler = (
    dispatch: AppDispatch,
    handleClose: LogInSignUpModalProps['handleClose'],
    mode: LogInModeType
  ) => {
    handleClose()
    dispatch(
      openModal({
        modalType: LOG_IN_SIGN_UP_MODAL,
        mode
      })
    )
  }

  const onRememberMeHandler = (
    input: FieldInputProps<CheckboxProps, HTMLElement>,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const email = values.email
    if (email && event.target.checked) {
      setCookieValue(EMAIL_ADDRESS_COOKIE, email)
    } else {
      const emailCookieValue = getCookieValue(EMAIL_ADDRESS_COOKIE)
      if (emailCookieValue) {
        setCookieValue(EMAIL_ADDRESS_COOKIE, '; expires=Thu, 01 Jan 1970 00:00:00 UTC;')
      }
    }
    input.onChange(event.target.checked)
  }

  const handleEmailBlur = (values: LoginType) => () => {
    if (mode === 'logIn' && values?.rememberMe && values.email) {
      setCookieValue(EMAIL_ADDRESS_COOKIE, values.email)
    }
  }

  return (
    <div>
      <p className='description'>{description}</p>
      {isSignUpMode ? (
        <Fragment>
          {SIGN_UP_FIELDS.map(({id, label, style}) => {
            return id === 'agreementField' ? (
              <AgreementTerms
                key={id}
                field={id}
                classname='agreement-terms'
                sqaPrefix='agreement-terms'
              />
            ) : (
              <Field name={id} key={id}>
                {({input, meta}) => {
                  return (
                    <TextInput
                      label={label}
                      value={input.value}
                      onChange={handleInputChange(input)}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      style={style || {}}
                      sqaPrefix={kebabCase(id)}
                    />
                  )
                }}
              </Field>
            )
          })}
        </Fragment>
      ) : (
        <Fragment>
          <Field name='email'>
            {({input, meta}) => {
              return (
                <TextInput
                  label='Email'
                  value={input.value}
                  onBlur={handleEmailBlur(values)}
                  onChange={handleInputChange(input)}
                  error={meta.error && meta.touched}
                  helperText={meta.touched && meta.error}
                  sqaPrefix='email'
                />
              )
            }}
          </Field>
          <Field name='password'>
            {({input, meta}) => {
              return (
                <PasswordInput
                  onChange={handleInputChange(input)}
                  value={input.value}
                  error={meta.submitError || (meta.error && meta.touched)}
                  helperText={meta.submitError || (meta.touched && meta.error)}
                  sqaPrefix='password'
                />
              )
            }}
          </Field>
          <Field name='rememberMe'>
            {({input}) => {
              return (
                <Checkbox
                  label={<span {...getSqaId('label', 'remember-me')}>Remember Me</span>}
                  checked={input.value}
                  onChange={event => {
                    onRememberMeHandler(input, event)
                  }}
                  sqaPrefix='remember-me'
                  inputProps={{'data-sqa-id': 'remember-me'}}
                />
              )
            }}
          </Field>
        </Fragment>
      )}
      {mode === 'logIn' && (
        <Button
          className='btn-forgot-password'
          variant='tertiary'
          title='Forgot Password?'
          onClick={onForgotPassword}
        />
      )}
      <Button
        isLoading={isLoading || submitting}
        title={ACTION_BUTTON_TITLE[mode]}
        type='submit'
        className='primary btn-submit'
        onClick={handleSubmit}
        disabled={shouldDisableSubmit}
        sqaPrefix={isSignUpMode ? 'sign-up' : 'log-in'}
      />
      <div className='separator'>
        <span>or</span>
      </div>
      <div className='federated-button-container'>
        <LogInSignUpModal.FederatedLoginButton
          mode={mode}
          provider='google'
          location={location}
          onClick={handleFederationLoginButtonClick}
        />
      </div>
      <div className='footer-log-in'>
        <span className='existing-account-title'>
          {isSignUpMode ? 'Already have an account?' : 'New to the Portal?'}
          &nbsp;
        </span>
        <Button
          variant='tertiary'
          className='btn-redirect'
          onClick={() =>
            onLinkHandler(dispatch, handleClose, mode === 'signUp' ? 'logIn' : 'signUp')
          }
          title={isSignUpMode ? 'Log In' : 'Create an account'}
        />
      </div>
    </div>
  )
}

type LogInSignUpModalEmailContentProps = {
  handleInputChange: (a: FieldInputProps<any, HTMLElement>) => ChangeEventHandler<HTMLInputElement>
  handleSubmit: () => void
  submitting: boolean
}

LogInSignUpModal.EmailContent = ({
  handleInputChange,
  handleSubmit,
  submitting
}: LogInSignUpModalEmailContentProps) => {
  return (
    <div>
      <p className='description'>We will send you a temporary code that is good for 3 minutes.</p>
      <div className='input-row'>
        <Field name='email'>
          {({input, meta}) => {
            return (
              <TextInput
                label='Email'
                placeholder='Email'
                value={input.value}
                onChange={handleInputChange(input)}
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
                autoFocus
              />
            )
          }}
        </Field>
        <Button
          className='continue-button'
          variant='primary'
          disabled={submitting}
          title='Continue'
          type='submit'
          size='large'
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

LogInSignUpModal.Styled = styled(Modal)`
  height: auto;
  .modal-title-wrap {
    h3 {
      margin: 0;
      display: flex;
      align-items: center;

      .back-icon-button {
        margin-left: -11px;
        margin-right: 8px;
      }
    }
  }

  .footer-log-in {
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .existing-account-title {
      color: ${({theme}) => theme.colors.grayscale.gray};
    }
  }

  .footer {
    .btn-change-password {
      width: 100%;
    }
  }

  .content.portal-MuiDialogContent-root {
    padding-bottom: 16px;

    .description {
      margin: 0 0 25px;
      line-height: 1.25rem;
      font-size: 0.9rem;
    }

    .input-row {
      display: flex;

      .continue-button {
        width: 107px;
        flex: none;
        align-self: flex-end;
        margin-bottom: 27px;
        margin-left: 8px;
      }
    }

    .portal-MuiFormControl-root {
      margin-bottom: 6px;
    }

    .portal-MuiFormLabel-root {
      font-weight: 500;
    }

    .btn-forgot-password {
      float: right;
    }
    .separator {
      position: relative;
      border-top: 0.5px solid ${({theme}) => theme.colors.grayscale.gray};
      height: 33px;
      margin-top: 10px;
      display: flex;
      justify-content: center;

      span {
        background-color: ${({theme}) => theme.colors.grayscale.white};
        position: absolute;
        top: -12px;
        padding: 0 12px;
      }
    }

    .federated-button-container {
      display: flex;
      justify-content: space-between;

      .federated-button {
        width: 100%;
        justify-content: center;
        padding: 8px 0;

        img {
          max-width: 16px;
          margin-right: 8px;
        }

        .material-icons {
          font-size: 22px;
          color: ${({theme}) => theme.colors.stone.dark};
          margin-right: 8px;
        }
      }
    }
    .btn-submit {
      width: 100%;
      margin: 22px 0;
    }

    .agreement-error {
      position: relative;
    }
    .agreement-terms {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
    }
  }
  .modal-footer {
    padding: 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0;
    a {
      font-size: 12px;
    }
  }

  .action-button-width {
    min-width: 93px;
  }

  .reset-password-submit-button {
    .progress {
      width: 22px;
      height: 22px;

      &-icon {
        color: ${({theme}) => theme.colors.stone.base};
      }
    }
  }

  .btn-redirect {
    cursor: pointer;
  }
`

export default LogInSignUpModal

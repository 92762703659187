// Core
import React from 'react'

// Component
import LinkButton from './LinkButton'
import {PRIVATE_POLICY_URL} from 'constants/appConstants'

const PrivacyPolicy = () => (
  <LinkButton variant='tertiary' title='Privacy Policy' href={PRIVATE_POLICY_URL} target='_blank' />
)
export default PrivacyPolicy

import useSWR, {SWRConfiguration} from 'swr'
import {useSelector} from 'react-redux'

import {CustomerUserTable, CustomerUsersResponse} from 'types/customerTypes'
import {customerKeySelector} from 'selectors/customerSelectors'
import {customerApi} from 'services/apis'
import {getCustomerUserStatus} from 'services/customerUsersServices'

const formattedCustomerUser = (customerUsers: CustomerUsersResponse): CustomerUserTable[] => {
  return customerUsers.map(customerUser => {
    const roles = customerUser?.roles?.find(role => role === 'user-management')
      ? 'User Manager'
      : 'Default'
    return {
      ...customerUser,
      roles,
      userStatus: getCustomerUserStatus(customerUser)
    }
  })
}

const useCustomerUsers = (swrConfig?: SWRConfiguration) => {
  const customerKey = useSelector(customerKeySelector)
  const url = `customer/${customerKey}/users`

  const {data, error, isLoading, mutate} = useSWR<CustomerUsersResponse>(
    customerKey ? url : null,
    async () => customerApi.get<CustomerUsersResponse>(url),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      ...swrConfig
    }
  )

  return {
    isCustomerUsersLoading: isLoading,
    customerUsers: data ? formattedCustomerUser(data) : [],
    customerUsersError: error,
    customerUsersMutate: mutate
  }
}

export default useCustomerUsers

import PasswordInputWithRequirements from 'components/PasswordInputWithRequirements'
import React from 'react'
import styled from 'styled-components'

const ResetExpiredPassword = ({isTempPassword = false}): JSX.Element => {
  const text =
    (isTempPassword
      ? 'The temporary password must be changed. '
      : 'We require users to reset their password every 90 days for security reasons. ') +
    'Please enter your new password to continue.'

  return (
    <ResetExpiredPassword.Styled>
      <p className='reset-message'>{text}</p>
      <PasswordInputWithRequirements fieldName='resetPassword' label='New Password' />
    </ResetExpiredPassword.Styled>
  )
}

ResetExpiredPassword.Styled = styled.div`
  .reset-message {
    margin: 0 0 20px 0;
  }
`

export default ResetExpiredPassword

// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'

// Components, services, etc
import ProductCard from 'components/ProductCard'
import {productGroupArray} from 'constants/productCardConstants'
import {getSqaId} from 'services/testingServices'

const ProductsList = () => {
  const sqaPrefix = 'landing-products'
  return (
    <ProductsList.Styled className='products-list'>
      <h2 className='header' {...getSqaId('header', sqaPrefix)}>
        Our Products
      </h2>
      <div className='product-card-list' {...getSqaId('list', sqaPrefix)}>
        {productGroupArray.map((productGroup, index) => {
          return <ProductCard key={productGroup} productGroup={productGroup} index={index} />
        })}
      </div>
    </ProductsList.Styled>
  )
}

ProductsList.Styled = styled.div`
  padding: 40px 0 60px 0px;

  .header {
    font-size: 1.125rem;
    margin-bottom: 16px;
  }

  .product-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 40px;
  }

  @media (max-width: 1440px) {
    padding-left: 40px;
  }
`

export default ProductsList

import {casApi} from 'services/apis'
import {SET_USER_ERROR, SET_USER_LOADED, SET_USER_LOADING} from 'constants/actionTypes'
import {setMixpanelUserData} from 'services/mixpanel'
import type {AppDispatch} from 'services/store'
import type {
  GetUserResponse,
  UpdateUserRequestBody,
  UpdateUserResponse
} from 'types/authenticationTypes'
import * as Sentry from '@sentry/react'

export function getUser() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_USER_LOADING
    })

    return casApi
      .get<GetUserResponse>('user')
      .then(res => {
        const {id} = res
        Sentry.setUser({id})

        dispatch({
          type: SET_USER_LOADED,
          payload: res
        })
      })
      .catch(err => {
        console.error(err)
        dispatch({
          type: SET_USER_ERROR,
          payload: err
        })
      })
  }
}

export function updateUser(updatedUser: UpdateUserRequestBody) {
  return (dispatch: AppDispatch) => {
    return casApi
      .put<UpdateUserResponse>('user', updatedUser)
      .then(res => {
        dispatch({
          type: SET_USER_LOADED,
          payload: res
        })

        // The user data was updated, update the Mixpanel user.
        setMixpanelUserData()
      })
      .catch(err => {
        console.error(err)
        throw err
      })
  }
}

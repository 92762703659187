import React, {ReactNode} from 'react'
import styled from 'styled-components'

import {getSqaId} from 'services/testingServices'

import colors from 'styles/colors'

type BaseProps = {
  children: ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const NotificationBase = ({children, className, ...props}: BaseProps) => {
  return (
    <NotificationBase.Styled className={className ?? ''} {...props}>
      {children}
    </NotificationBase.Styled>
  )
}

NotificationBase.Styled = styled.div`
  max-width: 500px;
  align-items: center;
  background-color: ${colors.stone.dark};
  border-radius: 0.5rem;
  color: ${colors.grayscale.white};
  display: flex;

  &.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }
`

type Props = {
  children: ReactNode
  sqaPrefix?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Notification = ({children, sqaPrefix, ...props}: Props) => {
  return (
    <NotificationBase {...getSqaId('notification', sqaPrefix)} {...props}>
      {children}
    </NotificationBase>
  )
}

interface NotificationIconProps {
  variant?: 'success' | 'error' | 'loading'
  children: ReactNode
}

export const NotificationIcon = ({variant = 'success', children}: NotificationIconProps) => {
  return <NotificationIcon.Styled className={variant}>{children}</NotificationIcon.Styled>
}

NotificationIcon.Styled = styled.div`
  width: 56px;
  align-items: center;
  align-self: stretch;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  color: ${colors.grayscale.white};
  display: flex;
  justify-content: center;

  &.loading {
    background-color: #0085f0;
  }

  &.error {
    background-color: #db5b52;
  }

  &.success {
    background-color: #519434;
  }
`

export const NotificationBody = ({children}: Props) => {
  return <NotificationBody.Styled>{children}</NotificationBody.Styled>
}

NotificationBody.Styled = styled.div`
  color: ${colors.grayscale.white};
  flex: 1 1 0%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
`

export const NotificationActions = ({children}: {children: ReactNode}) => {
  return <NotificationActions.Styled>{children}</NotificationActions.Styled>
}

NotificationActions.Styled = styled.div`
  align-self: stretch;
  border-color: #7b858a;
  border-width: 0;
  border-left-width: 1px;
  border-style: solid;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`

type NotificationButtonProps = {
  children: ReactNode
} & React.HTMLAttributes<HTMLButtonElement>

export const NotificationButton = ({children, ...props}: NotificationButtonProps) => {
  return <NotificationButton.Styled {...props}>{children}</NotificationButton.Styled>
}

NotificationButton.Styled = styled.button`
  width: 120px;
  min-height: 36px;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  border-color: #7b858a;
  border-style: solid;
  border-top-width: 1px;
  color: ${colors.grayscale.white};
  cursor: pointer;
  flex-grow: 1;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;

  &:first-child {
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  &:focus {
    outline: 0;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &:focus-visible {
    border-color: transparent;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 2px ${colors.grayscale.white}, 0 0 0 4px rgb(51, 163, 255),
      0 0 ${colors.grayscale.black};
    outline: 2px solid transparent;
    outline-offset: 2px;
    z-index: 10;
  }

  &:hover {
    background-color: #7b858a;
  }
`

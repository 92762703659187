// Style
import styled from 'styled-components'

// Core
import React, {ReactNode, useState} from 'react'

// Components, services, etc
import Button from 'components/Button'
import Modal from 'components/Modal'
import {getSqaId} from 'services/testingServices'

type ConfirmationModalProps = {
  open: boolean
  handleClose: () => void
  handleExited: () => void
  title: string
  content: ReactNode
  onConfirm: () => Promise<void>
  onConfirmText: string
  onConfirmLoading: string
  sqaPrefix: string
}

const ConfirmationModal = ({
  open,
  handleClose,
  handleExited,
  title,
  content,
  onConfirm,
  onConfirmText,
  onConfirmLoading,
  sqaPrefix
}: ConfirmationModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const closeModal = () => {
    handleClose()
  }
  const handleDeleteCard = async () => {
    setSubmitting(true)
    try {
      await onConfirm()
      closeModal()
    } catch (e) {
      // TODO: toast
    }

    setSubmitting(false)
  }

  return (
    <ConfirmationModal.Styled
      size='sm'
      open={open}
      onExited={handleExited}
      onClose={closeModal}
      showCloseButton
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Footer>
        <Button
          variant='secondary'
          title='Cancel'
          onClick={closeModal}
          {...getSqaId(`${sqaPrefix}-cancel-button`)}
        />
        <Button
          variant='warning'
          disabled={submitting}
          title={submitting ? <span>{onConfirmLoading}&hellip;</span> : onConfirmText}
          type='submit'
          onClick={handleDeleteCard}
          {...getSqaId(`${sqaPrefix}-confirm-button`)}
        />
      </Modal.Footer>
    </ConfirmationModal.Styled>
  )
}

ConfirmationModal.Styled = styled(Modal)`
  .content.portal-MuiDialogContent-root {
    padding-bottom: 24px;
  }

  p {
    margin: 0;
    + p {
      margin-top: 8px;
    }
  }
`
export default ConfirmationModal

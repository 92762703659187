import {casApi, getEnv} from 'services/apis'
import type {
  SignInRequestBody,
  SignInResponse,
  SignInWithPasswordRequestBody,
  SignInWithPasswordResponse,
  SignUpConfirmRequestBody,
  SignUpConfirmResponse,
  ForgotPasswordResponse,
  ForgotPasswordConfirmRequest,
  ForgotPasswordConfirmResponse,
  UpdateExpiredPasswordConfirmRequest,
  UpdateExpiredPasswordConfirmResponse,
  GetSocialAuthTokenResponse,
  GetSocialAuthTokenCode,
  RefreshTokenResponse
} from 'types/authenticationTypes'

export const casSignUpConfirmPost = (payload: SignUpConfirmRequestBody) => {
  return casApi.post<SignUpConfirmResponse>('sign-up-confirm', payload)
}

export const getCasClientId = (): string => {
  const env = getEnv()
  return process.env[`REACT_APP_CAS_CLIENT_ID_${env.toUpperCase()}`] as string
}

export const casSignInPost = (payload: SignInRequestBody) => {
  return casApi.post<SignInResponse>('sign-in', payload)
}

export const casSignInWithPasswordPost = (payload: SignInWithPasswordRequestBody) => {
  return casApi.post<SignInWithPasswordResponse>('sign-in-password', payload)
}

export const casSignOut = () => {
  return casApi.get('sign-out')
}

export const casSignUpChangePassword = (userName: string, password: string) => {
  return casApi.post<string>(`user/${userName}/password`, {password})
}

export const casForgotPassword = async (payload: SignInRequestBody) => {
  return casApi.post<ForgotPasswordResponse>('forgot-password', payload)
}

export const casResetPassword = async (payload: ForgotPasswordConfirmRequest) => {
  return casApi.post<ForgotPasswordConfirmResponse>('forgot-password/confirm', payload)
}

export const casResetExpiredPassword = async (payload: UpdateExpiredPasswordConfirmRequest) => {
  return casApi.post<UpdateExpiredPasswordConfirmResponse>('user/password', payload)
}

export const casSocialAuthToken = async (code: GetSocialAuthTokenCode) => {
  const clientId = getCasClientId()
  return casApi.get<GetSocialAuthTokenResponse>(
    `social-auth-tokens?code=${code}&client_id=${clientId}`
  )
}

export const casRefreshToken = async () => {
  const clientId = getCasClientId()
  return casApi.get<RefreshTokenResponse>(`refresh-token?UserPoolClientId=${clientId}`)
}

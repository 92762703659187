import React from 'react'
import type {CSSProperties} from 'react'
import classNames from 'classnames'

type Props = {
  className?: String
  icon: string
  onClick?: () => any
  style?: CSSProperties
}

const Icon = ({className, icon, onClick, style}: Props) => {
  return (
    <i className={classNames('material-icons', className)} onClick={onClick} style={style}>
      {icon}
    </i>
  )
}

export default Icon

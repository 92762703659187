import {
  INVALID_EMAIL_MSG,
  INVALID_PHONE_MSG,
  REQUIRED_FIELD_MSG,
  INVALID_PASSWORD_MSG,
  INVALID_ACCESS_CODE_MSG,
  MAX_STRING_LENGTH_START,
  MAX_STRING_LENGTH_END
} from 'constants/validationErrorMessagesConstants'
import validators from 'services/validationRules'

export const DATE_DISPLAY_FORMAT = 'MMDDYYYY'
export const MAX_COMPANY_CHARACTERS_LIMIT = 50
export const MAX_ORDER_STATUS_CHANGE_REASON_LIMIT = 1000

// set these up once and reuse them to avoid recreating a new array for each validated input in render
export const requiredValidationRules = [
  {
    rule: 'required' as const,
    errorMsg: REQUIRED_FIELD_MSG
  }
]

export const phoneValidationRules = [
  {
    rule: 'validateUSAPhoneNumber' as const,
    errorMsg: INVALID_PHONE_MSG
  }
]

export const optionalPhoneValidationRules = [
  {
    rule: 'validateOptionalPhoneNumber' as const,
    errorMsg: INVALID_PHONE_MSG
  }
]

export const requiredPhoneValidationRules = [...requiredValidationRules, ...phoneValidationRules]

export const requiredEINValidationRules = [
  ...requiredValidationRules,
  {
    rule: 'matchRegexp' as const,
    ruleParams: [/^\d{9}$/],
    errorMsg: 'Must match ##-####### format'
  }
]

export const emailValidationRules = [
  {
    rule: 'isEmail' as const,
    errorMsg: INVALID_EMAIL_MSG
  }
]

export const requiredEmailValidationRules = [...requiredValidationRules, ...emailValidationRules]

export const requiredDateRules = [
  ...requiredValidationRules,
  {
    rule: 'validateDate' as const,
    ruleParams: [DATE_DISPLAY_FORMAT],
    errorMsg: 'Date must match MM/DD/YYYY format'
  }
]

export const requiredMultiSelectRules = [
  {
    rule: 'isArrayWithLength' as const,
    errorMsg: REQUIRED_FIELD_MSG
  }
]

export const requiredSSNValidationRules = [
  ...requiredValidationRules,
  {
    rule: 'matchRegexp' as const,
    ruleParams: [/^\d{9}$/],
    errorMsg: 'Must match ###-##-#### format'
  }
]

export const requiredPasswordRules = [
  ...requiredValidationRules,
  {
    rule: 'validatePassword' as const,
    errorMsg: INVALID_PASSWORD_MSG
  }
]

export const requiredAccessCodeValidationRules = [
  ...requiredValidationRules,
  {
    rule: 'matchRegexp' as const,
    ruleParams: [/[0-9]{6}/],
    errorMsg: INVALID_ACCESS_CODE_MSG
  }
]

export const companyNameValidationRules = [
  {
    rule: 'maxStringLength' as const,
    ruleParams: [MAX_COMPANY_CHARACTERS_LIMIT],
    errorMsg: `${MAX_STRING_LENGTH_START} ${MAX_COMPANY_CHARACTERS_LIMIT} ${MAX_STRING_LENGTH_END}`
  }
]

// this can be used to create a custom validator that checks that the passed in string
// is not empty after calling trim on it
export const createRequiredStringValidationRules = (string = '') => [
  {
    rule: () => validators.required(string),
    errorMsg: REQUIRED_FIELD_MSG
  }
]

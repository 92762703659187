import {LOADED, LOADING} from 'constants/reduxStatuses'
import {createSelector} from 'reselect'
import {getAddressMetaData} from 'services/addressServices'
import {formatDataObject} from 'services/dataFormatter'
import type {RootState} from 'services/store'

export const rootPropertySelector = (state: RootState) => state.property
export const propertyStatusSelector = createSelector(
  [rootPropertySelector],
  property => property.status
)
export const propertyDataSelector = createSelector(
  [rootPropertySelector],
  property => property.data
)
export const propertyErrorSelector = createSelector(
  [rootPropertySelector],
  property => property.error
)
export const propertySearchingSelector = createSelector(
  [rootPropertySelector],
  property => property.searching
)
export const propertyDataAddressSelector = createSelector([propertyDataSelector], propertyData => {
  return propertyData.address
})

export const propertyHeroDataSelector = createSelector([propertyDataSelector], getAddressMetaData)

export const propertyDetailsSelector = createSelector([propertyDataSelector], propertyData => {
  let {characteristics = {}} = propertyData ?? {}
  // characteristics can be null and the default value above won't be applied
  characteristics = characteristics ?? {}
  const {
    bathFullCount,
    bathHalfCount,
    bedCount,
    lastValueEffectiveDate,
    propertyTypeName,
    styleName,
    buildingSize,
    lotSize
  } = characteristics
  type Value = string | number | undefined
  const propertyDetailsMockData: [string, Value | Array<Value>][] = [
    ['lastValueEffectiveDate', lastValueEffectiveDate],
    ['propertyTypeName', propertyTypeName],
    ['bedroomCount', bedCount],
    ['bath', [bathFullCount, bathHalfCount]],
    ['styleName', styleName],
    ['gla', buildingSize],
    ['lotSize', lotSize]
  ]
  return formatDataObject(propertyDetailsMockData)
})
export const additionalDetailsSelector = createSelector([propertyDataSelector], propertyData => {
  let {characteristics = {}} = propertyData
  // characteristics can be null and the default value above won't be applied
  characteristics = characteristics ?? {}
  const {
    garageTypeName,
    garageCarCount,
    poolName,
    heatingName,
    coolingName,
    firePlaceTypeName,
    levelsCount
  } = characteristics ?? {}
  const additionalDetailsMockData: [string, any][] = [
    ['heatingCooling', [heatingName, coolingName]],
    ['fireplace', firePlaceTypeName],
    ['garage', [garageTypeName, garageCarCount]],
    ['overviewPool', poolName],
    ['levels', levelsCount]
  ]
  return formatDataObject(additionalDetailsMockData)
})
export const propertyPidSelector = createSelector(
  [propertyDataSelector],
  propertyData => propertyData.pid
)
export const isPropertyDataLoadedSelector = createSelector(
  [propertyStatusSelector],
  propertyStatus => propertyStatus === LOADED
)
export const isPropertyDataLoadingSelector = createSelector(
  [propertyStatusSelector],
  propertyStatus => propertyStatus === LOADING
)

import {QueryAddressType, getPropertyByAddress} from 'actions/propertyActions'
import {SET_PROPERTY_ERROR} from 'constants/actionTypes'
import {LOADED} from 'constants/reduxStatuses'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {propertyStatusSelector} from 'selectors'
import {useAppDispatch} from 'services/store'

export function usePropertyData(addressArg?: QueryAddressType) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const propertyStatus = useSelector(propertyStatusSelector)

  useEffect(() => {
    try {
      const isLoaded = propertyStatus === LOADED
      const isOnPropertyOverviewPage = location.pathname.includes('property-overview')
      const address =
        isOnPropertyOverviewPage && location.search
          ? JSON.parse(
              decodeURIComponent(location.search.substring(location.search.indexOf('?') + 1))
            )
          : addressArg
      if (!isLoaded && address) {
        dispatch(getPropertyByAddress(address))
      }
    } catch (err) {
      dispatch({
        type: SET_PROPERTY_ERROR,
        payload: err as any
      })
    }
  }, [dispatch, location, history, propertyStatus, addressArg])
}

export const PurposeOptions = [
  {label: 'Appraisal Update', value: 'APPRAISAL_UPDATE'},
  {label: 'Asset Valuation', value: 'ASSET_VALUATION'},
  {label: 'Construction', value: 'CONSTRUCTION'},
  {label: 'Distressed', value: 'DISTRESSED'},
  {label: 'Home Equity', value: 'HOME_EQUITY'},
  {label: 'Other', value: 'OTHER'},
  {label: 'Pre-funding Decision', value: 'PREFUNDING_DECISION'},
  {label: 'Purchase', value: 'PURCHASE'},
  {label: 'Refinance', value: 'REFINANCE'},
  {label: 'Servicing', value: 'SERVICING'}
]

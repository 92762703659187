import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {CircularProgress, Divider} from '@material-ui/core'
import {Form, FormSpy} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {useSelector, useDispatch} from 'react-redux'

import Modal from 'components/Modal'
import {openModal, resetModal} from 'actions/modalActions'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import {propertyDataAddressSelector, propertyPidSelector} from 'selectors/propertySelectors'
import useProductGroups from 'hooks/useProductGroups'
import {OrderFormConfig, OrderFormType, ProductOptions, ProductType} from 'types/productTypes'
import PropertyDetails from 'components/OrderForm/PropertyDetails'
import ProductDetails from 'components/OrderForm/ProductDetails'
import LoanDetails from 'components/OrderForm/LoanDetails'
import UploadDocumentsSection from 'components/OrderForm/UploadDocumentsSection'
import DeliveryOptions from 'components/OrderForm/DeliveryOptions'
import PaymentOptions from 'components/OrderForm/PaymentOptions'
import {useElements, useStripe} from '@stripe/react-stripe-js'
import {calculateProductTotalPrice, getProductOptions, getProduct} from 'services/productsServices'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {getDeliveryOptionByTurnTime} from 'services/deliveryOptionsServices'
import {createPaymentIntent} from 'services/paymentServices'
import FetchApiError from 'services/FetchApiError'
import {openNotification} from 'services/notificationServices'
import {createOrder} from 'services/ordersServices'
import {customerKeySelector} from 'selectors/customerSelectors'
import history from 'services/history'
import CheckoutSummary from './CheckoutSummary'
import {PaymentIntentId} from 'types/orderTypes'
import {resetDocumentsState} from 'actions/documentsActions'
import {documentsLoadingSelector, documentsUploadedSelector} from 'selectors/documentsSelectors'
import {
  MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED,
  STRIPE_3D_SECURE_ERROR
} from 'constants/orderFormConstants'
import {
  GET_PRODUCTS_ERROR,
  PLACE_ORDER_FAILURE_MESSAGE
} from 'constants/notificationMessageConstants'
import {trackMixpanelEvent} from 'services/mixpanel'
import {PURCHASED_PRODUCT} from 'constants/mixpanelConstants'
import {getAddressKey} from 'services/addressServices'
import {getOrderDetailsLink} from 'services/productNavigationServices'
import ContactsDetails from 'components/OrderForm/ContactsDetails/ContactsDetails'
import {convertContactTypes} from 'services/contactsServices'

interface OrderFormModalProps {
  className?: string
  orderFormConfig: OrderFormConfig
  open: boolean
  handleClose: () => void
}

export const OrderFormModal = ({
  className,
  orderFormConfig,
  open,
  handleClose
}: OrderFormModalProps) => {
  const {image, sections} = orderFormConfig
  const formPristineRef = useRef<boolean>(false)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const address = useSelector(propertyDataAddressSelector)
  const {isProductGroupsLoading, productGroups, productGroupsError, mutate} = useProductGroups()
  const is404Error = productGroupsError?.response?.status === 404
  const [productsGroupCallRetryCount, setProductsGroupCallRetryCount] = useState(-1)
  const supportedProductGroups = useSupportedProductGroups()
  const customerKey = useSelector(customerKeySelector)
  const pid = useSelector(propertyPidSelector)
  const uploadedDocuments = useSelector(documentsUploadedSelector)
  const isDocumentsUploading = useSelector(documentsLoadingSelector)
  const isProductGroupsOrRetryCallLoading =
    isProductGroupsLoading ||
    (productsGroupCallRetryCount <= MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED && is404Error)

  let productOptions: ProductOptions = []
  if (sections.PRODUCT_DETAILS?.inputs.productCode) {
    productOptions = getProductOptions(orderFormConfig.productGroup, supportedProductGroups, pid)
  }

  let formInitialValues
  if (productOptions.length === 1) {
    const productType = productOptions[0].value
    const {prices: deliveryOptions} =
      getProduct({
        productId: productType,
        productGroups: supportedProductGroups,
        productGroupId: orderFormConfig.productGroup
      }) ?? {}
    const turnTime = deliveryOptions?.length === 1 ? deliveryOptions[0].turnTime || 0 : undefined

    formInitialValues = {
      productType,
      turnTime
    }
  }

  const handleCloseAndResetModal = useCallback(() => {
    handleClose()
    dispatch(resetModal())
  }, [dispatch, handleClose])

  // this mutate is for the retry logic to get triggered for products endpoint
  useEffect(() => {
    if (is404Error) {
      mutate()
    }
  }, [is404Error, mutate])

  useEffect(() => {
    if (is404Error) {
      setProductsGroupCallRetryCount(previous => previous + 1)
    }
  }, [is404Error, productGroupsError])

  useEffect(() => {
    if (productsGroupCallRetryCount === MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED + 1) {
      openNotification({
        type: 'error',
        text: GET_PRODUCTS_ERROR
      })

      handleCloseAndResetModal()
    }
  }, [handleCloseAndResetModal, productsGroupCallRetryCount])

  const onClose = () => {
    if (!formPristineRef.current) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: closeModal => () => {
            closeModal()
            handleCloseAndResetModal()
          }
        })
      )
    } else {
      handleCloseAndResetModal()
    }
    dispatch(resetDocumentsState())
  }

  const onSubmit = async (values: OrderFormType) => {
    try {
      const {prices: deliveryOptions} =
        getProduct({
          productId: values.productType,
          productGroups: supportedProductGroups,
          productGroupId: orderFormConfig.productGroup
        }) ?? {}

      let paymentIntentId: PaymentIntentId = ''
      if (values.paymentMethod === 'LENDER_CARD') {
        if (!stripe || !elements) {
          throw new Error()
        }

        const deliveryOptionSelected = getDeliveryOptionByTurnTime(
          deliveryOptions ?? [],
          values.turnTime
        )

        if (deliveryOptionSelected?.estimate) {
          const amount = Math.round(
            parseFloat(
              calculateProductTotalPrice(
                deliveryOptionSelected?.estimate ?? 0,
                deliveryOptionSelected.creditCardFee
              )
            ) * 100
          )

          elements?.update({amount})

          const {error: submitError} = await elements.submit()
          if (submitError) {
            // in case of error, stripe will display the localized error message in the error.message field
            return
          }

          const {paymentMethod, error} = await stripe.createPaymentMethod({elements})
          if (error) {
            throw new Error()
          }

          const response = await createPaymentIntent({
            product: values.productType,
            cardAction: 'CHARGE_ONLY',
            paymentAmount: amount,
            paymentMethodId: paymentMethod?.id
          })
          paymentIntentId = response.id

          const {nextAction, clientSecret} = response
          if (nextAction?.type === 'use_stripe_sdk') {
            const {error} = await stripe.handleNextAction({
              clientSecret
            })
            if (error) {
              throw new Error(error?.message, {cause: STRIPE_3D_SECURE_ERROR})
            }
          }
        }
      }

      const order = await createOrder({
        ...values,
        ...(values.contacts && {contacts: convertContactTypes(values.contacts ?? [])}),
        ...(address && {addressKey: getAddressKey(address)}),
        orgKey: customerKey,
        paymentDetails: {
          processorId: paymentIntentId,
          method: values.paymentMethod
        },
        pid,
        suppliedAddress: {
          street: address?.displayAddressLine1,
          city: address?.city,
          state: address?.state,
          zipcode: address?.zip,
          unitNumber: address?.standardizedAddress?.unitNum
        },
        customerDocumentList: uploadedDocuments
          .filter(
            document =>
              document.storageKey !== null ||
              document.storageKey !== undefined ||
              document.storageKey !== ''
          )
          .map(document => ({storageKey: document.storageKey as string}))
      })

      trackMixpanelEvent(PURCHASED_PRODUCT, {productType: values.productType})

      handleCloseAndResetModal()

      const productGroup = productGroups?.productGroups?.find(productGroup =>
        productGroup.products.find(
          ({product}: {product: ProductType}) => product === order.productType
        )
      )?.productGroup

      if (productGroup) {
        history.push(getOrderDetailsLink(productGroup, order.opsOrderId))
      }
    } catch (err) {
      const getErrorMessage = (err: unknown): string | undefined => {
        let errorMessage
        if (
          err instanceof FetchApiError &&
          err?.response?.status === 402 &&
          err?.payload?.errorMessage
        ) {
          errorMessage = err.payload.errorMessage
        } else if (err instanceof Error && err.cause === STRIPE_3D_SECURE_ERROR) {
          errorMessage = err.message
        }
        return errorMessage
      }

      const errorMessage = getErrorMessage(err)
      const text = errorMessage || PLACE_ORDER_FAILURE_MESSAGE
      openNotification({
        type: 'error',
        text
      })
    }
  }

  return (
    <OrderFormModal.Styled
      className={className}
      open={open}
      closeOnBackdropClick={false}
      disableEnforceFocus
      showCloseButton
      size='lg'
      onClose={onClose}
    >
      <Modal.Header>Checkout</Modal.Header>
      <Divider />
      <Modal.Content>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators
          }}
          initialValues={formInitialValues}
          render={({handleSubmit, submitting, hasValidationErrors, submitFailed}) => {
            const shouldDisablePlaceOrderButton =
              submitting ||
              (hasValidationErrors && submitFailed) ||
              isProductGroupsOrRetryCallLoading ||
              isDocumentsUploading

            return (
              <Fragment>
                <FormSpy
                  subscription={{pristine: true}}
                  onChange={state => {
                    formPristineRef.current = state.pristine
                  }}
                />
                <div className='content-left'>
                  {isProductGroupsOrRetryCallLoading ? (
                    <div className='content-left-loading'>
                      <CircularProgress size={100} />
                    </div>
                  ) : (
                    <Fragment>
                      <p className='content-left-description'>
                        Almost there. We just need a few more details from you.
                      </p>
                      <form
                        className={classNames(
                          {invalid: hasValidationErrors && submitFailed},
                          'form'
                        )}
                        onSubmit={handleSubmit}
                      >
                        <PropertyDetails />
                        {sections.PRODUCT_DETAILS && (
                          <ProductDetails
                            productGroup={orderFormConfig.productGroup}
                            {...sections.PRODUCT_DETAILS}
                          />
                        )}
                        {sections.LOAN_DETAILS && <LoanDetails {...sections.LOAN_DETAILS} />}
                        {sections.CONTACTS_DETAILS && (
                          <ContactsDetails
                            productGroup={orderFormConfig.productGroup}
                            {...sections.CONTACTS_DETAILS}
                          />
                        )}
                        {sections.UPLOAD_DOCUMENTS && (
                          <UploadDocumentsSection
                            productGroup={orderFormConfig.productGroup}
                            {...sections.UPLOAD_DOCUMENTS}
                          />
                        )}
                        {sections.DELIVERY_OPTIONS && (
                          <DeliveryOptions productGroup={orderFormConfig.productGroup} />
                        )}
                        {sections.PAYMENT_OPTIONS && (
                          <PaymentOptions productGroup={orderFormConfig.productGroup} />
                        )}
                      </form>
                    </Fragment>
                  )}
                </div>
                <Divider orientation='vertical' />
                <div className='content-right'>
                  <CheckoutSummary
                    productGroup={orderFormConfig.productGroup}
                    image={image}
                    isSubmitting={submitting}
                    shouldDisablePlaceOrderButton={shouldDisablePlaceOrderButton}
                    onPlaceOrder={handleSubmit}
                  />
                </div>
              </Fragment>
            )
          }}
        />
      </Modal.Content>
    </OrderFormModal.Styled>
  )
}

OrderFormModal.Styled = styled(Modal)`
  .header {
    padding: 20px 24px;
  }

  .content.portal-MuiDialogContent-root {
    padding: 0;
  }

  .content {
    // modal marginTop + marginBottom = 64px
    // modal header height = 72px
    // content paddingBottom = 24px
    height: calc(100vh - 64px - 72px - 24px);
    display: flex;

    &-left {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      overflow: auto;
      padding: 24px;
      row-gap: 20px;

      &-loading {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .form {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;

        > * {
          flex: 1 100%;
        }

        .checkout-details-section {
          min-width: 350px;
        }

        /* override the padding set in our MUI theme overrides for all inputs in this form */
        .portal-MuiFormControl-root {
          padding-bottom: 0;
        }

        &.invalid .portal-MuiFormControl-root:has(.portal-MuiFormHelperText-root) {
          padding-bottom: 27px;
        }
      }

      &-description {
        font-size: 1rem;
        margin: 0;
      }
    }

    &-right {
      flex: 0 0 399px;
      padding: 24px;
    }
  }
`

export default OrderFormModal

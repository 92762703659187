import React from 'react'
import PasswordInputWithRequirements from 'components/PasswordInputWithRequirements'
import styled from 'styled-components'

const CreatePassword = ({handleCreatePassword}: {handleCreatePassword: () => void}) => {
  return (
    <CreatePassword.Styled>
      <p className='password-subtitle'>Enter a password to continue.</p>
      <PasswordInputWithRequirements />
    </CreatePassword.Styled>
  )
}

CreatePassword.Styled = styled.div`
  .password-subtitle {
    margin: 0 0 24px 0;
  }
`

export default CreatePassword

// Style
import styled from 'styled-components'

// Core
import React, {useState} from 'react'
import {CircularProgress, Menu, MenuItem, MenuProps} from '@material-ui/core'
import {GetApp} from '@material-ui/icons'

type DownloadMenuItemProps = {label: string; onClick: () => void}
const DownloadMenuItem = ({label, onClick}: DownloadMenuItemProps) => {
  const [loading, setLoading] = useState(false)
  return (
    <MenuItem
      onClick={async () => {
        setLoading(true)
        await onClick()
        setLoading(false)
      }}
    >
      {loading ? (
        <div className='loading-icon'>
          <CircularProgress />
        </div>
      ) : (
        <GetApp />
      )}
      <span>{label}</span>
    </MenuItem>
  )
}

const DownloadMenu = ({items, ...props}: MenuProps & {items: DownloadMenuItemProps[]}) => {
  return (
    <MenuStyled
      {...props}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      getContentAnchorEl={null}
    >
      {items.map(({label, onClick}) => {
        return <DownloadMenuItem key={label} label={label} onClick={onClick} />
      })}
    </MenuStyled>
  )
}

const MenuStyled = styled(Menu)`
  .portal-MuiListItem-root {
    height: 40px;
  }

  .portal-MuiSvgIcon-root {
    font-size: 1.125rem;
    margin-right: 16px;
  }

  .loading-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 16px;
  }
`

export default DownloadMenu

import {CircularProgress} from '@material-ui/core'
import Card from 'components/Card'
import MessageCenterTable from 'components/MessageCenterTable'
import useOrderMessages from 'hooks/useOrderMessages'
import React from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import NoMessages from 'assets/no-messages.svg'
import classNames from 'classnames'

const MessageCenter = () => {
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: messages, isLoading} = useOrderMessages(opsOrderId)

  return (
    <MessageCenter.Styled
      className={classNames({loading: isLoading, 'empty-messages-card': messages?.length === 0})}
    >
      {isLoading ? (
        <CircularProgress />
      ) : messages?.length ? (
        <MessageCenterTable />
      ) : (
        <div className='empty-messages'>
          <img src={NoMessages} alt='No Messages' />
          <h3 className='title'>No Recent Messages</h3>
        </div>
      )}
    </MessageCenter.Styled>
  )
}

MessageCenter.Styled = styled(Card)`
  max-height: 375px;
  overflow-y: auto;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 340px;
    .portal-MuiCircularProgress-svg {
      height: 48px;
      width: 48px;
    }
  }
  &.empty-messages-card {
    display: flex;
    justify-content: center;
    min-height: 340px;
  }
  .empty-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${({theme}) => theme.colors.grayscale.gray};
    .title {
      margin-top: 24px;
    }
  }
`

export default MessageCenter

import {ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import {
  SET_DOCUMENT_UPLOAD_FORM_SUBMITTED,
  DELETE_UPLOADED_DOCUMENTS,
  RESET_DOCUMENTS,
  SET_UPLOADED_DOCUMENTS,
  SET_AURA_REVISION_PROCESSING
} from 'constants/actionTypes'

export type UploadedDocuments = {
  name: string
  loading: boolean
  storageKey?: string
}

type DocumentsState = {
  documentUploadFormSubmitted: boolean
  status: ReduxStateStatus
  error?: null
  uploadedDocuments: Array<UploadedDocuments>
  processing: boolean
}

const initialState: DocumentsState = {
  documentUploadFormSubmitted: false,
  status: UNSET,
  error: null,
  uploadedDocuments: [],
  processing: false
}

type DocumentAction =
  | {type: typeof RESET_DOCUMENTS}
  | {
      type: typeof SET_UPLOADED_DOCUMENTS
      payload: UploadedDocuments
    }
  | {
      type: typeof DELETE_UPLOADED_DOCUMENTS
      payload: string
    }
  | {
      type: typeof SET_DOCUMENT_UPLOAD_FORM_SUBMITTED
      payload: boolean
    }
  | {type: typeof SET_AURA_REVISION_PROCESSING; payload: boolean}

const documentsReducer = (state = initialState, action: DocumentAction): DocumentsState => {
  const {type} = action

  switch (type) {
    case SET_UPLOADED_DOCUMENTS: {
      let result = []
      const updatedIndex = state?.uploadedDocuments?.findIndex(
        document => document.name === action.payload.name
      )
      if (updatedIndex !== -1) {
        result = [...state.uploadedDocuments]
        result[updatedIndex] = action.payload
      } else {
        result = [...state.uploadedDocuments, action.payload]
      }
      return {
        ...state,
        uploadedDocuments: result
      }
    }
    case DELETE_UPLOADED_DOCUMENTS: {
      return {
        ...state,
        uploadedDocuments:
          state.uploadedDocuments.filter(document => document.name !== action.payload) ?? []
      }
    }
    case SET_DOCUMENT_UPLOAD_FORM_SUBMITTED: {
      return {
        ...state,
        documentUploadFormSubmitted: action.payload
      }
    }
    case SET_AURA_REVISION_PROCESSING: {
      return {
        ...state,
        processing: action.payload
      }
    }
    case RESET_DOCUMENTS:
      return initialState
    default:
      return state
  }
}

export default documentsReducer

import type {ProductGroups} from 'types/productTypes'
import {isSupportedProductGroup} from 'services/productsServices'
import useProductGroups from 'hooks/useProductGroups'
import {productGroupDictionary} from 'constants/productCardConstants'

const useSupportedProductGroups = (): ProductGroups => {
  const {productGroups} = useProductGroups()

  const supportedProductGroups: ProductGroups = []
  Object.keys(productGroupDictionary).forEach(key => {
    const productGroup = productGroups?.productGroups?.find(
      productGroup => productGroup.productGroup === key && isSupportedProductGroup(productGroup)
    )
    if (productGroup) {
      supportedProductGroups.push(productGroup)
    }
  })

  return supportedProductGroups
}

export default useSupportedProductGroups

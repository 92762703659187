// Styles
import styled from 'styled-components'

// Core
import {FormControl, FormHelperText, InputLabel} from '@material-ui/core'
import React, {Fragment} from 'react'

// Components and others
import {openModal} from 'actions/modalActions'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import {UpdateVerificationModalProps} from 'components/UpdateVerificationModal/UpdateVerificationModal'
import {UPDATE_VERIFICATION_MODAL} from 'constants/modalsConstants'
import {useAppDispatch} from 'services/store'

// third party
import {Field} from 'react-final-form'

const UserProfileVerificationFormField = ({
  value,
  field,
  label,
  buttonTitle,
  placeholder,
  helperText,
  suffix,
  verificationType
}: {
  value?: string | number
  field: string
  label: string
  placeholder?: string
  buttonTitle: string
  helperText?: string
  suffix?: string
  verificationType: UpdateVerificationModalProps['inputType']
}) => {
  const dispatch = useAppDispatch()

  const handleShowVerificationModal = () => {
    dispatch(openModal({modalType: UPDATE_VERIFICATION_MODAL, inputType: verificationType}))
  }

  return (
    <UserProfileVerificationFormField.Styled>
      <Field name={field}>
        {({input, meta}) => {
          const hasError = meta.error && meta.touched
          const helpMessage = (meta.touched && meta.error) || helperText
          const helperTextDom = <FormHelperText error={hasError}>{helpMessage}</FormHelperText>

          return value ? (
            <Fragment>
              <TextInput
                label={label}
                placeholder={placeholder}
                disabled
                value={input.value}
                onChange={e => input.onChange(e.target.value)}
                error={hasError}
              />
              <div className='field-suffix'>
                {suffix ? (
                  <p className='description'>{suffix}</p>
                ) : (
                  <Button variant='tertiary' title='Update' onClick={handleShowVerificationModal} />
                )}
              </div>
              {helperTextDom}
            </Fragment>
          ) : (
            <Fragment>
              <FormControl fullWidth>
                <InputLabel shrink>{label}</InputLabel>
                <Button
                  variant='tertiary'
                  className='add-button'
                  title={buttonTitle}
                  onClick={handleShowVerificationModal}
                />
              </FormControl>
              {helperTextDom}
            </Fragment>
          )
        }}
      </Field>
    </UserProfileVerificationFormField.Styled>
  )
}

UserProfileVerificationFormField.Styled = styled.div`
  grid-column-start: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  .field-suffix {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .portal-MuiFormHelperText-root {
    position: relative;
    line-height: 22px;
    margin-top: 4px;
    grid-column-start: span 2;
  }

  .field-suffix + .portal-MuiFormHelperText-root {
    margin-top: 8px;
  }

  .add-button {
    width: max-content;
    margin-top: 20px;
  }

  .description {
    margin: 0;
    color: ${({theme}) => theme.colors.grayscale.gray};
  }
`
export default UserProfileVerificationFormField

// Styles
import styled from 'styled-components/macro'

// Core
import React, {useCallback, useRef} from 'react'

// Components, services, etc
import MultiInputContainer from 'components/MultiInputContainer'

// 3rd-party
import classnames from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputBase from '@material-ui/core/InputBase'
import CircularProgress from '@material-ui/core/CircularProgress'

export type CodeValidationInputProps = {
  className?: string
  onChange?: (a: {
    target: {
      value: Array<string>
    }
  }) => void
  onComplete?: (valueString: string) => void
  onBlur?: () => void
  onFocus?: () => void
  type: 'text' | 'number'
  value: Array<string>
  verificationCodeLength: number
  error?: boolean
  helperText?: string | false
  isCodeValidating?: boolean
}

const PREV = 'prev'
const NEXT = 'next'

// this will either use the passed in value if it exists (after trimming it
// to length if needed), otherwise it will create a new array with an empty
// string as the value for each item
const getValues = (value: string[], verificationCodeLength: number) => {
  let vals
  if (value && value.length) {
    vals = []
    value.forEach((char, i) => {
      if (i < verificationCodeLength) {
        vals.push(char)
      }
    })
  } else {
    vals = Array(verificationCodeLength).fill('')
  }

  return vals
}

const CodeValidationInput = ({
  className,
  error,
  helperText,
  onChange,
  onComplete,
  onBlur,
  onFocus,
  type,
  value,
  verificationCodeLength,
  isCodeValidating
}: CodeValidationInputProps) => {
  // this object will hold refs to each individual input
  const validationRefs = useRef<{[key: string]: HTMLInputElement}>({})
  const autoFocusIndex = useRef(value?.length >= verificationCodeLength ? 0 : value?.length ?? 0)

  const createValidationInputRef = useCallback((name, el) => {
    validationRefs.current = {
      ...validationRefs.current,
      [name]: el
    }
  }, [])

  const focusInput = (direction: typeof PREV | typeof NEXT, currentInputName: string) => {
    const currentInputIndex = currentInputName.replace('code-', '')
    let inputToFocus = ''
    if (direction === PREV) {
      inputToFocus = `code-${+currentInputIndex - 1}`
    } else if (direction === NEXT) {
      inputToFocus = `code-${+currentInputIndex + 1}`
    }

    if (validationRefs.current[inputToFocus]) {
      validationRefs.current[inputToFocus].focus()
      setTimeout(() => {
        validationRefs.current[inputToFocus].select()
      })
    }
  }

  const handleKeyDown = useCallback(se => {
    const currentKey = se.key
    const inputName = se.target.id
    if (currentKey === 'Backspace') {
      if (!se.target.value) {
        focusInput(PREV, inputName)
      }
    } else if (currentKey === 'ArrowLeft') {
      focusInput(PREV, inputName)
    } else if (currentKey === 'ArrowRight') {
      focusInput(NEXT, inputName)
    }
  }, [])

  const handleFocus = useCallback(se => {
    se.target.select(se)
  }, [])

  const handleContainerFocus = () => {
    onFocus && onFocus()
  }

  const handleContainerBlur = () => {
    onBlur && onBlur()
  }

  const handleCodeInputChange: (
    index: number
  ) => React.ChangeEventHandler<HTMLInputElement> = useCallback(
    index => se => {
      const inputValue =
        type === 'number' ? se.target.value.replace(/[^\d]/gi, '') : se.target.value
      // we need to use getValues here in case the initial value is undefined. In that
      // case we need to ensure the shape of the value for this component is an array
      // with empty strings for each character in the input (driven by verificationCodeLength)
      const vals = [...getValues(value, verificationCodeLength)]
      let nextInputIndex

      if (inputValue.length > 1) {
        let nextIndex = inputValue.length + index - 1
        if (nextIndex >= verificationCodeLength) {
          nextIndex = verificationCodeLength - 1
        }
        nextInputIndex = nextIndex
        inputValue.split('').forEach((char, i) => {
          const cursor = index + i
          if (cursor < verificationCodeLength) {
            vals[cursor] = char
          }
        })
      } else {
        nextInputIndex = index + 1
        vals[index] = inputValue
      }

      onChange &&
        onChange({
          target: {
            value: vals
          }
        })

      const valueString = vals.join('')
      if (onComplete && valueString.length >= verificationCodeLength) {
        onComplete(valueString)
      }

      if (inputValue !== '' && nextInputIndex) {
        const inputToFocus = `code-${nextInputIndex}`
        if (validationRefs.current[inputToFocus]) {
          validationRefs.current[inputToFocus].focus()
          validationRefs.current[inputToFocus].select()
        }
      }
    },
    [onChange, onComplete, type, value, verificationCodeLength]
  )

  // see why we need to use getValues above
  const valuesArray = getValues(value, verificationCodeLength)

  return (
    <CodeValidationInput.Styled
      className={classnames('code-validation-input', className, {error})}
      onFocus={handleContainerFocus}
      onBlur={handleContainerBlur}
    >
      <MultiInputContainer>
        {valuesArray.map((value, i) => {
          const name = `code-${i}`
          return (
            <InputBase
              key={name}
              autoComplete='off'
              className='code-input'
              id={name}
              value={valuesArray[i]}
              // use onInput instead of onChange to trigger handler regardless
              // of if the input's value changes or not. This solves a bug where
              // entering the same character in an input with a value would not
              // cause the next input to be focused.
              onInput={handleCodeInputChange(i)}
              required
              autoFocus={i === autoFocusIndex?.current}
              inputRef={el => createValidationInputRef(name, el)}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              disabled={isCodeValidating}
            />
          )
        })}
      </MultiInputContainer>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isCodeValidating ? (
        <div className='inline-progress-wrap'>
          <CircularProgress thickness={3} className='progress' />
        </div>
      ) : null}
    </CodeValidationInput.Styled>
  )
}

CodeValidationInput.Styled = styled.div`
  &.code-validation-input {
    position: relative;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .multi-input {
      width: 262px;
      padding: 7px 16px;

      .code-input {
        width: 36px;

        > input {
          text-align: center;
          border-bottom: 1px solid ${({theme}) => theme.colors.stone.base};

          &:focus,
          &:active,
          &:valid {
            border-bottom: 1px solid ${({theme}) => theme.colors.cobalt.dark};
          }
        }

        & + .code-input {
          margin-left: 14px;
        }
      }
    }

    &.error {
      padding-bottom: 24px;
      margin-bottom: 8px;

      .multi-input {
        border: 1px solid ${({theme}) => theme.colors.ruby.base};

        .code-input {
          > input {
            border-bottom: 1px solid ${({theme}) => theme.colors.ruby.base};
          }
        }
      }

      .portal-MuiFormHelperText-root {
        color: ${({theme}) => theme.colors.ruby.base};
      }
    }

    .inline-progress-wrap {
      height: 24px;
      width: 24px;
      margin-left: 14px;

      .progress {
        color: ${({theme}) => theme.colors.cobalt.base};
      }
    }
  }
`

CodeValidationInput.defaultProps = {
  type: 'number',
  verificationCodeLength: 6
}

export default CodeValidationInput

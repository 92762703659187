// Core
import React, {useEffect} from 'react'

// Components, services, etc
import CookieModal from 'components/CookieModal'
import LogInSignUpModal from 'components/LogInSignUpModal'
import UnsavedWorkModal from 'components/UnsavedWorkDialog'
import UpdateVerificationModal from 'components/UpdateVerificationModal'
import UserProfileModal from 'components/UserProfileModal'
import WelcomeModal from 'components/WelcomeModal'
// import useWelcomeModal from 'hooks/useWelcomeModal'
import type {FC} from 'react'
import {ModalDefinition} from 'reducers/modalReducer'
import {modalsSelector} from 'selectors'
import type {MixpanelEventData} from 'services/mixpanel'
import ChangeOrderStatusModal from 'components/ChangeOrderStatusModal'
import HoldRemovalConfirmationModal from 'components/HoldRemovalConfirmationModal'
import OrderFormModal from 'components/OrderFormModal'
import InviteUserModal from 'components/InviteUserModal'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {closeModal} from 'actions/modalActions'
import usePrevious from 'hooks/usePrevious'
import UserIdleTimeoutModal from 'components/UserIdleTimeoutModal'
import DocumentUploadModal from 'components/DocumentUploadModal/DocumentUploadModal'
import AddContactModal from 'components/AddContactModal'
import ComposeMessageModal from 'components/ComposeMessageModal'

export const modalsMap = {
  ADD_CONTACT_MODAL: AddContactModal,
  DOCUMENT_UPLOAD_MODAL: DocumentUploadModal,
  LOG_IN_SIGN_UP_MODAL: LogInSignUpModal,
  USER_PROFILE_MODAL: UserProfileModal,
  UPDATE_VERIFICATION_MODAL: UpdateVerificationModal,
  UNSAVED_WORK_MODAL: UnsavedWorkModal,
  COOKIE_MODAL: CookieModal,
  WELCOME_MODAL: WelcomeModal,
  CHANGE_ORDER_STATUS_MODAL: ChangeOrderStatusModal,
  // REQUEST_REVISION_MODAL and ON_HOLD_MODAL point to the same component ChangeOrderStatusModal since we need
  // some differentiation for the mixpanel events between Revision Request and On Hold scenarios as we pass
  // the modal name using getMixpanelModalValue function to mixpanel.
  REQUEST_REVISION_MODAL: ChangeOrderStatusModal,
  ON_HOLD_MODAL: ChangeOrderStatusModal,
  HOLD_REMOVAL_CONFIRMATION_MODAL: HoldRemovalConfirmationModal,
  ORDER_FORM_MODAL: OrderFormModal,
  INVITE_USER_MODAL: InviteUserModal,
  USER_IDLE_TIMEOUT_MODAL: UserIdleTimeoutModal,
  COMPOSE_MESSAGE_MODAL: ComposeMessageModal
}

type ModalMap = typeof modalsMap
// need to handle union object type, use Omit directly will cause type merge
type OmitModalDefinition<T> = T extends {} ? Omit<T, keyof ModalDefinition> : never
export type ModalType = {
  [k in keyof ModalMap]: OmitModalDefinition<Parameters<ModalMap[k]>[0]> & {
    modalType: k
    mixpanelEventData?: MixpanelEventData
    handleClose?: (...args: any) => any
    handleExited?: (...args: any) => any
  }
}[keyof ModalMap]

const ModalsSystem = () => {
  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const previousPathName = usePrevious(pathname)
  // we want to close all modals when the pathname changes
  useEffect(() => {
    if (previousPathName && previousPathName !== pathname) dispatch(closeModal())
  }, [dispatch, pathname, previousPathName])
  const modals = useSelector(modalsSelector)
  // We use this hook in this component to ensure that the modal is properly opened after
  // the initial route change has completed which triggers the dispatch above which closes
  // all modals.

  // 12/13/22: PP-920 requires that we don't show the welcome modal but this is likely
  // temporary so I'm only commenting it out, not deleting the code
  // useWelcomeModal()
  return (
    <React.Fragment>
      {modals.map(({modalType, ...rest}) => {
        const Modal = modalsMap[modalType] as FC
        return <Modal {...rest} key={rest.id} />
      })}
    </React.Fragment>
  )
}

export default ModalsSystem

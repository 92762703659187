import {PROD_ORIGIN} from 'constants/appConstants'
import {isProdBuild} from 'services/apis'

/*
REACT_APP_GOOGLE_MAPS_API_KEY_PRODUCTION key:
To be used for production environment only
Domains Permitted:
portal.clearcapital.com
*/
/*
 REACT_APP_GOOGLE_MAPS_API_KEY_NPE key:
 To be used for local development and non production environments
 The .env.development file, used with npm run start, sets a dev only key for this var
 Domains Permitted:
   localhost
   xe-dev.clearcollateral.com
   10.0.2.2
   portal.dev.clearcapital.com
   portal.qa.clearcapital.com
   portal.integ.clearcapital.com
 */
const MAP_KEY =
  isProdBuild() && window.location.origin === PROD_ORIGIN
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PRODUCTION
    : process.env.REACT_APP_GOOGLE_MAPS_API_KEY_NPE

export default MAP_KEY

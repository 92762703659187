import {INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP} from 'constants/orderContactConstants'
import {Contacts, ContactType} from 'types/orderTypes'

interface ContactTypesOption {
  label: string
  value: ContactType
}

export const getOrderFormContactTypeOptions = (): ContactTypesOption[] => {
  return Object.entries(INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP).map<ContactTypesOption>(
    (entries): ContactTypesOption => {
      const [key, displayValue] = entries as [
        keyof typeof INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP,
        ContactTypesOption['label']
      ]

      return {
        label: displayValue,
        value: key
      }
    }
  )
}

export const convertContactType = (contact: Contacts): Contacts => {
  const phoneNumbers = contact.phoneNumbers?.filter(phoneNumber => phoneNumber.value)
  return {
    ...contact,
    ...(phoneNumbers && {phoneNumbers})
  }
}

export const convertContactTypes = (contacts: Contacts[]) =>
  contacts?.map(contact => convertContactType(contact))

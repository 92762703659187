import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Field, useForm} from 'react-final-form'

import DetailsSection from 'components/OrderForm/DetailsSection'
import {ProductGroupType, ProductPaymentMethodConfig} from 'types/productTypes'
import RadioGroup from 'components/RadioGroup'
import TextInput from 'components/TextInput'
import {PRODUCT_PAYMENT_METHODS_CONFIG_MAP} from 'constants/productConstants'
import {components} from 'types/api/order-placement'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {getProduct} from 'services/productsServices'
import {getInputValidity} from 'services/formValidationHelpers'
import {
  requiredValidationRules,
  requiredEmailValidationRules
} from 'constants/validationRulesConstants'
import {REQUIRED_PAYMENT_OPTION} from 'constants/validationErrorMessagesConstants'
import StripePaymentForm from 'components/StripePaymentForm'

interface PaymentOptionsProps {
  productGroup: ProductGroupType
}

const getAllowedPaymentOptions = (
  paymentMethods: components['schemas']['ProductDetails']['paymentMethods']
) => {
  const allowedPaymentMethods: ProductPaymentMethodConfig[] = []

  paymentMethods?.forEach(paymentMethod => {
    const paymentMethodConfig = PRODUCT_PAYMENT_METHODS_CONFIG_MAP[paymentMethod]

    if (paymentMethodConfig) {
      allowedPaymentMethods.push(paymentMethodConfig)
    }
  })

  return allowedPaymentMethods
}

const PaymentOptions = ({productGroup}: PaymentOptionsProps) => {
  const supportedProductGroups = useSupportedProductGroups()
  const {getFieldState, resetFieldState, change} = useForm()
  const productType = getFieldState('productType')?.value
  const paymentMethod = getFieldState('paymentMethod')?.value
  const paymentLinkEmail = getFieldState('paymentLinkEmail')?.value

  const {paymentMethods} =
    getProduct({
      productId: productType,
      productGroups: supportedProductGroups,
      productGroupId: productGroup
    }) ?? {}

  const allowedPaymentMethods = getAllowedPaymentOptions(paymentMethods ?? [])

  useEffect(() => {
    if (paymentMethod && !paymentMethods?.includes(paymentMethod)) {
      resetFieldState('paymentMethod')
      change('paymentMethod', undefined)
    } else if (paymentMethod !== 'PAYMENT_LINK' && paymentLinkEmail) {
      change('paymentLinkEmail', undefined)
    }
  }, [paymentMethods, paymentMethod, paymentLinkEmail, resetFieldState, change])

  const renderPaymentMethod = (
    paymentMethod: components['schemas']['PaymentMethod']
  ): JSX.Element | null => {
    let content
    if (paymentMethod === 'PAYMENT_LINK') {
      content = (
        <div className='email'>
          <label htmlFor='type' className='email-label'>
            Email Address
          </label>
          <Field
            name='paymentLinkEmail'
            id='type'
            validate={value => getInputValidity(value, requiredEmailValidationRules).errorMsg}
          >
            {({input, meta}) => (
              <TextInput
                className='email-input'
                value={input.value}
                error={meta.error && meta.submitFailed}
                helperText={meta.submitFailed && meta.error}
                onChange={input.onChange}
              />
            )}
          </Field>
        </div>
      )
    } else if (paymentMethod === 'LENDER_CARD') {
      content = (
        <div className='payment-form'>
          <StripePaymentForm />
        </div>
      )
    } else {
      content = null
    }

    return content
  }

  if (allowedPaymentMethods.length) {
    return (
      <PaymentOptions.Styled>
        <DetailsSection title='Payment Options' subtitle='Please choose a payment option.'>
          <Field
            name='paymentMethod'
            // we can't use type='radio' because that value changes the behavior of the Field
            // component in such a way that our RadioGroup won't get the proper value from input.value
            type='radio-group'
            validate={value =>
              getInputValidity(value, requiredValidationRules).fieldIsValid
                ? undefined
                : REQUIRED_PAYMENT_OPTION
            }
            initialValue={allowedPaymentMethods.length === 1 ? allowedPaymentMethods[0].id : ''}
          >
            {({input, meta}) => (
              <RadioGroup
                className='radio-group'
                value={input.value}
                options={allowedPaymentMethods.map(({id, title, description}) => ({
                  value: id,
                  label: (
                    <div className='radio-label-wrapper'>
                      <p className='radio-label-title'>{title}</p>
                      <p className='radio-label-description'>{description}</p>
                      {id === paymentMethod && renderPaymentMethod(paymentMethod)}
                    </div>
                  )
                }))}
                error={meta.error && meta.submitFailed}
                helperText={meta.submitFailed && meta.error}
                onChange={input.onChange}
              />
            )}
          </Field>
        </DetailsSection>
      </PaymentOptions.Styled>
    )
  } else {
    return null
  }
}

PaymentOptions.Styled = styled.div`
  .content-wrapper {
    row-gap: 16px;
  }

  .radio-label {
    &-wrapper {
      padding-left: 6px;
    }

    &-title,
    &-description {
      margin: 0;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }

    &-description {
      color: ${({theme}) => theme.colors.grayscale.gray};
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  .portal-MuiFormControlLabel-root {
    align-items: flex-start;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .email,
  .payment-form {
    margin-top: 16px;
  }

  .email {
    &-label {
      display: block;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      margin-bottom: 4px;
    }

    &-input {
      max-width: 293px;
      flex: 1 1 auto;
    }
  }
`

export default PaymentOptions

import {SUPPORT_CENTER_URL} from 'constants/appConstants'
import React from 'react'
import IconButton from 'components/IconButton'
import Support from 'assets/support.svg'
import styled from 'styled-components'
import classnames from 'classnames'
import {isExisty} from 'services/utils'

type StyledProps = {
  sqaPrefix?: string
}

export const SupportIcon = ({classname}: {classname: string | null}) => {
  return (
    <SupportIcon.Styled
      className={classnames('button-support', {[`${classname}`]: isExisty(classname)})}
      sqaPrefix='support'
      onClick={() => window.open(SUPPORT_CENTER_URL)}
    >
      <img className='icon-support' src={Support} alt='Contact Support' />
    </SupportIcon.Styled>
  )
}

SupportIcon.Styled = styled(IconButton)<StyledProps>`
  .icon-support {
    height: 24px;
    width: 24px;
  }
`

export default SupportIcon

// Core
import React from 'react'

// Component
import LinkButton from './LinkButton'
import {TERM_OF_USE_URL} from 'constants/appConstants'

const TermsOfService = () => (
  <LinkButton variant='tertiary' title='Terms of Service' href={TERM_OF_USE_URL} target='_blank' />
)

export default TermsOfService

import React, {useRef, useState} from 'react'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import type {DocumentProps} from 'react-pdf'
import styled from 'styled-components'
import classNames from 'classnames'
import {CircularProgress} from '@material-ui/core'

export interface PDFRendererProps {
  url: string
}

const PDFRenderer = ({url}: PDFRendererProps) => {
  const [numPages, setNumPages] = useState(0)
  const [activePageNumber, setActivePageNumber] = useState(1)
  const currentPage = useRef<HTMLDivElement>(null)

  const onDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = ({numPages}) => {
    setNumPages(numPages)
  }

  const onPageChangeHandler = (pageNumber: number) => {
    currentPage.current?.scrollTo(0, 0)
    setActivePageNumber(pageNumber)
  }

  return (
    <PDFRenderer.Styled
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      className='pdf-renderer'
      loading={
        <div>
          <CircularProgress size={100} />
        </div>
      }
    >
      <div className='preview-container'>
        {Array.from({length: numPages}, (_, i) => {
          const pageNumber = i + 1
          return (
            <div
              onClick={() => onPageChangeHandler(pageNumber)}
              className={classNames({
                'preview-page': true,
                active: pageNumber === activePageNumber
              })}
              key={i}
            >
              <Page pageNumber={pageNumber} width={124} />
              <p>Page {pageNumber}</p>
            </div>
          )
        })}
      </div>
      <div className='active-page' ref={currentPage}>
        <Page pageNumber={activePageNumber} width={1032} />
      </div>
    </PDFRenderer.Styled>
  )
}

PDFRenderer.Styled = styled(Document)`
  display: flex;
  max-height: 100%;

  .preview-container,
  .active-page {
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    row-gap: 24px;
    padding: 40px;
  }

  .preview-page {
    background: #fff;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 0.75rem;
      line-height: 15px;
      padding: 4px 8px;
    }

    &.active .react-pdf__Page {
      filter: contrast(0.8);
    }

    &:hover .react-pdf__Page {
      filter: contrast(0.8);
    }
  }

  .active-page {
    display: flex;
    justify-content: center;
    flex: 1;

    canvas {
      padding: 40px;
    }
  }
`

export default PDFRenderer

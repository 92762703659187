// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, useEffect, useState, useRef, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {preload} from 'swr'
import {useIdleTimer, workerTimers} from 'react-idle-timer'

// Components, services, etc
import {getBasicAuth, refreshTokens, signOut} from 'actions/authActions'
import AppHeader from 'components/AppHeader'
import Footer from 'components/Footer'
import ModalsSystem from 'components/ModalsSystem'
import NotFound from 'components/NotFound'
import ScrollToTop from 'components/ScrollToTop'
import {COOKIE_PREFERENCE_ANALYTICS} from 'constants/appConstants'
import {APP_LOADED, APP_UNMOUNTED} from 'constants/mixpanelConstants'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {availableRoutesSelector} from 'selectors/routesSelectors'
import {setCookieValue} from 'services/cookieServices'
import {
  handleMixpanelSuperPropertiesAndUser,
  initializeMixpanel,
  trackMixpanelEvent
} from 'services/mixpanel'
import NotificationsSystem from 'components/NotificationsSystem'
import {useAppDispatch} from 'services/store'
import {getProductGroups} from 'services/productsServices'
import {
  USER_IDLE_TIMEOUT,
  USER_IDLE_TIMEOUT_BUFFER,
  USER_IDLE_TIMEOUT_SIGN_OUT,
  USER_IDLE_TIMEOUT_USER_LOGGED_IN,
  USER_IDLE_TIMEOUT_KEEP_WORKING,
  USER_IDLE_TIMEOUT_ON_ACTION,
  USER_IDLE_TIMER_NAME,
  USER_IDLE_TIMEOUT_REFRESH_TOKEN_BUFFER,
  USER_IDLE_TIMEOUT_BASIC_TOKEN_SET,
  USER_IDLE_TIMEOUT_ON_MESSAGE_THROTTLE_TIME
} from 'constants/userIdleTimeoutConstants'
import {USER_IDLE_TIMEOUT_MODAL} from 'constants/modalsConstants'
import {openModal, resetModal} from 'actions/modalActions'
import {openNotification} from 'services/notificationServices'
import {LOGGED_OUT_INACTIVITY_MESSAGE} from 'constants/notificationMessageConstants'
import {trackError} from 'services/sentryServices'
import {
  getTokenExpirationTimeInMilliseconds,
  handleExpiredAccessToken,
  handleExpiredBasicToken
} from 'services/tokenServices'
import {getAccessToken, getBasicToken} from 'services/apis'

// 3rd-party
import CircularProgress from '@material-ui/core/CircularProgress'
import throttle from 'lodash/throttle'

const AppMain = () => {
  const dispatch = useAppDispatch()
  const location = useLocation<{error?: string; redirectRoutePath?: string} | undefined>()
  const history = useHistory()
  const availableRoutes = useSelector(availableRoutesSelector)
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  const [isLoading, setIsLoading] = useState(true)
  const isRedirect = location.pathname.includes('redirect')
  const redirectRoutePath = location?.state?.redirectRoutePath

  const [idleTimerThrottle, setIdleTimerThrottle] = useState(0)
  const initialOnActionSkippedRef = useRef<boolean>(false)

  const getIdleTimerThrottle = (token: string = '') => {
    let throttle = 0
    if (token) {
      const tokenExpirationTimeInMilliseconds = getTokenExpirationTimeInMilliseconds(token)
      if (tokenExpirationTimeInMilliseconds) {
        const remainingTokenExpirationTime =
          tokenExpirationTimeInMilliseconds - new Date().getTime()
        if (remainingTokenExpirationTime - USER_IDLE_TIMEOUT_REFRESH_TOKEN_BUFFER > 0) {
          throttle = remainingTokenExpirationTime - USER_IDLE_TIMEOUT_REFRESH_TOKEN_BUFFER
        }
      }
    }
    return throttle
  }

  useEffect(() => {
    const basicToken = getBasicToken()

    // resetting the throttle value resets the throttle behavior so to avoid
    // having onAction fire immediately after a token is set we reset
    // initialOnActionSkippedRef to false so we skip calling onAction on the next user action
    if (isLoggedIn) {
      setIdleTimerThrottle(getIdleTimerThrottle(getAccessToken()))
      initialOnActionSkippedRef.current = false
    } else if (basicToken) {
      setIdleTimerThrottle(getIdleTimerThrottle(basicToken))
      initialOnActionSkippedRef.current = false
      idleTimerRef.current.message(USER_IDLE_TIMEOUT_BASIC_TOKEN_SET)
    } else if (!isLoading) {
      dispatch(getBasicAuth()).then(() => {
        setIdleTimerThrottle(getIdleTimerThrottle(getBasicToken()))
        initialOnActionSkippedRef.current = false
      })
    }
  }, [isLoggedIn, dispatch, isLoading])

  const {activate, getRemainingTime, message, isPrompted, reset} = useIdleTimer({
    name: USER_IDLE_TIMER_NAME,
    timeout: USER_IDLE_TIMEOUT,
    promptBeforeIdle: USER_IDLE_TIMEOUT_BUFFER,
    throttle: idleTimerThrottle,
    eventsThrottle: 1000, // throttle the events handler so we don't restart the timer on every keypress and thus save CPU resources
    timers: workerTimers,
    crossTab: true,
    syncTimers: 200,
    onIdle: () => {
      if (isLoggedIn) {
        dispatch(resetModal())
        openNotification({
          type: 'error',
          text: LOGGED_OUT_INACTIVITY_MESSAGE
        })
        dispatch(signOut())
      }
    },
    onPrompt: () => {
      if (isLoggedIn) {
        dispatch(
          openModal({
            modalType: USER_IDLE_TIMEOUT_MODAL,
            getRemainingTime,
            handleKeepWorking,
            handleSignOut
          })
        )
      }
    },
    onMessage: message => {
      if (message === USER_IDLE_TIMEOUT_SIGN_OUT) {
        handleUserIdleTimeoutSignOut()
      } else if (message === USER_IDLE_TIMEOUT_KEEP_WORKING) {
        handleUserIdleTimeoutKeepWorking()
      } else if (message === USER_IDLE_TIMEOUT_USER_LOGGED_IN) {
        throttledHandleUserIdleTimeoutUserLoggedIn(isLoggedIn)
      } else if (message === USER_IDLE_TIMEOUT_BASIC_TOKEN_SET) {
        throttledHandeUserIdleTimeoutBasicTokenSet(isLoggedIn)
      } else if (message === USER_IDLE_TIMEOUT_ON_ACTION) {
        handleUserIdleTimeoutOnAction()
      }
    },
    onAction: () => {
      handleOnAction()
    },
    events: [
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove'
    ]
  })
  const idleTimerRef = useRef({activate, getRemainingTime, message, isPrompted, reset})

  useEffect(() => {
    if (isLoggedIn) {
      idleTimerRef.current.reset()
      // Broadcast a new timer initialized message to all IdleTimer instances after refreshing the tab/page
      // so that the countdown timer can be closed for other tabs/pages if it is being displayed
      idleTimerRef.current.message(USER_IDLE_TIMEOUT_USER_LOGGED_IN)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      preload('/products', getProductGroups).catch(() => {})
    }
  }, [isLoggedIn])

  useEffect(() => {
    const handleGetTokenSuccess = () => {
      setUpMixpanel()
      setIsLoading(false)
    }

    const getAuth = async () => {
      setIsLoading(true)
      dispatch(refreshTokens())
        .then(handleGetTokenSuccess)
        .catch(() =>
          dispatch(getBasicAuth())
            .then(handleGetTokenSuccess)
            .catch(() => setIsLoading(false))
        )
    }
    getAuth()

    const sessionEndHandler = () => {
      trackMixpanelEvent(APP_UNMOUNTED)
    }
    // Send app unmounted Mixpanel event before window is closed
    window.addEventListener('beforeunload', sessionEndHandler)
    return () => {
      window.removeEventListener('beforeunload', sessionEndHandler)
    }
  }, [dispatch])

  // Set cookie preference if doesnt exist
  useEffect(() => {
    const analyticsCookie = document.cookie
      ?.split('; ')
      ?.find(row => row.startsWith(COOKIE_PREFERENCE_ANALYTICS))
    if (!analyticsCookie) {
      setCookieValue(COOKIE_PREFERENCE_ANALYTICS, 'true; path=/')
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && redirectRoutePath) {
      history.push(redirectRoutePath)
    }
  }, [history, isLoggedIn, redirectRoutePath])

  const setUpMixpanel = () => {
    // initialize Mixpanel
    initializeMixpanel()

    // track the app loaded event in Mixpanel
    trackMixpanelEvent(APP_LOADED)
  }

  // We track the super properties based on the user's logged in status. We only
  // want to run this after the app loads. It will also fire again when the user's
  // logged in state changes.
  // IMPORTANT - be sure to keep this effect below the one that sets up Mixpanel
  // by calling initializeMixpanel
  useEffect(() => {
    if (!isLoading) {
      handleMixpanelSuperPropertiesAndUser()
    }
  }, [isLoading, isLoggedIn])

  const handleUserIdleTimeoutKeepWorking = () => {
    dispatch(resetModal())
    dispatch(refreshTokens()).catch(err => {
      trackError(err)
    })
  }

  const handleUserIdleTimeoutSignOut = () => {
    dispatch(signOut())
  }

  const handleKeepWorking = () => {
    idleTimerRef.current.message(USER_IDLE_TIMEOUT_KEEP_WORKING)
    idleTimerRef.current.activate()
    handleUserIdleTimeoutKeepWorking()
  }

  const handleSignOut = () => {
    idleTimerRef.current.message(USER_IDLE_TIMEOUT_SIGN_OUT)
    handleUserIdleTimeoutSignOut()
  }

  const handleUserIdleTimeoutOnAction = () => {
    if (!isLoggedIn) {
      handleExpiredBasicToken()
    } else if (!idleTimerRef.current.isPrompted()) {
      handleExpiredAccessToken()
    }
  }

  const handleOnAction = () => {
    const tokenExpiration =
      getTokenExpirationTimeInMilliseconds(isLoggedIn ? getAccessToken() : getBasicToken()) || 0
    const isWithinRefreshBufferTime =
      tokenExpiration - USER_IDLE_TIMEOUT_REFRESH_TOKEN_BUFFER < new Date().getTime()

    // initialOnActionSkippedRef is used to avoid refreshing the user's
    // token on the first click they perform after the timer and app initializes
    if (initialOnActionSkippedRef.current === true || isWithinRefreshBufferTime) {
      idleTimerRef.current.message(USER_IDLE_TIMEOUT_ON_ACTION)
      initialOnActionSkippedRef.current = true
      handleUserIdleTimeoutOnAction()
    } else {
      initialOnActionSkippedRef.current = true
    }
  }

  // this function is the event handler for a react-idle-timer message which that is used to
  // keep the tabs' logged in state in sync. as the tabs sync up they will all fire the same
  // message which means the other tabs receive duplicates of the same message. we only want
  // to handle the message once so we throttle it to ensure that happens.
  const throttledHandleUserIdleTimeoutUserLoggedIn = useMemo(() => {
    return throttle(
      isLoggedIn => {
        dispatch(resetModal())

        // this message indicates that the user just logged in on another tab so we call
        // `handleExpiredAccessToken` if the tab receiving the message isn't already logged in
        if (!isLoggedIn) {
          handleExpiredAccessToken()
        }
      },
      USER_IDLE_TIMEOUT_ON_MESSAGE_THROTTLE_TIME,
      {trailing: false}
    )
  }, [dispatch])

  // this function is the event handler for a react-idle-timer message which that is used to
  // keep the tabs' logged in state in sync. as the tabs sync up they will all fire the same
  // message which means the other tabs receive duplicates of the same message. we only want
  // to handle the message once so we throttle it to ensure that happens.
  const throttledHandeUserIdleTimeoutBasicTokenSet = useMemo(() => {
    return throttle(
      isLoggedIn => {
        // this message likely fired because the user logged out in another tab
        // or is already logged out and opened a new tab.  if the user is logged in
        // on this tab we want to log them out to keep the tabs in sync.
        if (isLoggedIn) {
          dispatch(signOut())
        }
      },
      USER_IDLE_TIMEOUT_ON_MESSAGE_THROTTLE_TIME,
      {trailing: false}
    )
  }, [dispatch])

  return (
    <AppMain.Styled className='app-main'>
      {isLoading ? (
        <div className='loading-wrap'>
          {/* inner div needed for IE11 */}
          <div>
            <CircularProgress size={100} />
          </div>
        </div>
      ) : (
        <Fragment>
          <ScrollToTop />
          <AppHeader />
          <Switch>
            {location?.state?.error ? (
              <NotFound />
            ) : (
              availableRoutes.map(({label, path, Component}) => (
                <Route key={label} exact={!path.includes(':')} path={path} component={Component} />
              ))
            )}
            <Redirect
              exact
              from='/'
              to={{
                pathname: '/products',
                state: {
                  redirectRoutePath: isRedirect ? `${location.pathname}${location.search}` : null
                }
              }}
            />
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
          <Footer />
          <ModalsSystem />
          <NotificationsSystem position='bottom-right' />
        </Fragment>
      )}
    </AppMain.Styled>
  )
}

AppMain.Styled = styled.div<{headerHeight?: number}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${({headerHeight}) => headerHeight ?? 0}px;
  overflow-x: hidden;
  padding-top: 60px;

  .loading-wrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: ${({headerHeight = 0}) => -headerHeight ?? 0}px;

    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 48px;
    }
  }
`

export default AppMain

// Styles
import styled from 'styled-components/macro'

// Core
import React, {MouseEventHandler} from 'react'
import type {ReactNode} from 'react'

// Components, services, etc
import Button from 'components/Button'
import Modal from 'components/Modal'

export type UnsavedWorkDialogProps = {
  content?: ReactNode
  handleClose: () => void
  handleExited?: () => void
  onCancel?: () => void
  onConfirm: (a: () => void) => MouseEventHandler<HTMLButtonElement>
  open: boolean
  title?: ReactNode
}

const UnsavedWorkDialog = ({
  content,
  handleClose,
  handleExited,
  onCancel,
  onConfirm,
  open,
  title
}: UnsavedWorkDialogProps) => {
  return (
    <UnsavedWorkDialog.Styled
      closeOnBackdropClick={false}
      closeOnEscapeKeyDown={false}
      open={open}
      onClose={handleClose}
      onExited={handleExited}
    >
      <Modal.Header>{title || <h3>Unsaved Changes</h3>}</Modal.Header>
      <Modal.Content>
        {content || <p>All unsaved changes will be lost. Are you sure you want to continue?</p>}
      </Modal.Content>
      <Modal.Footer>
        <Button variant='secondary' title='Cancel' size='large' onClick={onCancel || handleClose} />
        <Button
          variant='primary'
          title='Continue'
          size='large'
          onClick={onConfirm(onCancel || handleClose)}
        />
      </Modal.Footer>
    </UnsavedWorkDialog.Styled>
  )
}

UnsavedWorkDialog.Styled = styled(Modal)`
  .footer {
    padding: 16px 24px;
  }
`

export default UnsavedWorkDialog

import {getOrderProgressStepNames} from 'services/ordersServices'
import useProductGroups from './useProductGroups'
import {getProductGroup} from 'services/productsServices'
import {useSelector} from 'react-redux'
import {orderByOpsOrderIdSelector} from 'selectors'

export default function useGetOrderProgressStepNames() {
  const order = useSelector(orderByOpsOrderIdSelector)
  const {productGroups} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)
  return getOrderProgressStepNames(productGroup)
}

import FetchApi from 'services/FetchApi'
import {authContentMap} from 'services/authorization'
import {
  FALLBACK_ENV,
  DEV_ORIGIN,
  QA_ORIGIN,
  INTEG_ORIGIN,
  PROD_ORIGIN
} from 'constants/appConstants'
import {LOCALHOST, XE_DEV, DEV, QA, INTEG, PROD} from 'constants/environmentConstants'
import {HostedEnvironment} from 'types/environmentTypes'

export const opsUrl = getOpsApiUrl()
export const casApiUrl = getCasApiUrl()
export const propAggUrl = getPropAggApiUrl()
export const oauthUrl = getOauthUrl()
export const websocketUrl = getWebsocketUrl()
export const customerUrl = getCustomerApiUrl()

// We manage these separately and manually to avoid having to access them
// through Redux in the headers functions in the api creators below
let authAccessToken: string | undefined
let authBasicToken: string | undefined

export const updateAuthTokens = (token?: string) => {
  authAccessToken = token
  // once the user is authenticated, we can use the access token as the
  // auth basic token as well (to access prop agg)
  authBasicToken = token
}

export const updateBasicToken = (token?: string) => {
  authBasicToken = token
}

export const getAccessToken = () => authAccessToken
export const getBasicToken = () => authBasicToken

export function getClearPropHostname() {
  const env = getEnvAbbreviation()
  let hostname = ''

  switch (env) {
    case 'dev':
      // there is no DEV version of CP and the AWS architecture for
      // the DEV env is different than other envs so we just use QA CP directly
      hostname = 'https://qa-ss-clear-prop-single-source.clearcollateral.com'
      break
    default:
      // capture QA, INTEG and any other non DEV or PROD env
      hostname = `https://clearprop.ss-${env}.clearcollateral.com`
      break
  }

  return hostname
}

export const oauthApi = new FetchApi({
  baseUrl: `${oauthUrl}/oauth2`,
  headers: {
    Authorization: () => {
      const env = getEnv()
      return authContentMap[env as keyof typeof authContentMap]
    }
  }
})

export const opsApi = new FetchApi({
  baseUrl: opsUrl,
  headers: {
    Authorization: () => authAccessToken
  }
})

export const casApi = new FetchApi({
  baseUrl: casApiUrl,
  headers: {
    Authorization: () => authAccessToken
  }
})

export const propertyAggregationApi = new FetchApi({
  baseUrl: propAggUrl,
  headers: {
    Authorization: () => authBasicToken
  }
})

export const pdfExportApi = new FetchApi({
  baseUrl: `${getClearPropHostname()}/api/v1`,
  headers: {
    Accept: 'application/pdf',
    Authorization: () => `Bearer ${authAccessToken}`,
    'Content-Type': null,
    platformId: 'test-platformId',
    customerId: 'test-customerId',
    subjectPid: -1,
    reportId: 'test-reportId',
    reportType: 'test-reportType'
  }
})

export const customerApi = new FetchApi({
  baseUrl: customerUrl,
  headers: {
    Authorization: () => authAccessToken
  }
})

export function getEnv() {
  // process.env.REACT_APP_ENV is only set when running the app locally. It is set as part of the
  // start commands we run. For example `npm run start:qa` will start the app and set process.env.REACT_APP_ENV
  // to `qa`. process.env.NODE_ENV will always be set to 'production' when running the built version of
  // the app (by running `npm run build`).
  let env: string
  const isDevServer = process.env.NODE_ENV !== 'production' && !!process.env.REACT_APP_ENV

  if (isDevServer) {
    env = process.env.REACT_APP_ENV!.toLowerCase()
  } else if (isProdBuild()) {
    const origin = window.location.origin
    // check for either xe-dev origin
    if (origin.includes('xe-dev.clear')) {
      env = sessionStorage.getItem('env') || FALLBACK_ENV
    } else if (origin === QA_ORIGIN) {
      // the prod build is being loaded from its hosted QA URL
      env = 'qa'
    } else if (origin === DEV_ORIGIN) {
      // the prod build is being loaded from its hosted DEV URL
      env = 'dev'
    } else if (origin === INTEG_ORIGIN) {
      // the prod build is being loaded from its hosted INTEG URL
      env = 'integ'
    } else if (origin === PROD_ORIGIN) {
      // the prod build is being loaded from its hosted PROD URL
      env = 'production'
    } else if (
      origin.includes('localhost') ||
      origin.includes('127.0.0.1') ||
      origin.includes('10.0.2.2')
    ) {
      // the prod build is being loaded from localhost (10.0.2.2 is used to access localhost in Virtual Box)
      env = (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase()) || FALLBACK_ENV
    } else {
      // not sure where the prod build is being loaded from
      throw new Error(`Proper config not provided.`)
    }
  }

  if (process.env.REACT_APP_STAGING_DEPLOY) {
    env = sessionStorage.getItem('env') || FALLBACK_ENV
  }

  return env!
}

export const getHostedEnv = (): HostedEnvironment | undefined => {
  let env: HostedEnvironment | undefined
  const origin = window.location.origin

  if (origin.includes('xe-dev.clear')) {
    env = XE_DEV
  } else if (origin === DEV_ORIGIN) {
    env = DEV
  } else if (origin === QA_ORIGIN) {
    env = QA
  } else if (origin === INTEG_ORIGIN) {
    env = INTEG
  } else if (origin === PROD_ORIGIN) {
    env = PROD
  } else if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
    env = LOCALHOST
  }

  return env
}

export function isProdBuild() {
  return process.env.NODE_ENV === 'production'
}

// returns the env but maps to the standard abbreviation used in urls
function getEnvAbbreviation() {
  const env = getEnv()
  return env === 'production' ? `prod` : env
}

function getPropAggApiUrl() {
  const env = getEnvAbbreviation()
  return `https://property-aggregation.ss-${env}.clearcollateral.com`
}

function getOpsApiUrl() {
  const env = getEnvAbbreviation()
  return `https://order-placement.${env}.clearcapital.com`
}

function getCasApiUrl() {
  const env = getEnvAbbreviation()
  let apiUrl

  switch (env) {
    case 'prod':
      // cas uses portal as the subdomain for clearcapital.com, we may want to switch this to prod at some point to match our other services
      apiUrl = 'https://user-authentication.portal.clearcapital.com'
      break
    default:
      apiUrl = `https://user-authentication.${env}.clearcapital.com`
      break
  }

  return apiUrl
}

function getOauthUrl() {
  const env = getEnvAbbreviation()
  return `https://cas-${env}-sam-user-pool.auth.us-west-2.amazoncognito.com`
}

function getWebsocketUrl() {
  const env = getEnvAbbreviation()
  return `wss://order-placement-socket.ss-${env}.clearcollateral.com`
}

function getCustomerApiUrl() {
  const env = getEnvAbbreviation()
  return `https://customer-management.${env}.clearcapital.com`
}

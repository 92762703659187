import jwtDecode from 'jwt-decode'

export const isUserManagerService = (idToken?: string): boolean => {
  let isUserManager = false
  if (idToken) {
    const decodedIdToken = jwtDecode(idToken)
    if (
      typeof decodedIdToken === 'object' &&
      decodedIdToken !== null &&
      decodedIdToken instanceof Object &&
      'CUSTOMER_KEYS' in decodedIdToken
    ) {
      // @ts-expect-error
      isUserManager = JSON.parse(decodedIdToken[decodedIdToken.CUSTOMER_KEYS])?.['product-portal']
        ?.users
    }
  }

  return isUserManager
}

import {CLEAR_AVM_FULLY_SUPPORTED, CLEAR_AVM_VALUES_ONLY} from 'constants/productCardConstants'
import moment from 'moment'
import type {OpsOrderType} from 'types/orderTypes'

export type CDADisplayStatus =
  | 'ORDERED'
  | 'ACCEPTED'
  | 'ASSIGNED'
  | 'REVIEWING'
  | 'COMPLETED'
  | 'CANCEL_REQUESTED'
  | 'CANCELED'
  | 'ON_HOLD'
  | 'ON_HOLD_REMOVING'

export const getViewStatus = (order: OpsOrderType) => {
  let orderStatus: CDADisplayStatus
  if (order.status?.orderStatus === 'CANCELED') {
    orderStatus = 'CANCELED'
  } else if (order.status?.cancel?.cancelRequested) {
    orderStatus = 'CANCEL_REQUESTED'
  } else if (order.status?.hold) {
    if (order.status.hold.holdRemovalRequested) {
      orderStatus = 'ON_HOLD_REMOVING'
    } else {
      orderStatus = 'ON_HOLD'
    }
  } else if (!order.status?.orderStatus || order.status.orderStatus === 'ORDERED') {
    orderStatus = 'ORDERED'
  } else {
    orderStatus = order.status.orderStatus
  }

  return orderStatus
}

export const getDescription = (order: OpsOrderType) => {
  const momentFormat = 'MMM DD, YYYY, hA'
  if (order.status?.orderStatus === 'COMPLETED') {
    return `Delivered on ${moment(order.completedDate).format(momentFormat)}.`
  } else if (order.status?.orderStatus !== 'CANCELED') {
    const estimatedDeliveryDate = order.estimatedCompletionDate
    return `Estimated delivery date is ${
      estimatedDeliveryDate ? moment(estimatedDeliveryDate).format(momentFormat) : 'unknown'
    }.`
  }
}

export const isAVMOrder = (order: OpsOrderType): boolean => {
  return (
    order.productType === CLEAR_AVM_FULLY_SUPPORTED || order.productType === CLEAR_AVM_VALUES_ONLY
  )
}

export const shouldShowOrdersTab = (order: OpsOrderType): boolean => {
  const isCompleted = order.status?.orderStatus === 'COMPLETED'
  const isRevision = order.status?.revision
  const isCompletedorisRevision = isCompleted || isRevision
  return Boolean(isCompletedorisRevision && !isAVMOrder(order))
}

import {getCustomer} from 'actions/customerActions'
import {getUser} from 'actions/userActions'
import {
  RESET_APP,
  SET_AUTH_ERROR,
  SET_AUTH_LOADED,
  SET_AUTH_LOADING,
  SET_BASIC_AUTH_TOKEN
} from 'constants/actionTypes'
import {casApi, oauthApi, updateAuthTokens, updateBasicToken} from 'services/apis'
import {casSignOut, casSocialAuthToken, casRefreshToken} from 'services/casServices'
import {resetMixpanelData} from 'services/mixpanel'
import type {AppDispatch, RootState} from 'services/store'
import {isAccessTokenExpiredOrAboutToExpire} from 'services/tokenServices'
import type {
  AnswerChallengeRequest,
  AnswerChallengeResponse,
  GetAccessTokenResponse,
  GetSocialAuthTokenCode
} from 'types/authenticationTypes'
import {getWallet} from './walletActions'
import history from 'services/history'
import {orderByOpsOrderIdSelector} from 'selectors/ordersSelectors'
import {getPropertyUrlByAddress} from 'services/productNavigationServices'
import {isEmpty} from 'lodash'
import {isUserManagerService} from 'services/authServices'
import * as Sentry from '@sentry/react'

export function getBasicAuth() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_AUTH_LOADING
    })

    return oauthApi
      .post<GetAccessTokenResponse>(
        'token',
        {
          grant_type: 'client_credentials',
          scope: 'property-aggregation-service/public'
        },
        {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      )
      .then(res => {
        updateBasicToken(res?.access_token)
        dispatch({
          type: SET_BASIC_AUTH_TOKEN,
          payload: res?.access_token
        })
      })
      .catch(err => {
        console.error(err)
        dispatch({
          type: SET_AUTH_ERROR,
          payload: err
        })
      })
  }
}

export function answerAuthChallenge(payload: AnswerChallengeRequest) {
  return (dispatch: AppDispatch) => {
    return casApi
      .post<AnswerChallengeResponse>('answer-challenge', payload)
      .then(res => {
        return res
      })
      .catch(err => {
        handleUserAuthenticationError(dispatch, err)
      })
  }
}

export function refreshTokens() {
  return (dispatch: AppDispatch) => {
    return casRefreshToken()
      .then(res => {
        if (res.accessToken) {
          return handleUserAuthenticated(dispatch, {
            isUserAuthenticated: true,
            token: res.accessToken,
            idToken: res.idToken
          })
        }
      })
      .catch(err => {
        dispatch({
          type: RESET_APP
        })
        handleUserAuthenticationError(dispatch, err)
      })
  }
}

// this is used to take the code returned by a federated log in and use it to
// fetch the necessary CAS/Cognito tokens
export function getSocialAuthTokens(code: GetSocialAuthTokenCode) {
  return (dispatch: AppDispatch) => {
    return casSocialAuthToken(code)
      .then(res => {
        if (res.authenticated && res.authentication?.accessToken) {
          return handleUserAuthenticated(dispatch, {
            isUserAuthenticated: true,
            token: res.authentication?.accessToken
          }).then(() => {
            // we return the original payload on to the caller of answerAuthChallenge
            // so we can handle things appropriately
            return res
          })
        } else {
          return res
        }
      })
      .catch(err => {
        console.error('error...', err)
        // TODO: anything special to do here?
      })
  }
}

export function handleUserAuthenticated(
  dispatch: AppDispatch,
  {
    token,
    ...payload
  }: {
    isUserAuthenticated: boolean
    token: string
    idToken?: string
  }
) {
  // update the tokens (this will update both the access token and basic auth token)
  updateAuthTokens(token)

  return Promise.all([dispatch(getUser()), dispatch(getCustomer())]).then(() => {
    return dispatch(getWallet()).then(() => {
      dispatch({
        type: SET_AUTH_LOADED,
        payload: {...payload, isUserManager: isUserManagerService(payload.idToken)}
      })
      return true
    })
  })
}

function handleUserAuthenticationError(dispatch: AppDispatch, error: any) {
  dispatch({
    type: SET_AUTH_ERROR,
    payload: error
  })
  throw error
}

// During the sign out process, it's possible the user's access token has expired. In that case we
// need to get a new access token using the user's refresh token so we can use the new access token
// to make the call to /sign-out. This is necessary to ensure the user's refresh token is properly
// invalidated. If the refresh token is expired, then we can just reset the local
// access token and app state since refreshing the app at that point will not result in the user
// automatically being logged in again (since the refresh token is expired).
export function signOut() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const resetAuthTokensAndAppState = async () => {
      // We reset Mixpanel here to ensure it's done before additional tracking events are fired as a
      // result of resetting app state below
      resetMixpanelData()

      const isUserOnOrdersPage = window.location.pathname.includes('/orders')
      const order = orderByOpsOrderIdSelector(state)
      const suppliedAddress = order?.suppliedAddress
      const hasAddress = !isEmpty(suppliedAddress)

      if (isUserOnOrdersPage && hasAddress) {
        const url = getPropertyUrlByAddress({
          addressLine1: suppliedAddress?.street,
          city: suppliedAddress?.city,
          state: suppliedAddress?.state,
          zip: suppliedAddress?.zipcode
        })

        history.push(url)
      }

      // clear the locally stored tokens (this will update both the access token and basic auth token)
      updateAuthTokens(undefined)

      await dispatch(getBasicAuth())

      // reset app state
      dispatch({
        type: RESET_APP
      })
    }

    const handleSignOut = () => {
      return casSignOut()
        .then(() => {
          Sentry.setUser(null)
          resetAuthTokensAndAppState()
        })
        .catch(err => {
          resetAuthTokensAndAppState()
          console.error('error...', err)
        })
    }

    // check if access token is about to expire, if so, use refresh to get a new one, then continue with sign
    // out process. If refresh token is expired, then just log user out (locally)

    // if their access token is about to expire, we need to try to renew it
    if (isAccessTokenExpiredOrAboutToExpire()) {
      return casRefreshToken()
        .then(res => {
          if (res.accessToken) {
            // update the tokens (this will update both the access token and basic auth token)
            updateAuthTokens(res.accessToken)

            // now that we have an auth token in place, we can make the request to handle
            // the sign out request
            return handleSignOut()
          }
        })
        .catch(() => {
          // if the refresh token itself has expired or has been revoked (like on sign out)
          // then we can just set the auth tokens to undefined and reset app state
          resetAuthTokensAndAppState()
        })
    } else {
      return handleSignOut()
    }
  }
}

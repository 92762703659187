import {opsApi} from 'services/apis'
import useSWR from 'swr'
import {MessagesResponse, OpsOrderId} from 'types/orderTypes'

export default function useOrderMessages(opsOrderId: OpsOrderId) {
  const {data, error, isLoading, mutate} = useSWR<MessagesResponse[]>(
    opsOrderId ? `orders/${opsOrderId}/messages` : null,
    async () => opsApi.get<MessagesResponse[]>(`orders/${opsOrderId}/messages`),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false
    }
  )

  return {
    data,
    error,
    isLoading,
    mutate
  }
}

// Style
import styled from 'styled-components'

// Core
import React, {Fragment, useEffect, useState} from 'react'

// Components and services
import {Cancel, PauseCircleFilled, PlayCircleFilled} from '@material-ui/icons'
import Button from 'components/Button'
import Image from 'components/Image'
import {getDescription, getViewStatus} from 'services/orderDetailsServices'
import {getOrderProgressStepByOrderStatus} from 'services/ordersServices'
import {
  OpsOrderType,
  OrderDetailsAURATabData,
  OrderDetailsCDATabData,
  OrderDetailsTabData,
  OrderDetailsTabValues
} from 'types/orderTypes'
import LoanDetailsCard from './LoanDetailsCard'
import OrderDetailsCard from './OrderDetailsCard'
import StatusProgressBar from './StatusProgressBar'
import {useAppDispatch} from 'services/store'
import {openModal} from 'actions/modalActions'

// Third parties
import moment from 'moment'
import useGetOrderDetailStatusInfo from 'hooks/useGetOrderDetailStatusInfo'
import Tabs from 'components/Tabs'
import {trackMixpanelEvent} from 'services/mixpanel'
import {CLICKED_TAB, COMPOSE_MESSAGE} from 'constants/mixpanelConstants'
import {
  ORDER_DETAILS_TABS_WITHOUT_ACCESS_DETAILS,
  ORDER_DETAILS_TABS,
  ORDER_DETAILS_AURA_TABS
} from 'constants/orderDetailsConstants'
import useProductGroups from 'hooks/useProductGroups'
import {getProduct, getProductGroup} from 'services/productsServices'
import {AURA, PRICE_OPINION, CDA, INSPECTION, EVALUATION} from 'constants/productCardConstants'
import Icon from 'components/Icon'
import AccessDetails from 'components/AccessDetails'
import MessageCenter from 'components/MessageCenter'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {CircularProgress} from '@material-ui/core'
import Card from 'components/Card'

type OrderDetailsProps = {
  order: OpsOrderType
}
const OrderDetails = ({order}: OrderDetailsProps) => {
  const viewStatus = getViewStatus(order)
  const {imageUrl, title, description} = useGetOrderDetailStatusInfo()
  const {status: {orderStatus} = {orderStatus: 'ORDERED'}} = order
  const dispatch = useAppDispatch()
  const isCanceled = orderStatus === 'CANCELED'
  const isCompleted = orderStatus === 'COMPLETED'
  const isOrdered = orderStatus === 'ORDERED'
  const [activeTab, setActiveTab] = useState<OrderDetailsTabValues>('orderDetails')
  const [orderDetailsTab, setOrderDetailsTab] = useState<
    OrderDetailsTabData | OrderDetailsCDATabData | OrderDetailsAURATabData | undefined
  >()
  const {productGroups, isProductGroupsLoading} = useProductGroups()
  const productGroup = getProductGroup(order.productType, productGroups?.productGroups)
  const supportedProductGroups = useSupportedProductGroups()
  const {description: productDescription, isInteriorContactRequired} =
    getProduct({
      productId: order.productType,
      productGroups: supportedProductGroups,
      productGroupId: productGroup
    }) ?? {}
  const isLoading = isProductGroupsLoading || isInteriorContactRequired === undefined

  const onHold = () => {
    dispatch(
      openModal({
        modalType: 'ON_HOLD_MODAL',
        mode: 'ON_HOLD',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onDocumentUpload = () => {
    dispatch(
      openModal({
        modalType: 'DOCUMENT_UPLOAD_MODAL'
      })
    )
  }

  const onRemoveHold = () => {
    dispatch(
      openModal({
        modalType: 'HOLD_REMOVAL_CONFIRMATION_MODAL',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onCancelOrder = () => {
    dispatch(
      openModal({
        modalType: 'CHANGE_ORDER_STATUS_MODAL',
        mode: 'CANCELLATION',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onAddContact = () => {
    dispatch(
      openModal({
        modalType: 'ADD_CONTACT_MODAL',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onComposeMessage = () => {
    trackMixpanelEvent(COMPOSE_MESSAGE)
    dispatch(
      openModal({
        modalType: 'COMPOSE_MESSAGE_MODAL',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onTabChange = (event: React.ChangeEvent<{}>, value: OrderDetailsTabValues) => {
    const input = event.target as HTMLElement
    trackMixpanelEvent(CLICKED_TAB, {component: input.textContent})
    setActiveTab(value)
  }

  useEffect(() => {
    if (!isLoading) {
      if (
        productGroup === CDA ||
        ([EVALUATION, PRICE_OPINION, INSPECTION].includes(productGroup) &&
          !isInteriorContactRequired)
      ) {
        setOrderDetailsTab(ORDER_DETAILS_TABS_WITHOUT_ACCESS_DETAILS)
      } else if (productGroup === AURA) {
        setOrderDetailsTab(ORDER_DETAILS_AURA_TABS)
      } else {
        setOrderDetailsTab(ORDER_DETAILS_TABS)
      }
    }
  }, [productGroup, isInteriorContactRequired, isProductGroupsLoading, isLoading])

  return (
    <OrderDetails.Styled>
      <div className='status-container'>
        <div className='status-header'>
          <div>
            <h3>Order #{order.productOrderId}</h3>
            <p>Placed {moment(order.created).format('MMM DD, YYYY')}</p>
          </div>
          {!isOrdered && !isCompleted && !isCanceled && productGroup !== AURA && (
            <div className='button-container'>
              <Button
                title={
                  <Fragment>
                    <Icon className='icon-add' icon='add_circle' />
                    <span>Upload Document</span>
                  </Fragment>
                }
                variant='tertiary'
                onClick={onDocumentUpload}
              />
              {viewStatus !== 'ON_HOLD' && viewStatus !== 'ON_HOLD_REMOVING' ? (
                <Button
                  title={
                    <Fragment>
                      <PauseCircleFilled />
                      <span>Place on Hold</span>
                    </Fragment>
                  }
                  onClick={onHold}
                  variant='tertiary'
                  disabled={viewStatus === 'CANCEL_REQUESTED'}
                />
              ) : (
                <Button
                  title={
                    <Fragment>
                      <PlayCircleFilled />
                      <span>Request Hold Removal</span>
                    </Fragment>
                  }
                  onClick={onRemoveHold}
                  disabled={viewStatus === 'ON_HOLD_REMOVING'}
                  variant='tertiary'
                />
              )}
              <Button
                title={
                  <Fragment>
                    <Cancel />
                    <span>Request Cancellation</span>
                  </Fragment>
                }
                variant='tertiary'
                onClick={onCancelOrder}
                disabled={viewStatus === 'CANCEL_REQUESTED'}
              />
            </div>
          )}
        </div>
        <div className='status-details'>
          <Image src={imageUrl} width='96px' height='96px' />
          <h3>{title}</h3>
          <p>{description || getDescription(order)}</p>
        </div>
        {!isCanceled && <StatusProgressBar step={getOrderProgressStepByOrderStatus(orderStatus)} />}
        {isLoading ? (
          <Card className='spinner-card'>
            <CircularProgress />
          </Card>
        ) : (
          orderDetailsTab && (
            <div className='tab-wrapper'>
              <div className='tab'>
                <Tabs
                  tabs={orderDetailsTab}
                  value={activeTab}
                  onChange={onTabChange}
                  variant='pill'
                />
                {activeTab === 'accessDetails' && !isOrdered && !isCompleted && (
                  <Button
                    title={
                      <span className='title-wrapper'>
                        <Icon className='icon-add' icon='add_circle' />
                        <div className='title'>Add Contact</div>
                      </span>
                    }
                    variant='tertiary'
                    onClick={onAddContact}
                    sqaPrefix='add-contact'
                  />
                )}
                {activeTab === 'messageCenter' && !isOrdered && !isCompleted && (
                  <Button
                    title={
                      <span className='title-wrapper'>
                        <Icon className='icon-add' icon='message' />
                        <div className='title'>Compose Message</div>
                      </span>
                    }
                    variant='tertiary'
                    onClick={onComposeMessage}
                    sqaPrefix='compose-message'
                  />
                )}
              </div>
              <div className='tab-content'>
                {activeTab === 'orderDetails' && (
                  <OrderDetailsCard order={order} productDescription={productDescription} />
                )}
                {activeTab === 'loanDetails' && <LoanDetailsCard order={order} />}
                {activeTab === 'accessDetails' && <AccessDetails />}
                {activeTab === 'messageCenter' && <MessageCenter />}
              </div>
            </div>
          )
        )}
      </div>
    </OrderDetails.Styled>
  )
}

OrderDetails.Styled = styled.div`
  padding: 40px;
  flex: 1;

  .status-container {
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 24px;
  }

  .status-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    h3 {
      line-height: 28px;
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
      line-height: 17px;
    }

    .portal-MuiSvgIcon-root {
      margin-right: 6px;
      font-size: 0.875rem;
    }

    .button-container {
      .portal-MuiButton-root + .portal-MuiButton-root {
        margin-left: 24px;
      }

      .icon-add {
        font-size: 0.875rem;
        margin-right: 4px;
      }
    }
  }

  .status-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    h3 {
      margin-top: 24px;
      margin-bottom: 8px;
      line-height: 22px;
    }

    p {
      margin-top: 0;
      line-height: 22px;
    }
  }

  .spinner-card {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-wrapper {
    background: ${({theme}) => theme.colors.stone.lighter};
    border-radius: 8px;
    padding: 24px;
    .tab {
      display: flex;
      justify-content: space-between;
      .title-wrapper {
        display: flex;
        align-items: center;
        .title {
          color: ${({theme}) => theme.colors.cobalt.dark};
          font-weight: 500;
        }
        .icon-add {
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
    .tab-content {
      margin-top: 16px;
    }
  }
`

export default OrderDetails

import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

type Props = {
  text: string
  onClose: () => void
}

const SNACKBAR_POSITION = {vertical: 'top', horizontal: 'center'} as const

const SuccessSnackbar = ({onClose, text}: Props) => (
  <Snackbar open onClose={onClose} anchorOrigin={SNACKBAR_POSITION}>
    <Alert severity='success'>{text}</Alert>
  </Snackbar>
)

export default SuccessSnackbar

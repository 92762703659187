import CDAPromo from 'assets/promos/CDA.png'
import ClearAvmPromo from 'assets/promos/clearavm.png'
import ClearPropPromo from 'assets/promos/clearprop_promo.png'
import BPOPromo from 'assets/promos/bpo.png'
import PCIPromo from 'assets/promos/pci.png'
import AURAPromo from 'assets/promos/AURA.png'
import ResidentialEvaluationPromo from 'assets/promos/residential_evaluation.png'
import type {ChipProps} from 'components/Chip/Chip'
import {ProductGroupType} from 'types/productTypes'

export const CLEARPROP = 'CLEARPROP'
export const CLEAR_AVM_FULLY_SUPPORTED = 'CLEAR_AVM_FULLY_SUPPORTED'
export const CLEAR_AVM_VALUES_ONLY = 'CLEAR_AVM_VALUES_ONLY'
export const CDA = 'CDA'
export const CDA_MLS = 'CDA_MLS'
export const AVM = 'AVM'
export const PRICE_OPINION = 'PRICE_OPINION'
export const INSPECTION = 'INSPECTION'
export const EVALUATION = 'EVALUATION'
export const AURA = 'AURA'

export type ProductData = {
  title: string
  subtitle: string
  landingDescription: string
  popDescription: string
  learnMoreLink?: string
  isProductActive: boolean
  icon: string
  price?: number
  startingPrice?: number
  productColor: ChipProps['color']
  promoImg: string
  name: string
  // to check if the product is available.
  // since now we only have 2 product, use only one flag to for simplicity.
  // if the logic gets complex later, change this to a isAvailable() function
  needPID?: boolean
}

// TODO: remove this comment once AURA code is deployed
// @ts-ignore
export const productGroupDictionary: {[key in ProductGroupType]: ProductData} = {
  CLEARPROP: {
    title: 'ClearProp®',
    subtitle: 'An interactive property analysis and valuation tool',
    landingDescription:
      'An interactive tool that brings data on a property together to quickly perform your own cost-effective valuations.',
    popDescription:
      'Interactive property analysis and valuation tool using ranked comps, market history, ClearAVM™, and more.',
    isProductActive: true,
    icon: 'home',
    productColor: 'turquoise' as ChipProps['color'],
    promoImg: ClearPropPromo,
    price: 15,
    name: 'ClearProp®',
    needPID: true
  },
  AVM: {
    title: 'ClearAVM™',
    subtitle: 'Instant property value insights',
    landingDescription:
      'A lending-grade automated valuation model with near-real-time data that provides an instant property value.',
    popDescription:
      'A lending-grade automated valuation model with nationwide coverage that provides accurate valuations in less than a second.',
    isProductActive: true,
    icon: 'requestQuote',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: ClearAvmPromo,
    name: 'ClearAVM™',
    startingPrice: 5
  },
  CDA: {
    title: 'Collateral Desktop Analysis (CDA®)',
    subtitle: 'USPAP-compliant appraisal desktop review',
    landingDescription:
      'A USPAP-compliant appraisal desktop review that re-evaluates the original appraisal to enable pre-funding certainty.',
    popDescription:
      'CDA removes pre-funding uncertainty and supports due diligence by re-evaluating an appraisal to deliver a comprehensive loan file.',
    isProductActive: true,
    icon: 'verified_user',
    productColor: 'cobalt',
    promoImg: CDAPromo,
    name: 'CDA®',
    startingPrice: 90
  },
  PRICE_OPINION: {
    title: 'Broker Price Opinion (BPO)',
    subtitle: '',
    landingDescription: '',
    popDescription:
      'Morningstar’s top-rated BPO used as an appraisal alternative for residential servicing, origination, and investments.',
    isProductActive: true,
    icon: '',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: BPOPromo,
    name: 'BPO',
    startingPrice: 105
  },
  INSPECTION: {
    title: 'Property Inspections',
    subtitle: '',
    landingDescription: '',
    popDescription:
      'Includes Property Condition Inspections and Post Disaster Inspections on residential and commercial properties.',
    isProductActive: true,
    icon: '',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: PCIPromo,
    name: 'Property Inspections',
    startingPrice: 45
  },
  AURA: {
    title: 'AURA®',
    subtitle: '',
    landingDescription: '',
    popDescription:
      'An automated appraisal underwriting risk analyzer providing a summary report in 5 minutes or less.',
    isProductActive: true,
    icon: '',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: AURAPromo,
    name: 'AURA®',
    startingPrice: 8
  },
  EVALUATION: {
    title: 'Residential Evaluation',
    subtitle: '',
    landingDescription: '',
    popDescription:
      'IAEG-compliant evaluation for origination loans on transactions less than $400,000 — including HELOC.',
    isProductActive: true,
    icon: '',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: ResidentialEvaluationPromo,
    name: 'Residential Evaluation',
    startingPrice: 125
  }
}

export const productDataDictionaryHomePage: {[key in ProductGroupType]: ProductData} = {
  ...productGroupDictionary,
  CLEARPROP: {
    ...productGroupDictionary.CLEARPROP,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearprop/?utm_source=portal&utm_medium=homepage&utm_content=cp_learn_more'
  },
  CDA: {
    ...productGroupDictionary.CDA,
    learnMoreLink:
      'https://www.clearcapital.com/products/collateral-desktop-analysis-cda/?utm_source=portal&utm_medium=homepage&utm_content=cda_learn_more'
  },
  AURA: {
    ...productGroupDictionary.AURA,
    learnMoreLink:
      'https://www.clearcapital.com/products/aura/?utm_source=portal&utm_medium=homepage&utm_content=aura_learn_more'
  },
  AVM: {
    ...productGroupDictionary.AVM,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearavm/?utm_source=portal&utm_medium=homepage&utm_content=clearavm_learn_more'
  },
  PRICE_OPINION: {
    ...productGroupDictionary.PRICE_OPINION,
    learnMoreLink:
      'https://www.clearcapital.com/products/broker-price-opinion/?utm_source=portal&utm_medium=homepage&utm_content=bpo_learn_more'
  },
  EVALUATION: {
    ...productGroupDictionary.EVALUATION,
    learnMoreLink:
      'https://www.clearcapital.com/products/residential-evaluation/?utm_source=portal&utm_medium=homepage&utm_content=resi_eval_learn_more'
  },
  INSPECTION: {
    ...productGroupDictionary.INSPECTION,
    learnMoreLink:
      'https://www.clearcapital.com/products/property-condition-inspection-pci/?utm_source=portal&utm_medium=homepage&utm_content=pci_learn_more'
  }
}

export const productDataDictionaryNonHomePage: {[K in ProductGroupType]: ProductData} = {
  ...productGroupDictionary,
  CLEARPROP: {
    ...productGroupDictionary.CLEARPROP,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearprop/?utm_source=portal&utm_medium=property_page&utm_content=cp_learn_more'
  },
  CDA: {
    ...productGroupDictionary.CDA,
    learnMoreLink:
      'https://www.clearcapital.com/products/collateral-desktop-analysis-cda/?utm_source=portal&utm_medium=property_page&utm_content=cda_learn_more'
  },
  AVM: {
    ...productGroupDictionary.AVM,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearavm/?utm_source=portal&utm_medium=property_page&utm_content=clearavm_learn_more'
  },
  PRICE_OPINION: {
    ...productGroupDictionary.PRICE_OPINION,
    learnMoreLink:
      'https://www.clearcapital.com/products/broker-price-opinion/?utm_source=portal&utm_medium=property_page&utm_content=bpo_learn_more'
  },
  EVALUATION: {
    ...productGroupDictionary.EVALUATION,
    learnMoreLink:
      'https://www.clearcapital.com/products/residential-evaluation/?utm_source=portal&utm_medium=property_page&utm_content=resi_eval_learn_more'
  },
  INSPECTION: {
    ...productGroupDictionary.INSPECTION,
    learnMoreLink:
      'https://www.clearcapital.com/products/property-condition-inspection-pci/?utm_source=portal&utm_medium=property_page&utm_content=pci_learn_more'
  },
  AURA: {
    ...productGroupDictionary.AURA,
    learnMoreLink:
      'https://www.clearcapital.com/products/aura/?utm_source=portal&utm_medium=property_page&utm_content=aura_learn_more'
  }
}

export const productGroupArray = Object.keys(productGroupDictionary) as ProductGroupType[]

export const PRODUCT_UNAVAILABLE_MESSAGE =
  'This product is currently unavailable for the address provided.  Review the address for errors or contact support@clearcapital.com for assistance.'

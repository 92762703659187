// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'

// Components, services, etc
import Button from 'components/Button'
import CodeValidationInput from 'components/CodeValidationInput'
import {EMAIL} from 'constants/confirmCodeConstants'

// 3rd-party
import {Field, FieldInputProps} from 'react-final-form'
import {CodeValidationInputProps} from 'components/CodeValidationInput/CodeValidationInput'

export type ConfirmCodeModalContentProps = {
  codeSentTo: number | string
  codeSentToType: typeof EMAIL
  handleResendCode: () => void
  handleValidationCodeComplete: CodeValidationInputProps['onComplete']
  isValidationCodeValid: boolean | null
  isCodeValidating?: boolean
}

const ConfirmCodeModalContent = ({
  codeSentTo,
  codeSentToType,
  handleResendCode,
  handleValidationCodeComplete,
  isValidationCodeValid,
  isCodeValidating
}: ConfirmCodeModalContentProps) => {
  const handleCodeValidationInputChange: (
    input: FieldInputProps<any, HTMLInputElement>
  ) => CodeValidationInputProps['onChange'] = input => se => {
    const {value} = se.target
    input.onChange(value)
  }

  const isError = isValidationCodeValid !== null && !isValidationCodeValid

  return (
    <ConfirmCodeModalContent.Styled className='confirmation-content'>
      <p className='confirmation-message'>
        We sent the verification code to you at &nbsp;
        <b>{codeSentTo}</b>
      </p>
      <Field name='validationCode'>
        {({input, meta}) => {
          return (
            <CodeValidationInput
              {...input}
              type={input.type as 'number' | 'text'}
              onChange={handleCodeValidationInputChange(input)}
              onComplete={handleValidationCodeComplete}
              error={isError}
              helperText={meta.touched && isError && 'Verification code incorrect or expired'}
              isCodeValidating={isCodeValidating}
            />
          )
        }}
      </Field>
      <div className='resend-wrap'>
        <span className='secondary'>Didn't receive the code?</span>
        <Button
          className='resend-code-button'
          variant='tertiary'
          title='Resend'
          onClick={handleResendCode}
        />
      </div>
    </ConfirmCodeModalContent.Styled>
  )
}

ConfirmCodeModalContent.Styled = styled.div`
  &.confirmation-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .confirmation-message {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: ${({theme}) => theme.colors.grayscale.black};
      margin-top: 0;
      margin-bottom: 16px;
    }

    .resend-wrap {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;

      .secondary {
        color: ${({theme}) => theme.colors.grayscale.gray};
      }

      .resend-code-button {
        align-self: flex-start;
      }
    }
  }
`

export default ConfirmCodeModalContent

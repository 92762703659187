// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import {useSelector} from 'react-redux'

// Components, services, constants
import IconButton from 'components/IconButton'
import {lastEvaluatedSelector} from 'selectors'

// 3rd party
import classnames from 'classnames'

export type TablePaginationDirection = 'forward' | 'back'
export type TablePaginationProps = {
  className?: string
  onChange: (direction: TablePaginationDirection) => React.MouseEventHandler<HTMLButtonElement>
  currentPage: number
  newPageLoading: boolean
}

const TablePagination = ({
  className,
  currentPage,
  onChange,
  newPageLoading
}: TablePaginationProps) => {
  const lastEvaluated = useSelector(lastEvaluatedSelector)
  // The last page of results will not have a 'LastEvaluatedKey' key/value pair
  const forwardDisabled = newPageLoading || !lastEvaluated
  const backDisabled = newPageLoading || currentPage === 1
  return (
    <TablePagination.Styled className={classnames('table-pagination', className)}>
      <IconButton icon='keyboard_arrow_left' onClick={onChange('back')} disabled={backDisabled} />
      <IconButton
        icon='keyboard_arrow_right'
        onClick={onChange('forward')}
        disabled={forwardDisabled}
      />
    </TablePagination.Styled>
  )
}

TablePagination.Styled = styled.div`
  display: flex;
  align-items: center;

  .icon-button + .icon-button {
    margin-left: 8px;
  }
`

export default TablePagination

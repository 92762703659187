import {modalsMap} from 'components/ModalsSystem/ModalsSystem'

type ModalType = keyof typeof modalsMap

export const getMixpanelModalValue = (modalType: ModalType) => {
  const modalTypeDictionary: Partial<Record<ModalType, string>> = {
    ON_HOLD_MODAL: 'Place On Hold Modal',
    REQUEST_REVISION_MODAL: 'Revision Request Modal',
    ORDER_FORM_MODAL: 'Order Form Modal',
    DOCUMENT_UPLOAD_MODAL: 'Upload Document Modal'
  }

  return modalTypeDictionary[modalType]
}

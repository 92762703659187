// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// Components, services, etc
import {openModal} from 'actions/modalActions'
import Button from 'components/Button'
import UserMenu from 'components/UserMenu'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import {isUserManagerSelector, isUserAuthenticatedSelector} from 'selectors'
import type {AppDispatch} from 'services/store'
import NavLinkWithRef from 'components/NavLinkWithRef'
import {RoutingTabs} from 'components/Tabs/RoutingTabs'
import ClearCapitalLogo from 'assets/logo-small.svg'
import LinkRouter from 'components/LinkRouter'
import {SupportIcon} from 'components/SupportIcon/SupportIcon'
import {Divider} from '@material-ui/core'
import {LogInModeType} from 'types/loginSignUpModalTypes'

type Props = {
  className?: string
}

type LoggedOutProps = {
  dispatch: AppDispatch
}

export const tabsData = [
  {
    component: NavLinkWithRef,
    label: 'Products',
    value: '/products',
    to: '/products',
    sqaPrefix: 'products'
  },
  {
    component: NavLinkWithRef,
    label: 'Orders',
    value: '/orders',
    to: '/orders',
    sqaPrefix: 'orders'
  },
  {
    component: NavLinkWithRef,
    label: 'Admin',
    value: '/admin',
    to: '/admin',
    sqaPrefix: 'admin'
  }
]

const AppHeader = ({className}: Props) => {
  const dispatch = useDispatch()
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const isUserManager = useSelector(isUserManagerSelector)
  const [tabs, setTabs] = useState<typeof tabsData>([])

  useEffect(() => {
    if (!isUserManager) {
      setTabs(tabsData.filter(({label}) => label !== 'Admin'))
    } else {
      setTabs(tabsData)
    }
  }, [isUserManager])

  return (
    <AppHeader.Styled className={className}>
      <LinkRouter color='inherit' to='/'>
        <img className='logo' src={ClearCapitalLogo} alt='Clear Capital Logo' />
      </LinkRouter>
      {isUserAuthenticated ? <RoutingTabs navTabs={tabs} /> : null}
      <div className='header-right'>
        <SupportIcon classname={!isUserAuthenticated ? 'support-margin' : null} />
        {!isUserAuthenticated ? <Divider orientation='vertical' /> : null}
        {isUserAuthenticated ? <UserMenu /> : <AppHeader.LoggedOut dispatch={dispatch} />}
      </div>
    </AppHeader.Styled>
  )
}

AppHeader.LoggedOut = ({dispatch}: LoggedOutProps) => {
  const handleOpenModal = (mode: LogInModeType) => () => {
    dispatch(
      openModal({
        modalType: LOG_IN_SIGN_UP_MODAL,
        mode,
        mixpanelEventData: {
          initiatedFrom: 'App Header'
        }
      })
    )
  }

  return (
    <Fragment>
      <Button variant='tertiary' title='Log In' onClick={handleOpenModal('logIn')} />
      <Button variant='primary' title='Sign Up' onClick={handleOpenModal('signUp')} />
    </Fragment>
  )
}

AppHeader.Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  z-index: 3;
  background: ${({theme}) => theme.colors.grayscale.white};
  height: 60px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.04);
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  .logo {
    padding-top: 6px;
  }

  .portal-MuiTabs-root {
    height: 100%;
    .portal-MuiTabs-flexContainer {
      margin-top: 10px;
    }

    .portal-MuiTabs-indicator {
      height: 4px;
    }

    .portal-MuiTab-wrapper {
      width: 120px;
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tertiary-button {
      color: ${({theme}) => theme.colors.grayscale.black};
    }

    .icon-button {
      height: 24px;
      width: 24px;
    }

    .portal-MuiDivider-root {
      margin: auto;
      height: 24px;
    }

    .support-margin {
      margin-right: 34px;
    }

    button {
      margin-left: 24px;
    }
  }
`

export default AppHeader
